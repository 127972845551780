import api from "../utils/api";
import { SCHOOL_URL } from "../utils/endpoints";

export async function getSchoolByNatures(naturesID) {
  try {
    const response = await api.get(SCHOOL_URL, {
      params: {
        SchoolNatureId: naturesID,
      },
    });
    const data = response.data.result.map((option) => ({
      value: option.id,
      label: option.name,
    }));
    return data;
  } catch (error) {
    throw error;
  }
}
