import { useContext, useEffect, useState } from "react";
import Form from "./Form";
import { useForm } from "react-hook-form";
import { schema } from "./Form/schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate, useParams } from "react-router-dom";
import { Toast } from "../../components/Toast";
import api from "../../utils/api";
import { EDIT_STUDENT_URL, STUDENT_URL } from "../../utils/endpoints";
import { CrumbContext } from "../../provider/CrumbProvider";
import { AuthContext } from "../../provider/AuthProvider";

function EditStudent() {
  const [isDisabled, setIsDisabled] = useState(true);
  const [studentData, setStudentData] = useState([]);
  const [schoolBondRequests, setSchoolBondRequests] = useState([]);
  const [schoolBondRequestsData, setSchoolBondRequestsData] = useState([]);
  const [isActive, setIsActive] = useState();
  const navigate = useNavigate();
  const { addToast } = Toast();
  const { id, studentid } = useParams();
  const { crumb, setCrumb } = useContext(CrumbContext);
  const { auth } = useContext(AuthContext);
  const user = auth.user;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
    unregister,
  } = useForm({
    resolver: zodResolver(schema),
  });

  const populateStudendData = (data) => {
    setValue("typeDocument", data.typeDocument.id.toString());
    setValue("typeDocumentName", data.typeDocument.name);
    setValue("documentNumber", data.documentNumber);
    setValue("name", data.name);
    setValue("gender", data.gender.id.toString());
    setValue("nationality", {
      value: data.nationality.id,
      label: data.nationality.name,
    });
    setValue("birthDate", new Date(data.birthDate));
    setValue("educationSponsorName", data.educationSponsorName);
    setValue("educationSponsorPhone", data.educationSponsorPhone);
    setValue("specialEducationNeed", data.specialEducationNeed.id.toString());
    setValue(
      "specialEducationNeedType",
      data.specialEducationNeedType
        ? {
            value: data.specialEducationNeedType.id,
            label: data.specialEducationNeedType.name,
          }
        : null
    );
    setSchoolBondRequestsData(data.schoolBonds);
  };

  useEffect(
    () => {
      if (id) {
        api
          .get(`${STUDENT_URL}/${studentid ? studentid : id}`)
          .then((response) => {
            const data = response.data;
            setStudentData(data);
            setSchoolBondRequests(
              data.schoolBonds.map((i) => ({
                academicYearBondedId: i.academicYearBonded?.id,
                schoolBondedId: i.schoolBonded?.id,
                courseBondedId: i.courseBonded?.id,
                courseTechnicalBondedId: i.courseTechnicalBonded?.id,
                courseEducationalSubsystemBondedId:
                  i.courseEducationalSubsystemBonded?.id,
                courseEducationalCycleBondedId:
                  i.courseEducationalCycleBonded?.id,
                studentEnrollmentStatusBondedId:
                  i.studentEnrollmentStatusBonded?.id,
                classBondedId: i.classBonded?.id,
                classModalityBondedId: i.classModalityBonded?.id,
                classShiftBondedId: i.classShiftBonded?.id,
                classLetterBondedId: i.classLetterBonded?.id,
                schoolBondResultBondedId: i.schoolBondResultBonded?.id,
                courseCompletionAverage: i.courseCompletionAverage,
                hasLegalEquivalence: i.hasLegalEquivalence,
                hasLegalProof: i.hasLegalProof,
                classFullName: i.classFullName,
              }))
            );
            setSchoolBondRequestsData(data.schoolBonds);
            setIsActive(data.isActive);
            populateStudendData(data);
            setCrumb({
              ...crumb,
              student: response.data.name,
            });
          });
      }
    },
    // eslint-disable-next-line
    [id]
  );

  const editStudent = () => {
    const data = getValues();
    api
      .patch(EDIT_STUDENT_URL, {
        studentId: studentid ? studentid : id,
        name: data.name,
        typeDocument: data.typeDocument,
        documentNumber: data.documentNumber,
        birthDate: data.birthDate,
        gender: data.gender,
        educationSponsorName: data.educationSponsorName,
        educationSponsorPhone: data.educationSponsorPhone,
        nationality: data.nationality.value,
        specialEducationNeed: data.specialEducationNeed,
        specialEducationNeedType: data.specialEducationNeedType
          ? data.specialEducationNeedType.value
          : null,
        createSchoolBondRequests: schoolBondRequests,
      })
      .then(() => {
        addToast({
          title: "Aluno actualizado com sucesso",
          status: "success",
        });
        if (!user?.schoolId) {
          navigate("/alunos");
        } else {
          navigate(`/gerir/${user?.schoolId}/alunos`);
        }
      })
      .catch((error) => {
        let errors = error.response.data.errors;
        for (let err in errors) {
          addToast({ title: errors[err], status: "error" });
        }
      });
  };

  const cancelEdit = () => {
    populateStudendData(studentData);
    setIsDisabled(true);
  };

  return (
    <Form
      isDisabled={isDisabled}
      setIsDisabled={setIsDisabled}
      id={id}
      register={register}
      handleSubmit={handleSubmit}
      errors={errors}
      control={control}
      watch={watch}
      setValue={setValue}
      unregister={unregister}
      editStudent={editStudent}
      schoolBondRequests={schoolBondRequests}
      setSchoolBondRequests={setSchoolBondRequests}
      schoolBondRequestsData={schoolBondRequestsData}
      setSchoolBondRequestsData={setSchoolBondRequestsData}
      isActive={isActive}
      cancelEdit={cancelEdit}
    />
  );
}

export default EditStudent;
