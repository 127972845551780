import api from "../utils/api";
import { ACADEMIC_YEAR_URL } from "../utils/endpoints";

export async function getAcademicYearById(id) {
  try {
    const response = await api.get(`${ACADEMIC_YEAR_URL}/${id}`);
    const data = {
      id: response.data.id,
      year: response.data.year,
      open: response.data.open,
      closeable: response.data.closeable,
      isActive: response.data.isActive,
      yearFormat: response.data.yearFormatId,
      regex: response.data.regex,
    };
    return data;
  } catch (error) {
    throw error;
  }
}
