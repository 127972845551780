import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  Grid,
  GridItem,
  Text,
} from "@chakra-ui/react";
import Label from "../../../components/Label";
import Select from "react-select";
import { borderColor } from "../../../utils/colors";
import { selectStyles } from "../../../utils/styles";
import { Controller } from "react-hook-form";
import { useState, useEffect } from "react";
import { Toast } from "../../../components/Toast";
import { NO_OPTIONS_MESSAGE } from "../../../utils/constants";
import { GET_AUDIT_TYPOLOGIES } from "../../../utils/endpoints";
import api from "../../../utils/api";
import CustomDatePicker from "../../../components/CustomDatePicker";

function Filters({ control, setValue, onSubmit, isForAdmin = false }) {
  const [auditTypologies, setAuditTypologies] = useState([]);
  const { addToast } = Toast();

  async function getAuditTypologies() {
    const response = await api.get(
      `${GET_AUDIT_TYPOLOGIES}?TypologiesForAdmin=${isForAdmin}`
    );
    const data = Object.entries(response.data.typologies).map(
      ([value, label]) => ({
        value,
        label,
      })
    );
    return data;
  }

  useEffect(
    () => {
      getAuditTypologies()
        .then((res) => setAuditTypologies(res))
        .catch((error) => {
          addToast({ title: error.message, status: "error" });
        });
    },
    // eslint-disable-next-line
    []
  );

  const clearFilters = () => {
    setValue("typology", undefined);
    setValue("from", undefined);
    setValue("until", undefined);
    onSubmit();
  };

  return (
    <>
      <Box position="relative" py={8}>
        <Divider borderColor={borderColor} />
        <Box
          bg="white"
          px="2"
          position="absolute"
          transform="translate(5%, -50%)"
        >
          <Text as="b" fontSize="sm" textTransform="uppercase">
            Filtrar lista de acções
          </Text>
        </Box>
      </Box>
      <Box px="20px">
        <Grid gridTemplateColumns="1fr auto" gap={4}>
          <Grid templateColumns="repeat(3, 1fr)" gap={4}>
            <GridItem>
              <FormControl>
                <Label title="Tipologia" />
                <Controller
                  control={control}
                  name="typology"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      placeholder="Seleccionar"
                      options={auditTypologies}
                      onChange={onChange}
                      value={value || ""}
                      styles={selectStyles}
                      isClearable={true}
                      noOptionsMessage={NO_OPTIONS_MESSAGE}
                    />
                  )}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <Label title="De" />
                <Controller
                  control={control}
                  name="from"
                  render={({ field }) => (
                    <CustomDatePicker
                      selected={field.value}
                      placeholder="Seleccionar"
                      onChange={(date) => field.onChange(date)}
                    />
                  )}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <Label title="Até" />
                <Controller
                  control={control}
                  name="until"
                  render={({ field }) => (
                    <CustomDatePicker
                      selected={field.value}
                      placeholder="Seleccionar"
                      onChange={(date) => field.onChange(date)}
                    />
                  )}
                />
              </FormControl>
            </GridItem>
          </Grid>
          <Flex alignSelf="end">
            <Button mr={4} onClick={onSubmit}>
              Filtrar
            </Button>
            <Button variant="link" onClick={clearFilters}>
              Limpar filtros
            </Button>
          </Flex>
        </Grid>
      </Box>
    </>
  );
}

export default Filters;
