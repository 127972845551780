import api from "../utils/api";
import { ROLE_URL } from "../utils/endpoints";

export async function getRole() {
  try {
    const response = await api.get(ROLE_URL);
    const data = response.data.result.map((option) => ({
      value: option.id,
      label: option.name,
      level: option.level.id,
    }));
    return data;
  } catch (error) {
    throw error;
  }
}
