import Label from "../../../components/Label";
import { Button, Input, Box, Grid, Flex } from "@chakra-ui/react";
import { handleEnterOnSearch } from "../../../utils/constants";

export default function SearchArea({
  inputSearch,
  register,
  setInputSearch,
  setDataTable,
  courseData,
  getSchools,
  id,
  academicYear,
}) {
  const handleSearch = async () => {
    if (inputSearch) {
      setDataTable(
        courseData.courseForAcademicYears.filter((i) => {
          const lowerCaseName =
            i.academicYearForSchool.school.name.toLowerCase();
          const lowerCaseCode =
            i.academicYearForSchool.school.code.toLowerCase();
          const lowerCaseSearch = inputSearch.toLowerCase();

          return (
            lowerCaseName.includes(lowerCaseSearch) ||
            lowerCaseCode.includes(lowerCaseSearch)
          );
        })
      );
    }
  };

  const handleCleanFilters = async () => {
    if (inputSearch) {
      setInputSearch("search", undefined);
      getSchools();
    }
  };

  return (
    <>
      <Box px="20px">
        <Label title="Pesquisar pela designação ou código da escola" />

        <Grid gridTemplateColumns="1fr auto">
          <Flex mr={4}>
            <Input
              {...register("search")}
              sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              onKeyDown={(e) => {
                handleEnterOnSearch(e, handleSearch);
              }}
            />

            <Button
              onClick={handleSearch}
              sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            >
              Pesquisar
            </Button>
          </Flex>

          <Flex>
            <Button variant="link" onClick={handleCleanFilters}>
              Limpar consulta
            </Button>
          </Flex>
        </Grid>
      </Box>
    </>
  );
}
