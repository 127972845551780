import {
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Stack,
  Textarea,
  Box,
  Heading,
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import Label from "../../../components/Label";
import TableSubjectResume from "./TableSubject";

export default function FormTabInfoResume({
  errors,
  control,
  watch,
  dataTable,
}) {
  const educationLevelRead = watch("educationLevel");
  const subsystemEducationRead = watch("educationSubsystem");
  const generalAreaRead = watch("generalArea");
  const specificAreaRead = watch("specificArea");
  const detailedAreaRead = watch("detailedArea");
  const domainPNFQRead = watch("domainPNFQ");
  const technicalEducationRead = watch("technicalEducation");
  const name = watch("name");
  const cicleEducationRead = watch("cicleEducation");
  const className = watch("className");

  return (
    <>
      <Stack spacing={5} mb={5}>
        <FormControl isInvalid={!!errors.name}>
          <Label title="Designação do Curso" isRequired={true} />
          <Textarea value={name} id="name" isDisabled />
          <FormErrorMessage>
            {errors.name && errors.name.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.className}>
          <Label title="Iniciais do Curso para efeitos de designação das Turmas" isRequired={true} />
          <Input value={className} isDisabled />
          <FormErrorMessage>
            {errors.className && errors.className.message}
          </FormErrorMessage>
        </FormControl>

        <HStack width="50%">
          <FormControl isInvalid={!!errors.educationLevel}>
            <Label title="Nivel de Ensino" isRequired={true} />
            <Controller
              control={control}
              name="educationLevel"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={educationLevelRead}
                  id="educationLevel"
                  placeholder="Selecionar"
                  isSearchable={true}
                  isDisabled
                  onChange={onChange}
                />
              )}
            />

            <FormErrorMessage>
              {errors.educationLevel && <>Campo obrigatório</>}
            </FormErrorMessage>
          </FormControl>
        </HStack>

        <HStack width="50%">
          <FormControl isInvalid={!!errors.subsystemEducation}>
            <Label title="Subsistema de Ensino" isRequired={true} />
            <Controller
              control={control}
              name="educationSubsystem"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={subsystemEducationRead}
                  id="educationSubsystem"
                  placeholder="Selecionar"
                  isSearchable={false}
                  isDisabled={true}
                  onChange={onChange}
                />
              )}
            />

            <FormErrorMessage>
              {errors.subsystemEducation && <>Campo obrigatório</>}
            </FormErrorMessage>
          </FormControl>
        </HStack>

        <HStack width="50%">
          <FormControl isInvalid={!!errors.cicleEducation}>
            <Label title="Ciclo de Ensino" isRequired={true} />
            <Controller
              control={control}
              name="cicleEducation"
              render={({ field: { onChange, value } }) => (
                <Select
                  id="cicleEducation"
                  placeholder="Seleccionar"
                  value={cicleEducationRead}
                  isSearchable={false}
                  isDisabled={true}
                  onChange={onChange}
                />
              )}
            />
            <FormErrorMessage>
              {errors.cicleEducation && <>Campo obrigatório</>}
            </FormErrorMessage>
          </FormControl>
        </HStack>

        <Heading size="sm" as="h5">
          Classificação CITE (ISCED) 2013
        </Heading>
        <HStack>
          <FormControl isInvalid={!!errors.generalArea}>
            <Label title="Área Geral CITE (ISCED) 2013" />
            <Controller
              control={control}
              name="generalArea"
              render={({ field: { onChange, value } }) => (
                <Select
                  id="generalArea"
                  value={generalAreaRead}
                  placeholder="Seleccionar"
                  isSearchable={false}
                  onChange={onChange}
                  isDisabled={true}
                />
              )}
            />

            <FormErrorMessage>
              {errors.generalArea && <>Campo obrigatório</>}
            </FormErrorMessage>
          </FormControl>

          <FormControl>
            <Label title="Código" />
            <Input
              value={generalAreaRead?.code}
              disabled
              placeholder="Código"
              size="md"
            />
          </FormControl>
        </HStack>

        <HStack ms={5}>
          <FormControl isInvalid={!!errors.specificArea}>
            <Label
              title="Área Específica CITE (ISCED) 2013"
              isRequired={false}
            />
            <Controller
              control={control}
              name="specificArea"
              render={({ field: { onChange, value } }) => (
                <Select
                  id="specificArea"
                  value={specificAreaRead}
                  placeholder="Seleccionar"
                  isSearchable={false}
                  onChange={onChange}
                  isDisabled
                />
              )}
            />

            <FormErrorMessage>
              {errors.specificArea && <>Campo obrigatório</>}
            </FormErrorMessage>
          </FormControl>

          <FormControl>
            <Label title="Código" />
            <Input
              value={specificAreaRead?.code}
              disabled
              placeholder="Código"
              size="md"
            />
          </FormControl>
        </HStack>

        <HStack ms={10}>
          <FormControl isInvalid={!!errors.detailedArea}>
            <Label
              title="Área Detalhada CITE (ISCED) 2013"
              isRequired={false}
            />
            <Controller
              control={control}
              name="detailedArea"
              render={({ field: { onChange, value } }) => (
                <Select
                  id="detailedArea"
                  value={detailedAreaRead}
                  placeholder="Selecionar"
                  isSearchable={false}
                  onChange={onChange}
                  isDisabled
                />
              )}
            />

            <FormErrorMessage>
              {errors.detailedArea && <>Campo obrigatório</>}
            </FormErrorMessage>
          </FormControl>

          <FormControl>
            <Label title="Código" />
            <Input
              value={detailedAreaRead?.code}
              disabled
              placeholder="Código"
              size="md"
            />
          </FormControl>
        </HStack>

        <HStack>
          <FormControl isInvalid={!!errors.technicalEducation}>
            <Label title="Área de Formação do INFQE" isRequired={true} />
            <Controller
              control={control}
              name="technicalEducation"
              render={({ field: { onChange, value } }) => (
                <Select
                  id="technicalEducation"
                  placeholder="Seleccionar"
                  value={technicalEducationRead}
                  isSearchable={false}
                  onChange={onChange}
                  isDisabled
                />
              )}
            />

            <FormErrorMessage>
              {errors.technicalEducation && <>Campo obrigatório</>}
            </FormErrorMessage>
          </FormControl>

          <FormControl>
            <Label title="Código" />
            <Input
              value={technicalEducationRead?.code}
              disabled
              placeholder="Código"
              size="md"
            />
          </FormControl>
        </HStack>

        <HStack>
          <FormControl isInvalid={!!errors.domainPNFQ}>
            <Label title="Domínio PNFQ " />
            <Controller
              control={control}
              name="domainPNFQ"
              render={({ field: { onChange, value } }) => (
                <Select
                  id="domainPNFQ"
                  placeholder="Seleccionar"
                  value={domainPNFQRead}
                  isSearchable={false}
                  onChange={onChange}
                  isDisabled
                />
              )}
            />

            <FormErrorMessage>
              {errors.domainPNFQ && <>Campo obrigatório</>}
            </FormErrorMessage>
          </FormControl>

          <FormControl>
            <Label title="Código" />
            <Input
              value={domainPNFQRead?.code}
              disabled
              placeholder="Código"
              size="md"
            />
          </FormControl>
        </HStack>

        <Box>
          <Heading size="md" mb={2}>
            Disciplinas
          </Heading>
          <TableSubjectResume dataTable={dataTable} />
        </Box>
      </Stack>
    </>
  );
}
