import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { AUDIT_SUMMARY } from "../../utils/constants";
import Default from "./Default";
import Admin from "./Admin";
import { Link } from "react-router-dom";
import { MdArrowOutward } from "react-icons/md";

function Audit() {
  return (
    <Stack spacing={5}>
      <Box>
        <Heading as="h1" mb="10px">
          Auditoria
        </Heading>

        <Box w="lg">
          <Text>{AUDIT_SUMMARY}</Text>
        </Box>
      </Box>

      <Tabs>
        <TabList>
          <Tab>Geral</Tab>
          <Tab>Administração</Tab>
        </TabList>

        <TabPanels>
          <TabPanel p={0} pt="20px">
            <Default />
          </TabPanel>
          <TabPanel p={0} pt="20px">
            <Admin />
          </TabPanel>
        </TabPanels>

        <Flex justifyContent="end" mt={3}>
          <Link to="/docs/manual-administracao-sietp.pdf" target="_blank">
            <Button variant="secondary" rightIcon={<MdArrowOutward />}>
              Consultar Manual de Apoio
            </Button>
          </Link>
        </Flex>
      </Tabs>
    </Stack>
  );
}

export default Audit;
