import {
  Button,
  Box,
  Flex,
  Divider,
  Text,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import SelectLabel from "../SelectLabel";
import { borderColor } from "../../utils/colors";

export default function FilterArea({
  control,
  handleSearch,
  handleCleanFilters,
  dataSelect,
  title,
  gridNumberSelect,
}) {
  return (
    <>
      <Box position="relative" py={8}>
        <Divider borderColor={borderColor} />

        <Box
          bg="white"
          px="2"
          position="absolute"
          transform="translate(5%, -50%)"
        >
          <Text as="b" fontSize="sm" textTransform="uppercase">
            {title}
          </Text>
        </Box>
      </Box>

      <Box px="20px">
        <Grid
          templateColumns={`repeat(${gridNumberSelect}, 1fr)`}
          gap={4}
          alignItems="end"
        >
          {dataSelect.map((e) => (
            <GridItem>
              <SelectLabel
                control={control}
                fieldName={e.fieldObjectName}
                title={e.fieldName}
                data={e.data}
                parent={{ value: 1 }}
                isDisabled={e?.isDisabled}
              />
            </GridItem>
          ))}
        </Grid>

        <Flex justifyContent="flex-end" mt={5}>
          <Button onClick={handleSearch} mr={4}>
            Filtrar
          </Button>
          <Button onClick={handleCleanFilters} variant="link">
            Limpar filtros
          </Button>
        </Flex>
      </Box>
    </>
  );
}
