import { Flex, Image, Heading, Spacer, Box } from "@chakra-ui/react";
import {
  MdHome,
  MdPerson,
  MdWork,
  MdSchool,
  MdMenuBook,
  MdModeEdit,
  MdHolidayVillage,
  MdCalendarMonth,
} from "react-icons/md";
import MenuItem from "./MenuItem";
import Logout from "../Logout";
import { AuthContext } from "../../provider/AuthProvider";
import { useContext } from "react";
import { borderRadius } from "../../utils/styles";

function SchoolMenu({ schoolId, isValidated }) {
  const { auth } = useContext(AuthContext);
  const user = auth.user;

  return (
    <Flex flexDirection="column" w="100%">
      <Flex justifyContent="center" mb={5}>
        <Image
          src="/images/logo.png"
          alt="Sistema de Informação para o Ensino Técnico-Profissional"
        />
      </Flex>
      <MenuItem
        title="Início"
        link={`/gerir/${schoolId}`}
        icon={<MdHome color="#000" />}
      />

      {isValidated && (
        <>
          <Heading
            fontSize="14px"
            textTransform="uppercase"
            ms="10px"
            mt={5}
            mb={1}
          >
            Menu
          </Heading>

          <MenuItem
            title="Escola"
            link={`/gerir/${schoolId}/resumo`}
            icon={<MdHolidayVillage color="#000" />}
          />

          {user.permissions.includes("GetMySchool") && (
            <MenuItem
              title="Cursos"
              link={`/gerir/${schoolId}/cursos`}
              icon={<MdMenuBook color="#000" />}
            />
          )}

          {user.permissions.includes("GetTeachers") && (
            <MenuItem
              title="Docentes"
              link={`/gerir/${schoolId}/docentes`}
              icon={<MdWork color="#000" />}
            />
          )}
          {user.permissions.includes("GetStudents") && (
            <MenuItem
              title="Alunos"
              link={`/gerir/${schoolId}/alunos`}
              icon={<MdSchool color="#000" />}
            />
          )}

          {user.permissions.includes("GetUsersMySchool") && (
            <MenuItem
              title="Utilizadores"
              link={`/gerir/${schoolId}/utilizadores`}
              icon={<MdPerson color="#000" />}
            />
          )}

          {user.permissions.includes("GetAcademicYearsMySchool") && (
            <MenuItem
              title="Anos Lectivos"
              link={`/gerir/${schoolId}/anos-lectivos`}
              icon={<MdCalendarMonth color="#000" />}
            />
          )}
        </>
      )}

      <Heading
        fontSize="14px"
        textTransform="uppercase"
        ms="10px"
        mt={5}
        mb={1}
      >
        Utilizador
      </Heading>

      <MenuItem
        title="Editar conta"
        link="/editar-conta"
        icon={<MdModeEdit color="#000" />}
      />
      <Logout />
      <Spacer />
      <Flex justifyContent="center">
        <Box bg="#fff" w="80%" padding="4px 8px" borderRadius={borderRadius}>
          <Image src="/images/med.svg" alt="Ministério da Educação de Angola" />
        </Box>
      </Flex>
    </Flex>
  );
}

export default SchoolMenu;
