import { Box, Card, CardBody, Flex, Heading, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@chakra-ui/icons";

import { borderRadius } from "../../utils/styles";
import {
  borderColor,
  primaryColor,
  primaryColorDisabled,
} from "../../utils/colors";

function CardItem({ title, link, icon, text, id }) {
  return (
    <Link to={link}>
      <Card borderColor={borderColor} borderRadius={borderRadius} h="100%">
        <CardBody>
          <Box
            bgColor={primaryColorDisabled}
            display="inline-block"
            p={2}
            borderRadius={borderRadius}
            mb={2}
          >
            {icon}
          </Box>
          <Flex alignItems="center" mb={2}>
            <Heading fontSize="22px" mr={2}>
              {title}
            </Heading>
            <Box
              w="1.5rem"
              h="1.5rem"
              bgColor={primaryColor}
              borderRadius={borderRadius}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <ChevronRightIcon color="#fff" w="20px" h="auto" />
            </Box>
          </Flex>
          <Text fontSize="14px">{text}</Text>
        </CardBody>
      </Card>
    </Link>
  );
}

export default CardItem;
