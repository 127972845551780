import api from "../utils/api";
import { PAGE_SIZE } from "../utils/constants";
import { SCHOOL_URL } from "../utils/endpoints";

export async function getSchools(id = null) {
  try {
    const response = await api.get(
      !id
        ? `${SCHOOL_URL}?PageSize=${PAGE_SIZE}`
        : `${SCHOOL_URL}?PageSize=${PAGE_SIZE}&${id}`
    );
    const data = response.data.result;
    if (data.length > 0) {
      data[0] = {
        ...data[0],
        totalItems: response.data.totalItems,
      };
    }
    return data;
  } catch (error) {
    throw error;
  }
}
