import {
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Stack,
  Textarea,
  Heading,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getEducationLevel } from "../../../services/getEducationLevel";
import { getSubsystemEducation } from "../../../services/getSubsystemEducation";
import { getCicleEducation } from "../../../services/getCicleEducation";

import { Toast } from "../../../components/Toast";
import { getDetailedArea } from "../../../services/getDetailedArea";
import { getSepecificArea } from "../../../services/getSpecificArea";
import { getGeneralArea } from "../../../services/getGeneralArea";
import { getTechinicalEducation } from "../../../services/getTechinicalEducation";
import { getDomainPNFQ } from "../../../services/getDomainPNFQ";
import Label from "../../../components/Label";
import CustomFormControlSelect from "../../../components/CustomFormControlSelect";

export default function FormTabInfo({
  isDisabled,
  errors,
  register,
  control,
  setValue,
  watch,
  backEdit,
}) {
  const [dataEducationLevel, setDataEducationLevel] = useState([]);
  const [dataSubsystemEducation, setDataSubsystemEducation] = useState([]);
  const [cicleEducationData, setDataCicleEducation] = useState([]);
  const [specificAreaData, setSpecificAreaData] = useState([]);
  const [detailedAreaData, setDetailedAreaData] = useState([]);
  const [generalAreaData, setGeneralAreaData] = useState([]);
  const [domainPNFQData, setDomainPNFQData] = useState([]);
  const [technicalEducationData, setTechnicalEducationData] = useState([]);

  const name = watch("name");
  const educationLevel = watch("educationLevel");
  const educationSubsystem = watch("educationSubsystem");
  const generalArea = watch("generalArea");
  const specificArea = watch("specificArea");
  const detailedArea = watch("detailedArea");
  const domainPNFQ = watch("domainPNFQ");
  const technicalEducation = watch("technicalEducation");
  const cicleEducationRead = watch("cicleEducation");

  const { addToast } = Toast();

  useEffect(() => {
    const getData = async () => {
      const res = await getEducationLevel("OnlyActive=true");
      const resGeneralArea = await getGeneralArea();
      const resTechnicalEducation = await getTechinicalEducation();
      const resDomainPNFQ = await getDomainPNFQ();
      setDataEducationLevel(res);
      setGeneralAreaData(resGeneralArea);
      setTechnicalEducationData(resTechnicalEducation);
      setDomainPNFQData(resDomainPNFQ);
    };

    getData();
  }, []);

  useEffect(() => {
    if (!backEdit) {
      setValue("educationSubsystem", { value: undefined, label: "Selecionar" });
      setValue("cicleEducation", { value: undefined, label: "Selecionar" });
    }
    const getData = async () => {
      const res = await getSubsystemEducation(
        `OnlyActive=true&ParentId=${educationLevel?.value}`
      );
      setDataSubsystemEducation(res);
    };
    if (educationLevel?.value !== undefined) getData();
  }, [educationLevel]);

  useEffect(() => {
    if (!backEdit)
      setValue("cicleEducation", { value: undefined, label: "Selecionar" });
    const getData = async () => {
      const res = await getCicleEducation(educationSubsystem?.value);
      setDataCicleEducation(res);
    };
    getData();
  }, [educationSubsystem]);

  useEffect(() => {
    if (!backEdit) {
      setValue("specificArea", { value: undefined, label: "Selecionar" });
      setValue("detailedArea", { value: undefined, label: "Selecionar" });
    }
    const getData = async () => {
      const res = await getSepecificArea({ ParentId: generalArea?.value });
      setSpecificAreaData(res);
    };
    getData();
  }, [generalArea]);

  useEffect(() => {
    const getData = async () => {
      const res = await getDetailedArea({ ParentId: specificArea?.value });
      setDetailedAreaData(res);
      if (!backEdit) {
        setValue("detailedArea", { value: undefined, label: "Selecionar" });
      }
    };
    getData();
  }, [specificArea]);

  return (
    <>
      <Stack spacing={5}>
        <FormControl isInvalid={!!errors.name}>
          <Label title="Designação do Curso" isRequired={true} />
          <Textarea id="name" {...register("name")} isDisabled={isDisabled} />
          <FormErrorMessage>
            {errors.name && errors.name.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.className}>
          <Label title="Iniciais do Curso para efeitos de designação das Turmas" isRequired={true} />
          <Input {...register("className")} isDisabled={isDisabled} />
          <FormErrorMessage>
            {errors.className && errors.className.message}
          </FormErrorMessage>
        </FormControl>

        <HStack width="50%">
          <CustomFormControlSelect
            errors={errors}
            fieldNameObject="educationLevel"
            isDisabled={isDisabled}
            parent={{ value: 1 }}
            options={dataEducationLevel}
            fieldName="Nivel de Ensino"
            control={control}
            isRequired={true}
          />
        </HStack>
        <HStack width="50%">
          <CustomFormControlSelect
            errors={errors}
            fieldName="Subsistema de Ensino"
            isDisabled={isDisabled}
            options={dataSubsystemEducation}
            parent={educationLevel}
            fieldNameObject="educationSubsystem"
            control={control}
            isRequired={true}
          />
        </HStack>
        <HStack width="50%">
          <CustomFormControlSelect
            errors={errors}
            fieldName="Ciclo de Ensino"
            isDisabled={isDisabled}
            options={cicleEducationData}
            parent={educationSubsystem}
            fieldNameObject="cicleEducation"
            control={control}
            isRequired={true}
          />
        </HStack>
        <Heading size="lg" as="h4">
          Áreas de Formação
        </Heading>
        <Heading size="sm" as="h5">
          Classificação CITE (ISCED) 2013
        </Heading>
        <HStack>
          <CustomFormControlSelect
            errors={errors}
            fieldName="Área Geral CITE (ISCED) 2013"
            isDisabled={isDisabled}
            parent={{ value: 1 }}
            options={generalAreaData}
            fieldNameObject="generalArea"
            control={control}
            isRequired={false}
          />

          <FormControl>
            <Label title="Código" />
            <Input
              value={generalArea?.code ? generalArea?.code : ""}
              disabled
              placeholder="Código"
              size="md"
            />
          </FormControl>
        </HStack>
        <HStack ms={5}>
          <CustomFormControlSelect
            errors={errors}
            parent={generalArea}
            fieldName="Área Específica CITE (ISCED) 2013"
            isDisabled={isDisabled}
            options={specificAreaData}
            fieldNameObject="specificArea"
            control={control}
            isRequired={false}
          />

          <FormControl>
            <Label title="Código" />
            <Input
              value={specificArea?.code ? specificArea?.code : ""}
              disabled
              placeholder="Código"
              size="md"
            />
          </FormControl>
        </HStack>
        <HStack ms={10}>
          <CustomFormControlSelect
            errors={errors}
            parent={specificArea}
            fieldName="Área Detalhada CITE (ISCED) 2013"
            isDisabled={isDisabled}
            options={detailedAreaData}
            fieldNameObject="detailedArea"
            control={control}
            isRequired={false}
          />

          <FormControl>
            <Label title="Código" />
            <Input
              value={detailedArea?.code ? detailedArea?.code : ""}
              disabled
              placeholder="Código"
              size="md"
            />
          </FormControl>
        </HStack>
        <HStack>
          <CustomFormControlSelect
            errors={errors}
            fieldName="Área de Formação do INFQE"
            isDisabled={isDisabled}
            options={technicalEducationData}
            fieldNameObject="technicalEducation"
            control={control}
            isRequired={true}
            parent={{ value: 1 }}
          />

          <FormControl>
            <Label title="Código" />
            <Input
              value={technicalEducation?.code ? technicalEducation?.code : ""}
              disabled
              placeholder="Código"
              size="md"
            />
          </FormControl>
        </HStack>
        <HStack>
          <CustomFormControlSelect
            errors={errors}
            fieldName="Domínio PNFQ"
            isDisabled={isDisabled}
            options={domainPNFQData}
            fieldNameObject="domainPNFQ"
            control={control}
            isRequired={false}
            parent={{ value: 1 }}
          />

          <FormControl>
            <Label title="Código" />
            <Input
              value={domainPNFQ?.code ? domainPNFQ?.code : ""}
              disabled
              placeholder="Código"
              size="md"
            />
          </FormControl>
        </HStack>
      </Stack>
    </>
  );
}
