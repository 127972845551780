import { z } from "zod";
import { REQUIRED_FIELD } from "../../../utils/constants";

const baseSchema = z.object({
  name: z.string().nonempty(REQUIRED_FIELD),
  typeDocument: z.string().nonempty(REQUIRED_FIELD),
  documentNumber: z.string().nonempty(REQUIRED_FIELD),
  birthDate: z.date(),
  gender: z.string().nonempty(REQUIRED_FIELD),
  educationSponsorName: z.string().nonempty(REQUIRED_FIELD),
  educationSponsorPhone: z.string().nonempty(REQUIRED_FIELD),
  nationality: z.object({
    value: z.number(),
    label: z.string(),
  }),
  specialEducationNeed: z.string().nonempty(REQUIRED_FIELD),
  hasNee: z.literal(0),
});

const neeSchema = z.object({
  name: z.string().nonempty(REQUIRED_FIELD),
  typeDocument: z.string().nonempty(REQUIRED_FIELD),
  documentNumber: z.string().nonempty(REQUIRED_FIELD),
  birthDate: z.date().refine(
    (value) => {
      const inputDate = new Date(value);
      const currentDate = new Date();

      if (inputDate < currentDate - 14) {
        return true;
      } else {
        return false;
      }
    },
    {
      message: "O aluno deve ter pelo menos 14 anos de idade",
    }
  ),
  gender: z.string().nonempty(REQUIRED_FIELD),
  educationSponsorName: z.string().nonempty(REQUIRED_FIELD),
  educationSponsorPhone: z.string().nonempty(REQUIRED_FIELD),
  nationality: z.object({
    value: z.number(),
    label: z.string(),
  }),
  specialEducationNeed: z.string().nonempty(REQUIRED_FIELD),
  specialEducationNeedType: z.object({
    value: z.number(),
    label: z.string(),
  }),
  hasNee: z.literal(1),
});

export const schema = z.discriminatedUnion("hasNee", [baseSchema, neeSchema]);
