import { useState } from "react";
import { Box, Flex, Heading, Stack } from "@chakra-ui/react";
import TableErrorCloseAcademicYear from "./TableErrorToCloseAcademicYear";

export default function ErrorToCloseAcademicYear({ data }) {
  console.log("Data", data);
  const [actions, setActions] = useState();
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Stack spacing={5}>
      <Box>
        <Flex justifyContent="space-between" alignItems="end" mb="10px">
          <Heading as="h2" size="sm" textTransform="uppercase">
            Tabela de erros do encerramento do ano lectivo
          </Heading>

          {/* <Button
            variant="secondary"
            rightIcon={<MdDownload />}
            // onClick={download}
            // isLoading={isDownloading}
          >
            Descarregar lista
          </Button> */}
        </Flex>

        <TableErrorCloseAcademicYear
          data={data}
          isLoaded={isLoaded}
          setData={setActions}
          setIsLoaded={setIsLoaded}
        />
      </Box>
    </Stack>
  );
}
