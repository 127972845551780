import {
  Box,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { REPORTS_SUMMARY } from "../../utils/constants";
import TabStudents from "./components/TabStudents";
import TabSchools from "./components/TabSchools";
import TabTeachers from "./components/TabTeachers";

function Reports() {
  return (
    <Stack spacing={5}>
      <Box>
        <Heading as="h1" mb="10px">
          Estatísticas
        </Heading>

        <Box w="lg">
          <Text>{REPORTS_SUMMARY}</Text>
        </Box>
      </Box>

      <Tabs>
        <TabList>
          <Tab>Alunos</Tab>
          <Tab>Escolas</Tab>
          <Tab>Docentes</Tab>
        </TabList>

        <TabPanels>
          <TabPanel p={0} pt="20px">
            <TabStudents />
          </TabPanel>
          <TabPanel p={0} pt="20px">
            <TabSchools />
          </TabPanel>
          <TabPanel p={0} pt="20px">
            <TabTeachers />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
}

export default Reports;
