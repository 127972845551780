import {
  Table as ChakraTable,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Spinner,
  Box,
  Text,
  Flex,
  Checkbox,
} from "@chakra-ui/react";
import { useTable, useSortBy } from "react-table";
import { cellStyles, formStyles, tableStyles } from "../../utils/styles";
import { primaryColor } from "../../utils/colors";
import { NO_RESULTS, PAGE_SIZE } from "../../utils/constants";
import { Pagination } from "antd";
import { TriangleDownIcon, TriangleUpIcon, UpDownIcon } from "@chakra-ui/icons";
import {
  COURSE_URL,
  SCHOOL_URL,
  STUDENT_URL,
  TEACHER_URL,
} from "../../utils/endpoints";
import api from "../../utils/api";
import { useEffect, useState } from "react";

function Table({
  columns,
  data,
  setIsLoaded = false,
  isLoaded = true,
  setData = null,
  getFunction = null,
  isPaginated = true,
  entity = null,
  params = null,
  apiUrl = null,
  total = null,
  isFromPagination = null,
  setIsFromPagination = null,
  selectedRows,
  setSelectedRows,
}) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );
  const [totalItems, setTotalItems] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (data[0]?.totalItems) {
      setTotalItems(data[0].totalItems);
      setCurrentPage(1);
    } else if (total !== null && !isFromPagination) {
      setTotalItems(total);
      setCurrentPage(1);
    } else if (data.length < 1) {
      setTotalItems(0);
    }
  }, [data]);

  const toggleAllRows = () => {
    const allRowIds = data.map((item) => item.id);
    setSelectedRows(selectedRows.length === allRowIds.length ? [] : allRowIds);
  };

  return (
    <>
      {isLoaded ? (
        data.length > 0 ? (
          <TableContainer sx={tableStyles}>
            <ChakraTable {...getTableProps()} variant="striped">
              <Thead>
                {headerGroups.map((headerGroup) => (
                  <Tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <Th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        sx={cellStyles}
                      >
                        <Flex
                          alignItems="center"
                          style={{
                            justifyContent:
                              column.id === "edit" || column.id === "delete"
                                ? "center"
                                : "start",
                          }}
                        >
                          {column.id === "checkbox" && (
                            <Checkbox
                              isChecked={
                                selectedRows.length === data.length &&
                                data.length > 0
                              }
                              onChange={toggleAllRows}
                            >
                              <Text fontSize="xs">
                                {column.render("Header")}
                              </Text>
                            </Checkbox>
                          )}
                          {column.id !== "checkbox" && column.render("Header")}
                          {!column.disableSortBy &&
                            (column.isSorted ? (
                              column.isSortedDesc ? (
                                <TriangleDownIcon ms={1} />
                              ) : (
                                <TriangleUpIcon ms={1} />
                              )
                            ) : (
                              <UpDownIcon ms={1} />
                            ))}
                        </Flex>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <Tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <Td {...cell.getCellProps()} sx={cellStyles}>
                            {cell.render("Cell")}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </ChakraTable>
          </TableContainer>
        ) : (
          <Box width="100%" display="flex" sx={formStyles}>
            <Text>{NO_RESULTS}</Text>
          </Box>
        )
      ) : (
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          sx={formStyles}
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color={primaryColor}
            size="xl"
          />
        </Box>
      )}
      {isPaginated && totalItems > 0 && (
        <Box mt={3}>
          <Pagination
            current={currentPage}
            pageSize={PAGE_SIZE}
            total={totalItems}
            defaultPageSize={PAGE_SIZE}
            showSizeChanger={false}
            onChange={async (e) => {
              if (entity !== null) {
                let response;
                let qs;
                if (params !== null) {
                  const queryParams = [];
                  for (let [key, value] of Object.entries(params)) {
                    if (value !== undefined && value !== null) {
                      if (key === "IsValidated" && value === "pending") {
                        key = "OnlyPending";
                        value = true;
                      }
                      queryParams.push(`${key}=${encodeURIComponent(value)}`);
                    }
                  }
                  qs = queryParams.length > 0 ? `${queryParams.join("&")}` : "";
                }
                if (entity === "school") {
                  response = await api.get(
                    `${SCHOOL_URL}?PageSize=${PAGE_SIZE}&CurrentPage=${e}&${qs}`
                  );
                } else if (entity === "course") {
                  response = await api.get(
                    `${COURSE_URL}?PageSize=${PAGE_SIZE}&CurrentPage=${e}&${qs}`
                  );
                } else if (entity === "student") {
                  response = await api.get(
                    `${STUDENT_URL}?PageSize=${PAGE_SIZE}&CurrentPage=${e}&${qs}`
                  );
                } else if (entity === "teacher") {
                  response = await api.get(
                    `${TEACHER_URL}?PageSize=${PAGE_SIZE}&CurrentPage=${e}&${qs}`
                  );
                } else if (entity === "user") {
                  response = await api.get(`${apiUrl}&CurrentPage=${e}`);
                  setIsFromPagination(true);
                } else if (entity === "default") {
                  response = await api.get(`${apiUrl}&CurrentPage=${e}`);
                  setIsFromPagination(true);
                } else if (entity === "admin") {
                  response = await api.get(`${apiUrl}&CurrentPage=${e}`);
                  setIsFromPagination(true);
                }
                setData(response?.data.result);
                setTotalItems(response?.data.totalItems);
                if (setSelectedRows) {
                  setSelectedRows([]);
                }
              }
              setCurrentPage(e);
            }}
          />
        </Box>
      )}
    </>
  );
}

export default Table;
