import { useState, useEffect } from "react";

import {
  Button,
  Box,
  Flex,
  Divider,
  Text,
  Grid,
  GridItem,
} from "@chakra-ui/react";

import { getEducationLevel } from "../../../services/getEducationLevel";
import { getDomainPNFQ } from "../../../services/getDomainPNFQ";
import { getGeneralArea } from "../../../services/getGeneralArea";
import { getTechinicalEducation } from "../../../services/getTechinicalEducation";
import { getSepecificArea } from "../../../services/getSpecificArea";
import { getCourse } from "../../../services/getCourse";
import { getDetailedArea } from "../../../services/getDetailedArea";
import { getSubsystemEducation } from "../../../services/getSubsystemEducation";
import { getCicleEducation } from "../../../services/getCicleEducation";
import SelectLabel from "../../../components/SelectLabel";
import { borderColor } from "../../../utils/colors";
import { getCourseByQs } from "../../../services/getCourseByQs";
export default function FilterArea({
  setDataTable,
  handleFilterOrSearchCourse,
  control,
  educationLevel,
  subSystemEducation,
  generalArea,
  specificArea,
  params,
  addToast,
  setValue,
  inputSearch,
}) {
  const [educationLevelData, setEducationLevelData] = useState([]);
  const [subSystemEducationData, setSubSystemEducationData] = useState([]);
  const [educationCicleData, setEducationCicleData] = useState([]);
  const [technicalEducationData, setTechnicalEducationData] = useState([]);
  const [generalAreaData, setGeneralAreaData] = useState([]);
  const [detailedAreaData, setDetailedAreaData] = useState([]);
  const [specificAreaData, setSpecificAreaData] = useState([]);
  const [domainPNFQData, setDomainPNFQData] = useState([]);

  const handleSearch = async () => {
    const data = await handleFilterOrSearchCourse(
      params,
      addToast,
      getCourseByQs
    );
    setDataTable(data);
  };

  const handleCleanFilters = async () => {
    setValue("educationLevel", undefined);
    setValue("educationSubsystem", undefined);
    setValue("educationCycle", undefined);
    setValue("generalArea", undefined);
    setValue("specificArea", undefined);
    setValue("detailedArea", undefined);
    setValue("domainPNFQ", undefined);
    setValue("technicalEducation", undefined);
    setValue("isValidated", undefined);
    setValue("isActive", undefined);

    let response;
    if (inputSearch !== undefined) {
      response = await handleFilterOrSearchCourse(
        { Pesquisa: inputSearch },
        addToast,
        getCourse
      );
    } else {
      response = await getCourse();
    }
    setDataTable(response);
  };

  useEffect(() => {
    const getData = async () => {
      const responseEducationLevel = await getEducationLevel();
      const responseDomainPNFQ = await getDomainPNFQ();
      const responseGeneralArea = await getGeneralArea();
      const responseTechnicalEducation = await getTechinicalEducation();
      setEducationLevelData(responseEducationLevel);
      setDomainPNFQData(responseDomainPNFQ);
      setGeneralAreaData(responseGeneralArea);
      setTechnicalEducationData(responseTechnicalEducation);
    };
    getData();
  }, []);

  useEffect(() => {
    setValue("educationSubsystem", undefined);
    setValue("educationCycle", undefined);
    const getData = async () => {
      const response = await getSubsystemEducation(
        `OnlyActive=true&ParentId=${educationLevel?.value}`
      );
      setSubSystemEducationData(response);
    };
    if (educationLevel?.value !== undefined) getData();
  }, [educationLevel]);

  useEffect(() => {
    setValue("educationCycle", undefined);
    const getData = async () => {
      const response = await getCicleEducation(subSystemEducation?.value);
      setEducationCicleData(response);
    };
    getData();
  }, [subSystemEducation]);

  useEffect(() => {
    setValue("specificArea", undefined);
    setValue("detailedArea", undefined);
    const getData = async () => {
      const response = await getSepecificArea({ ParentId: generalArea?.value });
      setSpecificAreaData(response);
    };
    getData();
  }, [generalArea]);

  useEffect(() => {
    setValue("detailedArea", undefined);
    const getData = async () => {
      const response = await getDetailedArea({ ParentId: specificArea?.value });
      setDetailedAreaData(response);
    };
    getData();
  }, [specificArea]);

  return (
    <>
      <Box position="relative" py={8}>
        <Divider borderColor={borderColor} />
        <Box
          bg="white"
          px="2"
          position="absolute"
          transform="translate(5%, -50%)"
        >
          <Text as="b" fontSize="sm" textTransform="uppercase">
            FILTRAR LISTA DE CURSOS
          </Text>
        </Box>
      </Box>

      <Box px="20px">
        <Grid gridTemplateColumns="1fr auto" gap={4}>
          <Grid templateColumns="repeat(5, 1fr)" alignItems="end" gap={4}>
            <GridItem>
              <SelectLabel
                control={control}
                fieldName="educationLevel"
                title="Nível de Ensino"
                data={educationLevelData}
                parent={{ value: 1 }}
              />
            </GridItem>

            <GridItem>
              <SelectLabel
                control={control}
                fieldName="educationSubsystem"
                title="Subsistema de Ensino"
                data={subSystemEducationData}
                parent={educationLevel}
              />
            </GridItem>

            <GridItem>
              <SelectLabel
                control={control}
                fieldName="educationCycle"
                title="Ciclo de Ensino"
                data={educationCicleData}
                parent={subSystemEducation}
              />
            </GridItem>

            <GridItem>
              <SelectLabel
                control={control}
                fieldName="technicalEducation"
                title="Área de Formação do INFQE"
                data={technicalEducationData}
                parent={{ value: 1 }}
              />
            </GridItem>

            <GridItem>
              <SelectLabel
                title="Área Geral CITE (ISCED) 2013"
                control={control}
                fieldName="generalArea"
                data={generalAreaData}
                parent={{ value: 1 }}
              />
            </GridItem>

            <GridItem>
              <SelectLabel
                title="Área Específica CITE (ISCED) 2013"
                control={control}
                fieldName="specificArea"
                data={specificAreaData}
                parent={generalArea}
              />
            </GridItem>

            <GridItem>
              <SelectLabel
                control={control}
                fieldName="detailedArea"
                title="Área Detalhada CITE (ISCED) 2013"
                data={detailedAreaData}
                parent={specificArea}
              />
            </GridItem>

            <GridItem>
              <SelectLabel
                control={control}
                fieldName="domainPNFQ"
                title="Domínio PNFQ"
                data={domainPNFQData}
                parent={{ value: 1 }}
              />
            </GridItem>

            <GridItem>
              <SelectLabel
                control={control}
                fieldName="isValidated"
                title="Estado"
                parent={{ value: 1 }}
                data={[
                  { value: true, label: "Validado" },
                  { value: "pending", label: "Pendente de Validação" },
                  { value: false, label: "Não validado" },
                ]}
              />
            </GridItem>

            <GridItem>
              <SelectLabel
                control={control}
                fieldName="isActive"
                title="Arquivado"
                parent={{ value: 1 }}
                data={[
                  { value: false, label: "Sim" },
                  { value: true, label: "Não" },
                ]}
              />
            </GridItem>
          </Grid>
        </Grid>
        <Flex justifyContent="flex-end" mt={5}>
          <Button onClick={handleSearch} mr={4}>
            Filtrar
          </Button>
          <Button onClick={handleCleanFilters} variant="link">
            Limpar filtros
          </Button>
        </Flex>
      </Box>
    </>
  );
}
