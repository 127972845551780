import {
  Heading,
  Text,
  Box,
  Stack,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import TableSchool from "./TableSchool";
import {
  MdAddCircle,
  MdArrowOutward,
  MdCloudDownload,
  MdCloudUpload,
} from "react-icons/md";
import { useForm } from "react-hook-form";
import { SearchSchool } from "./components/SearchSchools";
import handleFilterOrSearch from "../../helpers/handleFilterOrSearch";
import { Toast } from "../../components/Toast";
import { useContext, useEffect, useState } from "react";
import { FilterSchools } from "./components/FilterSchools";
import { formStyles, selectStyles } from "../../utils/styles";
import { NO_OPTIONS_MESSAGE, SCHOOL_SUMMARY } from "../../utils/constants";
import { downloadExcelFile } from "../../utils/downloadExcelFile";
import {
  ACADEMIC_YEAR_URL,
  COUNTY_URL,
  DOWNLOAD_SCHOOLS,
  DOWNLOAD_TEMPLATE_SCHOOL,
  PROVINCE_URL,
  SCHOOL_URL,
} from "../../utils/endpoints";
import { AuthContext } from "../../provider/AuthProvider";
import Select from "react-select";
import Label from "../../components/Label";
import api from "../../utils/api";

function Schools() {
  const { addToast } = Toast();
  const [isDisabled, setIsDisabled] = useState(true);
  const { register, control, watch, setValue } = useForm();
  const province = watch("province");
  const county = watch("county");
  const natures = watch("natures");
  const type = watch("type");
  const inputSearch = watch("search");
  const state = watch("state");
  const isActive = watch("isActive");

  const { auth } = useContext(AuthContext);
  const user = auth.user;

  const paramsObj = {
    SchoolName: inputSearch,
    ProvinceId: province?.value,
    CountyId: county?.value,
    SchoolNatureId: natures?.value,
    SchoolTypologyId: type?.value,
    IsValidated: state?.value,
    IsActive: isActive?.value,
  };

  const [report, setReport] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  const [academicYear, setAcademicYear] = useState();
  const [selectedProvince, setSelectedProvince] = useState();
  const [selectedCounty, setSelectedCounty] = useState();
  const [school, setSchool] = useState();
  const [academicYearOptions, setAcademicYearOptions] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [countyOptions, setCountyOptions] = useState([]);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [isDisabledDownload, setIsDisabledDownload] = useState(true);

  const [dataTable, setDataTable] = useState();

  const download = () => {
    let apiUrl = "";
    if (report?.value === 1) {
      apiUrl = `${DOWNLOAD_SCHOOLS}`;
    } else if (report?.value === 2) {
      apiUrl = "/api/School/downloadSchoolsInstitutional";
    } else if (report?.value === 3) {
      apiUrl = "/api/School/downloadSchoolsContacts";
    } else if (report?.value === 4) {
      apiUrl = `/api/School/downloadSchoolsCourses?AcademicYearId=${academicYear?.value}`;
      if (selectedProvince?.value) {
        apiUrl += `&ProvinceId=${selectedProvince?.value}`;
      }
      if (selectedCounty?.value) {
        apiUrl += `&CountyId=${selectedCounty?.value}`;
      }
      if (school?.value) {
        apiUrl += `&SchoolId=${school?.value}`;
      }
    }
    setIsLoading(true);
    downloadExcelFile(apiUrl, `${report?.label}.xlsx`);
    setIsLoading(false);
    onClose();
  };

  const downloadTemplate = async () => {
    const apiUrl = DOWNLOAD_TEMPLATE_SCHOOL;
    downloadExcelFile(apiUrl, "Template Escola.xlsx");
  };

  const options = [
    {
      value: 1,
      label: "Lista com todos os dados",
    },
    {
      value: 2,
      label: "Lista de dados de informação institucional",
    },
    {
      value: 3,
      label: "Lista de dados de contacto",
    },
    {
      value: 4,
      label: "Lista dos cursos por escola e ano lectivo (1.ªs coluna escolas)",
    },
  ];

  const onChange = (e) => {
    setReport(e);
    if (e?.value) {
      onOpen();
    }
  };

  async function getAcademicYears() {
    api
      .get(ACADEMIC_YEAR_URL)
      .then((res) => {
        const data = res.data.result;
        setAcademicYearOptions(
          data
            .filter((i) => i.isActive)
            .map((i) => ({
              value: i.id,
              label: i.year,
              isBaseYear: i.isBaseYear,
            }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getProvinces() {
    api
      .get(PROVINCE_URL)
      .then((res) => {
        const data = res.data;
        setProvinceOptions(
          data
            .filter((i) => i.isActive)
            .map((i) => ({
              value: i.id,
              label: i.name,
            }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getCounties(provinceId) {
    api
      .get(`${COUNTY_URL}?ParentId=${provinceId}`)
      .then((res) => {
        const data = res.data;
        setCountyOptions(
          data
            .filter((i) => i.isActive)
            .map((i) => ({
              value: i.id,
              label: i.name,
            }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getSchools(provinceId = null, countyId = null) {
    let apiURL = `${SCHOOL_URL}?IsActive=true&IsValidated=true`;
    if (provinceId) {
      apiURL += `&ProvinceId=${provinceId}`;
    }
    if (countyId) {
      apiURL += `&CountyId=${countyId}`;
    }
    api
      .get(`${apiURL}`)
      .then((res) => {
        const data = res.data.result;
        setSchoolOptions(
          data.map((i) => ({
            value: i.id,
            label: i.name,
          }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  useEffect(() => {
    setAcademicYear();
    setSelectedProvince();
    setSelectedCounty();
    setSchool();
    setIsDisabledDownload(true);
    if (report?.value === 4) {
      getAcademicYears();
      getProvinces();
      getSchools();
    } else {
      setIsDisabledDownload(false);
    }
  }, [isOpen]);

  useEffect(() => {
    setSelectedCounty("");
    setSchool("");
    if (selectedProvince?.value) {
      getCounties(selectedProvince?.value);
      getSchools(selectedProvince?.value);
    } else {
      setCountyOptions([]);
    }
  }, [selectedProvince]);

  useEffect(() => {
    setSchool("");
    if (selectedCounty?.value) {
      getSchools(selectedProvince?.value, selectedCounty?.value);
    }
  }, [selectedCounty]);

  useEffect(() => {
    if (academicYear?.value) {
      setIsDisabledDownload(false);
    } else {
      setIsDisabledDownload(true);
    }
  }, [academicYear]);

  return (
    <Stack spacing={5}>
      <Box>
        <Heading mb="10px">Escolas</Heading>

        <Text width="sm">{SCHOOL_SUMMARY}</Text>
      </Box>

      {user.permissions.includes("CreateSchool") && (
        <Box>
          <Link to="/escolas/cadastrar">
            <Button rightIcon={<MdAddCircle />} mr={2}>
              Cadastrar
            </Button>
          </Link>

          <Link to="/escolas/carregar-lista">
            <Button rightIcon={<MdCloudUpload />} mr={2}>
              Carregar lista
            </Button>
          </Link>

          <Button rightIcon={<MdCloudDownload />} onClick={downloadTemplate}>
            Descarregar Template
          </Button>
        </Box>
      )}

      <Box>
        <Heading as="h2" size="md" textTransform="uppercase" mb="10px">
          Pesquisar
        </Heading>

        <Box as="form" sx={formStyles} px="0!important">
          <SearchSchool
            params={paramsObj}
            handleFilterOrSearchCourse={handleFilterOrSearch}
            register={register}
            addToast={addToast}
            setDataTable={setDataTable}
            setInputSearch={setValue}
          />

          <FilterSchools
            setValue={setValue}
            addToast={addToast}
            province={province}
            county={county}
            state={state}
            register={register}
            type={type}
            natures={natures}
            params={paramsObj}
            handleFilterOrSearchCourse={handleFilterOrSearch}
            control={control}
            setDataTable={setDataTable}
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
          />
        </Box>
      </Box>

      <Box mb="10px">
        <Flex justifyContent="space-between" alignItems="end" mb="10px">
          <Heading as="h2" size="md" textTransform="uppercase">
            Lista de Escolas
          </Heading>
          <Flex>
            <Box minW="300px">
              <Select
                placeholder="Descarregar lista"
                options={options}
                isSearchable={false}
                isClearable={true}
                onChange={onChange}
                styles={selectStyles}
              />
              <Modal isOpen={isOpen} onClose={onClose} size="lg">
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Descarregar lista</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Stack spacing={5}>
                      <Text>{report?.label}</Text>
                      {report?.value === 4 && (
                        <>
                          <Box>
                            <Label title="Ano Lectivo" isRequired />
                            <Select
                              placeholder="Seleccionar"
                              options={academicYearOptions}
                              onChange={(value) => {
                                setAcademicYear(value);
                              }}
                              noOptionsMessage={NO_OPTIONS_MESSAGE}
                            />
                          </Box>
                          <Box>
                            <Label title="Província" />
                            <Select
                              placeholder="Seleccionar"
                              options={provinceOptions}
                              onChange={(value) => {
                                setSelectedProvince(value);
                              }}
                              noOptionsMessage={NO_OPTIONS_MESSAGE}
                            />
                          </Box>
                          <Box>
                            <Label title="Município" />
                            <Select
                              placeholder="Seleccionar"
                              options={countyOptions}
                              value={selectedCounty}
                              onChange={(value) => {
                                setSelectedCounty(value);
                              }}
                              isDisabled={!selectedProvince?.value}
                              noOptionsMessage={NO_OPTIONS_MESSAGE}
                            />
                          </Box>
                          <Box>
                            <Label title="Escola" />
                            <Select
                              placeholder="Seleccionar"
                              options={schoolOptions}
                              value={school}
                              onChange={(value) => {
                                setSchool(value);
                              }}
                              noOptionsMessage={NO_OPTIONS_MESSAGE}
                            />
                          </Box>
                        </>
                      )}
                    </Stack>
                  </ModalBody>

                  <ModalFooter>
                    <Button variant="secondary" onClick={onClose} mr={3}>
                      Cancelar
                    </Button>
                    <Button
                      type="submit"
                      onClick={download}
                      isLoading={isLoading}
                      isDisabled={isDisabledDownload}
                    >
                      Descarregar
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </Box>
          </Flex>
        </Flex>

        <TableSchool
          setDataTable={setDataTable}
          dataTable={dataTable}
          params={paramsObj}
          setValue={setValue}
        />

        <Flex justifyContent="end" mt={3}>
          <Link to="#" target="_blank">
            <Button variant="secondary" rightIcon={<MdArrowOutward />}>
              Consultar Manual de Apoio
            </Button>
          </Link>
        </Flex>
      </Box>
    </Stack>
  );
}

export default Schools;
