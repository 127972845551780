import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  HStack,
  Input,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { Controller } from "react-hook-form";

import Select from "react-select";
import { getSchoolType } from "../../../services/getSchoolTypes";
import { getSchoolNatures } from "../../../services/getSchoolNatures";
import { getSchoolTipology } from "../../../services/getSchoolTipology";
import { getCounty } from "../../../services/getCounty";
import { getProvince } from "../../../services/getProvince";
import { Toast } from "../../../components/Toast";
import { getSchoolByNatures } from "../../../services/getSchoolByNatures";
import Label from "../../../components/Label";

import {
  NO_OPTIONS_MESSAGE,
  PRIVATE_NATURES,
  REQUIRED_FIELD,
} from "../../../utils/constants";

import { AuthContext } from "../../../provider/AuthProvider";

import CustomDatePicker from "../../../components/CustomDatePicker";

export function FormTab1({
  isDisabled,
  province,
  errors,
  register,
  control,
  natures,
  setValue,
  watch,
  mode,
  unregister,
  schooltype,
  setSchooltype,
  schoolAffiliation,
  diplomaTypeValue,
  hasLicenceValue,
}) {
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [naturesOptions, setNaturesOptions] = useState([]);
  const [countyOptions, setCountyOptions] = useState([]);
  const [schoolTypesOptions, setSchoolTypeOptions] = useState([]);
  const [schoolTypologiesOptions, setSchoolTypologiesOptions] = useState([]);
  const [schoolByNatures, setSchoolByNatures] = useState([]);

  const minDate = new Date(1976, 1, 1);

  const { addToast } = Toast();
  const nature = watch("natures");
  const tipology = watch("tipology");
  const types = watch("types");
  const provinceEdit = watch("province");
  const county = watch("county");
  const hasLicence = watch("hasLicence");
  const alvara = watch("alvara");
  const dateOfAlvara = watch("dateOfAlvara");
  const diplomaType = watch("diplomaType");
  const diploma = watch("diploma");
  const diplomaCreationDate = watch("diplomaCreationDate");
  const diplomaTypeOptions = [
    {
      value: 1,
      label: "Decreto Executivo",
    },
    {
      value: 2,
      label: "Decreto Executivo Conjunto",
    },
    {
      value: 3,
      label: "Não tem",
    },
  ];
  const hasLicenceOptions = [
    {
      value: true,
      label: "Sim",
    },
    {
      value: false,
      label: "Não tem",
    },
  ];

  useEffect(() => {
    if (typeof hasLicenceValue === 'boolean') {
      setValue(
        "hasLicence",
        hasLicenceOptions.filter((i) => i.value == hasLicenceValue)[0]
      );
    }
  }, [hasLicenceValue]);

  useEffect(() => {
    if (diplomaTypeValue) {
      setValue(
        "diplomaType",
        diplomaTypeOptions.filter((i) => i.value == diplomaTypeValue)[0]
      );
    }
  }, [diplomaTypeValue]);

  useEffect(() => {
    if (hasLicence?.value === true) {
      if (alvara && dateOfAlvara) {
        let date = new Date(Date.parse(dateOfAlvara));
        let year = date.getFullYear();

        setValue("licence", `Licença n.º ${alvara}/${year}`);
      }
    } else {
      setValue("licence", undefined);
    }
  }, [hasLicence, alvara, dateOfAlvara]);

  useEffect(() => {
    if (diplomaType?.value !== "3") {
      if (diploma && diplomaCreationDate) {
        let date = new Date(Date.parse(diplomaCreationDate));
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        day = day < 10 ? "0" + day : day;
        if (month === 1) month = "Janeiro";
        if (month === 2) month = "Fevereiro";
        if (month === 3) month = "Março";
        if (month === 4) month = "Abril";
        if (month === 5) month = "Maio";
        if (month === 6) month = "Junho";
        if (month === 7) month = "Julho";
        if (month === 8) month = "Agosto";
        if (month === 9) month = "Setembro";
        if (month === 10) month = "Outubro";
        if (month === 11) month = "Novembro";
        if (month === 12) month = "Dezembro";

        setValue(
          "diplomeOfcreate",
          `${diplomaType?.label} n.º ${diploma}/${year}, de ${day} de ${month}`
        );
      }
    } else {
      setValue("diplomeOfcreate", undefined);
    }
  }, [diplomaType, diploma, diplomaCreationDate]);

  const { auth } = useContext(AuthContext);
  const user = auth;

  setSchooltype(nature);

  async function getProvinceOptions() {
    getProvince()
      .then((res) => setProvinceOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getCountyOptions(id) {
    if (id === undefined) {
      return;
    }
    getCounty({ ParentId: id })
      .then((res) => setCountyOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getSchoolTypologiesOptions() {
    getSchoolTipology()
      .then((res) => setSchoolTypologiesOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getSchoolNaturesOptions() {
    getSchoolNatures()
      .then((res) => {
        setNaturesOptions(res);
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getSchoolTypes() {
    getSchoolType()
      .then((res) => setSchoolTypeOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getSchoolByNaturesOption(id) {
    getSchoolByNatures(id)
      .then((res) => setSchoolByNatures(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  useEffect(() => {
    getProvinceOptions();
    getSchoolTypologiesOptions();
    getSchoolNaturesOptions();
    getSchoolTypes();
    // eslint-disable-next-line
  }, []);

  useEffect(
    () => {
      if (province) {
        getCountyOptions(province?.value);
      }
    },
    // eslint-disable-next-line
    [province]
  );

  useEffect(
    () => {
      if (natures) {
        getSchoolByNaturesOption(
          naturesOptions ? naturesOptions[0]?.value : null
        );
        if (natures.value !== 2) {
          setValue("hasLicence", "");
          setValue("schoolAffiliation", undefined);
        }
        if (natures.value === 2) {
          setValue("diplomaType", undefined);
        }
      }
    },
    // eslint-disable-next-line
    [natures]
  );

  return (
    <>
      <Stack spacing={5}>
        <FormControl isInvalid={!!errors.name}>
          <Label title="Designação da escola" isRequired />
          <Textarea
            id="name"
            {...register("name")}
            isDisabled={user.isSchool ? true : isDisabled}
          />
          <FormErrorMessage>
            {errors.name && errors.name.message}
          </FormErrorMessage>
        </FormControl>

        <HStack>
          <FormControl isInvalid={!!errors.code}>
            <Label title="Código da escola" isRequired />
            <Input
              id="code"
              {...register("code")}
              isDisabled={user.isSchool ? true : isDisabled}
            />
            <FormErrorMessage>
              {errors.code && errors.code.message}
            </FormErrorMessage>
          </FormControl>
        </HStack>

        <HStack>
          <FormControl isInvalid={!!errors.email}>
            <Label title="Email da escola" isRequired />
            <Input
              id="email"
              {...register("email")}
              isDisabled={user.isSchool ? true : isDisabled}
            />
            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>
        </HStack>

        <HStack gap="5" alignItems="start">
          <FormControl isInvalid={!!errors.natures}>
            <Label title="Natureza da escola" isRequired />
            <Controller
              control={control}
              name="natures"
              render={({ field: { onChange, value } }) => (
                <Select
                  placeholder="Seleccionar"
                  options={naturesOptions}
                  isSearchable={false}
                  isDisabled={user.isSchool ? true : isDisabled}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            <FormErrorMessage>
              {errors.natures && <>{REQUIRED_FIELD}</>}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.schoolAffiliation}>
            <Label title="Escola pública de filiação" />
            <Controller
              control={control}
              name="schoolAffiliation"
              render={({ field: { onChange, value } }) => (
                <Select
                  placeholder="Seleccionar"
                  options={schoolByNatures}
                  isSearchable={true}
                  isDisabled={
                    user.isSchool
                      ? true
                      : mode === "resume"
                      ? true
                      : false ||
                        (mode === "create" &&
                        natures &&
                        natures.label !== `${PRIVATE_NATURES}`
                          ? true
                          : false) ||
                        !natures
                  }
                  onChange={onChange}
                  value={value || ""}
                  isMulti
                  isClearable={false}
                />
              )}
            />
            <FormErrorMessage>
              {errors.schoolAffiliation && <>{REQUIRED_FIELD}</>}
            </FormErrorMessage>
          </FormControl>
        </HStack>

        <FormControl isInvalid={errors.yearOfCreate}>
          <Label title="Ano de criação" />
          <Input
            id="yearOfCreate"
            {...register("yearOfCreate")}
            isDisabled={user.isSchool ? true : isDisabled}
          />
          {errors.yearOfCreate && (
            <FormErrorMessage>{errors.yearOfCreate.message}</FormErrorMessage>
          )}
          <FormHelperText>Formato: aaaa</FormHelperText>
        </FormControl>

        <FormControl isInvalid={!!errors.diplomaType}>
          <Label title="Tipo de decreto" />
          <Controller
            control={control}
            name="diplomaType"
            render={({ field: { onChange, value } }) => (
              <Select
                placeholder="Seleccionar"
                options={diplomaTypeOptions}
                onChange={onChange}
                value={value || ""}
                isSearchable={false}
                isDisabled={
                  !natures?.value || user.isSchool
                    ? true
                    : natures?.label !== `${PRIVATE_NATURES}`
                    ? isDisabled
                    : true
                }
                noOptionsMessage={NO_OPTIONS_MESSAGE}
              />
            )}
          />
          <FormErrorMessage>
            {errors.diplomaType && <>{REQUIRED_FIELD}</>}
          </FormErrorMessage>
        </FormControl>

        {diplomaType &&
          (diplomaType?.value !== 3 ? (
            <>
              <HStack gap="5">
                <FormControl isInvalid={!!errors.diploma}>
                  <Label title="Número do Decreto" isRequired />
                  <Input
                    type="number"
                    min="0"
                    max="9999"
                    {...register("diploma")}
                    isDisabled={user.isSchool ? true : isDisabled}
                  />
                  <FormErrorMessage>
                    {errors.diploma && <>{errors.diploma.message}</>}
                  </FormErrorMessage>
                  <FormHelperText>Formato: ####</FormHelperText>
                </FormControl>
                <FormControl isInvalid={!!errors.diplomaCreationDate}>
                  <Label title="Data do Decreto" isRequired />
                  <Controller
                    control={control}
                    name="diplomaCreationDate"
                    render={({ field }) => (
                      <CustomDatePicker
                        id="name"
                        selected={field.value}
                        placeholder="Seleccionar"
                        onChange={(date) => field.onChange(date)}
                        minDate={minDate}
                        disabled={user.isSchool ? true : isDisabled}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.diplomaCreationDate && (
                      <>{errors.diplomaCreationDate.message}</>
                    )}
                  </FormErrorMessage>
                  <FormHelperText>Formato: dd/mm/aaaa</FormHelperText>
                </FormControl>
              </HStack>
              <FormControl>
                <Label title="Decreto de Criação" isRequired />
                <Input {...register("diplomeOfcreate")} isDisabled="true" />
              </FormControl>
            </>
          ) : (
            <FormControl isInvalid={!!errors.reasonForAbsenceOfDiploma}>
              <Label title="Justificar motivo de não ter decreto" isRequired />
              <Textarea
                {...register("reasonForAbsenceOfDiploma")}
                isDisabled={
                  user.isSchool
                    ? true
                    : natures?.label !== `${PRIVATE_NATURES}`
                    ? isDisabled
                    : true
                }
              />
              <FormErrorMessage>
                {errors.reasonForAbsenceOfDiploma &&
                  errors.reasonForAbsenceOfDiploma.message}
              </FormErrorMessage>
            </FormControl>
          ))}

        <HStack gap="5">
          <FormControl isInvalid={!!errors.hasLicence}>
            <Label title="Tem licença?" />
            <Controller
              control={control}
              name="hasLicence"
              render={({ field: { onChange, value } }) => (
                <Select
                  placeholder="Seleccionar"
                  options={hasLicenceOptions}
                  onChange={onChange}
                  value={value || ""}
                  isSearchable={false}
                  isDisabled={
                    user.isSchool
                      ? true
                      : natures?.label === `${PRIVATE_NATURES}`
                      ? isDisabled
                      : true
                  }
                  noOptionsMessage={NO_OPTIONS_MESSAGE}
                />
              )}
            />
            <FormErrorMessage>
              {errors.hasLicence && REQUIRED_FIELD}
            </FormErrorMessage>
          </FormControl>
        </HStack>

        {hasLicence !== undefined &&
          hasLicence !== "" &&
          (hasLicence.value === true ? (
            <>
              <HStack gap="5">
                <FormControl isInvalid={!!errors.alvara}>
                  <Label title="Número da Licença" isRequired />
                  <Input
                    type="number"
                    min="0"
                    max="9999"
                    {...register("alvara")}
                    isDisabled={
                      user.isSchool
                        ? true
                        : natures?.label === `${PRIVATE_NATURES}`
                        ? isDisabled
                        : true
                    }
                  />
                  <FormErrorMessage>
                    {errors.alvara && <>{errors.alvara.message}</>}
                  </FormErrorMessage>
                  <FormHelperText>Formato: ####</FormHelperText>
                </FormControl>
                <FormControl isInvalid={!!errors.dateOfAlvara}>
                  <Label title="Data da Licença" isRequired />
                  <Controller
                    control={control}
                    name="dateOfAlvara"
                    render={({ field }) => (
                      <CustomDatePicker
                        id="name"
                        selected={field.value}
                        placeholder="Seleccionar"
                        onChange={(date) => field.onChange(date)}
                        minDate={minDate}
                        disabled={
                          user.isSchool
                            ? true
                            : natures?.label === `${PRIVATE_NATURES}`
                            ? isDisabled
                            : true
                        }
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.dateOfAlvara && <>{errors.dateOfAlvara.message}</>}
                  </FormErrorMessage>
                  <FormHelperText>Formato: dd/mm/aaaa</FormHelperText>
                </FormControl>
              </HStack>
              <HStack gap="5">
                <FormControl>
                  <Label title="Licença" isRequired />
                  <Input {...register("licence")} isDisabled />
                </FormControl>
              </HStack>
            </>
          ) : (
            <HStack gap="5">
              <FormControl isInvalid={!!errors.reasonForAbsenceOfLicence}>
                <Label
                  title="Justificar motivo de não ter licença"
                  isRequired
                />
                <Textarea
                  {...register("reasonForAbsenceOfLicence")}
                  isDisabled={
                    user.isSchool
                      ? true
                      : natures?.label === `${PRIVATE_NATURES}`
                      ? isDisabled
                      : true
                  }
                />
                <FormErrorMessage>
                  {errors.reasonForAbsenceOfLicence &&
                    errors.reasonForAbsenceOfLicence.message}
                </FormErrorMessage>
              </FormControl>
            </HStack>
          ))}

        <HStack gap="5" alignItems="start">
          <FormControl isInvalid={!!errors.tipology}>
            <Label title="Tipologia de escola" isRequired />
            <Controller
              control={control}
              name="tipology"
              render={({ field: { onChange, value } }) => (
                <Select
                  id="tipology"
                  placeholder="Seleccionar"
                  options={schoolTypologiesOptions}
                  isSearchable={false}
                  onChange={onChange}
                  isDisabled={user.isSchool ? true : isDisabled}
                  // isDisabled={isDisabled}
                  value={tipology}
                />
              )}
            />
            <FormErrorMessage>
              {errors.tipology && <>{REQUIRED_FIELD}</>}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.types}>
            <Label title="Ramo da escola" isRequired />
            <Controller
              control={control}
              name="types"
              render={({ field: { onChange, value } }) => (
                <Select
                  id="types"
                  placeholder="Seleccionar"
                  options={schoolTypesOptions}
                  isSearchable={false}
                  onChange={onChange}
                  isDisabled={user.isSchool ? true : isDisabled}
                  // isDisabled={isDisabled}
                  value={types}
                />
              )}
            />

            <FormErrorMessage>
              {errors.types && <>{REQUIRED_FIELD}</>}
            </FormErrorMessage>
          </FormControl>
        </HStack>

        <HStack gap="5" alignItems="start">
          <FormControl isInvalid={!!errors.province}>
            <Label title="Província" isRequired />
            <Controller
              control={control}
              name="province"
              render={({ field: { onChange, value } }) => (
                <Select
                  id="province"
                  placeholder="Seleccionar"
                  options={provinceOptions}
                  isSearchable={false}
                  isDisabled={user.isSchool ? true : isDisabled}
                  onChange={(selectedProvince) => {
                    setValue("county", {
                      label: "Seleccionar",
                      value: "",
                    });
                    onChange(selectedProvince);
                  }}
                  value={provinceEdit}
                />
              )}
            />
            <FormErrorMessage>
              {errors.province && <>{REQUIRED_FIELD}</>}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.county}>
            <Label title="Município" isRequired />
            <Controller
              control={control}
              name="county"
              render={({ field: { onChange, value } }) => (
                <Select
                  id="county"
                  placeholder="Seleccionar"
                  options={countyOptions}
                  isSearchable={false}
                  isDisabled={user.isSchool ? true : isDisabled || !province}
                  onChange={onChange}
                  value={county}
                />
              )}
            />
            <FormErrorMessage>
              {errors.county && <>{REQUIRED_FIELD}</>}
            </FormErrorMessage>
          </FormControl>
        </HStack>
      </Stack>
    </>
  );
}
