import Select from "react-select";
import { Controller } from "react-hook-form";
import Label from "../Label";
import { Box } from "@chakra-ui/react";
import { NO_OPTIONS_MESSAGE } from "../../utils/constants";
export default function SelectLabel({
  title,
  data = [],
  fieldName,
  control,
  parent,
  isDisabled = false,
}) {
  return (
    <Box>
      <Label title={title} />
      <Controller
        name={fieldName}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            isClearable
            placeholder="Seleccionar"
            options={data}
            isDisabled={data.length < 1 || !parent?.value || isDisabled}
            onChange={onChange}
            value={value || ""}
            noOptionsMessage={NO_OPTIONS_MESSAGE}
          />
        )}
      />
    </Box>
  );
}
