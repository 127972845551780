import axios from "axios";
import { REFRESH_TOKEN } from "./endpoints";
import jwtDecode from "jwt-decode";

interface DecodedToken {
  nameid: string;
}

const API_URL = process.env.REACT_APP_API_URL;

let isRefreshing = false;

const api = axios.create({
  baseURL: API_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("@retfoptoken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
      const decodedToken = jwtDecode<DecodedToken>(token);
      const userId = decodedToken.nameid;

      if (!isRefreshing && config.url !== REFRESH_TOKEN) {
        isRefreshing = true;
        api
          .post(REFRESH_TOKEN, { userId: userId, userToken: token })
          .then((response) => {
            localStorage.removeItem("@retfoptoken");
            localStorage.setItem("@retfoptoken", response.data.token);
            isRefreshing = false;
          })
          .catch((error) => {
            console.error("Token refresh error: ", error);
            isRefreshing = false;
          });
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      localStorage.removeItem("@retfoptoken");
      window.location.href = "/";
    }
  }
);

export default api;
