import { FormErrorMessage, FormControl } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import Label from "../Label";
import { REQUIRED_FIELD } from "../../utils/constants";

export default function CustomFormControlSelect({
  errors,
  fieldNameObject,
  isDisabled,
  options,
  fieldName,
  control,
  isRequired,
  parent = {},
  defaultValue = false,
  isMulti = false,
  isClearable = true,
}) {
  return (
    <FormControl isInvalid={!!errors[fieldNameObject]}>
      <Label title={fieldName} isRequired={isRequired} />
      {!defaultValue ? (
        <Controller
          control={control}
          name={fieldNameObject}
          render={({ field: { onChange, value } }) => (
            <Select
              id={fieldNameObject}
              value={value}
              placeholder="Seleccionar"
              options={options}
              isSearchable={false}
              isDisabled={isDisabled || !parent?.value}
              onChange={(e) => {
                onChange(e);
              }}
              isMulti={isMulti}
              isClearable={isClearable}
            />
          )}
        />
      ) : (
        <Controller
          control={control}
          name={fieldNameObject}
          render={({ field: { onChange, value } }) => (
            <Select
              id={fieldNameObject}
              value={value}
              placeholder="Seleccionar"
              options={options}
              isSearchable={false}
              isDisabled={isDisabled || !parent?.value}
              onChange={(e) => {
                onChange(e);
              }}
            />
          )}
        />
      )}

      <FormErrorMessage>
        {errors[fieldNameObject] && <>{REQUIRED_FIELD}</>}
      </FormErrorMessage>
    </FormControl>
  );
}
