import { useContext, useEffect, useState } from "react";
import { Heading, Stack, Text, Box, Flex, Button } from "@chakra-ui/react";
import { getCourse } from "../../services/getCourse";
import { formStyles } from "../../utils/styles";
import TableStudent from "./components/TableStudent";
import ButtonGroupStudent from "./components/ButtonGroupStudent";
import { Toast } from "../../components/Toast";
import { useForm } from "react-hook-form";
import handleFilterOrSearch from "../../helpers/handleFilterOrSearch";
import ModalConfirmStudent from "./components/Modal";
import { getStudent } from "../../services/getStudent";
import SearchArea from "../../components/SearchArea";
import FilterArea from "../../components/FilterArea";
import { getSchool } from "../../services/getSchool";
import { getAcademicYear } from "../../services/getAcademicYear";
import { getClass } from "../../services/getClass";
import { STUDENT_SUMMARY } from "../../utils/constants";
import { AuthContext } from "../../provider/AuthProvider";
import { getClassModality } from "../../services/getClassModality";
import { getClassShift } from "../../services/getClassShift";
import { getEnrollmentStatus } from "../../services/getEnrollmentStatus";
import { getBondResult } from "../../services/getBondResult";
import api from "../../utils/api";
import { COURSE_URL } from "../../utils/endpoints";
import { Link } from "react-router-dom";
import { MdArrowOutward } from "react-icons/md";

function Student() {
  const { addToast } = Toast();
  const { register, control, watch, setValue } = useForm();

  const [academicYearData, setAcademicYearData] = useState([]);
  const [schoolData, setSchoolData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [classData, setClassData] = useState([]);
  const [classModalityData, setClassModalityData] = useState([]);
  const [classShiftData, setClassShiftData] = useState([]);
  const [classFullNameData, setClassFullNameData] = useState([]);
  const [studentEnrollmentStatusData, setStudentEnrollmentStatusData] =
    useState([]);
  const [schoolBondResult, setSchoolBondResult] = useState([]);
  const [classFullNameDisabled, setClassFullNameDisabled] = useState(true);

  const inputSearch = watch("search");
  const academicYear = watch("academicYear");
  const school = watch("school");
  const course = watch("course");
  const classId = watch("grade");
  const modality = watch("modality");
  const shift = watch("shift");
  const classFullName = watch("class");
  const enrollmentStatus = watch("enrollmentStatus");
  const bondResult = watch("schoolBondResult");
  const state = watch("state");
  const isActive = watch("isActive");

  const { auth } = useContext(AuthContext);
  const user = auth.user;

  const dataSelect = [
    {
      fieldObjectName: "academicYear",
      fieldName: "Ano Lectivo",
      data: academicYearData,
    },
    {
      fieldObjectName: "school",
      fieldName: "Escola",
      data: schoolData,
    },
    {
      fieldObjectName: "course",
      fieldName: "Curso",
      data: courseData,
    },
    {
      fieldObjectName: "grade",
      fieldName: "Classe",
      data: classData,
    },
    {
      fieldObjectName: "modality",
      fieldName: "Modalidade",
      data: classModalityData,
    },
    {
      fieldObjectName: "shift",
      fieldName: "Turno",
      data: classShiftData,
    },
    {
      fieldObjectName: "class",
      fieldName: "Turma",
      data: classFullNameData,
      isDisabled: classFullNameDisabled,
    },
    {
      fieldObjectName: "enrollmentStatus",
      fieldName: "Estado da matrícula",
      data: studentEnrollmentStatusData,
    },
    {
      fieldObjectName: "schoolBondResult",
      fieldName: "Resultado",
      data: schoolBondResult,
    },
    {
      fieldObjectName: "state",
      fieldName: "Estado",
      data: [
        { value: true, label: "Validado" },
        { value: "pending", label: "Pendente de Validação" },
        { value: false, label: "Não validado" },
      ],
    },
    {
      fieldObjectName: "isActive",
      fieldName: "Arquivado",
      data: [
        { value: false, label: "Sim" },
        { value: true, label: "Não" },
      ],
    },
  ];

  const getClassFullNameData = async (params) => {
    let qs = "";
    qs += params?.AcademicYearId
      ? `&AcademicYearId=${params.AcademicYearId}`
      : "";
    qs += params?.SchoolId ? `&SchoolId=${params.SchoolId}` : "";
    qs += params?.CourseId ? `&CourseId=${params.CourseId}` : "";
    qs += params?.ClassId ? `&ClassId=${params.ClassId}` : "";
    const response = await api.get(`/api/Lookup/ClassFullName?${qs}`);
    setClassFullNameData(
      response.data.result.map((item) => ({
        value: item.classFullName,
        label: item.classFullName,
      }))
    );
  };

  useEffect(() => {
    const getData = async () => {
      const responseAcademicYear = (await getAcademicYear()).map((e) => ({
        label: e.year,
        value: e.id,
      }));
      const responseSchool = await getSchool();
      const responseCourse = await getCourse();
      const responseClass = await getClass();
      const responseClassModality = await getClassModality();
      const responseClassShift = await getClassShift();
      const responseStudentEnrollmentStatus = await getEnrollmentStatus();
      const responseSchoolBondResultd = await getBondResult();

      setAcademicYearData(responseAcademicYear);
      setSchoolData(responseSchool);
      setCourseData(
        responseCourse.map((e) => ({
          label: e.name,
          value: e.id,
        }))
      );
      setClassData(responseClass);
      setClassModalityData(responseClassModality);
      setClassShiftData(responseClassShift);
      setStudentEnrollmentStatusData(responseStudentEnrollmentStatus);
      setSchoolBondResult(responseSchoolBondResultd);
    };
    getData();
  }, []);

  useEffect(() => {
    if (academicYear && school && course && classId) {
      let params = {
        AcademicYearId: academicYear.value,
        SchoolId: school.value,
        CourseId: course.value,
        ClassId: classId.value,
      };
      getClassFullNameData(params);
      setClassFullNameDisabled(false);
      setValue("class", undefined);
    } else {
      setClassFullNameDisabled(true);
    }
  }, [academicYear, school, course, classId]);

  const paramsObj = {
    Pesquisa: inputSearch,
    AcademicYearBonded: academicYear?.value,
    SchoolId: school?.value,
    CourseId: course?.value,
    ClassId: classId?.value,
    ClassModalityBonded: modality?.value,
    ClassShiftBonded: shift?.value,
    ClassFullName: classFullName?.value,
    StudentEnrollmentStatusId: enrollmentStatus?.value,
    SchoolBondResultBonded: bondResult?.value,
    IsValidated: state?.value,
    IsActive: isActive?.value,
  };

  const [isOpen, setIsOpen] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [isFilter, setIsFilter] = useState(false);

  const handleSearch = async () => {
    setIsFilter(true);
    const data = await handleFilterOrSearch(paramsObj, addToast, getStudent);
    setDataTable(data);
  };

  useEffect(() => {
    setValue("course", undefined);
    if (school) {
      api.get(`${COURSE_URL}?SchoolId=${school?.value}`).then((res) => {
        const response = res.data.result;
        setCourseData(
          response.map((e) => ({
            label: e.name,
            value: e.id,
          }))
        );
      });
    } else {
      api.get(COURSE_URL).then((res) => {
        const response = res.data.result;
        setCourseData(
          response.map((e) => ({
            label: e.name,
            value: e.id,
          }))
        );
      });
    }
  }, [school]);

  const handleCleanSearch = async () => {
    setValue("search", undefined);
    let valParams = [];
    for (const key in paramsObj) {
      valParams.push(paramsObj[key]);
    }

    const response = valParams.includes(undefined)
      ? await handleFilterOrSearch(
          { ...paramsObj, Pesquisa: undefined },
          addToast,
          getStudent,
          false
        )
      : await getStudent();
    setDataTable(response);
  };

  const handleCleanFilters = async () => {
    setValue("academicYear", undefined);
    setValue("school", undefined);
    setValue("course", undefined);
    setValue("grade", undefined);
    setValue("modality", undefined);
    setValue("shift", undefined);
    setValue("class", undefined);
    setValue("enrollmentStatus", undefined);
    setValue("schoolBondResult", undefined);
    setValue("state", undefined);
    setValue("isActive", undefined);

    let response;
    if (inputSearch !== undefined) {
      response = await handleFilterOrSearch(
        { Pesquisa: inputSearch },
        addToast,
        getStudent
      );
    } else {
      response = await getStudent();
    }
    setDataTable(response);
  };

  return (
    <Stack spacing={5}>
      <Box>
        <Heading mb="10px">Alunos</Heading>
        <Text width="sm">{STUDENT_SUMMARY}</Text>
      </Box>
      {user.permissions.includes("CreateStudents") && (
        <ButtonGroupStudent setIsOpen={setIsOpen} />
      )}

      <Box>
        <Heading as="h2" size="md" textTransform="uppercase" mb="10px">
          Pesquisar
        </Heading>

        <Box as="form" sx={formStyles} px="0!important">
          <SearchArea
            register={register}
            handleCleanFilters={handleCleanSearch}
            handleSearch={handleSearch}
            title={"Pesquisar pelo código ou designação do Aluno"}
          />
          <FilterArea
            control={control}
            handleSearch={handleSearch}
            handleCleanFilters={handleCleanFilters}
            dataSelect={dataSelect}
            title="Filtrar Alunos"
            gridNumberSelect={6}
          />
        </Box>
      </Box>

      <Box>
        <TableStudent
          setDataTable={setDataTable}
          dataTable={dataTable}
          params={paramsObj}
          isFilter={isFilter}
          className={classFullName}
          classId={classId}
          setValue={setValue}
        />

        <Flex justifyContent="end" mt={3}>
          <Link to="#" target="_blank">
            <Button variant="secondary" rightIcon={<MdArrowOutward />}>
              Consultar Manual de Apoio
            </Button>
          </Link>
        </Flex>
      </Box>
      <ModalConfirmStudent
        title="Matricular Aluno"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </Stack>
  );
}

export default Student;
