import { Box, Button, Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useContext } from "react";
import { AuthContext } from "../../../provider/AuthProvider";
import Delete from "../components/Delete";

function FormButtons({
  isDisabled,
  setIsDisabled,
  id,
  submitSchool,
  patchSchool,
  activeTab,
  handleTabChange,
  deleteSchool,
  isActive,
}) {
  const navigate = useNavigate();

  const { auth } = useContext(AuthContext);
  const user = auth.user;

  return (
    <>
      {isDisabled ? (
        id ? (
          <>
            {user.permissions.includes("EditMySchool") && (
              <Button mr={4} mt="5" onClick={() => setIsDisabled(false)}>
                Editar Escola
              </Button>
            )}
          </>
        ) : (
          <>
            <Box display="flex" alignItems="center" flexDir="row">
              <Button mr={4} mt="5" onClick={submitSchool}>
                Confirmar
              </Button>
              <Button
                variant="secondary"
                mt="5"
                onClick={() => setIsDisabled(false)}
              >
                Voltar a editar
              </Button>
            </Box>
          </>
        )
      ) : id ? (
        <>
          <Box mb={4}>
            <Button mr={3} onClick={patchSchool} type="submit">
              Guardar
            </Button>
            <Button variant="secondary" onClick={() => setIsDisabled(true)}>
              Cancelar
            </Button>
          </Box>

          <Flex justifyContent="space-between" alignItems="center">
            {user.permissions.includes("DeleteSchool") ? (
              <Delete deleteSchool={deleteSchool} isActive={isActive} />
            ) : (
              <Box />
            )}

            <Box>
              {activeTab === 0 && (
                <Button
                  variant="secondary"
                  onClick={() => handleTabChange(1)}
                  rightIcon={<MdKeyboardArrowRight />}
                >
                  Localização Geográfica
                </Button>
              )}

              {activeTab === 1 && (
                <Box>
                  <Button
                    variant="secondary"
                    leftIcon={<MdKeyboardArrowLeft />}
                    mr={5}
                    onClick={() => handleTabChange(0)}
                  >
                    Informação Institucional
                  </Button>
                  <Button
                    variant="secondary"
                    mr={5}
                    rightIcon={<MdKeyboardArrowRight />}
                    onClick={() => handleTabChange(2)}
                  >
                    Contactos
                  </Button>
                </Box>
              )}

              {activeTab === 2 && (
                <Box>
                  <Button
                    variant="secondary"
                    mr={5}
                    onClick={() => handleTabChange(1)}
                    leftIcon={<MdKeyboardArrowLeft />}
                  >
                    Localização Geográfica
                  </Button>
                  <Button
                    variant="secondary"
                    mr={5}
                    rightIcon={<MdKeyboardArrowRight />}
                    onClick={() => handleTabChange(3)}
                  >
                    Infra-estruturas
                  </Button>
                </Box>
              )}

              {activeTab === 3 && (
                <Box>
                  <Button
                    variant="secondary"
                    mr={5}
                    leftIcon={<MdKeyboardArrowLeft />}
                    onClick={() => handleTabChange(2)}
                  >
                    Contactos
                  </Button>
                </Box>
              )}
            </Box>
          </Flex>
        </>
      ) : (
        <>
          <Flex justifyContent="space-between" alignItems="center">
            <Box>
              <Button type="submit" name="create" mr={3}>
                Cadastrar Escola
              </Button>
              <Button variant="secondary" onClick={() => navigate("/escolas")}>
                Cancelar
              </Button>
            </Box>

            <Box>
              {activeTab === 0 && (
                <Button
                  type="submit"
                  name="paginate"
                  variant="secondary"
                  rightIcon={<MdKeyboardArrowRight />}
                >
                  Localização Geográfica
                </Button>
              )}

              {activeTab === 1 && (
                <Box>
                  <Button
                    variant="secondary"
                    leftIcon={<MdKeyboardArrowLeft />}
                    mr={5}
                    onClick={() => handleTabChange(0)}
                  >
                    Informação Institucional
                  </Button>
                  <Button
                    variant="secondary"
                    rightIcon={<MdKeyboardArrowRight />}
                    mr={5}
                    onClick={() => handleTabChange(2)}
                  >
                    Contactos
                  </Button>
                </Box>
              )}

              {activeTab === 2 && (
                <Box>
                  <Button
                    variant="secondary"
                    mr={5}
                    leftIcon={<MdKeyboardArrowLeft />}
                    onClick={() => handleTabChange(1)}
                  >
                    Localização Geográfica
                  </Button>
                </Box>
              )}
            </Box>
          </Flex>
        </>
      )}
    </>
  );
}

export default FormButtons;
