import { z } from "zod";
import { REQUIRED_FIELD } from "../../../utils/constants";

const date = new Date();
const currentYear = date.getFullYear();

export const schoolSchema = z.object({
  name: z.string().nonempty(REQUIRED_FIELD),
  code: z
    .string()
    .max(55, { message: "Máximo 55 caracteres" })
    .nonempty(REQUIRED_FIELD),
  yearOfCreate: z
    .string()
    .refine(
      (value) => {
        if (value === null || value === undefined || value === "") {
          return true;
        }
        return /^\d+$/.test(value);
      },
      {
        message: REQUIRED_FIELD,
      }
    )
    .refine(
      (value) => {
        if (value === null || value === undefined || value === "") {
          return true;
        }
        const num = parseInt(value, 10);
        return num >= 1940 && num < currentYear;
      },
      {
        message: `O ano deve ser maior ou igual a 1940 e menor que ${currentYear}`,
      }
    )
    .nullable()
    .optional(),

  diplomeOfcreate: z.string().optional(),
  alvara: z
    .string()
    .refine(
      (value) => {
        if (value === null || value === undefined || value === "") {
          return true;
        }
        const regex = new RegExp(
          `^\\d{1,4}\/(198\\d|199[0-9]|20[0-${currentYear - 2000}]\\d)$`
        );
        if (regex.test(value)) {
          if (parseInt(value.slice(-4)) > currentYear) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      },
      {
        message: "Formato inválido",
      }
    )
    .nullable()
    .optional(),
  // dateOfAlvara: z.string().nullable().optional(),
  email: z.string().nonempty(REQUIRED_FIELD).email("Email inválido"),
  province: z.object({
    value: z.number(),
    label: z.string(),
  }),
  county: z.object({
    value: z.number(),
    label: z.string(),
  }),

  natures: z.object({
    value: z.number(),
    label: z.string(),
  }),
  types: z.object({
    value: z.number(),
    label: z.string(),
  }),

  tipology: z.object({
    value: z.number(),
    label: z.string(),
  }),

  schoolAffiliation: z
    .array(
      z.object({
        value: z.number(),
        label: z.string(),
      })
    )
    .optional(),

  comunetype: z
    .object({
      value: z.number(),
      label: z.string(),
    })
    .optional(),

  districttype: z
    .object({
      value: z.number(),
      label: z.string(),
    })
    .optional(),

  fromCommuneId: z.number().optional(),
  address: z.string().optional(),
  pointOfReference: z.string().optional(),
  locality: z.string().optional(),
  fromUrbanDistrictId: z.number().optional(),

  phone: z.string().optional(),
  directorName: z.string().optional(),
  directorPhone: z.string().optional(),
  directorEmail: z.string().optional(),
  directorAdminName: z.string().optional(),
  directorAdminPhone: z.string().optional(),
  directorAdminEmail: z.string().optional(),
  directorPedName: z.string().optional(),
  directorPedPhone: z.string().optional(),
  directorPedEmail: z.string().optional(),
  entityName: z.string().optional(),
  entityPhone: z.string().optional(),
  entityEmail: z.string().optional(),
  website: z.string().optional(),

  latitude: z
    .string()
    .refine(
      (value) => {
        if (value === null || value === undefined || value === "") {
          return true;
        }
        if (value.length !== 10) {
          return false;
        }
        const num = parseFloat(value);
        if (num >= -18.027338 && num <= -4.387205) {
          const decimalCount = (value.split(".")[1] || []).length;
          return decimalCount === 6;
        }
      },
      {
        message:
          "O campo latitude tem de estar compreendido entre -04.387205 e -18.027338",
      }
    )
    .nullable()
    .optional(),
  longitude: z
    .string()
    .refine(
      (value) => {
        if (value === null || value === undefined || value === "") {
          return true;
        }
        if (value.length !== 9) {
          return false;
        }
        const num = parseFloat(value);
        if (num >= 11.656349 && num <= 24.093379) {
          const decimalCount = (value.split(".")[1] || []).length;
          return decimalCount === 6;
        }
      },
      {
        message:
          "O campo longitude tem de estar compreendido entre 11.656349 e 24.093379",
      }
    )
    .nullable()
    .optional(),
});

export const privateSchoolSchema = z.object({
  name: z.string().nonempty(REQUIRED_FIELD),
  code: z
    .string()
    .max(55, { message: "Máximo 55 caracteres" })
    .nonempty(REQUIRED_FIELD),
  yearOfCreate: z
    .string()
    .refine(
      (value) => {
        if (value === null || value === undefined || value === "") {
          return true;
        }
        return /^-?\d+$/.test(value);
      },
      {
        message: REQUIRED_FIELD,
      }
    )
    .refine(
      (value) => {
        if (value === null || value === undefined || value === "") {
          return true;
        }
        const num = parseInt(value, 10);
        return num >= 1940 && num < currentYear;
      },
      {
        message: `O ano deve ser maior ou igual a 1940 e menor que ${currentYear}`,
      }
    )
    .nullable()
    .optional(),

  diplomeOfcreate: z.string().optional(),
  reasonForAbsenceOfLicence: z.string().nullable().optional(),
  // dateOfAlvara: z.date().nullable().optional(),
  alvara: z.string().nullable().optional(),
  licence: z.string().optional(),
  email: z.string().nonempty(REQUIRED_FIELD).email("Email inválido"),
  province: z.object({
    value: z.number(),
    label: z.string(),
  }),
  county: z.object({
    value: z.number(),
    label: z.string(),
  }),

  natures: z.object({
    value: z.number(),
    label: z.string(),
  }),
  types: z.object({
    value: z.number(),
    label: z.string(),
  }),

  tipology: z.object({
    value: z.number(),
    label: z.string(),
  }),

  schoolAffiliation: z.array(
    z.object({
      value: z.number(),
      label: z.string(),
    })
  ),

  comunetype: z
    .object({
      value: z.number(),
      label: z.string(),
    })
    .optional(),

  districttype: z
    .object({
      value: z.number(),
      label: z.string(),
    })
    .optional(),

  fromCommuneId: z.number().optional(),
  address: z.string().optional(),
  pointOfReference: z.string().optional(),
  locality: z.string().optional(),
  fromUrbanDistrictId: z.number().optional(),

  phone: z.string().optional(),
  directorName: z.string().optional(),
  directorPhone: z.string().optional(),
  directorEmail: z.string().optional(),
  directorAdminName: z.string().optional(),
  directorAdminPhone: z.string().optional(),
  directorAdminEmail: z.string().optional(),
  directorPedName: z.string().optional(),
  directorPedPhone: z.string().optional(),
  directorPedEmail: z.string().optional(),
  entityName: z.string().optional(),
  entityPhone: z.string().optional(),
  entityEmail: z.string().optional(),
  website: z.string().optional(),

  latitude: z
    .string()
    .refine(
      (value) => {
        if (value === null || value === undefined || value === "") {
          return true;
        }
        if (value.length !== 10) {
          return false;
        }
        const num = parseFloat(value);
        if (num >= -18.027338 && num <= -4.387205) {
          const decimalCount = (value.split(".")[1] || []).length;
          return decimalCount === 6;
        }
      },
      {
        message:
          "O campo latitude tem de estar compreendido entre -04.387205 e -18.027338",
      }
    )
    .nullable()
    .optional(),
  longitude: z
    .string()
    .refine(
      (value) => {
        if (value === null || value === undefined || value === "") {
          return true;
        }
        if (value.length !== 9) {
          return false;
        }
        const num = parseFloat(value);
        if (num >= 11.656349 && num <= 24.093379) {
          const decimalCount = (value.split(".")[1] || []).length;
          return decimalCount === 6;
        }
      },
      {
        message:
          "O campo longitude tem de estar compreendido entre 11.656349 e 24.093379",
      }
    )
    .nullable()
    .optional(),
});
