import { Box, Button, HStack, Heading, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { useNavigate } from "react-router";

import Table from "../../../components/Table";

export function ListErrorsUploadList({ data }) {
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        Header: "Linha",
        accessor: "lineExcel",
      },
      {
        Header: "Nº de Erros",
        accessor: "countErrors",
      },
      {
        Header: "Descrição de erros encontrados",
        Cell: (item) => {
          return <Text>{item.data[item.row.index].errors.join(", ")}</Text>;
        },
      },
    ],
    []
  );

  return (
    <>
      <Box mb={5}>
        <Button
          onClick={() => {
            navigate("/escolas/carregar-lista");
          }}
        >
          Voltar
        </Button>
      </Box>
      <Heading mb={7}>Erros na lista carregada</Heading>
      <HStack>
        <Table columns={columns} data={data} />
      </HStack>
      <Box mt={10}>
        <Button>Descarregar lista(.csv)</Button>
      </Box>
    </>
  );
}
