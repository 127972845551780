import api from "../utils/api";
import { ROLE_FEATURES_URL } from "../utils/endpoints";

export async function getFeatures(roleTypeId, roleLevelId) {
  try {
    const response = await api.get(
      roleTypeId && roleLevelId
        ? `${ROLE_FEATURES_URL}?RoleTypeId=${roleTypeId}&RoleLevelId=${roleLevelId}`
        : ROLE_FEATURES_URL
    );
    const data = response.data.features.map((option) => ({
      value: option.code,
      label: option.name,
      level: option.level,
      description: option.description,
    }));
    return data;
  } catch (error) {
    throw error;
  }
}
