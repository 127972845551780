import { MdAddCircle, MdCloudDownload, MdCloudUpload } from "react-icons/md";
import { Link } from "react-router-dom";
import { Button, HStack } from "@chakra-ui/react";
import { downloadExcelFile } from "../../../utils/downloadExcelFile";
import { DOWNLOAD_TEMPLATE_COURSE } from "../../../utils/endpoints";
export default function ButtonGroupCourse() {
  const downloadTemplate = async () => {
    const apiUrl = DOWNLOAD_TEMPLATE_COURSE;
    downloadExcelFile(apiUrl, "Template Cursos.xlsx");
  };

  return (
    <HStack>
      <Link to="/cursos/cadastrar">
        <Button rightIcon={<MdAddCircle />}>Cadastrar</Button>
      </Link>
      <Link to="/cursos/carregar-lista">
        <Button rightIcon={<MdCloudUpload />}>Carregar lista</Button>
      </Link>

      <Button rightIcon={<MdCloudDownload />} onClick={downloadTemplate}>
        Descarregar Template
      </Button>
    </HStack>
  );
}
