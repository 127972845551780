import { FormControl, HStack, Input, Stack } from "@chakra-ui/react";

import Label from "../../../components/Label";

export function FormUploadList({ fileRef, setFile }) {
  return (
    <>
      <Stack spacing={4} w={350}>
        <HStack>
          <FormControl>
            <Label title="Lista (.xls)" />
            <Input type="file" id="name" ref={fileRef} />
          </FormControl>
        </HStack>
      </Stack>
    </>
  );
}
