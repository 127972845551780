import { Box, Button, Flex, Grid, Input } from "@chakra-ui/react";
import Label from "../../../components/Label";
import { getSchools } from "../../../services/getsSchools";
import { handleEnterOnSearch } from "../../../utils/constants";

export function SearchSchool({
  setInputSearch,
  register,
  handleFilterOrSearchCourse,
  params,
  setDataTable,
  addToast,
}) {
  const handleSearch = async () => {
    const data = await handleFilterOrSearchCourse(params, addToast, getSchools);
    setDataTable(data);
  };

  const handleCleanFilters = async () => {
    setInputSearch("search", undefined);
    const response = await getSchools();
    setDataTable(response);
  };

  return (
    <Box px="20px">
      <Label title="Pesquisar pela designação da escola" />

      <Grid gridTemplateColumns="1fr auto">
        <Flex mr={4}>
          <Input
            {...register("search")}
            sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            onKeyDown={(e) => {
              handleEnterOnSearch(e, handleSearch);
            }}
          />

          <Button
            onClick={handleSearch}
            sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          >
            Pesquisar
          </Button>
        </Flex>

        <Flex>
          <Button variant="link" onClick={handleCleanFilters}>
            Limpar consulta
          </Button>
        </Flex>
      </Grid>
    </Box>
  );
}
