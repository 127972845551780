import { primaryColor, borderColor } from "./colors";

export const borderRadius = "0.375rem";

export const menuItemStyles = {
  display: "block",
  padding: "8px 10px 6px 8px",
  borderLeftWidth: "4px",
  borderLeftColor: primaryColor,
};

export const menuItemActiveStyles = {
  display: "block",
  padding: "8px 10px 6px 8px",
  borderLeftWidth: "4px",
  borderRadius: borderRadius,
  borderColor: "#000",
  backgroundColor: "rgba(0, 0, 0, 0.15)",
};

export const styleFilterSection = {
  backgroundColor: "white",
  padding: "30px",
  borderRadius: "10px",
};

export const mainSectionOfSearchAndFilter = {
  border: "1.5px solid grey",
  borderRadius: "8px",
  backgroundColor: "white",
};

export const formStyles = {
  padding: "30px",
  bgColor: "#fff",
  borderWidth: "2px",
  borderColor: borderColor,
  borderRadius: borderRadius,
};

export const tableStyles = {
  bgColor: "#fff",
  borderWidth: "1px",
  borderColor: borderColor,
  borderRadius: borderRadius,
};

export const cellStyles = {
  paddingTop: "1rem",
  paddingBottom: "1rem",
};

export const selectStyles = {
  control: (styles) => ({
    ...styles,
    borderRadius: borderRadius,
    "&:hover": {
      borderColor: "none",
    },
  }),
};
