import { useEffect, useState } from "react";
import Form from "./Form";
import { useForm } from "react-hook-form";
import { schema } from "./Form/schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate, useParams } from "react-router-dom";
import { getRoleTypes } from "../../services/getRoleTypes";
import { Toast } from "../../components/Toast";
import api from "../../utils/api";
import { ROLE_URL } from "../../utils/endpoints";

function NewRole() {
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [roleTypesOptions, setRoleTypesOptions] = useState([]);
  const navigate = useNavigate();
  const { addToast } = Toast();
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
  } = useForm({
    resolver: zodResolver(schema),
  });

  async function getRoleTypesOptions() {
    getRoleTypes()
      .then((res) => {
        setRoleTypesOptions(res);
        setIsLoaded(true);
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  useEffect(
    () => {
      getRoleTypesOptions();
    },
    // eslint-disable-next-line
    []
  );

  const createRole = () => {
    const data = getValues();
    api
      .post(ROLE_URL, {
        name: data.name,
        roleTypeId: data.roleTypeId,
        roleLevelId: data.roleLevelId,
        permissions: data.permissions,
      })
      .then(() => {
        addToast({
          title: "Perfil criado com sucesso",
          status: "success",
        });
        navigate("/perfis");
      })
      .catch((error) => {
        let errors = error.response.data.errors;
        for (let err in errors) {
          addToast({ title: errors[err], status: "error" });
        }
      });
  };

  return (
    <>
      {isLoaded && (
        <Form
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
          id={id}
          register={register}
          handleSubmit={handleSubmit}
          errors={errors}
          control={control}
          watch={watch}
          setValue={setValue}
          roleTypesOptions={roleTypesOptions}
          createRole={createRole}
        />
      )}
    </>
  );
}

export default NewRole;
