import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  Grid,
  GridItem,
  Text,
} from "@chakra-ui/react";
import Label from "../../../components/Label";
import Select from "react-select";
import { borderColor } from "../../../utils/colors";
import { selectStyles } from "../../../utils/styles";
import { Controller } from "react-hook-form";
import { useState, useEffect } from "react";
import { Toast } from "../../../components/Toast";
import { getRole } from "../../../services/getRole";
import { getProvince } from "../../../services/getProvince";
import { getCounty } from "../../../services/getCounty";
import { getSchool } from "../../../services/getSchool";
import { NO_OPTIONS_MESSAGE } from "../../../utils/constants";

function Filters({ watch, control, setValue, onSubmit, isSchool }) {
  const [roleOptions, setRoleOptions] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [countyOptions, setCountyOptions] = useState([]);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const { addToast } = Toast();

  async function getRoleOptions() {
    getRole()
      .then((res) => setRoleOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getProvinceOptions() {
    getProvince()
      .then((res) => setProvinceOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getCountyOptions(id) {
    getCounty({ ParentId: id })
      .then((res) => setCountyOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getSchoolOptions(provinceId, countyId) {
    getSchool(provinceId, countyId)
      .then((res) => setSchoolOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  useEffect(
    () => {
      getRoleOptions();
      getProvinceOptions();
      getSchoolOptions();
    },
    // eslint-disable-next-line
    []
  );

  const province = watch("province");

  useEffect(
    () => {
      if (province) {
        getCountyOptions(province?.value);
        getSchoolOptions(province?.value);
      } else {
        setValue("county", undefined);
        setValue("school", undefined);
        getSchoolOptions();
      }
    },
    // eslint-disable-next-line
    [province]
  );

  const county = watch("county");

  useEffect(
    () => {
      if (county) {
        setValue("school", undefined);
        getSchoolOptions(null, county?.value);
      } else {
        setValue("school", undefined);
        getSchoolOptions(province?.value);
      }
    },
    // eslint-disable-next-line
    [county]
  );

  const stateOptions = [
    {
      value: true,
      label: "Activo",
    },
    { value: false, label: "Inactivo" },
  ];

  const clearFilters = () => {
    setValue("role", undefined);
    setValue("province", undefined);
    setValue("county", undefined);
    setValue("school", undefined);
    setValue("state", undefined);
    onSubmit();
  };

  return (
    <>
      <Box position="relative" py={8}>
        <Divider borderColor={borderColor} />
        <Box
          bg="white"
          px="2"
          position="absolute"
          transform="translate(5%, -50%)"
        >
          <Text as="b" fontSize="sm" textTransform="uppercase">
            Filtrar lista de utilizadores
          </Text>
        </Box>
      </Box>
      <Box px="20px">
        <Grid gridTemplateColumns="1fr auto" gap={4}>
          <Grid templateColumns="repeat(5, 1fr)" gap={4}>
            <GridItem>
              <FormControl>
                <Label title="Perfil" />
                <Controller
                  control={control}
                  name="role"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      placeholder="Seleccionar"
                      options={roleOptions}
                      onChange={onChange}
                      value={value || ""}
                      styles={selectStyles}
                      isClearable={true}
                      noOptionsMessage={NO_OPTIONS_MESSAGE}
                    />
                  )}
                />
              </FormControl>
            </GridItem>
            {!isSchool && (
              <GridItem>
                <FormControl>
                  <Label title="Província" />
                  <Controller
                    control={control}
                    name="province"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        placeholder="Seleccionar"
                        options={provinceOptions}
                        onChange={onChange}
                        value={value || ""}
                        styles={selectStyles}
                        isClearable={true}
                        noOptionsMessage={NO_OPTIONS_MESSAGE}
                      />
                    )}
                  />
                </FormControl>
              </GridItem>
            )}

            {!isSchool && (
              <GridItem>
                <FormControl>
                  <Label title="Município" />
                  <Controller
                    control={control}
                    name="county"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        placeholder="Seleccionar"
                        options={countyOptions}
                        isDisabled={!province}
                        onChange={onChange}
                        value={value || ""}
                        styles={selectStyles}
                        isClearable={true}
                        noOptionsMessage={NO_OPTIONS_MESSAGE}
                      />
                    )}
                  />
                </FormControl>
              </GridItem>
            )}

            {!isSchool && (
              <GridItem>
                <FormControl>
                  <Label title="Escola" />
                  <Controller
                    control={control}
                    name="school"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        placeholder="Seleccionar"
                        options={schoolOptions}
                        onChange={onChange}
                        value={value || ""}
                        styles={selectStyles}
                        isClearable={true}
                        noOptionsMessage={NO_OPTIONS_MESSAGE}
                      />
                    )}
                  />
                </FormControl>
              </GridItem>
            )}
            <GridItem>
              <FormControl>
                <Label title="Estado" />
                <Controller
                  control={control}
                  name="state"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      placeholder="Seleccionar"
                      options={stateOptions}
                      onChange={onChange}
                      value={value || ""}
                      styles={selectStyles}
                      isClearable={true}
                      noOptionsMessage={NO_OPTIONS_MESSAGE}
                    />
                  )}
                />
              </FormControl>
            </GridItem>
          </Grid>
          <Flex alignSelf="end">
            <Button mr={4} onClick={onSubmit}>
              Filtrar
            </Button>
            <Button variant="link" onClick={clearFilters}>
              Limpar filtros
            </Button>
          </Flex>
        </Grid>
      </Box>
    </>
  );
}

export default Filters;
