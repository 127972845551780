import { ChevronLeftIcon, ChevronRightIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Icon,
  Input,
  StackDivider,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { MdInfoOutline } from "react-icons/md";
import { backgroundColor } from "../../../utils/colors";

function PickList({
  id,
  setValue,
  watch,
  featuresOptions,
  selectedPermissions,
  setSelectedPermissions,
  isDisabled,
  roleData,
  rolePermissions,
  isCancel,
  setIsCancel,
}) {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [filteredSelectedPermissions, setFilteredSelectedPermissions] =
    useState([]);

  const searchFeatures = (value) => {
    setFilteredOptions(
      featuresOptions.filter((item) =>
        item.label.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const searchPermissions = (value) => {
    setFilteredSelectedPermissions(
      selectedPermissions.filter((item) =>
        item.label.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const addAll = () => {
    setFilteredOptions([]);
    setSelectedPermissions([...selectedPermissions, ...filteredOptions]);
    setFilteredSelectedPermissions([
      ...filteredSelectedPermissions,
      ...filteredOptions,
    ]);
  };

  const addPermission = (item) => {
    setFilteredOptions(filteredOptions.filter((el) => el.value !== item.value));
    setSelectedPermissions([...selectedPermissions, item]);
    setFilteredSelectedPermissions([...selectedPermissions, item]);
  };

  const removePermission = (item) => {
    setSelectedPermissions(
      selectedPermissions.filter((el) => el.value !== item.value)
    );
    setFilteredSelectedPermissions(
      filteredSelectedPermissions.filter((el) => el.value !== item.value)
    );
    setFilteredOptions([...filteredOptions, item]);
  };

  const removeAll = () => {
    setFilteredOptions([...filteredOptions, ...filteredSelectedPermissions]);
    setSelectedPermissions([]);
    setFilteredSelectedPermissions([]);
  };

  const roleLevelId = watch("roleLevelId");
  const permissions = watch("permissions");

  useEffect(
    () => {
      if (permissions?.length > 0) {
        const matchingObjects = featuresOptions.filter((item) =>
          permissions.includes(item.value)
        );
        const nonMatchingObjects = featuresOptions.filter(
          (item) => !permissions.includes(item.value)
        );
        setFilteredOptions(nonMatchingObjects);
        setSelectedPermissions([...selectedPermissions, ...matchingObjects]);
        setFilteredSelectedPermissions([
          ...selectedPermissions,
          ...matchingObjects,
        ]);
      }
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (!id) {
        setFilteredOptions(featuresOptions);
      }
      if (!id && selectedPermissions.length > 0) {
        removeAll();
      }
      if (id && roleLevelId !== roleData.level.id.toString()) {
        setFilteredOptions(featuresOptions);
        setSelectedPermissions([]);
        setFilteredSelectedPermissions([]);
        setValue("permissions", undefined);
      }
    },
    // eslint-disable-next-line
    [featuresOptions]
  );

  useEffect(
    () => {
      setValue(
        "permissions",
        selectedPermissions.map((item) => item.value)
      );
    },
    // eslint-disable-next-line
    [selectedPermissions]
  );

  useEffect(
    () => {
      if (isCancel) {
        const matchingObjects = featuresOptions.filter((item) =>
          rolePermissions.includes(item.value)
        );
        const nonMatchingObjects = featuresOptions.filter(
          (item) => !rolePermissions.includes(item.value)
        );
        setFilteredOptions(nonMatchingObjects);
        setSelectedPermissions([...[], ...matchingObjects]);
        setFilteredSelectedPermissions([...[], ...matchingObjects]);
        setIsCancel(false);
      }
    },
    // eslint-disable-next-line
    [isCancel]
  );

  return (
    <Flex mt={4}>
      <VStack spacing="4" w="30%">
        <Input
          onChange={(e) => searchFeatures(e.target.value)}
          placeholder="Pesquisar..."
          isDisabled={isDisabled}
        />
        <VStack
          divider={<StackDivider borderColor="gray.200" />}
          spacing={1}
          align="stretch"
          w="100%"
        >
          {filteredOptions.map((item, i) => (
            <button
              key={i}
              onClick={(e) => {
                e.preventDefault();
                addPermission(item);
              }}
              disabled={isDisabled}
              style={{ backgroundColor: isDisabled ? "#fff" : "" }}
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
                textAlign="left"
                my={1}
              >
                <Flex
                  alignItems="center"
                  style={{ opacity: isDisabled ? "0.4" : "1" }}
                >
                  {item.label}
                  {item.description && (
                    <Tooltip
                      hasArrow
                      label={item.description}
                      isDisabled={isDisabled}
                    >
                      <Flex ms={1}>
                        <Icon as={MdInfoOutline} />
                      </Flex>
                    </Tooltip>
                  )}
                </Flex>
                <ChevronRightIcon
                  style={{ opacity: isDisabled ? "0.4" : "1" }}
                />
              </Flex>
            </button>
          ))}
        </VStack>
      </VStack>
      <VStack
        w="40%"
        flexDirection="column"
        justifyContent="start"
        alignItems="center"
      >
        <Button
          variant="secondary"
          rightIcon={<ChevronRightIcon />}
          w="180px"
          onClick={addAll}
          isDisabled={isDisabled}
        >
          Atribuir todas
        </Button>
        <Button
          variant="secondary"
          leftIcon={<ChevronLeftIcon />}
          w="180px"
          onClick={removeAll}
          isDisabled={isDisabled}
        >
          Remover todas
        </Button>
      </VStack>
      <VStack spacing="4" w="30%">
        <Input
          onChange={(e) => searchPermissions(e.target.value)}
          placeholder="Pesquisar..."
          isDisabled={isDisabled}
        />
        <VStack
          divider={<StackDivider borderColor="gray.200" />}
          spacing={1}
          align="stretch"
          w="100%"
        >
          {filteredSelectedPermissions.map((item, i) => (
            <button
              key={i}
              onClick={(e) => {
                e.preventDefault();
                removePermission(item);
              }}
              disabled={isDisabled}
              style={{ backgroundColor: isDisabled ? "#fff" : "" }}
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
                textAlign="left"
                my={1}
              >
                <Flex
                  alignItems="center"
                  style={{
                    opacity: isDisabled ? "0.4" : "1",
                  }}
                >
                  {item.label}
                  {item.description && (
                    <Tooltip
                      hasArrow
                      label={item.description}
                      isDisabled={isDisabled}
                    >
                      <Flex ms={1}>
                        <Icon as={MdInfoOutline} />
                      </Flex>
                    </Tooltip>
                  )}
                </Flex>
                <CloseIcon
                  boxSize={2}
                  style={{ opacity: isDisabled ? "0.4" : "1" }}
                />
              </Flex>
            </button>
          ))}
        </VStack>
      </VStack>
    </Flex>
  );
}

export default PickList;
