import { useContext, useEffect, useState } from "react";
import { Box, Button, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import { getCourse } from "../../services/getCourse";
import { formStyles } from "../../utils/styles";
import FilterArea from "./components/FilterArea";
import TableCourse from "./components/TableCourse";
import ButtonGroupCourse from "./components/ButtonGroupCourse";
import { Toast } from "../../components/Toast";
import { useForm } from "react-hook-form";
import handleFilterOrSearch from "../../helpers/handleFilterOrSearch";
import SearchArea from "../../components/SearchArea";
import { COURSE_SUMMARY } from "../../utils/constants";
import { AuthContext } from "../../provider/AuthProvider";
import { CrumbContext } from "../../provider/CrumbProvider";
import { getCourseByQs } from "../../services/getCourseByQs";
import { Link } from "react-router-dom";
import { MdArrowOutward } from "react-icons/md";

function Course() {
  const { auth } = useContext(AuthContext);
  const user = auth.user;
  const { crumb, setCrumb } = useContext(CrumbContext);

  const { addToast } = Toast();
  const { register, control, watch, setValue } = useForm();
  const educationLevel = watch("educationLevel");
  const generalArea = watch("generalArea");
  const specificArea = watch("specificArea");
  const subSystemEducation = watch("educationSubsystem");
  const domainPNFQ = watch("domainPNFQ");
  const technicalEducation = watch("technicalEducation");
  const detailedArea = watch("detailedArea");
  const educationCycle = watch("educationCycle");
  const isValidated = watch("isValidated");
  const inputSearch = watch("search");
  const isActive = watch("isActive");

  const paramsObj = {
    Pesquisa: inputSearch,
    EducationalLevel: educationLevel?.value,
    GeneralArea: generalArea?.value,
    SpecificArea: specificArea?.value,
    EducationalSubsystem: subSystemEducation?.value,
    PNFQDomain: domainPNFQ?.value,
    CourseTechnical: technicalEducation?.value,
    DetailedArea: detailedArea?.value,
    EducationalCycle: educationCycle?.value,
    IsValidated: isValidated?.value,
    IsActive: isActive?.value,
  };

  const [dataTable, setDataTable] = useState([]);

  const handleSearch = async () => {
    if (inputSearch) {
      const data = await handleFilterOrSearch(
        paramsObj,
        addToast,
        getCourseByQs
      );
      setDataTable(data);
    }
  };

  useEffect(() => {
    setCrumb({
      ...crumb,
      course: undefined,
    });
  }, []);

  const handleCleanFilters = async () => {
    setValue("search", undefined);

    let valParams = [];
    for (const key in paramsObj) {
      valParams.push(paramsObj[key]);
    }

    const response = valParams.includes(undefined)
      ? await handleFilterOrSearch(
          { ...paramsObj, Pesquisa: undefined },
          addToast,
          getCourse
        )
      : await getCourse();
    setDataTable(response);
  };

  return (
    <Stack spacing={5}>
      <Box>
        <Heading mb="10px">Cursos</Heading>
        <Text width="sm">{COURSE_SUMMARY}</Text>
      </Box>

      {user.permissions.includes("CreateCourses") && <ButtonGroupCourse />}

      <Box>
        <Heading as="h2" size="md" textTransform="uppercase" mb="10px">
          Pesquisar
        </Heading>

        <Box as="form" sx={formStyles} px="0!important">
          <SearchArea
            register={register}
            handleCleanFilters={handleCleanFilters}
            handleSearch={handleSearch}
            title={"Pesquisar pelo código ou designação do curso"}
          />

          <FilterArea
            setValue={setValue}
            addToast={addToast}
            educationLevel={educationLevel}
            subSystemEducation={subSystemEducation}
            generalArea={generalArea}
            specificArea={specificArea}
            params={paramsObj}
            handleFilterOrSearchCourse={handleFilterOrSearch}
            control={control}
            setDataTable={setDataTable}
            inputSearch={inputSearch}
          />
        </Box>
      </Box>
      <Box>
        <TableCourse
          setDataTable={setDataTable}
          dataTable={dataTable}
          params={paramsObj}
          setValue={setValue}
        />

        <Flex justifyContent="end" mt={3}>
          <Link to="/docs/manual-cursos-sietp.pdf" target="_blank">
            <Button variant="secondary" rightIcon={<MdArrowOutward />}>
              Consultar Manual de Apoio
            </Button>
          </Link>
        </Flex>
      </Box>
    </Stack>
  );
}

export default Course;
