import { Flex, Box } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import Menu from "../Menu";
import SchoolMenu from "../Menu/SchoolMenu";
import { primaryColor, backgroundColor } from "../../utils/colors";
import Header from "../Header";
import api from "../../utils/api";
import { useContext, useState } from "react";
import { AuthContext } from "../../provider/AuthProvider";

function Layout() {
  const [schoolId, setSchoolId] = useState(null);
  const [isValidated, setIsValidated] = useState(null);
  const { auth } = useContext(AuthContext);
  const user = auth.user;

  if (auth.isSchool) {
    api.get(`/api/School/userSchool?Id=${user.id}`).then((response) => {
      setSchoolId(response.data.id);
      setIsValidated(response.data.isValidated);
    });
  }

  return (
    <Flex as="main" width="100vw" height="100vh">
      <Flex
        width="350px"
        color="#fff"
        bgColor={primaryColor}
        padding="60px 30px 30px 30px"
        overflowY="auto"
      >
        {auth.isSchool ? (
          <SchoolMenu schoolId={schoolId} isValidated={isValidated} />
        ) : (
          <Menu />
        )}
      </Flex>
      <Box w="100%" bgColor={backgroundColor} padding="60px" overflowY="auto">
        <Header />
        <Outlet />
      </Box>
    </Flex>
  );
}

export default Layout;
