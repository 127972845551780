import { useMemo } from "react";
import Table from "../../../components/Table";
import { Button } from "@chakra-ui/react";
import { downloadExcelFile } from "../../../utils/downloadExcelFile";

function TableStudents({ data }) {
  let columns = useMemo(
    () => [
      {
        Header: "Indicador",
        accessor: "name",
        disableSortBy: true,
      },
      {
        Header: "Tabela base",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <Button
              variant="secondary"
              onClick={() => {
                const apiUrl = `/api/Reports/downloadStudentsReport?ReportType=${item.row.original.id}`;
                downloadExcelFile(
                  apiUrl,
                  `Alunos ${item.row.original.id}.xlsx`
                );
              }}
            >
              Descarregar
            </Button>
          );
        },
      },
      {
        Header: "Quadros padronizados",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <Button variant="secondary" isDisabled="true">
              Descarregar
            </Button>
          );
        },
      },
    ],
    []
  );

  return (
    <>{data && <Table columns={columns} data={data} isPaginated={false} />}</>
  );
}

export default TableStudents;
