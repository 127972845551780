import { z } from "zod";
import { INVALID_EMAIL, REQUIRED_FIELD } from "../../../utils/constants";

const baseSchema = z.object({
  name: z.string().nonempty(REQUIRED_FIELD),
  email: z.string().nonempty(REQUIRED_FIELD).email(INVALID_EMAIL),
  role: z.array(
    z.object({
      value: z.string(),
      label: z.string(),
    })
  ),
  level: z.literal(1),
});

const nationalSchema = z.object({
  name: z.string().nonempty(REQUIRED_FIELD),
  email: z.string().nonempty(REQUIRED_FIELD).email(INVALID_EMAIL),
  role: z.array(
    z.object({
      value: z.string(),
      label: z.string(),
    })
  ),
  level: z.literal(2),
});

const provinceSchema = z.object({
  name: z.string().nonempty(REQUIRED_FIELD),
  email: z.string().nonempty(REQUIRED_FIELD).email(INVALID_EMAIL),
  role: z.array(
    z.object({
      value: z.string(),
      label: z.string(),
    })
  ),
  level: z.literal(3),
  province: z.object({
    value: z.number(),
    label: z.string(),
  }),
});

const countySchema = z.object({
  name: z.string().nonempty(REQUIRED_FIELD),
  email: z.string().nonempty(REQUIRED_FIELD).email(INVALID_EMAIL),
  role: z.array(
    z.object({
      value: z.string(),
      label: z.string(),
    })
  ),
  level: z.literal(4),
  province: z.object({
    value: z.number(),
    label: z.string(),
  }),
  county: z.object({
    value: z.number(),
    label: z.string(),
  }),
});

const schoolSchema = z.object({
  name: z.string().nonempty(REQUIRED_FIELD),
  email: z.string().nonempty(REQUIRED_FIELD).email(INVALID_EMAIL),
  role: z.array(
    z.object({
      value: z.string(),
      label: z.string(),
    })
  ),
  level: z.literal(5),
  province: z.object({
    value: z.number(),
    label: z.string(),
  }),
  county: z.object({
    value: z.number(),
    label: z.string(),
  }),
  school: z.object({ value: z.number(), label: z.string() }),
});

export const schema = z.discriminatedUnion("level", [
  baseSchema,
  nationalSchema,
  provinceSchema,
  countySchema,
  schoolSchema,
]);
