import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../provider/AuthProvider";
import { Box, Button, Flex } from "@chakra-ui/react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import DeleteTeacher from "./DeleteTeacher";
import ArchiveTeacher from "./ArchiveTeacher";

function FormButtons({
  isDisabled,
  setIsDisabled,
  id,
  handleTabChange,
  activeTab,
  createTeacher,
  cancelEdit,
  isActive,
}) {
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const user = auth.user;

  return (
    <Box>
      {isDisabled ? (
        id ? (
          <>
            {user.permissions.includes("EditTeachers") && (
              <Box mb={4}>
                <Button onClick={() => setIsDisabled(false)}>
                  Editar Docente
                </Button>
              </Box>
            )}
            <Flex direction="row" gap={3}>
              {user.permissions.includes("EditTeachers") && (
                <ArchiveTeacher id={id} isActive={isActive} />
              )}
              {user.permissions.includes("DeleteTeachers") && (
                <DeleteTeacher id={id} />
              )}
            </Flex>
          </>
        ) : (
          <>
            <Button mr={4} onClick={createTeacher}>
              Confirmar
            </Button>
            <Button
              variant="link"
              onClick={() => {
                handleTabChange(0);
                setIsDisabled(false);
              }}
            >
              Voltar a editar
            </Button>
          </>
        )
      ) : id ? (
        activeTab === 0 ? (
          <>
            <Button
              variant="secondary"
              onClick={() => {
                handleTabChange(1);
              }}
              mr={3}
              rightIcon={<MdKeyboardArrowRight />}
            >
              Informações do Docente
            </Button>
            <Button variant="secondary" onClick={cancelEdit}>
              Cancelar
            </Button>
          </>
        ) : activeTab === 1 ? (
          <Flex justifyContent="space-between">
            <Box>
              <Button
                variant="secondary"
                onClick={() => {
                  handleTabChange(0);
                }}
                mr={4}
                leftIcon={<MdKeyboardArrowLeft />}
              >
                Informações do Docente
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  if (!user?.schoolId) {
                    navigate("/docentes");
                  } else {
                    navigate(`/gerir/${user?.schoolId}/docentes`);
                  }
                }}
              >
                Cancelar
              </Button>
            </Box>
            <Button
              variant="secondary"
              onClick={() => {
                handleTabChange(2);
              }}
              rightIcon={<MdKeyboardArrowRight />}
            >
              Associar Cursos/Disciplinas
            </Button>
          </Flex>
        ) : (
          <Flex justifyContent="space-between">
            <Box>
              <Button type="submit" mr={3}>
                Guardar
              </Button>
              <Button variant="secondary" onClick={cancelEdit}>
                Cancelar
              </Button>
            </Box>
            <Button
              variant="secondary"
              onClick={() => {
                handleTabChange(0);
              }}
              leftIcon={<MdKeyboardArrowLeft />}
            >
              Informações do Docente
            </Button>
          </Flex>
        )
      ) : activeTab === 0 ? (
        <>
          <Button
            variant="secondary"
            onClick={() => {
              handleTabChange(1);
            }}
            mr={3}
            rightIcon={<MdKeyboardArrowRight />}
          >
            Vínculo e Actividade Profissional
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              if (!user?.schoolId) {
                navigate("/docentes");
              } else {
                navigate(`/gerir/${user?.schoolId}/docentes`);
              }
            }}
          >
            Cancelar
          </Button>
        </>
      ) : activeTab === 1 ? (
        <Flex justifyContent="space-between">
          <Box>
            <Button
              variant="secondary"
              onClick={() => {
                handleTabChange(0);
              }}
              mr={3}
              leftIcon={<MdKeyboardArrowLeft />}
            >
              Informações do Docente
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                if (!user?.schoolId) {
                  navigate("/docentes");
                } else {
                  navigate(`/gerir/${user?.schoolId}/docentes`);
                }
              }}
            >
              Cancelar
            </Button>
          </Box>
          <Button
            variant="secondary"
            onClick={() => {
              handleTabChange(2);
            }}
            rightIcon={<MdKeyboardArrowRight />}
          >
            Associar Cursos/Disciplinas
          </Button>
        </Flex>
      ) : (
        <Flex justifyContent="space-between">
          <Box>
            <Button type="submit" mr={3}>
              Cadastrar Docente
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                if (!user?.schoolId) {
                  navigate("/docentes");
                } else {
                  navigate(`/gerir/${user?.schoolId}/docentes`);
                }
              }}
            >
              Cancelar
            </Button>
          </Box>
          <Button
            variant="secondary"
            onClick={() => {
              handleTabChange(1);
            }}
            leftIcon={<MdKeyboardArrowLeft />}
          >
            Vínculo e Actividade Profissional
          </Button>
        </Flex>
      )}
    </Box>
  );
}

export default FormButtons;
