import {
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import Table from "../../../../components/Table";

function Details({ id, data, setData, text = null, details }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const columns = useMemo(
    () => [
      {
        Header: "Código / Identificação",
        accessor: (row) => row.code || row.documentNumber,
        disableSortBy: true,
      },
      {
        Header: "Nome / Designação",
        accessor: "name",
      },
    ],
    []
  );

  return (
    <>
      {text ? (
        <Text
          onClick={onOpen}
          _hover={{
            cursor: "pointer",
          }}
        >
          {text}
        </Text>
      ) : (
        <Center
          _hover={{
            cursor: "pointer",
          }}
        >
          <MdOutlineRemoveRedEye onClick={onOpen} />
        </Center>
      )}
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader mr="5">Detalhes: {details}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table
              // setIsLoaded={setIsLoded}
              setData={setData}
              columns={columns}
              data={data}
              // isLoaded={isLoaded}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Fechar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Details;
