import api from "../utils/api";
import { ENROLLMENT_STATUS_URL } from "../utils/endpoints";

export async function getEnrollmentStatus(params) {
  let qs = "";
  qs += `OnlyActive=${
    params?.OnlyActive !== undefined ? params.OnlyActive : true
  }`;
  qs += params?.ParentId ? `&ParentId=${params.ParentId}` : "";
  try {
    const response = await api.get(`${ENROLLMENT_STATUS_URL}?${qs}`);
    const data = response.data.map((option) => ({
      value: option.id,
      label: option.name,
      code: option.code,
      isActive: option.isActive,
    }));
    return data;
  } catch (error) {
    throw error;
  }
}
