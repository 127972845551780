import { Box, Button, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { MdAddCircle, MdArrowOutward, MdDownload } from "react-icons/md";
import { useContext, useEffect, useState } from "react";
import api from "../../utils/api";
import { DOWNLOAD_ROLES_URL, ROLE_URL } from "../../utils/endpoints";
import { ROLE_SUMMARY } from "../../utils/constants";
import TableRoles from "./TableRoles";
import { downloadExcelFile } from "../../utils/downloadExcelFile";
import { AuthContext } from "../../provider/AuthProvider";

function Roles() {
  const [roles, setRoles] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const { auth } = useContext(AuthContext);
  const user = auth.user;

  useEffect(() => {
    api.get(`${ROLE_URL}?PageSize=2500`).then((response) => {
      const data = response.data.result;
      setRoles(data);
      setIsLoaded(true);
    });
  }, []);

  const download = () => {
    setIsDownloading(true);
    downloadExcelFile(DOWNLOAD_ROLES_URL, "Perfis.xlsx");
    setIsDownloading(false);
  };

  return (
    <Stack spacing={5}>
      <Box>
        <Heading as="h1" mb="10px">
          Perfis
        </Heading>

        <Box w="sm">
          <Text>{ROLE_SUMMARY}</Text>
        </Box>
      </Box>

      {user.permissions.includes("CreateRole") && (
        <Box>
          <Link to="/perfis/criar">
            <Button rightIcon={<MdAddCircle />} mr={2}>
              Criar Perfil
            </Button>
          </Link>
        </Box>
      )}

      <Box>
        <Flex justifyContent="space-between" alignItems="end" mb="10px">
          <Heading as="h2" size="md" textTransform="uppercase">
            Lista de perfis
          </Heading>
          <Button
            variant="secondary"
            rightIcon={<MdDownload />}
            onClick={download}
            isLoading={isDownloading}
          >
            Descarregar lista
          </Button>
        </Flex>

        <TableRoles data={roles} isLoaded={isLoaded} />

        <Flex justifyContent="end" mt={3}>
          <Link to="/docs/manual-administracao-sietp.pdf" target="_blank">
            <Button variant="secondary" rightIcon={<MdArrowOutward />}>
              Consultar Manual de Apoio
            </Button>
          </Link>
        </Flex>
      </Box>
    </Stack>
  );
}

export default Roles;
