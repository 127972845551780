import { handleEnterOnSearch } from "../../utils/constants";
import Label from "../Label";
import { Button, Input, Box, Grid, Flex } from "@chakra-ui/react";
export default function SearchArea({
  register,
  handleCleanFilters,
  handleSearch,
  title,
}) {
  return (
    <Box px="20px">
      <Label title={title} />

      <Grid gridTemplateColumns="1fr auto">
        <Flex mr={4}>
          <Input
            {...register("search")}
            sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            onKeyDown={(e) => {
              handleEnterOnSearch(e, handleSearch);
            }}
          />

          <Button
            onClick={handleSearch}
            sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          >
            Pesquisar
          </Button>
        </Flex>

        <Flex>
          <Button variant="link" onClick={handleCleanFilters}>
            Limpar consulta
          </Button>
        </Flex>
      </Grid>
    </Box>
  );
}
