import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  HStack,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { BsQuestionCircle } from "react-icons/bs";
import { DIALOG_MESSAGE } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { Toast } from "../../../components/Toast";
import { useContext } from "react";
import { AuthContext } from "../../../provider/AuthProvider";
import { TEACHER_URL } from "../../../utils/endpoints";

function DeleteTeacher({ id }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { addToast } = Toast();
  const { auth } = useContext(AuthContext);
  const user = auth.user;

  const deleteTeacher = async () => {
    const API_URL = process.env.REACT_APP_API_URL;
    let token = localStorage.getItem("@retfoptoken");

    let options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json", // Add content-type
      },
    };

    if (token) {
      options.headers["Authorization"] = `Bearer ${token}`;
    }

    try {
      const response = await fetch(`${API_URL}${TEACHER_URL}/${id}`, options);

      if (!response.ok) {
        const error = await response.json();
        let errors = error.errors;
        for (let err in errors) {
          addToast({ title: errors[err], status: "error" });
        }
        onClose();
      } else {
        addToast({
          title: "Docente excluído com sucesso",
          status: "success",
        });
        if (!user?.schoolId) {
          navigate("/docentes");
        } else {
          navigate(`/gerir/${user?.schoolId}/docentes`);
        }
      }
    } catch (error) {
      console.error("Request error:", error);
    }
  };

  return (
    <HStack>
      <Button variant="secondary" onClick={onOpen}>
        Excluir
      </Button>
      <Tooltip
        hasArrow
        label="Esta acção exclui, de forma permanente, este docente do sistema"
      >
        <Flex alignItems="center">
          <BsQuestionCircle />
        </Flex>
      </Tooltip>

      <AlertDialog isOpen={isOpen} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Excluir Docente
            </AlertDialogHeader>

            <AlertDialogBody>{DIALOG_MESSAGE}</AlertDialogBody>

            <AlertDialogFooter>
              <Button variant="secondary" onClick={onClose} mr={3}>
                Cancelar
              </Button>
              <Button onClick={deleteTeacher}>Excluir</Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </HStack>
  );
}
export default DeleteTeacher;
