import api from "../utils/api";
import { GENERAL_AREA_URL } from "../utils/endpoints";

export async function getGeneralArea(params) {
  try {
    let qs = "";
    qs += `OnlyActive=${
      params?.OnlyActive !== undefined ? params.OnlyActive : true
    }`;
    qs += params?.ParentId ? `&ParentId=${params.ParentId}` : "";
    const response = await api.get(`${GENERAL_AREA_URL}?${qs}`);
    const data = response.data.map((option) => ({
      value: option.id,
      label: option.name,
      isActive: option.isActive,
      code: option.code,
    }));
    return data;
  } catch (error) {
    throw error;
  }
}
