export const LOGIN_URL = "/api/User/authenticate";
export const USER_URL = "/api/User";
export const GET_SELF_DATA_URL = "/api/User/getselfdata";
export const EDIT_USER_URL = "/api/User/editUser";
export const ENABLE_USER_URL = "/api/User/enable";
export const DISABLE_USER_URL = "/api/User/disable";
export const DOWNLOAD_USERS_URL = "/api/User/downloadUsers";
export const RESET_PASSWORD = "/api/User/ResetPassword";
export const USER_PASSWORD = "/api/User/password";
export const REFRESH_TOKEN = "/api/User/refreshtoken";
export const ROLE_URL = "/api/Role";
export const GET_ROLE_URL = "/api/Role/getRoleById";
export const EDIT_ROLE_URL = "/api/Role/editRole";
export const ROLE_TYPES_URL = "/api/Role/getroletypes";
export const ROLE_LEVELS_URL = "/api/Role/getrolelevels";
export const ROLE_FEATURES_URL = "/api/Role/getallfeatures";
export const DOWNLOAD_ROLES_URL = "/api/Role/downloadRoles";
export const PROVINCE_URL = "/api/Lookup/province";
export const COUNTY_URL = "/api/Lookup/county";
export const SCHOOL_URL = "/api/School";
export const SCHOOL_NATURES = "/api/Lookup/SchoolNatures";
export const SCHOOL_TIPOLOGY = "/api/Lookup/SchoolTypologies";
export const SCHOOL_TYPE = "/api/Lookup/SchoolTypes";
export const SCHOOL_VALIDATE = "/api/School/validate";
export const SCHOOL_INVALIDATE = "/api/School/invalidate";
export const ENABLE_SCHOOL_URL = "/api/School/enable";
export const DISABLE_SCHOOL_URL = "/api/School/disable";
export const COMMUNE_URL = "/api/Lookup/commune";
export const URBANDISTRICT_URL = "/api/Lookup/urbandistricts";
export const SUBJECT_URL = "/api/Lookup/subject";
export const EDUCATION_LEVEL_URL = "/api/Lookup/EducationalLevel";
export const SUBSYSTEM_EDUCATION_URL = "/api/Lookup/EducationalSubsystem";
export const CICLE_EDUCATION_URL = "/api/Lookup/EducationalCycle";
export const GENERAL_AREA_URL = "/api/Lookup/CourseGeneralArea";
export const SPECIFIC_AREA_URL = "/api/Lookup/CourseSpecificArea";
export const DETAILED_AREA_URL = "/api/Lookup/CourseDetailedArea";
export const TECHNICAL_EDUCATION_URL = "/api/Lookup/CourseTechnical";
export const DOMAIN_PNFQ_URL = "/api/Lookup/CoursePNFQ";
export const COURSE_URL = "/api/Course";
export const COURSE_URL_JSON = "../../fake-data/course.json";
export const LINK_COURSE_SUBJECT = "/api/Course/linkCourseSubjects";
export const UNLINK_COURSE_SUBJECT = "/api/Course/unlinkCourseSubjects";
export const LINK_COURSE_TO_SCHOOL =
  "/api/School/LinkCoursesToSchoolByAcademicYear";
export const SCHOOL_URL_EDIT = "/api/School/editSchool";
export const LINK_COURSE_MY_SCHOOL =
  "/api/School/LinkCoursesToMySchoolByAcademicYear";
export const LINK_COURSE_SCHOOL =
  "/api/Course/linkCourseToSchoolsByAcademicYear";
export const UNLINK_COURSE_SCHOOL = "/api/Course/unlinkCourseSchools";
export const DISABLE_COURSE_URL = "/api/Course/disable";
export const ENABLE_COURSE_URL = "/api/Course/enable";
export const VALIDATE_COURSE = "/api/Course/validate";
export const INVALIDATE_COURSE = "/api/Course/invalidate";
export const TEACHER_URL = "/api/Teacher";
export const EDIT_TEACHER_URL = "/api/Teacher/editTeacher";
export const ACADEMIC_YEAR_URL = "/api/AcademicYear";
export const CONTRATUAL_BONDS_URL = "/api/Lookup/ContractualBondType";
export const LAB_TYPES_URL = "/api/Lookup/LabTypes";
export const ACADEMIC_QUALIFICATION_URL = "/api/Lookup/AcademicQualification";
export const TRAINING_AREA_URL = "/api/Lookup/TrainingArea";
export const NATIONALITY_URL = "/api/Lookup/Nationality";
export const CONTRACTUAL_BOND_TYPE_URL = "/api/Lookup/ContractualBondType";
export const PROBATIONARY_PERIOD_URL = "/api/Lookup/ProbationaryPeriod";
export const TEACHING_FUNCTION_URL = "/api/Lookup/TeachingFunction";
export const GENDER_URL = "/api/Lookup/Gender";
export const ADMINISTRATIVE_POSITION_URL = "/api/Lookup/AdministrativePosition";
export const ACADEMIC_YEAR_FORMAT_URL = "/api/Lookup/AcademicYearFormat";
export const PEDAGOGICAL_TRAINING_URL = "/api/Lookup/PedagogicalTraining";
export const UPLOAD_FILE = "/api/School/upload";
export const VALIDATE_TEACHER = "/api/Teacher/validate";
export const VALIDATE_STUDENT = "/api/Student/validate";
export const INVALIDATE_TEACHER = "/api/Teacher/invalidate";
export const INVALIDATE_STUDENT = "/api/Student/invalidate";
export const DELETE_STUDENT_URL = "/api/Student/delete";
export const CLASS_LETTER_URL = "/api/Lookup/ClassLetter";
export const CLASS_MODALITY_URL = "/api/Lookup/ClassModality";
export const CLASS_SHIFT_URL = "/api/Lookup/ClassShift";
export const CLASS_URL = "/api/Lookup/Class";
export const SPECIAL_NEEDS_TYPES_URL = "/api/Lookup/SpecialEducationNeedTypes";
export const SPECIAL_NEEDS_URL = "/api/Lookup/SpecialEducationNeeds";
export const ENROLLMENT_STATUS_URL = "/api/Lookup/StudentEnrollmentStatus";
export const BOND_RESULT_URL = "/api/Lookup/SchoolBondResult";
export const IDENTIFICATION_DOCUMENT_TYPE_URL =
  "/api/Lookup/IdentificationDocumentType";
export const STUDENT_URL = "/api/Student";
export const EDIT_STUDENT_URL = "/api/Student/editStudent";
export const GET_MY_SCHOOL = "/api/School/userSchool";
export const START_CLOSE_ACADEMIC_YEAR = "/api/AcademicYear/StartClosing";
export const STOP_CLOSE_ACADEMIC_YEAR = "/api/AcademicYear/StopClosing";
export const DOWNLOAD_SCHOOLS = "/api/School/downloadSchools";
export const DOWNLOAD_COURSES = "/api/Course/downloadCourses";
export const DOWNLOAD_STUDENTS = "/api/Student/downloadStudents";
export const DOWNLOAD_TEACHERS = "/api/Teacher/downloadTeachers";
export const ADD_ITEM_LOOKUP = "/api/Lookup/AddItemToLookup";
export const ENABLE_DISABLE_LOOKUP_ITEM = "/api/Lookup/EnableDisableLookupItem";
export const DOWNLOAD_SCHOOL_URL = "/api/School/downloadSchools";
export const SCHOOL_CLOSE_ACADEMIC_YEAR_FOR_SCHOOL =
  "/api/School/CloseAcademicYearForSchool";
export const SCHOOL_CLOSE_ACADEMIC_YEAR_FOR_MY_SCHOOL =
  "/api/School/CloseAcademicYearForMySchool";
export const DELETE_LOOKUP_ITEM = "/api/Lookup/DeleteLookupItem";
export const UPDATE_ITEM_LOOKUP = "/api/Lookup/UpdateLookupItem";
export const GET_AUDIT_TYPOLOGIES = "/api/Audit/getAuditTypologies";
export const GET_AUDIT_LIST_DEFAULT = "/api/Audit/getAuditListDefault";
export const GET_AUDIT_LIST_ADMIN = "/api/Audit/getAuditListAdmin";
export const DOWNLOAD_AUDIT_DEFAULT = "/api/Audit/downloadAuditDefault";
export const DOWNLOAD_AUDIT_ADMIN = "/api/Audit/downloadAuditAdmin";
export const ADD_ITEM_LOOKUP_BULK = "/api/Lookup/AddItemToLookupBulk";
export const UPDATE_MYSCHOOL = "/api/School/editUserSchool";
export const DOWNLOAD_TEMPLATE_SCHOOL = "/api/School/downloadTemplateSchools";
export const DOWNLOAD_TEMPLATE_COURSE = "/api/Course/downloadTemplateCourses";
export const DOWNLOAD_TEMPLATE_TEACHER =
  "/api/Teacher/downloadTemplateTeachers";
export const DOWNLOAD_TEMPLATE_STUDENT =
  "/api/Student/downloadTemplateStudents";
