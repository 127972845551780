import { Box, Button, Heading, Stack, Text } from "@chakra-ui/react";
import { Link, useParams } from "react-router-dom";
import { MdAddCircle } from "react-icons/md";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import TableUsers from "../../../Users/TableUsers";
import { Toast } from "../../../../components/Toast";
import { USER_SUMMARY } from "../../../../utils/constants";
import { formStyles } from "../../../../utils/styles";
import api from "../../../../utils/api";
import Search from "../../../Users/components/Search";
import Filter from "../../../Users/components/Filters";
import { AuthContext } from "../../../../provider/AuthProvider";
import { USER_URL } from "../../../../utils/endpoints";

function ManagerUsers() {
  const [users, setUsers] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSchool, setIsSchool] = useState(true);
  const [showFilter, setShowFilter] = useState(false);

  const { id } = useParams();
  const { auth } = useContext(AuthContext);
  const { addToast } = Toast();
  const user = auth;

  async function getUsers() {
    setIsLoaded(true);
    try {
      if (!user.isSchool) {
        const response = await api.get(USER_URL, {
          params: {
            SchoolId: id,
          },
        });

        const data = response.data.result;
        setUsers(data);
        setIsLoaded(true);
        setShowFilter(true);
      } else {
        const response = await api.get("/api/User/getUsersMySchool");
        const data = response.data.result;

        setUsers(data);
        setIsLoaded(true);
      }
    } catch (error) {
      setIsLoaded(false);
      let errors = error.response.data.errors;
      for (let err in errors) {
        addToast({ title: errors[err], status: "error" });
      }
    }
  }

  useEffect(() => {
    getUsers();
  }, []);

  const { register, handleSubmit, setValue, getValues, watch, control } =
    useForm();

  const onSubmit = () => {
    const data = getValues();

    let apiUrl =
      user.isSchool === true
        ? `/api/User/getUsersMySchool?NameOrEmail=${data.name}&SchoolId=${id}`
        : USER_URL;

    if (data.role?.value) {
      apiUrl += `&RoleId=${data.role?.value}`;
    }
    if (data.province?.value) {
      apiUrl += `&ProvinceId=${data.province?.value}`;
    }
    if (data.county?.value) {
      apiUrl += `&CountyId=${data.county?.value}`;
    }
    if (data.school?.value) {
      apiUrl += `&SchoolId=${data.school?.value}`;
    }
    if (data.state?.value !== undefined) {
      apiUrl += `&IsActive=${data.state?.value}`;
    }
    setIsLoaded(false);
    api.get(apiUrl).then((response) => {
      const result = response.data.result;

      setUsers(result);
      setIsLoaded(true);
    });
  };

  return (
    <Stack spacing={5}>
      <Box>
        <Heading as="h1" mb={2}>
          Utilizadores
        </Heading>

        <Box w="lg">
          <Text>{USER_SUMMARY}</Text>
        </Box>
      </Box>

      {user.user.permissions.includes("CreateUser") && (
        <Box>
          <Link to="/utilizadores/cadastrar">
            <Button rightIcon={<MdAddCircle />}>Cadastrar</Button>
          </Link>
        </Box>
      )}

      <Box>
        <Heading as="h2" size="md" textTransform="uppercase" mb={2}>
          Pesquisar
        </Heading>
        <Box
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={formStyles}
          px="0!important"
        >
          <Search register={register} setValue={setValue} onSubmit={onSubmit} />

          {showFilter && (
            <Filter
              watch={watch}
              control={control}
              setValue={setValue}
              onSubmit={onSubmit}
              isSchool={false}
            />
          )}
        </Box>
      </Box>

      <Box>
        <Heading as="h2" size="md" textTransform="uppercase" mb={2}>
          Lista de utilizadores
        </Heading>

        <TableUsers data={users} isLoaded={isLoaded} isSchool={isSchool} />
      </Box>
    </Stack>
  );
}

export default ManagerUsers;
