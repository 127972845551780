import { useMemo, useEffect, useState } from "react";
import { Button, Heading, Text } from "@chakra-ui/react";
import { MdModeEditOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import { getAcademicYear } from "../../../services/getAcademicYear";
import Table from "../../../components/Table";
import { START_CLOSE_ACADEMIC_YEAR } from "../../../utils/endpoints";
import api from "../../../utils/api";
import { Toast } from "../../../components/Toast";
import Alert from "../../../components/Alert";
export default function TableAcademicYear({ setDataTable, dataTable }) {
  const { addToast } = Toast();
  const [isLoaded, setIsLoded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [idAcademicYear, setIdAcademicYear] = useState(0);

  useEffect(() => {
    const getData = async () => {
      const response = await getAcademicYear();

      setDataTable(response);
      setIsLoded(true);
    };
    getData();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Ano Lectivo",
        accessor: "year",
      },
      {
        Header: "Estado",
        Cell: (item) => {
          console.log(item.data[item.row.id].closeable);
          return !item.data[item.row.id].closeable ? (
            <Text>A decorrer</Text>
          ) : (
            <Text>Encerrado</Text>
          );
        },
      },
      /*  {
            Header: "Escolas Por Encerrar",
            Cell: (item) => {
              return !item.data[item.row.id].isValidated
              ? <Text>Pendente de Validação</Text> :
              <Text>Validado</Text>
              ;
            },
          }
          ,*/

      {
        Header: "Notificar",
        Cell: (item) => {
          return (
            <Button
              isDisabled={item.data[item.row.id].closeable}
              onClick={() => {
                setIsOpen(true);
                setIdAcademicYear(item.data[item.row.id].id);
              }}
            >
              Notificar fecho do Ano Lectivo
            </Button>
          );
        },
      },
      /*{
        Header: "Escolas Por Encerrar",
        Cell: (item) => {
          return <></>;
        },
      },*/
      {
        Header: "Editar",
        Cell: (item) => {
          return (
            <Link to={`/anos-lectivos/${item.data[item.row.id].id}`}>
              <MdModeEditOutline />
            </Link>
          );
        },
      },
    ],
    []
  );

  const handleBack = async () => {
    try {
      const response = await api.patch(START_CLOSE_ACADEMIC_YEAR, {
        id: idAcademicYear,
      });
      if (response.status === 200) {
        addToast({
          title: "Estado do ano lectivo alterado com sucesso",
          status: "success",
        });
        setIsLoded(false);
        const response1 = await getAcademicYear();
        setDataTable(response1);
        setIsLoded(true);
      }
    } catch (e) {
      let errors = e.response.data.errors;
      for (let err in errors) {
        addToast({ title: errors[err], status: "error" });
      }
    }
  };
  return (
    <>
      <Heading as="h2" size="md" textTransform="uppercase">
        Lista De Anos Lectivos
      </Heading>
      <Table
        setIsLoaded={setIsLoded}
        getFunction={getAcademicYear}
        setData={setDataTable}
        columns={columns}
        data={dataTable}
        isLoaded={isLoaded}
      />
      <Alert
        text="Pretende confirmar o envio de notificações?"
        buttonText="Confirmar"
        handleDelete={handleBack}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Notificar fecho do Ano Lectivo"
      />
    </>
  );
}
