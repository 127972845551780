import { Box, Button, Flex, FormControl, Grid, Input } from "@chakra-ui/react";
import Label from "../../../components/Label";
import { handleEnterOnSearch } from "../../../utils/constants";

function Search({ register, setValue, onSubmit }) {
  const clearSearch = () => {
    setValue("name", "");
    onSubmit();
  };

  return (
    <Box px="20px">
      <FormControl>
        <Label title="Pesquisar pelo nome ou email do utilizador" />
        <Grid gridTemplateColumns="1fr auto">
          <Flex mr={4}>
            <Input
              {...register("name")}
              sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              onKeyDown={(e) => {
                handleEnterOnSearch(e, onSubmit);
              }}
            />
            <Button
              onClick={onSubmit}
              sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            >
              Pesquisar
            </Button>
          </Flex>
          <Flex>
            <Button variant="link" onClick={clearSearch}>
              Limpar consulta
            </Button>
          </Flex>
        </Grid>
      </FormControl>
    </Box>
  );
}

export default Search;
