import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Input,
  Stack,
  FormControl,
  FormErrorMessage,
  HStack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import Label from "../../../../components/Label";
import api from "../../../../utils/api";
import {
  ADD_ITEM_LOOKUP,
  ADD_ITEM_LOOKUP_BULK,
  COMMUNE_URL,
  COUNTY_URL,
  PROVINCE_URL,
  UPDATE_ITEM_LOOKUP,
  URBANDISTRICT_URL,
} from "../../../../utils/endpoints";
import { Toast } from "../../../../components/Toast";
import {
  NO_OPTIONS_MESSAGE,
  REQUIRED_FIELD,
} from "../../../../utils/constants";
import { ItemSchemForProvince } from "../../ItemSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { selectStyles } from "../../../../utils/styles";

import Select from "react-select";
import { EditIcon } from "@chakra-ui/icons";

export default function ModalProvinceEdit({
  item,
  entityName,
  setDataCommune,
  setDataUrbanDistrict,
}) {
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(ItemSchemForProvince),
  });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { addToast } = Toast();

  useEffect(() => {
    setValue("code_province", item.row.original.fromCounty.fromProvince.code);
    setValue("name_province", item.row.original.fromCounty.fromProvince.name);
    setValue("code_county", item.row.original.fromCounty.code);
    setValue("name_county", item.row.original.fromCounty.name);
    if (entityName === "Commune") {
      setValue("code_commune", item.row.original.code);
      setValue("name_commune", item.row.original.name);
    } else if (entityName === "UrbanDistrict") {
      setValue("code_urban", item.row.original.code);
      setValue("name_urban", item.row.original.name);
    }
  }, [item]);

  const code_province = watch("code_province");
  const name_province = watch("name_general");

  const code_county = watch("code_county");
  const name_county = watch("name_county");

  const code_commune = watch("code_commune");
  const name_commune = watch("name_commune");

  const code_urban = watch("code_urban");
  const name_urban = watch("name_urban");

  const onSubmit = async (data) => {
    let bodyRequest = {};

    try {
      if (entityName === "Commune") {
        bodyRequest = {
          Code: data.code_commune,
          Name: data.name_commune,
          EntityType: entityName,
        };
      } else if (entityName === "UrbanDistrict") {
        bodyRequest = {
          Code: data.code_urban,
          Name: data.name_urban,
          EntityType: entityName,
        };
      }

      await api.patch(UPDATE_ITEM_LOOKUP, {
        ...bodyRequest,
        id: item.row.original.id,
      });

      addToast({
        status: "success",
        title: "Item actualizado com sucesso",
      });

      if (entityName === "UrbanDistrict") {
        const response = await api.get(
          `${URBANDISTRICT_URL}?OnlyActive=false&PageSize=2500`
        );
        setDataUrbanDistrict(
          response.data
            .slice()
            .sort((a, b) => a.fromCounty.name.localeCompare(b.fromCounty.name))
            .sort((a, b) =>
              a.fromCounty.fromProvince.name.localeCompare(
                b.fromCounty.fromProvince.name
              )
            )
        );
      } else if (entityName === "Commune") {
        const response = await api.get(
          `${COMMUNE_URL}?OnlyActive=false&PageSize=2500`
        );
        setDataCommune(
          response.data
            .slice()
            .sort((a, b) => a.fromCounty.name.localeCompare(b.fromCounty.name))
            .sort((a, b) =>
              a.fromCounty.fromProvince.name.localeCompare(
                b.fromCounty.fromProvince.name
              )
            )
        );
      }
      onClose();
    } catch (e) {
      addToast({
        status: "error",
        title: "Ocorreu um erro ao actualizar o item",
      });
    }
  };

  return (
    <>
      <EditIcon
        onClick={() => {
          onOpen();
        }}
      />

      <Modal isOpen={isOpen} size="3xl" onClose={onClose}>
        <ModalOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent>
            <ModalHeader>Actualizar item</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Stack spacing={5}>
                <Box>
                  <Text mb="5" fontWeight="bold">
                    Província
                  </Text>

                  <HStack gap={5}>
                    <FormControl isInvalid={!!errors.code_province}>
                      <Label title="Código" isRequired />
                      <Input
                        value={code_province}
                        {...register("code_province")}
                        size="md"
                        disabled
                      />
                      <FormErrorMessage>
                        {errors.code_province && <>{REQUIRED_FIELD}</>}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!errors.name_province}>
                      <Label title="Nome" isRequired />
                      <Input
                        value={name_province}
                        {...register("name_province")}
                        size="md"
                        disabled
                      />
                      <FormErrorMessage>
                        {errors.name_province && <>{REQUIRED_FIELD}</>}
                      </FormErrorMessage>
                    </FormControl>
                  </HStack>
                </Box>

                <Box>
                  <Text mb="5" fontWeight="bold">
                    Município
                  </Text>

                  <HStack gap={5}>
                    <FormControl isInvalid={!!errors.code_county}>
                      <Label title="Código" isRequired />
                      <Input
                        value={code_county}
                        {...register("code_county")}
                        size="md"
                        disabled
                      />
                      <FormErrorMessage>
                        {errors.code_county && <>{REQUIRED_FIELD}</>}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!errors.name_county}>
                      <Label title="Nome" isRequired />
                      <Input
                        value={name_county}
                        {...register("name_county")}
                        size="md"
                        disabled
                      />
                      <FormErrorMessage>
                        {errors.name_county && <>{REQUIRED_FIELD}</>}
                      </FormErrorMessage>
                    </FormControl>
                  </HStack>
                </Box>

                <Box>
                  <Text mb="5" fontWeight="bold">
                    Comuna
                  </Text>

                  <HStack gap={5}>
                    <FormControl isInvalid={!!errors.code_commune}>
                      <Label title="Código" />
                      <Input
                        value={code_commune}
                        {...register("code_commune")}
                        size="md"
                        isDisabled={!!code_urban || !!name_urban}
                      />
                      <FormErrorMessage>
                        {errors.code_commune && <>{REQUIRED_FIELD}</>}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!errors.name_commune}>
                      <Label title="Nome" />
                      <Input
                        value={name_commune}
                        {...register("name_commune")}
                        size="md"
                        isDisabled={!!code_urban || !!name_urban}
                      />
                      <FormErrorMessage>
                        {errors.name_commune && <>{REQUIRED_FIELD}</>}
                      </FormErrorMessage>
                    </FormControl>
                  </HStack>
                </Box>

                <Box>
                  <Text mb="5" fontWeight="bold">
                    Distrito Urbano
                  </Text>

                  <HStack gap={5}>
                    <FormControl isInvalid={!!errors.code_urban}>
                      <Label title="Código" />
                      <Input
                        value={code_urban}
                        {...register("code_urban")}
                        size="md"
                        isDisabled={!!code_commune || !!name_commune}
                      />
                      <FormErrorMessage>
                        {errors.code_urban && <>{REQUIRED_FIELD}</>}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!errors.name_urban}>
                      <Label title="Nome" />
                      <Input
                        value={name_urban}
                        {...register("name_urban")}
                        size="md"
                        isDisabled={!!code_commune || !!name_commune}
                      />
                      <FormErrorMessage>
                        {errors.name_urban && <>{REQUIRED_FIELD}</>}
                      </FormErrorMessage>
                    </FormControl>
                  </HStack>
                </Box>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button variant="secondary" onClick={onClose} mr={3}>
                Cancelar
              </Button>
              <Button type="submit">Actualizar</Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}
