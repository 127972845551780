import { useToast } from "@chakra-ui/react";

interface ToastProps {
  title: string;
  status: "success" | "error" | "warning" | "info" | "loading";
}

export const Toast = () => {
  const toast = useToast();

  const addToast = ({ title, status }: ToastProps) => {
    toast({
      title: title,
      status: status,
      position: "top-right",
      isClosable: true,
    });
  };

  return { addToast };
};
