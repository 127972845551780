import { useEffect, useState } from "react";
import { getCounty } from "../../../services/getCounty";
import { getProvince } from "../../../services/getProvince";
import { getSchoolNatures } from "../../../services/getSchoolNatures";
import { getSchoolTipology } from "../../../services/getSchoolTipology";
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  Grid,
  GridItem,
  Text,
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";

import Select from "react-select";
import { getSchools } from "../../../services/getsSchools";
import { borderColor } from "../../../utils/colors";
import Label from "../../../components/Label";
import { NO_OPTIONS_MESSAGE } from "../../../utils/constants";

export function FilterSchools({
  setDataTable,
  handleFilterOrSearchCourse,
  control,
  province,
  county,
  natures,
  type,
  params,
  addToast,
  setValue,
  register,
  isDisabled,
  setIsDisabled,
  state,
}) {
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [countyOptions, setCountyOptions] = useState([]);
  const [schoolNaturesOptions, setSchoolNaturesOptions] = useState([]);
  const [schoolTypologiesOptions, setSchoolTypologiesOptions] = useState([]);

  async function getProvinceOptions() {
    getProvince()
      .then((res) => {
        setProvinceOptions(res);
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getCountyOptions(id) {
    if (id === undefined) {
      return;
    }
    getCounty({ ParentId: id })
      .then((res) => setCountyOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getSchoolNaturesOprions() {
    getSchoolNatures()
      .then((res) => setSchoolNaturesOptions(res))
      .catch((error) => {
        console.log("Error", error);
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getSchoolTypologiesOptions() {
    getSchoolTipology()
      .then((res) => setSchoolTypologiesOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  useEffect(() => {
    getProvinceOptions();
    getSchoolNaturesOprions();
    getSchoolTypologiesOptions();
    // eslint-disable-next-line
  }, []);

  useEffect(
    () => {
      getCountyOptions(province?.value);
      setIsDisabled(false);
      setValue("county", undefined);
    },
    // eslint-disable-next-line
    [province]
  );

  const handleSearch = async () => {
    const data = await handleFilterOrSearchCourse(params, addToast, getSchools);
    setDataTable(data);
  };

  const handleCleanFilters = async () => {
    setValue("province", undefined);
    setValue("county", undefined);
    setValue("natures", undefined);
    setValue("type", undefined);
    setValue("state", undefined);
    setValue("isActive", undefined);

    const response = await getSchools();
    setDataTable(response);
  };

  return (
    <>
      <Box position="relative" py={8}>
        <Divider borderColor={borderColor} />
        <Box
          bg="white"
          px="2"
          position="absolute"
          transform="translate(5%, -50%)"
        >
          <Text as="b" fontSize="sm" textTransform="uppercase">
            Filtrar lista de escolas
          </Text>
        </Box>
      </Box>

      <Box px="20px">
        <Grid gridTemplateColumns="1fr auto" gap={4}>
          <Grid templateColumns="repeat(6, 1fr)" gap={4}>
            <GridItem>
              <FormControl>
                <Label title="Província" />
                <Controller
                  control={control}
                  name="province"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      id="province"
                      isClearable
                      placeholder="Seleccionar"
                      options={provinceOptions}
                      value={value || ""}
                      onChange={onChange}
                      noOptionsMessage={NO_OPTIONS_MESSAGE}
                    />
                  )}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <Label title="Município" />
                <Controller
                  control={control}
                  name="county"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      id="county"
                      placeholder="Seleccionar"
                      options={countyOptions}
                      value={value || ""}
                      isSearchable={false}
                      isClearable={value !== ""}
                      isDisabled={isDisabled || !province}
                      onChange={onChange}
                      noOptionsMessage={NO_OPTIONS_MESSAGE}
                    />
                  )}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <Label title="Natureza" />
                <Controller
                  control={control}
                  name="natures"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      id="natures"
                      isClearable
                      placeholder="Seleccionar"
                      options={schoolNaturesOptions}
                      value={value || ""}
                      isSearchable={false}
                      onChange={onChange}
                      noOptionsMessage={NO_OPTIONS_MESSAGE}
                    />
                  )}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <Label title="Tipologia" />
                <Controller
                  control={control}
                  name="type"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      id="type"
                      isClearable
                      placeholder="Seleccionar"
                      value={value || ""}
                      options={schoolTypologiesOptions}
                      isSearchable={false}
                      onChange={onChange}
                      noOptionsMessage={NO_OPTIONS_MESSAGE}
                    />
                  )}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <Label title="Estado" />
                <Controller
                  control={control}
                  name="state"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      id="state"
                      isClearable
                      placeholder="Seleccionar"
                      value={value || ""}
                      options={[
                        { value: true, label: "Validado" },
                        { value: "pending", label: "Pendente de Validação" },
                        { value: false, label: "Não validado" },
                      ]}
                      isSearchable={false}
                      onChange={onChange}
                      noOptionsMessage={NO_OPTIONS_MESSAGE}
                    />
                  )}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <Label title="Arquivado" />
                <Controller
                  control={control}
                  name="isActive"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      isClearable
                      placeholder="Seleccionar"
                      value={value || ""}
                      options={[
                        { value: false, label: "Sim" },
                        { value: true, label: "Não" },
                      ]}
                      isSearchable={false}
                      onChange={onChange}
                      noOptionsMessage={NO_OPTIONS_MESSAGE}
                    />
                  )}
                />
              </FormControl>
            </GridItem>
          </Grid>
        </Grid>
        <Flex justifyContent="end" mt={5}>
          <Button onClick={handleSearch} mr={4}>
            Filtrar
          </Button>
          <Button variant="link" onClick={handleCleanFilters}>
            Limpar filtros
          </Button>
        </Flex>
      </Box>
    </>
  );
}
