import { useContext, useMemo } from "react";
import Table from "../../components/Table";
import { Link } from "react-router-dom";
import { EditIcon } from "@chakra-ui/icons";
import { AuthContext } from "../../provider/AuthProvider";
import { getUsers } from "../../services/getUsers";
import { Center } from "@chakra-ui/react";

function TableUsers({
  data,
  isLoaded,
  isSchool = false,
  setData,
  setIsLoaded,
  apiUrl,
  total,
  isFromPagination,
  setIsFromPagination,
}) {
  const { auth } = useContext(AuthContext);

  const user = auth.user;

  let columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "name",
        Cell: (item) => {
          return (
            <Link to={`/utilizadores/${item.row.original.id}`}>
              {item.row.original.name}
            </Link>
          );
        },
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Perfil",
        accessor: (row) => row.roles.map((i) => i.name).join(", "),
        sortType: (rowA, rowB, columnId) => {
          const valueA = String(
            rowA.original.roles.map((i) => i.name).join(", ")
          );
          const valueB = String(
            rowB.original.roles.map((i) => i.name).join(", ")
          );
          return valueA.localeCompare(valueB);
        },
        Cell: (item) => {
          return item.row.original.roles.map((i) => i.name).join(", ");
        },
      },
      {
        Header: "Província",
        accessor: "userProvince.name",
      },
      {
        Header: "Município",
        accessor: "userCounty.name",
      },
      {
        Header: "Escola",
        accessor: "userSchool.name",
      },
      {
        Header: "Estado",
        accessor: (row) => row.isActive,
        sortType: (rowA, rowB, columnId) => {
          const valueA = String(rowA.original.isActive);
          const valueB = String(rowB.original.isActive);
          return valueA.localeCompare(valueB);
        },
        Cell: (item) => {
          return item.row.original.isActive ? "Activo" : "Inactivo";
        },
      },
      {
        Header: "Editar",
        accessor: "edit",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <Link to={`/utilizadores/${item.row.original.id}`}>
              <Center>
                <EditIcon />
              </Center>
            </Link>
          );
        },
      },
    ],
    []
  );

  if (isSchool) {
    columns = columns.filter((column) => column.accessor !== "userSchool.name");
    columns = columns.filter(
      (column) => column.accessor !== "userProvince.name"
    );
    columns = columns.filter((column) => column.accessor !== "userCounty.name");
  }

  if (!user.permissions.includes("GetUser")) {
    columns = columns.filter((column) => column.accessor !== "edit");
  }

  return (
    <>
      {data && (
        <Table
          setData={setData}
          setIsLoaded={setIsLoaded}
          getFunction={getUsers}
          columns={columns}
          data={data}
          isLoaded={isLoaded}
          entity="user"
          apiUrl={apiUrl}
          total={total}
          isFromPagination={isFromPagination}
          setIsFromPagination={setIsFromPagination}
        />
      )}
    </>
  );
}

export default TableUsers;
