import {
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import TableProvinceCommune from "./TableProvinceCommune";
import TableProvinceUrban from "./TableProvinceUrbanDistricts";

export default function TableProvince({
  isLoaded,
  setIsLoaded,
  dataCommune,
  dataUrbanDistrict,
  setDataCommune,
  setDataUrbanDistrict,
}) {
  return (
    <Stack spacing={5}>
      <Tabs>
        <TabList>
          <Tab>Comunas</Tab>
          <Tab>Distritos Urbanos</Tab>
        </TabList>

        <TabPanels>
          <TabPanel p={0} pt="20px">
            <TableProvinceCommune
              isLoaded={isLoaded}
              setIsLoaded={setIsLoaded}
              dataCommune={dataCommune}
              setDataCommune={setDataCommune}
            />
          </TabPanel>
          <TabPanel p={0} pt="20px">
            <TableProvinceUrban
              isLoaded={isLoaded}
              setIsLoaded={setIsLoaded}
              dataUrbanDistrict={dataUrbanDistrict}
              setDataUrbanDistrict={setDataUrbanDistrict}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
}
