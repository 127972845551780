import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Stack,
} from "@chakra-ui/react";
import SearchArea from "../../../Course/components/SearchArea";
import { Toast } from "../../../../components/Toast";
import { useForm } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import TableCourseManager from "./TableCourseManager";
import { formStyles } from "../../../../utils/styles";
import Label from "../../../../components/Label";
import Select from "react-select";
import ModalAssociate from "./Modal";
import { getCourse } from "../../../../services/getCourse";
import { getAcademicYear } from "../../../../services/getAcademicYear";
import api from "../../../../utils/api";
import {
  LINK_COURSE_MY_SCHOOL,
  LINK_COURSE_TO_SCHOOL,
} from "../../../../utils/endpoints";
import { useParams } from "react-router-dom";
import { MdAddCircle } from "react-icons/md";
import { AuthContext } from "../../../../provider/AuthProvider";
import { NO_OPTIONS_MESSAGE } from "../../../../utils/constants";

export default function ManagerCourses() {
  const { auth } = useContext(AuthContext);
  const isSchool = auth.isSchool;

  const [dataTable, setDataTable] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [optionsAcademicYear, setOptionsAcademicYear] = useState([]);
  const [academicYear, setAcademicYear] = useState(null);
  const [courses, setCourses] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const { register, watch, setValue } = useForm();
  const inputSearch = watch("search");
  const { id } = useParams();

  const { addToast } = Toast();

  const getCourseData = async () => {
    const response = await getCourse({
      PageSize: 2500,
      SchoolId: id,
      AcademicYearId: academicYear.value,
      IsActive: true,
      IsValidated: true,
    });
    setDataTable(response);
  };

  useEffect(() => {
    const getData = async () => {
      const responseCourses = await getCourse({
        PageSize: 2500,
        IsActive: true,
        IsValidated: true,
      });
      setCourses(
        responseCourses.map((e) => ({
          value: e.id,
          label: e.name,
          id: e.id,
          name: e.name,
          code: e.code,
        }))
      );
      const responseAcademicYear = await getAcademicYear();
      setOptionsAcademicYear(
        responseAcademicYear
          .map((e) => ({
            value: e.id,
            label: e.year,
            code: e.code,
          }))
          .reverse()
      );
    };
    getData();
  }, []);

  useEffect(() => {
    if (optionsAcademicYear.length > 0) {
      setAcademicYear(optionsAcademicYear[0]);
    }
  }, [optionsAcademicYear]);

  useEffect(() => {
    if (academicYear !== null) {
      getCourseData();
    }
  }, [academicYear]);

  const handleAssociate = async () => {
    const url = isSchool ? LINK_COURSE_MY_SCHOOL : LINK_COURSE_TO_SCHOOL;

    const dataTablesCode = dataTable.map((e) => ({
      courseId: e.id,
      courseCapacity: e.courseForAcademicYears?.filter(
        (e) =>
          e.academicYearForSchool.academicYear.id === academicYear.value &&
          e.academicYearForSchool.school.id == id
      )[0]?.capacity
        ? e.courseForAcademicYears.filter(
            (e) =>
              e.academicYearForSchool.academicYear.id === academicYear.value &&
              e.academicYearForSchool.school.id == id
          )[0].capacity
        : null,
      courseApplications: e.courseForAcademicYears?.filter(
        (e) =>
          e.academicYearForSchool.academicYear.id === academicYear.value &&
          e.academicYearForSchool.school.id == id
      )[0]?.applications
        ? e.courseForAcademicYears.filter(
            (e) =>
              e.academicYearForSchool.academicYear.id === academicYear.value &&
              e.academicYearForSchool.school.id == id
          )[0].applications
        : null,
    }));

    try {
      const response = await api.patch(url, {
        id,
        academicYearId: academicYear.value,
        courseApplications: dataTablesCode,
      });

      if (response.status === 200) {
        addToast({
          title: "Lista de cursos actualizada com sucesso",
          status: "success",
        });
        setConfirm(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleConfirm = (state) => {
    setConfirm(state);
  };

  return (
    <Stack spacing={5}>
      <Box
        display="flex"
        flexDir="row"
        justifyContent="space-between"
        alignItems="end"
      >
        <Heading>Cursos</Heading>

        {optionsAcademicYear.length > 0 && (
          <FormControl width="230px">
            <Label title="Ano lectivo" />
            <Select
              id="academicyear"
              defaultValue={optionsAcademicYear[0]}
              placeholder="Seleccionar"
              isSearchable={false}
              onChange={(e) => {
                setAcademicYear(e);
              }}
              options={optionsAcademicYear}
              noOptionsMessage={NO_OPTIONS_MESSAGE}
            />
          </FormControl>
        )}
      </Box>

      {academicYear && (
        <Box sx={formStyles} px="0!important">
          <SearchArea
            inputSearch={inputSearch}
            register={register}
            addToast={addToast}
            setDataTable={setDataTable}
            setInputSearch={setValue}
            getCourseData={getCourseData}
            id={id}
            academicYear={academicYear}
          />
        </Box>
      )}

      <Box>
        <Flex justifyContent="space-between" alignItems="end" mb="10px">
          <Heading as="h2" size="md" textTransform="uppercase">
            Lista de Cursos
          </Heading>
          {!isSchool && (
            <Box>
              <Button
                onClick={() => {
                  setIsOpen(true);
                }}
                rightIcon={<MdAddCircle />}
              >
                Associar
              </Button>

              <ModalAssociate
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                title="Associar Curso"
                setDataTable={setDataTable}
                dataTable={dataTable}
                courses={courses}
              />
            </Box>
          )}
        </Flex>

        <TableCourseManager
          setDataTable={setDataTable}
          dataTable={dataTable}
          academicYear={academicYear}
          id={id}
          confirm={confirm}
        />
      </Box>

      <Box>
        {!isSchool ? (
          <Button onClick={handleAssociate}>Guardar</Button>
        ) : confirm ? (
          <>
            <Button
              onClick={() => handleConfirm(false)}
              variant="secondary"
              mr={3}
            >
              Cancelar
            </Button>
            <Button onClick={handleAssociate}>Confirmar</Button>
          </>
        ) : (
          <Button onClick={() => handleConfirm(true)}>Guardar</Button>
        )}
      </Box>
    </Stack>
  );
}
