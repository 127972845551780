import { Box, Button, Flex, Stack, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { AuthContext } from "../../provider/AuthProvider";
import Breadcrumbs from "../Breadcrumbs";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { primaryColor } from "../../utils/colors";
import { useLocation, useNavigate } from "react-router-dom";

function Header() {
  const { auth } = useContext(AuthContext);
  const user = auth.user;
  const roleName = auth.roleName;
  const { pathname } = useLocation();
  const pathSegments = pathname.split("/").filter((segment) => segment !== "");
  const navigate = useNavigate();

  return (
    <Flex justifyContent="space-between" alignItems="start" mb={4}>
      <Stack>
        <Breadcrumbs />
        {!(
          (pathSegments[0] === "inicio" && pathSegments.length === 1) ||
          (pathSegments[0] === "gerir" && pathSegments.length === 2)
        ) && (
          <Box mt={1}>
            <Button
              variant="link"
              leftIcon={<ChevronLeftIcon boxSize={5} />}
              color={primaryColor}
              onClick={() => navigate(-1)}
            >
              Voltar
            </Button>
          </Box>
        )}
      </Stack>
      <Stack gap={0} textAlign="right">
        <Text as="b">{user?.name}</Text>
        <Text fontSize="sm">{roleName}</Text>
      </Stack>
    </Flex>
  );
}

export default Header;
