import api from "../utils/api";
import { PAGE_SIZE } from "../utils/constants";
import { TEACHER_URL } from "../utils/endpoints";

export async function getTeacher(id = false, schoolId = false) {
  try {
    const response = await api.get(
      !id
        ? schoolId
          ? `${TEACHER_URL}?SchoolId=${schoolId}&PageSize=${PAGE_SIZE}`
          : `${TEACHER_URL}?PageSize=${PAGE_SIZE}`
        : `${TEACHER_URL}?${id}&PageSize=${PAGE_SIZE}`
    );

    const data = response.data.result.map((option) => ({
      id: option.id,
      typeDocument: option.typeDocument,
      documentNumber: option.documentNumber,
      name: option.name,
      agentNumber: option.agentNumber,
      birthDate: option.birthDate,
      gender: option.gender.name,
      nationality: option.nationality,
      academicQualification: option.academicQualification,
      trainingArea: option.trainingArea,
      pedagogicalTraining: option.pedagogicalTraining.name,
      isActive: option.isActive,
      isValidated: option.isValidated,
      school: option?.contractualBonds[0]?.schoolBonded.name,
      contractualBonds: option?.contractualBonds,
      schoolInfo: option?.contractualBonds,
      allSchools: option?.contractualBonds.map((j) => j.schoolBonded.name),
      totalItems: response.data.totalItems,
    }));
    return data;
  } catch (error) {
    throw error;
  }
}
