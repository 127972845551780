import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  Grid,
  GridItem,
  Text,
} from "@chakra-ui/react";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import Label from "../../../../components/Label";
import { NO_OPTIONS_MESSAGE } from "../../../../utils/constants";
import { selectStyles } from "../../../../utils/styles";
import { borderColor } from "../../../../utils/colors";
import {
  COMMUNE_URL,
  COUNTY_URL,
  PROVINCE_URL,
  URBANDISTRICT_URL,
} from "../../../../utils/endpoints";
import api from "../../../../utils/api";
import { Toast } from "../../../../components/Toast";

export default function ProvinceFilters({
  setDataCommune,
  setDataUrbanDistrict,
  setIsLoaded,
}) {
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [countyOptions, setCountyOptions] = useState([]);
  const [communeOptions, setCommuneOptions] = useState([]);
  const [urbanDistrictOptions, setUrbanDistrictOptions] = useState([]);
  const { addToast } = Toast();
  const { handleSubmit, control, watch, setValue } = useForm();

  async function getOptions(url, parentId = null) {
    try {
      const response = await api.get(
        parentId
          ? `${url}?ParentId=${parentId}&OnlyActive=false`
          : `${url}?OnlyActive=false`
      );
      const data = response.data.map((option) => ({
        value: option.id,
        label: option.name,
      }));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async function getProvinceOptions() {
    getOptions(PROVINCE_URL)
      .then((res) => setProvinceOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getCountyOptions(parentId = null) {
    getOptions(COUNTY_URL, parentId)
      .then((res) => setCountyOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getCommuneOptions(parentId = null) {
    getOptions(COMMUNE_URL, parentId)
      .then((res) => setCommuneOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getUrbanDistrictOptions(parentId = null) {
    getOptions(URBANDISTRICT_URL, parentId)
      .then((res) => setUrbanDistrictOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  useEffect(
    () => {
      getProvinceOptions();
      getCountyOptions();
      getCommuneOptions();
      getUrbanDistrictOptions();
    },
    // eslint-disable-next-line
    []
  );

  const province = watch("province");

  useEffect(
    () => {
      if (province) {
        setValue("county", undefined);
        setValue("commune", undefined);
        setValue("urban_district", undefined);
        getCountyOptions(province.value);
      } else {
        setValue("county", undefined);
        setValue("commune", undefined);
        setValue("urban_district", undefined);
      }
    },
    // eslint-disable-next-line
    [province]
  );

  const county = watch("county");

  useEffect(
    () => {
      if (county) {
        setValue("commune", undefined);
        setValue("urban_district", undefined);
        getCommuneOptions(county.value);
        getUrbanDistrictOptions(county.value);
      } else {
        setValue("commune", undefined);
        setValue("urban_district", undefined);
      }
    },
    // eslint-disable-next-line
    [county]
  );

  const onSubmit = async (data) => {
    setIsLoaded(false);
    const responseCommune = await api.get(
      `${COMMUNE_URL}?OnlyActive=false&PageSize=2500`
    );
    const responseUrbanDistrict = await api.get(
      `${URBANDISTRICT_URL}?OnlyActive=false&PageSize=2500`
    );
    if (data.commune && !data.urban_district) {
      setDataCommune(
        responseCommune.data.filter((item) => item.id === data.commune.value)
      );
      setDataUrbanDistrict(
        responseUrbanDistrict.data
          .slice()
          .sort((a, b) => a.fromCounty.name.localeCompare(b.fromCounty.name))
          .sort((a, b) =>
            a.fromCounty.fromProvince.name.localeCompare(
              b.fromCounty.fromProvince.name
            )
          )
      );
    } else if (!data.commune && data.urban_district) {
      setDataCommune(
        responseCommune.data
          .slice()
          .sort((a, b) => a.fromCounty.name.localeCompare(b.fromCounty.name))
          .sort((a, b) =>
            a.fromCounty.fromProvince.name.localeCompare(
              b.fromCounty.fromProvince.name
            )
          )
      );
      setDataUrbanDistrict(
        responseUrbanDistrict.data.filter(
          (item) => item.id === data.urban_district.value
        )
      );
    } else if (data.commune && data.urban_district) {
      setDataCommune(
        responseCommune.data.filter((item) => item.id === data.commune.value)
      );
      setDataUrbanDistrict(
        responseUrbanDistrict.data.filter(
          (item) => item.id === data.urban_district.value
        )
      );
    } else if (data.county) {
      setDataCommune(
        responseCommune.data.filter(
          (item) => item.fromCounty.id === data.county.value
        )
      );
      setDataUrbanDistrict(
        responseUrbanDistrict.data.filter(
          (item) => item.fromCounty.id === data.county.value
        )
      );
    } else if (data.province) {
      setDataCommune(
        responseCommune.data.filter(
          (item) => item.fromCounty.fromProvince.id === data.province.value
        )
      );
      setDataUrbanDistrict(
        responseUrbanDistrict.data.filter(
          (item) => item.fromCounty.fromProvince.id === data.province.value
        )
      );
    } else {
      const responseCommune = await api.get(
        `${COMMUNE_URL}?OnlyActive=false&PageSize=2500`
      );
      setDataCommune(
        responseCommune.data
          .slice()
          .sort((a, b) => a.fromCounty.name.localeCompare(b.fromCounty.name))
          .sort((a, b) =>
            a.fromCounty.fromProvince.name.localeCompare(
              b.fromCounty.fromProvince.name
            )
          )
      );
      const responseUrbanDistrict = await api.get(
        `${URBANDISTRICT_URL}?OnlyActive=false&PageSize=2500`
      );
      setDataUrbanDistrict(
        responseUrbanDistrict.data
          .slice()
          .sort((a, b) => a.fromCounty.name.localeCompare(b.fromCounty.name))
          .sort((a, b) =>
            a.fromCounty.fromProvince.name.localeCompare(
              b.fromCounty.fromProvince.name
            )
          )
      );
    }
    setIsLoaded(true);
  };

  const clearFilters = async () => {
    setValue("province", undefined);
    setValue("county", undefined);
    setValue("commune", undefined);
    setValue("urban_district", undefined);
    setIsLoaded(false);
    getProvinceOptions();
    getCountyOptions();
    getCommuneOptions();
    getUrbanDistrictOptions();
    const responseCommune = await api.get(
      `${COMMUNE_URL}?OnlyActive=false&PageSize=2500`
    );
    setDataCommune(
      responseCommune.data
        .slice()
        .sort((a, b) => a.fromCounty.name.localeCompare(b.fromCounty.name))
        .sort((a, b) =>
          a.fromCounty.fromProvince.name.localeCompare(
            b.fromCounty.fromProvince.name
          )
        )
    );
    const responseUrbanDistrict = await api.get(
      `${URBANDISTRICT_URL}?OnlyActive=false&PageSize=2500`
    );
    setDataUrbanDistrict(
      responseUrbanDistrict.data
        .slice()
        .sort((a, b) => a.fromCounty.name.localeCompare(b.fromCounty.name))
        .sort((a, b) =>
          a.fromCounty.fromProvince.name.localeCompare(
            b.fromCounty.fromProvince.name
          )
        )
    );
    setIsLoaded(true);
  };

  return (
    <>
      <Box position="relative" py={8}>
        <Divider borderColor={borderColor} />
        <Box
          bg="white"
          px="2"
          position="absolute"
          transform="translate(5%, -50%)"
        >
          <Text as="b" fontSize="sm" textTransform="uppercase">
            Filtrar itens
          </Text>
        </Box>
      </Box>
      <Box as="form" onSubmit={handleSubmit(onSubmit)} px="20px">
        <Grid gridTemplateColumns="1fr auto" gap={4}>
          <Grid templateColumns="repeat(4, 1fr)" gap={4}>
            <GridItem>
              <FormControl>
                <Label title="Província" />
                <Controller
                  control={control}
                  name="province"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      placeholder="Seleccionar"
                      options={provinceOptions}
                      onChange={onChange}
                      value={value || ""}
                      styles={selectStyles}
                      isClearable={true}
                      noOptionsMessage={NO_OPTIONS_MESSAGE}
                    />
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <Label title="Município" />
                <Controller
                  control={control}
                  name="county"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      placeholder="Seleccionar"
                      options={countyOptions}
                      onChange={onChange}
                      value={value || ""}
                      styles={selectStyles}
                      isClearable={true}
                      noOptionsMessage={NO_OPTIONS_MESSAGE}
                    />
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <Label title="Comuna" />
                <Controller
                  control={control}
                  name="commune"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      placeholder="Seleccionar"
                      options={communeOptions}
                      onChange={onChange}
                      value={value || ""}
                      styles={selectStyles}
                      isClearable={true}
                      noOptionsMessage={NO_OPTIONS_MESSAGE}
                      isDisabled={
                        (province && !county) || !communeOptions.length > 0
                      }
                    />
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <Label title="Distrito Urbano" />
                <Controller
                  control={control}
                  name="urban_district"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      placeholder="Seleccionar"
                      options={urbanDistrictOptions}
                      onChange={onChange}
                      value={value || ""}
                      styles={selectStyles}
                      isClearable={true}
                      noOptionsMessage={NO_OPTIONS_MESSAGE}
                      isDisabled={
                        (province && !county) ||
                        !urbanDistrictOptions.length > 0
                      }
                    />
                  )}
                />
              </FormControl>
            </GridItem>
          </Grid>
          <Flex alignSelf="end">
            <Button mr={4} type="submit">
              Filtrar
            </Button>
            <Button variant="link" onClick={clearFilters}>
              Limpar filtros
            </Button>
          </Flex>
        </Grid>
      </Box>
    </>
  );
}
