import { useContext, useEffect, useState } from "react";
import Form from "./Form";
import { useForm } from "react-hook-form";
import { schema } from "./Form/schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate, useParams } from "react-router-dom";
import { Toast } from "../../components/Toast";
import api from "../../utils/api";
import { EDIT_TEACHER_URL, TEACHER_URL } from "../../utils/endpoints";
import { CrumbContext } from "../../provider/CrumbProvider";
import { AuthContext } from "../../provider/AuthProvider";

function EditTeacher() {
  const [isDisabled, setIsDisabled] = useState(true);
  const [teacherData, setTeacherData] = useState([]);
  const [contractualBondRequests, setContractualBondRequests] = useState([]);
  const [contractualBondRequestsData, setContractualBondRequestsData] =
    useState([]);
  const [isActive, setIsActive] = useState();
  const navigate = useNavigate();
  const { addToast } = Toast();
  const { id, teacherid } = useParams();
  const { crumb, setCrumb } = useContext(CrumbContext);
  const { auth } = useContext(AuthContext);
  const user = auth.user;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
    unregister,
  } = useForm({
    resolver: zodResolver(schema),
  });

  const populateTeacherData = (data) => {
    setValue("typeDocument", data.typeDocument.id.toString());
    setValue("typeDocumentName", data.typeDocument.name);
    setValue("documentNumber", data.documentNumber);
    setValue("name", data.name);
    setValue("agentNumber", data.agentNumber);
    setValue("gender", data.gender.id.toString());
    setValue("nationality", {
      value: data.nationality.id,
      label: data.nationality.name,
    });
    setValue("birthDate", new Date(data.birthDate));
    setValue("academicQualification", {
      value: data.academicQualification.id,
      label: data.academicQualification.name,
    });
    setValue("trainingArea", {
      value: data.trainingArea.id,
      label: data.trainingArea.name,
    });
    setValue("pedagogicalTraining", data.pedagogicalTraining.id.toString());
    setContractualBondRequestsData(data.contractualBonds);
  };

  useEffect(
    () => {
      if (id) {
        api
          .get(`${TEACHER_URL}/${teacherid ? teacherid : id}`)
          .then((response) => {
            const data = response.data;
            setTeacherData(data);
            setContractualBondRequests(
              data.contractualBonds.map((i) => ({
                academicYearId: i.academicYearBonded?.id,
                schoolId: i.schoolBonded?.id,
                contractualBondTypeId: i.contractualBondTypeBonded?.id,
                probationaryPeriodId: i.probationaryPeriodBonded?.id.toString(),
                teachingFunctionId: i.teachingFunctionBonded?.id.toString(),
                administrativePositionId: i.administrativePositionBonded?.map(
                  (i) => i.id
                ),
                coursesBound: i.coursesBound?.map((i) => i.id),
                subjectsBound: i.subjectsBound?.map((i) => i.id),
              }))
            );
            setContractualBondRequestsData(data.contractualBonds);
            setIsActive(data.isActive);
            populateTeacherData(data);
            setCrumb({
              ...crumb,
              teacher: response.data.name,
            });
          });
      }
    },
    // eslint-disable-next-line
    [id]
  );

  const editTeacher = () => {
    const data = getValues();
    api
      .patch(EDIT_TEACHER_URL, {
        teacherId: teacherid ? teacherid : id,
        name: data.name,
        typeDocument: data.typeDocument,
        documentNumber: data.documentNumber,
        agentNumber: data.agentNumber,
        birthDate: data.birthDate,
        gender: data.gender,
        nationality: data.nationality.value,
        academicQualification: data.academicQualification.value,
        trainingArea: data.trainingArea.value,
        pedagogicalTraining: data.pedagogicalTraining,
        createContractualBondRequests: contractualBondRequests,
      })
      .then(() => {
        addToast({
          title: "Docente actualizado com sucesso",
          status: "success",
        });
        if (!user?.schoolId) {
          navigate("/docentes");
        } else {
          navigate(`/gerir/${user?.schoolId}/docentes`);
        }
      })
      .catch((error) => {
        let errors = error.response.data.errors;
        for (let err in errors) {
          addToast({ title: errors[err], status: "error" });
        }
      });
  };

  const cancelEdit = () => {
    populateTeacherData(teacherData);
    setIsDisabled(true);
  };

  return (
    <Form
      isDisabled={isDisabled}
      setIsDisabled={setIsDisabled}
      id={id}
      register={register}
      handleSubmit={handleSubmit}
      errors={errors}
      control={control}
      watch={watch}
      setValue={setValue}
      unregister={unregister}
      editTeacher={editTeacher}
      contractualBondRequests={contractualBondRequests}
      setContractualBondRequests={setContractualBondRequests}
      contractualBondRequestsData={contractualBondRequestsData}
      setContractualBondRequestsData={setContractualBondRequestsData}
      isActive={isActive}
      cancelEdit={cancelEdit}
    />
  );
}

export default EditTeacher;
