import { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import Select from "react-select";
import { NO_OPTIONS_MESSAGE } from "../../../utils/constants";

function ModalAssociateLabs({
  isOpen,
  setIsOpen,
  labsData,
  setLabsData,
  labsOptions,
}) {
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    setSelectedOptions([]);
    setOptions(
      labsOptions
        .filter((obj2) => !labsData.some((obj1) => obj1.labType === obj2.value))
        .map((i) => ({
          value: i.value,
          label: i.label,
        }))
    );
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Adicionar Infra-estrutura</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Select
            isMulti={true}
            placeholder="Seleccionar"
            options={options}
            onChange={(values) => {
              setSelectedOptions(values);
            }}
            noOptionsMessage={NO_OPTIONS_MESSAGE}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            variant="secondary"
            onClick={() => {
              setIsOpen(false);
            }}
            mr={3}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              const modifiedOptions = selectedOptions.map((option) => ({
                labType: option.value,
                labName: option.label,
                numberOfAvailableLabs: 0,
                numberOfUnavailableLabs: 0,
                numberOfLabsTotal: 0,
              }));
              setLabsData([...labsData, ...modifiedOptions]);
              setIsOpen(false);
            }}
          >
            Adicionar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ModalAssociateLabs;
