import { useEffect, useState } from "react";
import Form from "./Form";
import { useForm } from "react-hook-form";
import { schema } from "./Form/schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate, useParams } from "react-router-dom";
import { getRole } from "../../services/getRole";
import { Toast } from "../../components/Toast";
import api from "../../utils/api";
import { USER_URL } from "../../utils/endpoints";

function NewUser() {
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const navigate = useNavigate();
  const { addToast } = Toast();
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
    unregister,
  } = useForm({
    resolver: zodResolver(schema),
  });

  async function getRoleOptions() {
    getRole()
      .then((res) => {
        setRoleOptions(res);
        setIsLoaded(true);
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  useEffect(
    () => {
      getRoleOptions();
    },
    // eslint-disable-next-line
    []
  );

  const createUser = () => {
    const data = getValues();
    api
      .post(USER_URL, {
        name: data.name,
        email: data.email,
        roles: data.role.map((role) => role.value),
        provinceId: data.province ? data.province.value : null,
        countyId: data.county ? data.county.value : null,
        schoolId: data.school ? data.school.value : null,
      })
      .then(() => {
        addToast({
          title: "Utilizador cadastrado com sucesso",
          status: "success",
        });
        navigate("/utilizadores");
      })
      .catch((error) => {
        let errors = error.response.data.errors;
        for (let err in errors) {
          addToast({ title: errors[err], status: "error" });
        }
      });
  };

  return (
    <>
      {isLoaded && (
        <Form
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
          id={id}
          register={register}
          handleSubmit={handleSubmit}
          errors={errors}
          control={control}
          watch={watch}
          setValue={setValue}
          unregister={unregister}
          roleOptions={roleOptions}
          createUser={createUser}
        />
      )}
    </>
  );
}

export default NewUser;
