import { Box, Button, Flex, Grid, Input } from "@chakra-ui/react";
import Label from "../../../components/Label";
import { useState } from "react";
import { handleEnterOnSearch } from "../../../utils/constants";

export function SearchConfig({
  setInputSearch,
  register,
  handleFilterOrSearchCourse,
  params,
  setDataTable,
  addToast,
  dataTable,
  inputSearch,
  lookUps,
  table,
}) {
  const [originalDataTable, setOriginalDataTable] = useState([]);
  const handleSearchCall = () => {
    if (inputSearch === undefined || inputSearch === "") {
      return;
    }

    setOriginalDataTable(dataTable);

    const inputSearchLowerCase = inputSearch?.toLowerCase();
    const filtered = dataTable.filter((e) =>
      e.name?.toLowerCase().includes(inputSearchLowerCase)
    );
    setDataTable(filtered);
  };

  const handleCleanFilters = async () => {
    if (inputSearch === undefined || inputSearch === "") {
      return;
    }
    setInputSearch("search", undefined);

    setDataTable(originalDataTable);
  };

  return (
    <Box px="20px">
      <Label title="Pesquisar pelo nome da tabela auxiliar" />

      <Grid gridTemplateColumns="1fr auto">
        <Flex mr={4}>
          <Input
            {...register("search")}
            sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            onKeyDown={(e) => {
              handleEnterOnSearch(e, handleSearchCall);
            }}
          />

          <Button
            onClick={handleSearchCall}
            sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          >
            Pesquisar
          </Button>
        </Flex>

        <Flex>
          <Button variant="link" onClick={handleCleanFilters}>
            Limpar consulta
          </Button>
        </Flex>
      </Grid>
    </Box>
  );
}
