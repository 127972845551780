import api from "../utils/api";
import { PAGE_SIZE } from "../utils/constants";
import { COURSE_URL } from "../utils/endpoints";

export async function getCourse(params) {
  try {
    let qs = "";
    qs +=
      params?.PageSize !== undefined
        ? `PageSize=${params.PageSize}`
        : `PageSize=${PAGE_SIZE}`;
    qs += params?.SchoolId !== undefined ? `&SchoolId=${params.SchoolId}` : "";
    qs +=
      params?.AcademicYearId !== undefined
        ? `&AcademicYearId=${params.AcademicYearId}`
        : "";
    qs += params?.IsActive !== undefined ? `&IsActive=${params.IsActive}` : "";
    qs +=
      params?.IsValidated !== undefined
        ? `&IsValidated=${params.IsActive}`
        : "";
    qs += params?.Pesquisa !== undefined ? `&Pesquisa=${params.Pesquisa}` : "";
    const response = await api.get(`${COURSE_URL}?${qs}`);

    const data = response.data.result.map((option) => ({
      id: option.id,
      code: option.code,
      value: option.code,
      name: option.name,
      label: option.name,
      educationLevel: option.educationalLevel?.name,
      subsystemEducation: option.educationalSubsystem?.name,
      educationCicle: option.educationalCycle?.name,
      generalArea: option.generalArea?.name,
      specificArea: option.specificArea?.name,
      detailedArea: option.detailedArea?.name,
      technicalEducation: option.courseTechnical?.name,
      dominainPNFQ: option.pnfqDomain?.name,
      isValidated: option.isValidated,
      totalItems: response.data.totalItems,
      courseForAcademicYears: option.courseForAcademicYears,
      courseTechnicalName: option?.courseTechnical?.name,
      courseTechnicalValue: option?.courseTechnical?.id,
      isActive: option?.isActive,
    }));
    return data;
  } catch (error) {
    throw error;
  }
}
