import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useContext } from "react";
import { AuthContext } from "../../../provider/AuthProvider";

function SubjectsAccordion({
  id,
  index,
  isDisabled,
  contractualBondRequests,
  setContractualBondRequests,
  contractualBondRequestsData,
  setContractualBondRequestsData,
  academicYear,
  school,
  schoolId,
  courses,
  subjects,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { auth } = useContext(AuthContext);
  const user = auth.user;

  const deleteData = () => {
    const updateCoursesSubjectsBound = contractualBondRequests.map((obj, i) =>
      i === index
        ? {
            ...obj,
            coursesBound: [],
            subjectsBound: [],
          }
        : obj
    );
    const updateCoursesSubjectsBoundData = contractualBondRequestsData.map(
      (obj, i) =>
        i === index
          ? {
              ...obj,
              coursesBound: [],
              subjectsBound: [],
            }
          : obj
    );
    setContractualBondRequests(updateCoursesSubjectsBound);
    setContractualBondRequestsData(updateCoursesSubjectsBoundData);
    onClose();
  };

  return (
    <Accordion allowToggle>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box
              as="span"
              flex="1"
              textAlign="left"
              fontWeight="bold"
              fontSize="xl"
            >
              Ano Lectivo {academicYear}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel py={4}>
          <Flex justifyContent="space-between">
            <Stack spacing={8}>
              <Box>
                <Text
                  textTransform="uppercase"
                  fontWeight="bold"
                  fontSize="sm"
                  mb={2}
                >
                  Escola
                </Text>
                {school}
              </Box>
              <Box>
                <Text
                  textTransform="uppercase"
                  fontWeight="bold"
                  fontSize="sm"
                  mb={2}
                >
                  Cursos
                </Text>
                {courses}
              </Box>
              <Box>
                <Text
                  textTransform="uppercase"
                  fontWeight="bold"
                  fontSize="sm"
                  mb={2}
                >
                  Disciplinas
                </Text>
                {subjects}
              </Box>
            </Stack>
            {!isDisabled &&
              (user?.schoolId && user?.schoolId !== schoolId ? (
                <></>
              ) : (
                <Box>
                  <Button variant="link" onClick={onOpen} ml={4}>
                    Excluir
                  </Button>
                  <AlertDialog isOpen={isOpen} onClose={onClose}>
                    <AlertDialogOverlay>
                      <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                          Excluir Cursos e Disciplinas
                        </AlertDialogHeader>

                        <AlertDialogBody>
                          Tem a certeza que pretende excluir os cursos e
                          disciplinas?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                          <Button variant="secondary" onClick={onClose} mr={3}>
                            Cancelar
                          </Button>
                          <Button onClick={deleteData}>Excluir</Button>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialogOverlay>
                  </AlertDialog>
                </Box>
              ))}
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

export default SubjectsAccordion;
