import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    HStack,
    Heading,
    Input,
    Stack,
    Text,
    Textarea,
  } from "@chakra-ui/react";
  import { useEffect, useState, useMemo } from "react";
  import { Controller } from "react-hook-form";
  import { useNavigate } from "react-router";
  import { Toast } from "../Toast";
  import Label from "../Label";
import Table from "../Table";
  
  export function ListErrorsUploadList({
    data
  }) {
    const navigate = useNavigate()
    const { addToast } = Toast();

    const columns = useMemo(
        () => [
        {
            Header: "Linha",
            accessor: "lineExcel"
        },
          {
            Header: "Nº de Erros",
            accessor: "countErrors",

          },
          {
            Header: "Descrição de erros encontrados",
            Cell: (item) => {

                return(<Text>{
                    item.data[item.row.index].errors.join(", ")
                    }</Text>)
                
              }
          }
        ],
        []
      );

    return (
      <>
       <Box mb={5}>
       <Text mb={5}>Início > Docentes > Erros na lista carregada</Text>

            {/* <Button onClick={()=>{
                navigate("/escolas/carregar-lista")
            }}>
                Voltar
            </Button> */}
          </Box>
        <Heading mb={7}>Erros na lista carregada</Heading>
          <HStack  >
            <Table 
                columns={columns}
                data={data}
            />
          </HStack> 
          <Box mt={10}>
            <Button>
                Descarregar lista(.csv)
            </Button>
          </Box>
      </>
    );
  }
  