import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { BsQuestionCircle } from "react-icons/bs";
import { DIALOG_MESSAGE } from "../../../utils/constants";

function Delete({ deleteUser }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex>
      <Button mr={2} variant="link" onClick={onOpen}>
        Excluir utilizador
      </Button>
      <Tooltip
        hasArrow
        label="Esta acção exclui, de forma permanente, este utilizador do sistema"
      >
        <Flex alignItems="center">
          <BsQuestionCircle />
        </Flex>
      </Tooltip>

      <AlertDialog isOpen={isOpen} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Excluir utilizador
            </AlertDialogHeader>

            <AlertDialogBody>{DIALOG_MESSAGE}</AlertDialogBody>

            <AlertDialogFooter>
              <Button variant="secondary" onClick={onClose} mr={3}>
                Cancelar
              </Button>
              <Button onClick={deleteUser}>Excluir</Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
}

export default Delete;
