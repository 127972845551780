/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useMemo, useEffect, useContext } from "react";
import {
  Button,
  Checkbox,
  HStack,
  Heading,
  Flex,
  Center,
  Box,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  Text,
  ModalFooter,
} from "@chakra-ui/react";
import { MdCheckCircle, MdCancel, MdArchive } from "react-icons/md";
import { Link } from "react-router-dom";
import { getCourse } from "../../../services/getCourse";
import Table from "../../../components/Table";
import {
  ACADEMIC_YEAR_URL,
  COUNTY_URL,
  DOWNLOAD_COURSES,
  INVALIDATE_COURSE,
  PROVINCE_URL,
  SCHOOL_URL,
  VALIDATE_COURSE,
} from "../../../utils/endpoints";
import StateRender from "../../../components/StateRender";
import { downloadExcelFile } from "../../../utils/downloadExcelFile";
import { AuthContext } from "../../../provider/AuthProvider";
import { EditIcon } from "@chakra-ui/icons";
import { Toast } from "../../../components/Toast";
import api from "../../../utils/api";
import Select from "react-select";
import { selectStyles } from "../../../utils/styles";
import Label from "../../../components/Label";
import { ARCHIVED, NO_OPTIONS_MESSAGE } from "../../../utils/constants";

export default function TableCourse({
  setDataTable,
  dataTable,
  params,
  setValue,
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [dataTableIn, setDataTableIn] = useState([]);
  const { addToast } = Toast();
  const [selectedRows, setSelectedRows] = useState([]);

  const [report, setReport] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  const [academicYear, setAcademicYear] = useState();
  const [province, setProvince] = useState();
  const [county, setCounty] = useState();
  const [school, setSchool] = useState();
  const [academicYearOptions, setAcademicYearOptions] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [countyOptions, setCountyOptions] = useState([]);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);

  const { auth } = useContext(AuthContext);
  const user = auth.user;

  useEffect(() => {
    const getData = async () => {
      const response = await getCourse();

      setDataTable(response);
      setIsLoaded(true);
    };
    getData();
  }, []);

  const download = () => {
    let apiUrl = "";
    if (report?.value === 1) {
      apiUrl = DOWNLOAD_COURSES;
    } else if (report?.value === 2) {
      apiUrl = "/api/Course/downloadCoursesSubjects";
    } else if (report?.value === 3) {
      apiUrl = `/api/Course/downloadCoursesSchools?AcademicYearId=${academicYear?.value}`;
      if (province?.value) {
        apiUrl += `&ProvinceId=${province?.value}`;
      }
      if (county?.value) {
        apiUrl += `&CountyId=${county?.value}`;
      }
      if (school?.value) {
        apiUrl += `&SchoolId=${school?.value}`;
      }
    }
    setIsLoading(true);
    downloadExcelFile(apiUrl, `${report?.label}.xlsx`);
    setIsLoading(false);
    onClose();
  };

  useEffect(() => {
    setDataTableIn(dataTable);
  }, [dataTable]);

  const columns = useMemo(
    () => [
      {
        Header: "Seleccionar todos",
        disableSortBy: true,
        accessor: "checkbox",
        Cell: (item) => {
          return (
            <Checkbox
              isChecked={selectedRows.includes(item.row.original.id)}
              onChange={() => toggleRowSelection(item.row.original.id)}
            />
          );
        },
      },
      {
        Header: "Estado",
        Cell: (item) => {
          return item.row.original.isActive ? (
            <StateRender isValidated={item.row.original.isValidated} />
          ) : (
            <Flex gap={1}>
              <MdArchive size="20px" />
              {ARCHIVED}
            </Flex>
          );
        },
      },
      {
        Header: "Designação do Curso",
        accessor: "name",
        Cell: (item) => {
          return (
            <Link to={`/cursos/${item.row.original.id}`}>
              {item.row.original.name}
            </Link>
          );
        },
      },
      {
        Header: "Ciclo de Ensino",
        accessor: "educationCicle",
      },
      {
        Header: "Área de Formação do INFQE",
        accessor: "technicalEducation",
      },
      {
        Header: "Editar",
        accessor: "edit",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <Link to={`/cursos/${item.data[item.row.id].id}`}>
              <Center>
                <EditIcon />
              </Center>
            </Link>
          );
        },
      },
      {
        Header: "Associar Escola",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <Link to={`/cursos/associar/${item.data[item.row.id].id}`}>
              <Button>Associar</Button>
            </Link>
          );
        },
      },
    ],
    [selectedRows]
  );

  const toggleRowSelection = (rowId) => {
    const updatedSelection = selectedRows.includes(rowId)
      ? selectedRows.filter((id) => id !== rowId)
      : [...selectedRows, rowId];
    setSelectedRows(updatedSelection);
  };

  const handleValidateOrInvalidate = async (url) => {
    try {
      await api.patch(url, {
        courseIds: selectedRows,
      });
      const responseAfterUpdate = await getCourse();
      setDataTable(responseAfterUpdate);
      setValue("educationLevel", undefined);
      setValue("educationSubsystem", undefined);
      setValue("educationCycle", undefined);
      setValue("generalArea", undefined);
      setValue("specificArea", undefined);
      setValue("detailedArea", undefined);
      setValue("domainPNFQ", undefined);
      setValue("technicalEducation", undefined);
      setValue("isValidated", undefined);
      setSelectedRows([]);
      addToast({
        status: "success",
        title: `Curso ${
          url.includes("invalidate") ? "invalidado" : "validado"
        } com sucesso`,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const options = [
    {
      value: 1,
      label: "Lista de cursos",
    },
    {
      value: 2,
      label: "Lista das disciplinas por curso",
    },
    {
      value: 3,
      label: "Lista dos cursos por escola e ano lectivo (1.ªs coluna cursos)",
    },
  ];

  const onChange = (e) => {
    setReport(e);
    if (e?.value) {
      onOpen();
    }
  };

  async function getAcademicYears() {
    api
      .get(ACADEMIC_YEAR_URL)
      .then((res) => {
        const data = res.data.result;
        setAcademicYearOptions(
          data
            .filter((i) => i.isActive)
            .map((i) => ({
              value: i.id,
              label: i.year,
              isBaseYear: i.isBaseYear,
            }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getProvinces() {
    api
      .get(PROVINCE_URL)
      .then((res) => {
        const data = res.data;
        setProvinceOptions(
          data
            .filter((i) => i.isActive)
            .map((i) => ({
              value: i.id,
              label: i.name,
            }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getCounties(provinceId) {
    api
      .get(`${COUNTY_URL}?ParentId=${provinceId}`)
      .then((res) => {
        const data = res.data;
        setCountyOptions(
          data
            .filter((i) => i.isActive)
            .map((i) => ({
              value: i.id,
              label: i.name,
            }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getSchools(provinceId = null, countyId = null) {
    let apiURL = `${SCHOOL_URL}?IsActive=true&IsValidated=true`;
    if (provinceId) {
      apiURL += `&ProvinceId=${provinceId}`;
    }
    if (countyId) {
      apiURL += `&CountyId=${countyId}`;
    }
    api
      .get(`${apiURL}`)
      .then((res) => {
        const data = res.data.result;
        setSchoolOptions(
          data.map((i) => ({
            value: i.id,
            label: i.name,
          }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  useEffect(() => {
    setAcademicYear();
    setProvince();
    setCounty();
    setSchool();
    setIsDisabled(true);
    if (report?.value === 3) {
      getAcademicYears();
      getProvinces();
      getSchools();
    } else {
      setIsDisabled(false);
    }
  }, [isOpen]);

  useEffect(() => {
    setCounty("");
    setSchool("");
    if (province?.value) {
      getCounties(province?.value);
      getSchools(province?.value);
    } else {
      setCountyOptions([]);
    }
  }, [province]);

  useEffect(() => {
    setSchool("");
    if (county?.value) {
      getSchools(province?.value, county?.value);
    }
  }, [county]);

  useEffect(() => {
    if (academicYear?.value) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [academicYear]);

  return (
    <>
      <Flex justifyContent="space-between" alignItems="end" mb="10px">
        <Heading as="h2" size="md" textTransform="uppercase">
          Lista de cursos
        </Heading>
        <Flex>
          <Box minW="300px">
            <Select
              placeholder="Descarregar lista"
              options={options}
              isSearchable={false}
              isClearable={true}
              onChange={onChange}
              styles={selectStyles}
            />
            <Modal isOpen={isOpen} onClose={onClose} size="lg">
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Descarregar lista</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Stack spacing={5}>
                    <Text>{report?.label}</Text>
                    {report?.value === 3 && (
                      <>
                        <Box>
                          <Label title="Ano Lectivo" isRequired />
                          <Select
                            placeholder="Seleccionar"
                            options={academicYearOptions}
                            onChange={(value) => {
                              setAcademicYear(value);
                            }}
                            noOptionsMessage={NO_OPTIONS_MESSAGE}
                          />
                        </Box>
                        <Box>
                          <Label title="Província" />
                          <Select
                            placeholder="Seleccionar"
                            options={provinceOptions}
                            onChange={(value) => {
                              setProvince(value);
                            }}
                            noOptionsMessage={NO_OPTIONS_MESSAGE}
                          />
                        </Box>
                        <Box>
                          <Label title="Município" />
                          <Select
                            placeholder="Seleccionar"
                            options={countyOptions}
                            value={county}
                            onChange={(value) => {
                              setCounty(value);
                            }}
                            isDisabled={!province?.value}
                            noOptionsMessage={NO_OPTIONS_MESSAGE}
                          />
                        </Box>
                        <Box>
                          <Label title="Escola" />
                          <Select
                            placeholder="Seleccionar"
                            options={schoolOptions}
                            value={school}
                            onChange={(value) => {
                              setSchool(value);
                            }}
                            noOptionsMessage={NO_OPTIONS_MESSAGE}
                          />
                        </Box>
                      </>
                    )}
                  </Stack>
                </ModalBody>

                <ModalFooter>
                  <Button variant="secondary" onClick={onClose} mr={3}>
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    onClick={download}
                    isLoading={isLoading}
                    isDisabled={isDisabled}
                  >
                    Descarregar
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Box>
        </Flex>
      </Flex>

      <Table
        route="cursos"
        columns={columns}
        data={dataTableIn}
        isLoaded={isLoaded}
        setIsLoaded={setIsLoaded}
        setData={setDataTableIn}
        getFunction={getCourse}
        entity="course"
        params={params}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />

      {user.permissions.includes("ValidateCourses") && (
        <HStack mt="5">
          <Button
            rightIcon={<MdCheckCircle />}
            onClick={() => {
              handleValidateOrInvalidate(VALIDATE_COURSE);
            }}
          >
            Validar Seleccionados
          </Button>

          <Button
            variant="danger"
            rightIcon={<MdCancel />}
            onClick={() => {
              handleValidateOrInvalidate(INVALIDATE_COURSE);
            }}
          >
            Não Validar Seleccionados
          </Button>
        </HStack>
      )}
    </>
  );
}
