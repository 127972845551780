import { atom } from "jotai";
import jwtDecode from "jwt-decode";

const token = localStorage.getItem("@retfoptoken");
let initialState = {
  isAuthenticated: false,
  isSchool: null,
  user: null,
  roleName: null,
};

if (token) {
  try {
    const decodedToken = jwtDecode(token);
    initialState = {
      isAuthenticated: true,
      isSchool: !!decodedToken.schoolId,
      user: {
        id: decodedToken.nameid,
        email: decodedToken.email,
        name: decodedToken.unique_name,
        permissions: decodedToken.role,
        provinceId: decodedToken.provinceId,
        countyId: decodedToken.countyId,
        schoolId: decodedToken.schoolId,
      },
      roleName: decodedToken.roleName,
    };
  } catch (error) {
    console.error("Invalid token", error);
  }
}

export const authAtom = atom(initialState);
