import { Box, Text } from "@chakra-ui/react";
import {
  MdCheckCircle,
  MdDoDisturb,
  MdDoDisturbOn,
  MdError,
} from "react-icons/md";
import { errorColor } from "../../utils/colors";

function StateRender({ isValidated }) {
  // return !isValidated ? (
  //   <Box style={{ display: "flex", alignItems: "center" }}>
  //     <MdOutlineReport size="1.3em" color="orange" />{" "}
  //     <Text> Pendente de Validação</Text>
  //   </Box>
  // ) : (
  //   <Box style={{ display: "flex", alignItems: "center" }}>
  //     <MdVerifiedUser size="1.3em" color="green" /> <Text>Validado</Text>
  //   </Box>
  // );

  return isValidated === null ? (
    <Box style={{ display: "flex", alignItems: "center" }}>
      <MdError size="1.3em" color="orange" />{" "}
      <Text ml="5px">Pendente de Validação</Text>
    </Box>
  ) : !isValidated ? (
    <Box style={{ display: "flex", alignItems: "center" }}>
      <MdDoDisturbOn size="1.3em" color={errorColor} />{" "}
      <Text ml="5px">Não validado</Text>
    </Box>
  ) : (
    <Box style={{ display: "flex", alignItems: "center" }}>
      <MdCheckCircle size="1.3em" color="green" />{" "}
      <Text ml="5px">Validado</Text>
    </Box>
  );
}

export default StateRender;
