import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  HStack,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { BsQuestionCircle } from "react-icons/bs";
import api from "../../../utils/api";
import { useNavigate } from "react-router-dom";
import { Toast } from "../../../components/Toast";
import { TEACHER_URL } from "../../../utils/endpoints";
import { useContext } from "react";
import { AuthContext } from "../../../provider/AuthProvider";

function ArchiveTeacher({ id, isActive }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { addToast } = Toast();
  const { auth } = useContext(AuthContext);
  const user = auth.user;

  const archiveTeacher = () => {
    api
      .patch(`${TEACHER_URL}/disable`, {
        id: id,
      })
      .then(() => {
        addToast({
          title: "Docente arquivado com sucesso",
          status: "success",
        });
        if (!user?.schoolId) {
          navigate("/docentes");
        } else {
          navigate(`/gerir/${user?.schoolId}/docentes`);
        }
      })
      .catch((error) => {
        let errors = error.response.data.errors;
        for (let err in errors) {
          addToast({ title: errors[err], status: "error" });
        }
      });
  };

  const dearchiveTeacher = () => {
    api
      .patch(`${TEACHER_URL}/enable`, {
        id: id,
      })
      .then(() => {
        addToast({
          title: "Docente desarquivado com sucesso",
          status: "success",
        });
        if (!user?.schoolId) {
          navigate("/docentes");
        } else {
          navigate(`/gerir/${user?.schoolId}/docentes`);
        }
      })
      .catch((error) => {
        let errors = error.response.data.errors;
        for (let err in errors) {
          addToast({ title: errors[err], status: "error" });
        }
      });
  };

  return (
    <>
      {isActive ? (
        <HStack>
          <Button variant="secondary" onClick={onOpen}>
            Arquivar
          </Button>
          <Tooltip hasArrow label="Esta acção arquiva este docente no sistema">
            <Flex alignItems="center">
              <BsQuestionCircle />
            </Flex>
          </Tooltip>

          <AlertDialog isOpen={isOpen} onClose={onClose}>
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Arquivar Docente
                </AlertDialogHeader>

                <AlertDialogBody>
                  Tem a certeza que pretende arquivar o Docente?
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button variant="secondary" onClick={onClose} mr={3}>
                    Cancelar
                  </Button>
                  <Button onClick={archiveTeacher}>Arquivar</Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </HStack>
      ) : (
        <HStack>
          <Button variant="secondary" onClick={onOpen}>
            Desarquivar
          </Button>
          <Tooltip
            hasArrow
            label="Esta acção desarquiva este docente no sistema"
          >
            <Flex alignItems="center">
              <BsQuestionCircle />
            </Flex>
          </Tooltip>

          <AlertDialog isOpen={isOpen} onClose={onClose}>
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Desarquivar Docente
                </AlertDialogHeader>

                <AlertDialogBody>
                  Tem a certeza que pretende desarquivar o Docente?
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button variant="secondary" onClick={onClose} mr={3}>
                    Cancelar
                  </Button>
                  <Button onClick={dearchiveTeacher}>Desarquivar</Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </HStack>
      )}
    </>
  );
}
export default ArchiveTeacher;
