export const handleAssociate = (
  setDataTable,
  dataTable,
  selectedOptions,
  setIsOpen,
  setOptions,
  options
) => {
  setDataTable((current) => [...current, ...selectedOptions]);
  let dataTablesCodes = [...dataTable, ...selectedOptions].map((e) => e.code);
  setOptions(options.filter((e) => !dataTablesCodes.includes(e.code)));

  setIsOpen(false);
};
