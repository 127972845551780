import {
  Box,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import TextArea from "antd/es/input/TextArea";
import { MdOutlineRemoveRedEye } from "react-icons/md";

function Details({ id, data, text = null }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {text ? (
        <Text
          onClick={onOpen}
          _hover={{
            cursor: "pointer",
          }}
        >
          {text}
        </Text>
      ) : (
        <Center
          _hover={{
            cursor: "pointer",
          }}
        >
          <MdOutlineRemoveRedEye onClick={onOpen} />
        </Center>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Detalhes: {id}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={1}>
              <Text as="b">Descrição</Text>
            </Box>
            <TextArea value={data} />
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Fechar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Details;
