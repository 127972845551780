import api from "../utils/api";
import { PAGE_SIZE } from "../utils/constants";
import { COURSE_URL } from "../utils/endpoints";

export async function getCourseByQs(params) {
  try {
    const response = await api.get(
      `${COURSE_URL}?PageSize=${PAGE_SIZE}&${params}`
    );
    const data = response.data.result.map((option) => ({
      id: option.id,
      code: option.code,
      name: option.name,
      educationLevel: option.educationalLevel?.name,
      subsystemEducation: option.educationalSubsystem?.name,
      educationCicle: option.educationalCycle?.name,
      generalArea: option.generalArea?.name,
      specificArea: option.specificArea?.name,
      detailedArea: option.detailedArea?.name,
      technicalEducation: option.courseTechnical?.name,
      dominainPNFQ: option.pnfqDomain?.name,
      isValidated: option.isValidated,
      totalItems: response.data.totalItems,
      courseForAcademicYears: option.courseForAcademicYears,
      isActive: option.isActive,
    }));
    return data;
  } catch (error) {
    throw error;
  }
}
