import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  HStack,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { BsQuestionCircle } from "react-icons/bs";
import api from "../../../utils/api";
import { useNavigate } from "react-router-dom";
import { Toast } from "../../../components/Toast";
import { STUDENT_URL } from "../../../utils/endpoints";
import { useContext } from "react";
import { AuthContext } from "../../../provider/AuthProvider";

function ArchiveStudent({ id, isActive }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { addToast } = Toast();
  const { auth } = useContext(AuthContext);
  const user = auth.user;

  const archiveStudent = () => {
    api
      .patch(`${STUDENT_URL}/disable`, {
        ids: [id],
      })
      .then(() => {
        addToast({
          title: "Aluno arquivado com sucesso",
          status: "success",
        });
        if (!user?.schoolId) {
          navigate("/alunos");
        } else {
          navigate(`/gerir/${user?.schoolId}/alunos`);
        }
      })
      .catch((error) => {
        let errors = error.response.data.errors;
        for (let err in errors) {
          addToast({ title: errors[err], status: "error" });
        }
      });
  };

  const dearchiveStudent = () => {
    api
      .patch(`${STUDENT_URL}/enable`, {
        ids: [id],
      })
      .then(() => {
        addToast({
          title: "Aluno desarquivado com sucesso",
          status: "success",
        });
        if (!user?.schoolId) {
          navigate("/alunos");
        } else {
          navigate(`/gerir/${user?.schoolId}/alunos`);
        }
      })
      .catch((error) => {
        let errors = error.response.data.errors;
        for (let err in errors) {
          addToast({ title: errors[err], status: "error" });
        }
      });
  };

  return (
    <>
      {isActive ? (
        <HStack>
          <Button variant="secondary" onClick={onOpen}>
            Arquivar
          </Button>
          <Tooltip hasArrow label="Esta acção arquiva este aluno no sistema">
            <Flex alignItems="center">
              <BsQuestionCircle />
            </Flex>
          </Tooltip>

          <AlertDialog isOpen={isOpen} onClose={onClose}>
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Arquivar Aluno
                </AlertDialogHeader>

                <AlertDialogBody>
                  Tem a certeza que pretende arquivar o Aluno?
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button variant="secondary" onClick={onClose} mr={3}>
                    Cancelar
                  </Button>
                  <Button onClick={archiveStudent}>Arquivar</Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </HStack>
      ) : (
        <HStack>
          <Button variant="secondary" onClick={onOpen}>
            Desarquivar
          </Button>
          <Tooltip hasArrow label="Esta acção desarquiva este aluno no sistema">
            <Flex alignItems="center">
              <BsQuestionCircle />
            </Flex>
          </Tooltip>

          <AlertDialog isOpen={isOpen} onClose={onClose}>
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Desarquivar Aluno
                </AlertDialogHeader>

                <AlertDialogBody>
                  Tem a certeza que pretende desarquivar o Aluno?
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button variant="secondary" onClick={onClose} mr={3}>
                    Cancelar
                  </Button>
                  <Button onClick={dearchiveStudent}>Desarquivar</Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </HStack>
      )}
    </>
  );
}
export default ArchiveStudent;
