import { FormControl, HStack, Input, Stack } from "@chakra-ui/react";
import Label from "../../../components/Label";

export function FormTab3({ register, isDisabled, email }) {
  return (
    <Stack spacing={5}>
      <HStack gap={5}>
        <FormControl>
          <Label title="Telefone da Escola" />
          <Input id="email" {...register("phone")} isDisabled={isDisabled} />
        </FormControl>

        <FormControl>
          <Label title="Email da Escola" />
          <Input id="email" value={email && email} isDisabled={true} />
        </FormControl>

        <FormControl>
          <Label title="Website da Escola" />
          <Input
            id="website"
            {...register("website")}
            isDisabled={isDisabled}
          />
        </FormControl>
      </HStack>

      <HStack gap={5}>
        <FormControl>
          <Label title="Nome do Director" />
          <Input
            id="directorName"
            {...register("directorName")}
            isDisabled={isDisabled}
          />
        </FormControl>

        <FormControl>
          <Label title="Telefone do Director" />
          <Input
            id="directorPhone"
            {...register("directorPhone")}
            isDisabled={isDisabled}
          />
        </FormControl>

        <FormControl>
          <Label title="Email do Director" />
          <Input
            id="directorEmail"
            {...register("directorEmail")}
            isDisabled={isDisabled}
          />
        </FormControl>
      </HStack>

      <HStack gap={5}>
        <FormControl>
          <Label title="Nome do Subdirector administrativo" />
          <Input
            id="directorAdminName"
            {...register("directorAdminName")}
            isDisabled={isDisabled}
          />
        </FormControl>

        <FormControl>
          <Label title="Telefone do Subdirector administrativo" />
          <Input
            id="directorAdminPhone"
            {...register("directorAdminPhone")}
            isDisabled={isDisabled}
          />
        </FormControl>

        <FormControl>
          <Label title="Email do Subdirector administrativo" />
          <Input
            id="directorAdminEmail"
            {...register("directorAdminEmail")}
            isDisabled={isDisabled}
          />
        </FormControl>
      </HStack>

      <HStack gap={5}>
        <FormControl>
          <Label title="Nome do Subdirector pedagógico" />
          <Input
            id="directorPedName"
            {...register("directorPedName")}
            isDisabled={isDisabled}
          />
        </FormControl>

        <FormControl>
          <Label title="Telefone do Subdirector pedagógico" />
          <Input
            id="directorPedPhone"
            {...register("directorPedPhone")}
            isDisabled={isDisabled}
          />
        </FormControl>

        <FormControl>
          <Label title="Email do Subdirector pedagógico" />
          <Input
            id="directorPedEmail"
            {...register("directorPedEmail")}
            isDisabled={isDisabled}
          />
        </FormControl>
      </HStack>

      <HStack gap={5}>
        <FormControl>
          <Label title="Nome da Entidade titular" />
          <Input
            id="entityName"
            {...register("entityName")}
            isDisabled={isDisabled}
          />
        </FormControl>

        <FormControl>
          <Label title="Telefone da Entidade titular" />
          <Input
            id="entityPhone"
            {...register("entityPhone")}
            isDisabled={isDisabled}
          />
        </FormControl>

        <FormControl>
          <Label title="Email da Entidade titular" />
          <Input
            id="entityEmail"
            {...register("entityEmail")}
            isDisabled={isDisabled}
          />
        </FormControl>
      </HStack>
    </Stack>
  );
}
