import Label from "../../../components/Label";
import { Button, Input, Box, Grid, Flex } from "@chakra-ui/react";
import { getCourse } from "../../../services/getCourse";
import { handleEnterOnSearch } from "../../../utils/constants";

export default function SearchArea({
  inputSearch,
  register,
  setInputSearch,
  setDataTable,
  getCourseData,
  id,
  academicYear
}) {
  const params = {
    Pesquisa: inputSearch,
    PageSize: 2500,
    SchoolId: id,
    AcademicYearId: academicYear.value
  };

  const handleSearch = async () => {
    if (params.Pesquisa) {
      const data = await getCourse(
        params,
      );
      setDataTable(data);
    }
  };

  const handleCleanFilters = async () => {
    if (params.Pesquisa) {
      setInputSearch("search", undefined);
      getCourseData();
    }
  };

  return (
    <>
      <Box px="20px">
        <Label title="Pesquisar pela designação ou código do curso" />

        <Grid gridTemplateColumns="1fr auto">
          <Flex mr={4}>
            <Input
              {...register("search")}
              sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              onKeyDown={(e) => {
                handleEnterOnSearch(e, handleSearch);
              }}
            />

            <Button
              onClick={handleSearch}
              sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            >
              Pesquisar
            </Button>
          </Flex>

          <Flex>
            <Button variant="link" onClick={handleCleanFilters}>
              Limpar consulta
            </Button>
          </Flex>
        </Grid>
      </Box>
    </>
  );
}
