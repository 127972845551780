import api from "../utils/api";
import { SUBJECT_URL } from "../utils/endpoints";

export async function getSubject(params) {
  try {
    let qs = "";
    qs += `OnlyActive=${
      params?.OnlyActive !== undefined ? params.OnlyActive : true
    }`;
    qs += params?.CourseId ? `&CourseId=${params.CourseId}` : "";
    qs += `&PageSize=${params?.PageSize ? params.PageSize : 0}`;
    const response = await api.get(`${SUBJECT_URL}?${qs}`);
    const data = response.data.result.map((option) => ({
      value: option.id,
      label: option.name,
      code: option.code,
      isActive: option.isActive,
      totalItems: response.data.totalItems,
    }));
    return data;
  } catch (error) {
    throw error;
  }
}
