import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Heading,
  Stack,
  Box,
  FormControl,
  Button,
  Flex,
} from "@chakra-ui/react";
import { getCourseById } from "../../services/getCourseById";
import Select from "react-select";
import { getAcademicYear } from "../../services/getAcademicYear";
import { CrumbContext } from "../../provider/CrumbProvider";
import Label from "../../components/Label";
import { NO_OPTIONS_MESSAGE } from "../../utils/constants";
import { useForm } from "react-hook-form";
import { formStyles } from "../../utils/styles";
import { Toast } from "../../components/Toast";
import SearchArea from "./ManageSchools/SearchArea";
import TableSchools from "./ManageSchools/TableSchools";
import { MdAddCircle } from "react-icons/md";
import ModalAssociate from "./ManageSchools/ModalAssociate";
import api from "../../utils/api";
import { LINK_COURSE_SCHOOL, SCHOOL_URL } from "../../utils/endpoints";

function AssociateCourseToSchool() {
  const [dataTable, setDataTable] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [optionsAcademicYear, setOptionsAcademicYear] = useState([]);
  const [academicYear, setAcademicYear] = useState(null);
  const [courseData, setCourseData] = useState([]);
  const [schools, setSchools] = useState([]);
  const { register, watch, setValue } = useForm();
  const inputSearch = watch("search");
  const { id } = useParams();
  const { addToast } = Toast();
  const { crumb, setCrumb } = useContext(CrumbContext);

  const getSchools = async () => {
    setDataTable(
      courseData.courseForAcademicYears.filter(
        (i) => i.academicYearForSchool.academicYear.id === academicYear.value
      )
    );
    const response = await api.get(
      `${SCHOOL_URL}?IsActive=true&IsValidated=true`
    );
    setSchools(response.data.result);
  };

  useEffect(() => {
    const getData = async () => {
      const response = await getCourseById(id);
      setCourseData(response);
      setCrumb({
        ...crumb,
        course: response.name,
      });
      const responseAcademicYear = await getAcademicYear();
      setOptionsAcademicYear(
        responseAcademicYear
          .map((e) => ({
            value: e.id,
            label: e.year,
            code: e.code,
          }))
          .reverse()
      );
    };
    getData();
  }, []);

  useEffect(() => {
    if (optionsAcademicYear.length > 0) {
      setAcademicYear(optionsAcademicYear[0]);
    }
  }, [optionsAcademicYear]);

  useEffect(() => {
    if (academicYear !== null) {
      getSchools();
    }
  }, [academicYear]);

  const handleAssociate = async () => {
    const dataTablesCode = dataTable.map((e) => ({
      schoolId: e.academicYearForSchool.school.id,
      courseCapacity: e.capacity ? e.capacity : null,
      courseApplications: e.applications ? e.applications : null,
    }));

    try {
      const response = await api.patch(LINK_COURSE_SCHOOL, {
        id,
        academicYearId: academicYear.value,
        schoolApplications: dataTablesCode,
      });

      if (response.status === 200) {
        addToast({
          title: "Lista de escolas actualizada com sucesso",
          status: "success",
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Stack spacing={5}>
      <Box
        display="flex"
        flexDir="row"
        justifyContent="space-between"
        alignItems="end"
      >
        <Heading>Escolas</Heading>

        {optionsAcademicYear.length > 0 && (
          <FormControl width="230px">
            <Label title="Ano lectivo" />
            <Select
              id="academicyear"
              defaultValue={optionsAcademicYear[0]}
              placeholder="Seleccionar"
              isSearchable={false}
              onChange={(e) => {
                setAcademicYear(e);
              }}
              options={optionsAcademicYear}
              noOptionsMessage={NO_OPTIONS_MESSAGE}
            />
          </FormControl>
        )}
      </Box>

      {academicYear && (
        <Box sx={formStyles} px="0!important">
          <SearchArea
            inputSearch={inputSearch}
            register={register}
            addToast={addToast}
            setDataTable={setDataTable}
            setInputSearch={setValue}
            courseData={courseData}
            getSchools={getSchools}
            id={id}
            academicYear={academicYear}
          />
        </Box>
      )}

      <Box>
        <Flex justifyContent="space-between" alignItems="end" mb="10px">
          <Heading as="h2" size="md" textTransform="uppercase">
            Lista de Escolas
          </Heading>

          <Box>
            <Button
              onClick={() => {
                setIsOpen(true);
              }}
              rightIcon={<MdAddCircle />}
            >
              Associar
            </Button>

            <ModalAssociate
              setIsOpen={setIsOpen}
              isOpen={isOpen}
              title="Associar Escola"
              setDataTable={setDataTable}
              dataTable={dataTable}
              schools={schools}
            />
          </Box>
        </Flex>

        <TableSchools
          setDataTable={setDataTable}
          dataTable={dataTable}
          academicYear={academicYear}
          id={id}
        />
      </Box>

      <Box>
        <Button onClick={handleAssociate}>Guardar</Button>
      </Box>
    </Stack>
  );
}

export default AssociateCourseToSchool;
