import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  Grid,
  GridItem,
  Text,
} from "@chakra-ui/react";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import Label from "../../../../components/Label";
import { NO_OPTIONS_MESSAGE } from "../../../../utils/constants";
import { selectStyles } from "../../../../utils/styles";
import { borderColor } from "../../../../utils/colors";
import {
  DETAILED_AREA_URL,
  GENERAL_AREA_URL,
  SPECIFIC_AREA_URL,
} from "../../../../utils/endpoints";
import api from "../../../../utils/api";
import { Toast } from "../../../../components/Toast";
import { lookUps } from "../..";
import { useParams } from "react-router-dom";

export default function AreaFilters({ setDataTable, setIsLoaded }) {
  const [generalAreaOptions, setGeneralAreaOptions] = useState([]);
  const [specificAreaOptions, setspecificAreaOptions] = useState([]);
  const [detailedAreaOptions, setDetailedAreaOptions] = useState([]);
  const { addToast } = Toast();
  const { handleSubmit, control, watch, setValue } = useForm();
  const { table } = useParams();

  async function getOptions(url, parentId = null) {
    try {
      const response = await api.get(
        parentId
          ? `${url}?ParentId=${parentId}&OnlyActive=false`
          : `${url}?OnlyActive=false`
      );
      const data = response.data.map((option) => ({
        value: option.id,
        label: option.name,
      }));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async function getGeneralAreaOptions(parentId = null) {
    getOptions(GENERAL_AREA_URL, parentId)
      .then((res) => setGeneralAreaOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getSpecificAreaOptions(parentId = null) {
    getOptions(SPECIFIC_AREA_URL, parentId)
      .then((res) => setspecificAreaOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getDetailedAreaOptions(parentId = null) {
    getOptions(DETAILED_AREA_URL, parentId)
      .then((res) => setDetailedAreaOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  useEffect(
    () => {
      getGeneralAreaOptions();
      getSpecificAreaOptions();
      getDetailedAreaOptions();
    },
    // eslint-disable-next-line
    []
  );

  const generalArea = watch("generalArea");

  useEffect(
    () => {
      if (generalArea) {
        setValue("specificArea", undefined);
        getSpecificAreaOptions(generalArea.value);
      } else {
        setValue("specificArea", undefined);
        setValue("detailedArea", undefined);
      }
    },
    // eslint-disable-next-line
    [generalArea]
  );

  const specificArea = watch("specificArea");

  useEffect(
    () => {
      if (specificArea) {
        setValue("detailedArea", undefined);
        getDetailedAreaOptions(specificArea.value);
      } else {
        setValue("detailedArea", undefined);
      }
    },
    // eslint-disable-next-line
    [specificArea]
  );

  const onSubmit = async (data) => {
    setIsLoaded(false);
    const response = await lookUps[table - 1].getFunction({
      OnlyActive: false,
      PageSize: 2500,
    });
    if (data.detailedArea) {
      setDataTable(
        response.filter((item) => item.value === data.detailedArea.value)
      );
    } else if (data.specificArea) {
      setDataTable(
        response.filter(
          (item) => item.specificArea.id === data.specificArea.value
        )
      );
    } else if (data.generalArea) {
      setDataTable(
        response.filter(
          (item) => item.specificArea.generalArea.id === data.generalArea.value
        )
      );
    } else {
      setDataTable(response);
    }
    setIsLoaded(true);
  };

  const clearFilters = async () => {
    setValue("generalArea", undefined);
    setValue("specificArea", undefined);
    setValue("detailedArea", undefined);
    setIsLoaded(false);
    getGeneralAreaOptions();
    getSpecificAreaOptions();
    const response = await lookUps[table - 1].getFunction({
      OnlyActive: false,
      PageSize: 2500,
    });
    setDetailedAreaOptions(response);
    setDataTable(response);
    setIsLoaded(true);
  };

  return (
    <>
      <Box position="relative" py={8}>
        <Divider borderColor={borderColor} />
        <Box
          bg="white"
          px="2"
          position="absolute"
          transform="translate(5%, -50%)"
        >
          <Text as="b" fontSize="sm" textTransform="uppercase">
            Filtrar áreas de formação
          </Text>
        </Box>
      </Box>
      <Box as="form" onSubmit={handleSubmit(onSubmit)} px="20px">
        <Grid gridTemplateColumns="1fr auto" gap={4}>
          <Grid templateColumns="repeat(3, 1fr)" gap={4}>
            <GridItem>
              <FormControl>
                <Label title="Área de Formação CITE (ISCED) 2013 - Geral" />
                <Controller
                  control={control}
                  name="generalArea"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      placeholder="Seleccionar"
                      options={generalAreaOptions}
                      onChange={onChange}
                      value={value || ""}
                      styles={selectStyles}
                      isClearable={true}
                      noOptionsMessage={NO_OPTIONS_MESSAGE}
                    />
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <Label title="Área de Formação CITE (ISCED) 2013 - Específica" />
                <Controller
                  control={control}
                  name="specificArea"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      placeholder="Seleccionar"
                      options={specificAreaOptions}
                      onChange={onChange}
                      value={value || ""}
                      styles={selectStyles}
                      isClearable={true}
                      noOptionsMessage={NO_OPTIONS_MESSAGE}
                    />
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <Label title="Área de Formação CITE (ISCED) 2013 - Detalhada" />
                <Controller
                  control={control}
                  name="detailedArea"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      placeholder="Seleccionar"
                      options={detailedAreaOptions}
                      onChange={onChange}
                      value={value || ""}
                      styles={selectStyles}
                      isClearable={true}
                      noOptionsMessage={NO_OPTIONS_MESSAGE}
                      isDisabled={generalArea && !specificArea}
                    />
                  )}
                />
              </FormControl>
            </GridItem>
          </Grid>
          <Flex alignSelf="end">
            <Button mr={4} type="submit">
              Filtrar
            </Button>
            <Button variant="link" onClick={clearFilters}>
              Limpar filtros
            </Button>
          </Flex>
        </Grid>
      </Box>
    </>
  );
}
