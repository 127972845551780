import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { handleAssociate } from "../../../../helpers/handleAssociate";

import Select from "react-select";
export default function ModalAssociate({
  setIsOpen,
  isOpen,
  selectedOptions,
  options,
  setSelectedOptions,
  title,
  setDataTable,
  dataTable,
  setOptions,
  handleAssociateParam,
}) {
  const [finalOptions, setFinalOptions] = useState([]);

  const handleShowAsOptionOnlyTheOnesNotSelected = async () => {
    const selectedOnes = dataTable.map((e) => e.code);
    const finalOpt = options.filter((e) => !selectedOnes.includes(e.code));
    setFinalOptions(finalOpt);
  };

  useEffect(() => {
    handleShowAsOptionOnlyTheOnesNotSelected();
  }, []);

  useEffect(() => {
    handleShowAsOptionOnlyTheOnesNotSelected();
  }, [options]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Select
            isMulti
            placeholder="Seleccionar"
            options={finalOptions}
            onChange={(values) => {
              setSelectedOptions(values);
            }}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            variant="secondary"
            onClick={() => {
              setIsOpen(false);
            }}
            mr={3}
          >
            Cancelar
          </Button>

          <Button
            onClick={() => {
              setIsOpen(false);
              typeof handleAssociateParam !== "undefined"
                ? handleAssociateParam()
                : handleAssociate(
                    setDataTable,
                    dataTable,
                    selectedOptions,
                    setIsOpen,
                    setOptions,
                    options
                  );
            }}
          >
            Associar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
