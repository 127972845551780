import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import EditCourse from "../pages/Course/EditCourse";
import PrivateRoute from "./PrivateRoute";
import Login from "../pages/Login";
import Home from "../pages/Home";
import Users from "../pages/Users";
import NewUser from "../pages/Users/NewUser";
import EditUser from "../pages/Users/EditUser";
import NewCourse from "../pages/Course/NewCourse";
import Course from "../pages/Course";
import Schools from "../pages/Schools";
import NewSchool from "../pages/Schools/NewSchool";
import EditSchool from "../pages/Schools/EditSchool";
import AcademicYear from "../pages/AcademicYear";
import Roles from "../pages/Roles";
import NewRole from "../pages/Roles/NewRole";
import EditRole from "../pages/Roles/EditRole";
import AssociateCourseToSchool from "../pages/Course/AssociateCourseToSchool";
import ManagerSchools from "../pages/Schools/ManagerSchool";
import ManagerCourses from "../pages/Schools/Managers/Courses";
import NewAcademicYear from "../pages/AcademicYear/NewAcademicYear";
import EditAcademicYear from "../pages/AcademicYear/EditAcademicYear";
import Teacher from "../pages/Teacher";
import Config from "../pages/config";
import ManagerTeacher from "../pages/Schools/Managers/Teacher";
import NewStudent from "../pages/Student/NewStudent";
import EditTeacher from "../pages/Teacher/EditTeacher";
import NewTeacher from "../pages/Teacher/NewTeacher";
import { UploadList } from "../pages/Schools/UploadList";
import Student from "../pages/Student";
import EditStudent from "../pages/Student/EditStudent";
import ManagerUsers from "../pages/Schools/Managers/Users";
import ManagerStudent from "../pages/Schools/Managers/Students";
import { UploadListGeneral } from "../components/UploadList";
import EditAccount from "../pages/EditAccount";
import ItemsPage from "../pages/config/ItemsPage";
import CloseAcademicYear from "../pages/Schools/Managers/CloseAcademicYear";
import Audit from "../pages/Audit";
import Reports from "../pages/Reports";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Login />} />
      <Route element={<PrivateRoute />}>
        <Route path="/inicio" element={<Home />} />

        <Route path="/utilizadores" element={<Users />} />
        <Route path="/utilizadores/cadastrar" element={<NewUser />} />
        <Route path="/utilizadores/:id" element={<EditUser />} />

        <Route path="/perfis" element={<Roles />} />
        <Route path="/perfis/criar" element={<NewRole />} />
        <Route path="/perfis/:id" element={<EditRole />} />

        <Route path="/escolas" element={<Schools />} />
        <Route path="/escolas/cadastrar" element={<NewSchool />} />
        <Route path="/escolas/carregar-lista" element={<UploadList />} />
        <Route
          path="/escolas/carregar-lista"
          element={
            <UploadListGeneral
              routeForm="/api/School/upload"
              backRoute="/escolas"
              titlePage="Carregar Lista de Escolas"
            />
          }
        />

        <Route path="/cursos" element={<Course />} />
        <Route path="/cursos/cadastrar" element={<NewCourse />} />
        <Route path="/cursos/:id" element={<EditCourse />} />
        <Route
          path="/cursos/associar/:id"
          element={<AssociateCourseToSchool />}
        />
        <Route
          path="/cursos/carregar-lista"
          element={
            <UploadListGeneral
              routeForm="/api/Course/Upload"
              backRoute="/cursos"
              titlePage="Carregar Lista de Cursos"
            />
          }
        />

        <Route path="/docentes" element={<Teacher />} />
        <Route path="/docentes/cadastrar" element={<NewTeacher />} />
        <Route path="/docentes/:id" element={<EditTeacher />} />
        <Route
          path="/docentes/carregar-lista"
          element={
            <UploadListGeneral
              routeForm="/api/Teacher/upload"
              backRoute="/docentes"
              titlePage="Carregar Lista de Docentes"
            />
          }
        />

        <Route path="/alunos" element={<Student />} />
        <Route path="/alunos/cadastrar" element={<NewStudent />} />
        <Route path="/alunos/:id" element={<EditStudent />} />
        <Route
          path="/alunos/carregar-lista"
          element={
            <UploadListGeneral
              routeForm="/api/Student/upload"
              backRoute="/alunos"
              titlePage="Carregar Lista de Alunos"
            />
          }
        />

        <Route path="/anos-lectivos" element={<AcademicYear />} />
        <Route path="/anos-lectivos/cadastrar" element={<NewAcademicYear />} />
        <Route path="/anos-lectivos/:id" element={<EditAcademicYear />} />

        <Route path="/configuracoes" element={<Config />} />
        <Route path="/configuracoes/items/:table" element={<ItemsPage />} />

        <Route path="/editar-conta" element={<EditAccount />} />
        <Route path="/auditoria" element={<Audit />} />

        <Route path="/estatisticas" element={<Reports />} />

        <Route path="/gerir/:id" element={<ManagerSchools />} />
        <Route path="/gerir/:id/resumo" element={<EditSchool />} />
        <Route path="/gerir/:id/utilizadores" element={<ManagerUsers />} />
        <Route path="/gerir/:id/alunos" element={<ManagerStudent />} />
        <Route path="/gerir/:id/cursos" element={<ManagerCourses />} />
        <Route path="/gerir/:id/docentes" element={<ManagerTeacher />} />
        <Route
          path="/gerir/:id/anos-lectivos"
          element={<CloseAcademicYear />}
        />

        <Route path="/escolas/gerir/:id" element={<ManagerSchools />} />
        <Route path="/escolas/gerir/:id/resumo" element={<EditSchool />} />

        <Route
          path="/escolas/gerir/:id/docentes"
          element={<ManagerTeacher />}
        />
        <Route
          path="/escolas/gerir/:id/docentes/cadastrar"
          element={<NewTeacher />}
        />
        <Route
          path="/escolas/gerir/:id/docentes/:teacherid"
          element={<EditTeacher />}
        />

        <Route
          path="/escolas/gerir/:id/utilizadores"
          element={<ManagerUsers />}
        />

        <Route path="/escolas/gerir/:id/cursos/" element={<ManagerCourses />} />

        <Route path="/escolas/gerir/:id/alunos/" element={<ManagerStudent />} />
        <Route
          path="/escolas/gerir/:id/alunos/cadastrar"
          element={<NewStudent />}
        />
        <Route
          path="/escolas/gerir/:id/alunos/:studentid"
          element={<EditStudent />}
        />

        <Route path="/escolas/gerir/cursos/:id" element={<ManagerCourses />} />

        <Route
          path="/escolas/gerir/:id/anos-lectivos/"
          element={<CloseAcademicYear />}
        />
      </Route>
    </>
  )
);

export default router;
