import { Box, Button, Flex, Stack } from "@chakra-ui/react";
import { MdAddCircle } from "react-icons/md";
import TableLabs from "./TableLabs";
import { useEffect, useState } from "react";
import ModalAssociateLabs from "./ModalAssociateLabs";
import { getLabType } from "../../../services/getLabType";

export function FormTab4({
  labsData,
  setLabsData,
  isDisabled,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [labsOptions, setLabsOptions] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const response = await getLabType();
      setLabsOptions(response);
    };
    getData();
  }, [])

  return (
    <Stack spacing={5}>
      {!isDisabled &&
        <Flex justifyContent="end">
          <Button
            onClick={() => {
              setIsOpen(true);
            }}
            rightIcon={<MdAddCircle />}
          >
            Associar
          </Button>

          <ModalAssociateLabs
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            labsData={labsData}
            setLabsData={setLabsData}
            labsOptions={labsOptions}
          />
        </Flex>
      }
      <TableLabs labsData={labsData} setLabsData={setLabsData} isDisabled={isDisabled} />
    </Stack>
  );
}
