import api from "../utils/api";
import { SCHOOL_URL } from "../utils/endpoints";

export async function getSchool(provinceId, countyId = null) {
  try {
    const response = await api.get(
      provinceId
        ? `${SCHOOL_URL}?ProvinceId=${provinceId}`
        : countyId
        ? `${SCHOOL_URL}?CountyId=${countyId}`
        : SCHOOL_URL
    );
    const data = response.data.result.map((option) => ({
      value: option.id,
      code: option.code,
      label: option.name,
    }));
    return data;
  } catch (error) {
    throw error;
  }
}
