const handleSearch =  async (inputSearch,setDataTable,  theData, id = null) => {
    const inputSearchLowerCase = inputSearch.toLowerCase()
    const course = typeof theData == "function" ? (await theData(id)).subjects : theData
    const filtered = course.filter( (e) => ( 
      e.code.toLowerCase().includes(inputSearchLowerCase) || e.name.toLowerCase().includes(inputSearchLowerCase) 
    )).map(e => ({
      code: e.code,
      value: e.value,
      label: e.name
    }))
 
    setDataTable(filtered)
  }


  export default handleSearch