/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import Table from "../../components/Table";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Box, Center, Heading, Spinner, Text } from "@chakra-ui/react";

import { Switch } from "@chakra-ui/react";

import { Checkbox } from "@chakra-ui/react";
import { DIALOG_MESSAGE, NO_RESULTS } from "../../utils/constants";
import { BsTrash } from "react-icons/bs";
import {
  DELETE_LOOKUP_ITEM,
  ENABLE_DISABLE_LOOKUP_ITEM,
  SPECIFIC_AREA_URL,
} from "../../utils/endpoints";
import api from "../../utils/api";
import { lookUps } from ".";
import Alert from "../../components/Alert";
import { Toast } from "../../components/Toast";
import { formStyles } from "../../utils/styles";
import ModalAreaEdit from "./components/Modal/ModalAreaEdit";
import ChangeStatus from "./components/ChangeStatus";
import Edit from "./components/Edit";
import Delete from "./components/Delete";
import { getGeneralArea } from "../../services/getGeneralArea";

export default function TableSpecificArea({
  setIdUpdateItem,
  setDataTable,
  dataTable,
  isLoaded,
  setIsLoaded,
  setTitle,
  setValue,
  setButtonTitle,
  hasProvince,
  dataSpecificArea,
  setDataSpecificArea,
}) {
  const { table } = useParams();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [idItem, setIdItem] = useState(0);
  const { addToast } = Toast();

  const handleDeleteItem = async () => {
    try {
      await api.delete(DELETE_LOOKUP_ITEM, {
        data: {
          entityType: "CourseSpecificArea",
          id: idItem,
        },
      });
      const response = await api.get(SPECIFIC_AREA_URL, {
        OnlyActive: false,
        PageSize: 2500,
      });
      setDataSpecificArea(response.data);
      setIsAlertOpen(false);
      addToast({
        status: "success",
        title: "Item eliminado com sucesso",
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleOnChangeStateItem = async (id, enabled) => {
    try {
      await api.post(ENABLE_DISABLE_LOOKUP_ITEM, {
        entityType: "CourseSpecificArea",
        id,
        enabled: enabled,
      });
      const response = await api.get(SPECIFIC_AREA_URL, {
        OnlyActive: false,
        PageSize: 2500,
      });
      setDataTable(response.data);
      addToast({
        title: "Estado do item alterado com sucesso",
        status: "success",
      });
    } catch (e) {
      addToast({
        title: "Ocorreu um erro ao alterar o estado do item",
        status: "error",
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Área de Formação CITE (ISCED) 2013 - Geral",
        accessor: "generalArea.name",
      },
      {
        Header: "Área de Formação CITE (ISCED) 2013 - Específica",
        accessor: "name",
      },
      {
        Header: "Estado",
        accessor: "status",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <ChangeStatus
              item={item.row.original}
              entityType="CourseSpecificArea"
              entityName="CourseSpecificArea"
              setData={setDataSpecificArea}
            />
          );
        },
      },
      {
        Header: "Editar",
        accessor: "specificArea.edit",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <Link>
              <Edit
                item={item.row.original}
                entityType="CourseSpecificArea"
                entityName="CourseSpecificArea"
                setData={setDataSpecificArea}
              />
            </Link>
          );
        },
      },
      {
        Header: "Excluir",
        accessor: "specificArea.delete",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <Link>
              <Delete
                item={item.row.original}
                entityType="CourseSpecificArea"
                entityName="CourseSpecificArea"
                setData={setDataSpecificArea}
              />
            </Link>
          );
        },
      },
    ],
    []
  );

  const [selectedIds, setSelectedIds] = useState([]);

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  return (
    <>
      <>
        {dataSpecificArea ? (
          dataSpecificArea.length > 0 ? (
            <Table
              setIsLoaded={setIsLoaded}
              isLoaded={isLoaded}
              setData={setDataSpecificArea}
              getFunction={getGeneralArea}
              columns={columns}
              data={dataSpecificArea}
              isPaginated={false}
            />
          ) : (
            <Box width="100%" display="flex" sx={formStyles}>
              <Text>{NO_RESULTS}</Text>
            </Box>
          )
        ) : (
          <Spinner />
        )}
      </>
      <Alert
        isOpen={isAlertOpen}
        setIsOpen={setIsAlertOpen}
        text={DIALOG_MESSAGE}
        title="Excluir item"
        handleDelete={handleDeleteItem}
        buttonText="Excluir"
      />
    </>
  );
}
