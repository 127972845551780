import {
  FormControl,
  FormErrorMessage,
  HStack,
  Stack,
  Radio,
  RadioGroup
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { Controller } from "react-hook-form";

import { Toast } from "../../../components/Toast";

import Label from "../../../components/Label";
import { getAcademicYearFormat } from "../../../services/getAcademicYearFormat";
  export default function FormEditTabInfo({
    isDisabled,
    errors,
    register,
    control,
    setValue,
    year,
    yearFormat,
    formatYear,
    setFormatYear
  }) {
    const [academicYearFormatData, setAcademicYearFormatData]= useState([])
    useEffect(()=>{
      const getData = async () => {
        const response = await getAcademicYearFormat()
        setAcademicYearFormatData(response)
      }
      getData()
    }, [])
    useEffect(()=>{
      console.log()
    }, [])
    const inputCodeStyle= {
      padding:'10px',
      width: '100%',
      border:'1px solid gray',
      borderRadius:'5px'
    }
    const { addToast } = Toast();
    const handleLabelInAcademicYear = (year) => {
      const label = year.includes("/") ? "4 + 4 dígitos - xxxx/xxxx" : "4 dígitos - xxxx"
      return label
    }
    return (
      <>
      <Stack spacing={5} mb={5} >
      <FormControl isInvalid={!!errors.yearFormat}  width="70%">
        <Label
          title="Selecione o Formato do Ano Lectivo"
          isRequired={true}
        />
       <Controller
        control={control}
        name="yearFormat"
        render={({ field: { onChange, value } }) => (
          <RadioGroup value={Number(value)} onChange={(e)=>{
            setValue("year", "")
            onChange(Number(e))
          }} >
            <Stack direction='column'>
            {
                    academicYearFormatData.map(e => (
                      <Radio  onChange={()=>{
                        setFormatYear(e.label)
                        console.log(e)
                      }}
                      value={Number(e.value)}>{handleLabelInAcademicYear(e.code)}</Radio>
                    ))
                  }
            </Stack>
          </RadioGroup>
        )}
      />
        <FormErrorMessage>
          {errors.yearFormat && <>Campo Obrigatório</>}
        </FormErrorMessage>
      </FormControl>

      <HStack  width="15%">
      <FormControl isInvalid={!!errors.year}>
          <Label
            title="Ano Lectivo"
            isRequired={true}
          />
            <InputMask
              {...register("year")} 
              style={inputCodeStyle}
              mask={formatYear} 
              value={year}
            />
          <FormErrorMessage>
            {errors.year && <>Campo Obrigatório</> }
          </FormErrorMessage>
        </FormControl>
      </HStack>
    </Stack>
  </>
  );
}
