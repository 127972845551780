import {
  FormControl,
  FormHelperText,
  HStack,
  Input,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";

import Select from "react-select";
import { getCommune } from "../../../services/getCommune";
import { useEffect, useState } from "react";
import { Toast } from "../../../components/Toast";
import { getUrbanDistrict } from "../../../services/getUrbanDistrict";
import Label from "../../../components/Label";

export function FormTab2({
  register,
  control,
  province,
  county,
  isDisabled,
  commune,
  district,
}) {
  const { addToast } = Toast();

  const [communeOptions, setCommuneOptions] = useState([]);
  const [urbanDistrictOptions, setUrbanDistrictOptions] = useState([]);

  async function getCommuneOptions(id) {
    getCommune({ ParentId: id })
      .then((res) => {
        setCommuneOptions(res);
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getUrbanDistrictOptions(id) {
    getUrbanDistrict({ ParentId: id })
      .then((res) => setUrbanDistrictOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  useEffect(
    () => {
      if (county) {
        getCommuneOptions(county?.value);
        getUrbanDistrictOptions(county?.value);
      }
    },
    // eslint-disable-next-line
    [county]
  );

  return (
    <>
      <Stack spacing={5} mb={5}>
        <FormControl>
          <Label title="Morada" />
          <Textarea
            id="address"
            placeholder="Exemplo: Bairro/Avenida, número x, Luanda, Angola, nº Policia "
            {...register("address")}
            isDisabled={isDisabled}
          />
        </FormControl>

        <FormControl>
          <Label title="Ponto de referência" />
          <Textarea
            id="pointOfReference"
            {...register("pointOfReference")}
            isDisabled={isDisabled}
          />
        </FormControl>

        <HStack>
          <FormControl>
            <Label title="Localidade" />
            <Input
              id="locality"
              {...register("locality")}
              isDisabled={isDisabled}
            />
          </FormControl>
        </HStack>

        <HStack gap={5}>
          <FormControl>
            <Label title="Província" isRequired />
            <Controller
              control={control}
              name="province"
              render={({ field: { onChange, value } }) => (
                <Select
                  id="province"
                  placeholder={(province && province.label) || "Seleccionar"}
                  isDisabled={true}
                  isSearchable={false}
                  onChange={onChange}
                />
              )}
            />
          </FormControl>

          <FormControl>
            <Label title="Município" isRequired />
            <Controller
              control={control}
              name="county"
              render={({ field: { onChange, value } }) => (
                <Select
                  id="county"
                  placeholder={(county && county.label) || "Seleccionar"}
                  value={county && county.label | ""}
                  isDisabled={true}
                  isSearchable={false}
                  onChange={onChange}
                />
              )}
            />
          </FormControl>
        </HStack>

        {county?.label &&
          (communeOptions.length > 0 || urbanDistrictOptions.length > 0) && (
            <HStack gap={5}>
              <FormControl>
                <Label title="Comuna" />
                <Controller
                  control={control}
                  name="commune"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      id="commune"
                      placeholder="Seleccionar"
                      value={commune}
                      options={communeOptions && communeOptions}
                      isSearchable={false}
                      onChange={onChange}
                      isDisabled={
                        isDisabled ||
                        county.value === undefined ||
                        communeOptions.length === 0
                      }
                    />
                  )}
                />
              </FormControl>

              <FormControl>
                <Label title="Distrito urbano" />
                <Controller
                  control={control}
                  name="district"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      id="district"
                      placeholder="Seleccionar"
                      value={district}
                      options={urbanDistrictOptions && urbanDistrictOptions}
                      isSearchable={false}
                      onChange={onChange}
                      isDisabled={
                        isDisabled ||
                        county.value === undefined ||
                        urbanDistrictOptions.length === 0
                      }
                    />
                  )}
                />
              </FormControl>
            </HStack>
          )}

        <HStack gap="5">
          <FormControl>
            <Label title="Latitude" />
            <Input
              id="latitude"
              {...register("latitude")}
              // isDisabled="{isDisabled}"
              isDisabled="true"
            />
            <FormHelperText>
              O campo latitude tem de estar compreendido entre -04.387205 e
              -18.027338
            </FormHelperText>
          </FormControl>

          <FormControl>
            <Label title="Longitude" />
            <Input
              id="longitude"
              {...register("longitude")}
              // isDisabled={isDisabled}
              isDisabled="true"
            />
            <FormHelperText>
              O campo longitude tem de estar compreendido entre 11.656349 e
              24.093379
            </FormHelperText>
          </FormControl>
        </HStack>
      </Stack>
    </>
  );
}
