import { useContext, useEffect, useState } from "react";
import Form from "./Form";
import { useForm } from "react-hook-form";
import { schema } from "./Form/schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Toast } from "../../components/Toast";
import api from "../../utils/api";
import { STUDENT_URL } from "../../utils/endpoints";
import { AuthContext } from "../../provider/AuthProvider";

function NewStudent() {
  const [isDisabled, setIsDisabled] = useState(false);
  const [schoolBondRequests, setSchoolBondRequests] = useState([]);
  const [schoolBondRequestsData, setSchoolBondRequestsData] = useState([]);
  const navigate = useNavigate();
  const { addToast } = Toast();
  const [searchParams] = useSearchParams();
  const { auth } = useContext(AuthContext);
  const user = auth.user;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
    unregister,
  } = useForm({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    populateStudendData();
  }, []);

  const populateStudendData = () => {
    setValue("typeDocument", searchParams.get("typeDocumentValue"));
    setValue("typeDocumentName", searchParams.get("typeDocument"));
    setValue("documentNumber", searchParams.get("documentNumber"));
    setValue("hasNee", 0);
  };

  const createStudent = () => {
    const data = getValues();
    api
      .post(STUDENT_URL, {
        name: data.name,
        typeDocument: data.typeDocument,
        documentNumber: data.documentNumber,
        birthDate: data.birthDate,
        gender: data.gender,
        educationSponsorName: data.educationSponsorName,
        educationSponsorPhone: data.educationSponsorPhone,
        nationality: data.nationality.value,
        specialEducationNeed: data.specialEducationNeed,
        specialEducationNeedType: data.specialEducationNeedType
          ? data.specialEducationNeedType.value
          : null,
        createSchoolBondRequests: schoolBondRequests,
      })
      .then(() => {
        addToast({
          title: "Aluno cadastrado com sucesso",
          status: "success",
        });
        if (!user?.schoolId) {
          navigate("/alunos");
        } else {
          navigate(`/gerir/${user?.schoolId}/alunos`);
        }
      })
      .catch((error) => {
        let errors = error.response.data.errors;
        for (let err in errors) {
          addToast({ title: errors[err], status: "error" });
        }
      });
  };

  return (
    <Form
      isDisabled={isDisabled}
      setIsDisabled={setIsDisabled}
      register={register}
      handleSubmit={handleSubmit}
      errors={errors}
      control={control}
      watch={watch}
      setValue={setValue}
      unregister={unregister}
      createStudent={createStudent}
      schoolBondRequests={schoolBondRequests}
      setSchoolBondRequests={setSchoolBondRequests}
      schoolBondRequestsData={schoolBondRequestsData}
      setSchoolBondRequestsData={setSchoolBondRequestsData}
    />
  );
}

export default NewStudent;
