import {
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Stack,
  Textarea,
  Heading,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getEducationLevel } from "../../../services/getEducationLevel";
import { getSubsystemEducation } from "../../../services/getSubsystemEducation";
import { getCicleEducation } from "../../../services/getCicleEducation";

import { getDetailedArea } from "../../../services/getDetailedArea";
import { getSepecificArea } from "../../../services/getSpecificArea";
import { getGeneralArea } from "../../../services/getGeneralArea";
import { getTechinicalEducation } from "../../../services/getTechinicalEducation";
import { getDomainPNFQ } from "../../../services/getDomainPNFQ";
import Label from "../../../components/Label";
import CustomFormControlSelect from "../../../components/CustomFormControlSelect";
export default function FormEditTabInfo({
  isDisabled,
  errors,
  register,
  control,
  educationLevel,
  subsystemEducation,
  generalArea,
  specificArea,
  detailedArea,
  technicalEducation,
  domainPNFQ,
  setValue,
  cicleEducationRead,
  needsInitialValue,
  backEdit,
  setBackEdit,
}) {
  const [dataEducationLevel, setDataEducationLevel] = useState([]);
  const [dataSubsystemEducation, setDataSubsystemEducation] = useState([]);
  const [cicleEducationData, setDataCicleEducation] = useState([]);
  const [specificAreaData, setSpecificAreaData] = useState([]);
  const [detailedAreaData, setDetailedAreaData] = useState([]);
  const [generalAreaData, setGeneralAreaData] = useState([]);
  const [domainPNFQData, setDomainPNFQData] = useState([]);
  const [technicalEducationData, setTechnicalEducationData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      setBackEdit(false);
      const res = await getEducationLevel();
      const resGeneralArea = await getGeneralArea();
      const resTechnicalEducation = await getTechinicalEducation();
      const resDomainPNFQ = await getDomainPNFQ();
      setDataEducationLevel(res);
      setGeneralAreaData(resGeneralArea);
      setTechnicalEducationData(resTechnicalEducation);
      setDomainPNFQData(resDomainPNFQ);
    };
    getData();
  }, []);

  useEffect(() => {
    if (!backEdit) {
      setValue("educationSubsystem", "");
      setValue("cicleEducation", "");
    }
    const getData = async () => {
      const res = await getSubsystemEducation(educationLevel?.value);
      setDataSubsystemEducation(res);
    };
    getData();
  }, [educationLevel]);

  useEffect(() => {
    if (!backEdit) setValue("cicleEducation", "");
    const getData = async () => {
      const res = await getCicleEducation(subsystemEducation?.value);
      setDataCicleEducation(res);
    };
    getData();
  }, [subsystemEducation]);

  useEffect(() => {
    const getData = async () => {
      const res = await getSepecificArea({ ParentId: generalArea?.value });
      setSpecificAreaData(res);
      if (!backEdit) {
        setValue("specificArea", "");
        setValue("detailedArea", "");
      }
    };
    getData();
  }, [generalArea]);

  useEffect(() => {
    const getData = async () => {
      const res = await getDetailedArea({ ParentId: specificArea?.value });
      setDetailedAreaData(res);
      if (!backEdit) {
        setValue("detailedArea", "");
      }
    };
    getData();
  }, [specificArea]);

  return (
    <>
      <Stack spacing={5} mb={5}>
        <FormControl isInvalid={!!errors.name}>
          <Label title="Designação do Curso" isRequired={true} />
          <Textarea id="name" {...register("name")} isDisabled={isDisabled} />
          <FormErrorMessage>
            {errors.educationLevel && <>Campo obrigatório</>}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.className}>
          <Label title="Iniciais do Curso para efeitos de designação das Turmas" isRequired={true} />
          <Input {...register("className")} isDisabled={isDisabled} />
          <FormErrorMessage>
            {errors.className && errors.className.message}
          </FormErrorMessage>
        </FormControl>

        <HStack width="50%">
          <CustomFormControlSelect
            errors={errors}
            fieldNameObject="educationLevel"
            parent={{ value: 1 }}
            options={dataEducationLevel}
            fieldName="Nivel de Ensino"
            control={control}
            isRequired={true}
          />
        </HStack>

        <HStack width="50%">
          <CustomFormControlSelect
            errors={errors}
            fieldNameObject="educationSubsystem"
            isDisabled={!educationLevel?.value}
            parent={{ value: 1 }}
            options={dataSubsystemEducation}
            fieldName="Subsistema de Ensino"
            control={control}
            isRequired={true}
          />
        </HStack>

        <HStack width="50%">
          <CustomFormControlSelect
            errors={errors}
            fieldNameObject="cicleEducation"
            isDisabled={!subsystemEducation?.value}
            parent={{ value: 1 }}
            options={cicleEducationData}
            fieldName="Ciclo de Ensino"
            control={control}
            isRequired={true}
          />
        </HStack>

        <Heading size="lg" as="h4">
          Áreas de Formação
        </Heading>
        <Heading size="sm" as="h5">
          Classificação CITE (ISCED) 2013
        </Heading>

        <HStack>
          <CustomFormControlSelect
            errors={errors}
            fieldNameObject="generalArea"
            isDisabled={false}
            parent={{ value: 1 }}
            options={generalAreaData}
            fieldName="Área Geral CITE (ISCED) 2013"
            control={control}
            isRequired={false}
          />

          <FormControl>
            <Label title="Código" />
            <Input
              value={generalArea?.code ? generalArea?.code : ""}
              disabled
              placeholder="Código"
              size="md"
            />
          </FormControl>
        </HStack>

        <HStack ms={5}>
          <CustomFormControlSelect
            errors={errors}
            fieldNameObject="specificArea"
            isDisabled={!generalArea?.value}
            parent={{ value: 1 }}
            options={specificAreaData}
            fieldName="Área Específica CITE (ISCED) 2013"
            control={control}
            isRequired={false}
          />

          <FormControl>
            <Label title="Código" />
            <Input
              value={specificArea?.code ? specificArea?.code : ""}
              disabled
              placeholder="Código"
              size="md"
            />
          </FormControl>
        </HStack>

        <HStack ms={10}>
          <CustomFormControlSelect
            errors={errors}
            fieldNameObject="detailedArea"
            isDisabled={!specificArea?.value}
            parent={{ value: 1 }}
            options={detailedAreaData}
            fieldName="Área Detalhada CITE (ISCED) 2013"
            control={control}
            isRequired={false}
          />

          <FormControl>
            <Label title="Código" />
            <Input
              value={detailedArea?.code ? detailedArea?.code : ""}
              disabled
              placeholder="Código"
              size="md"
            />
          </FormControl>
        </HStack>

        <HStack>
          <CustomFormControlSelect
            errors={errors}
            fieldNameObject="technicalEducation"
            isDisabled={false}
            parent={{ value: 1 }}
            options={technicalEducationData}
            fieldName="Área de Formação do INFQE"
            control={control}
            isRequired={true}
          />

          <FormControl>
            <Label title="Código" />
            <Input
              value={technicalEducation?.code ? technicalEducation?.code : ""}
              disabled
              placeholder="Código"
              size="md"
            />
          </FormControl>
        </HStack>

        <HStack>
          <CustomFormControlSelect
            errors={errors}
            fieldNameObject="domainPNFQ"
            isDisabled={false}
            parent={{ value: 1 }}
            options={domainPNFQData}
            fieldName="Domínio PNFQ"
            control={control}
            isRequired={false}
          />

          <FormControl>
            <Label title="Código" />
            <Input
              value={domainPNFQ?.code ? domainPNFQ?.code : ""}
              disabled
              placeholder="Código"
              size="md"
            />
          </FormControl>
        </HStack>
      </Stack>
    </>
  );
}
