import {
  Box,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import { formStyles } from "../../../utils/styles";
import Label from "../../../components/Label";
import { Controller } from "react-hook-form";
import { REQUIRED_FIELD } from "../../../utils/constants";
import { getRoleLevels } from "../../../services/getRoleLevels";
import { getFeatures } from "../../../services/getFeatures";
import { useState, useEffect } from "react";
import { Toast } from "../../../components/Toast";
import FormButtons from "./FormButtons";
import PickList from "../components/Picklist";

function Form({
  isDisabled,
  setIsDisabled,
  id,
  register,
  handleSubmit,
  errors,
  control,
  watch,
  setValue,
  roleTypesOptions,
  roleData,
  rolePermissions,
  createRole,
  editRole,
  cancelEdit,
  deleteRole,
  isCancel,
  setIsCancel,
}) {
  const [roleLevelsOptions, setRoleLevelsOptions] = useState([]);
  const [featuresOptions, setFeaturesOptions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const { addToast } = Toast();

  const name = watch("name");
  const roleTypeId = watch("roleTypeId");
  const roleLevelId = watch("roleLevelId");

  async function getRoleLevelsOptions(id) {
    getRoleLevels(id)
      .then((res) => setRoleLevelsOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getFeaturesOptions(roleTypeId, roleLevelId) {
    getFeatures(roleTypeId, roleLevelId)
      .then((res) => setFeaturesOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  useEffect(
    () => {
      if (roleTypeId) {
        getRoleLevelsOptions(roleTypeId);
        if (!id) {
          setValue("roleLevelId", "");
          setFeaturesOptions([]);
        }
        if (id && roleTypeId !== roleData.roleType.id.toString()) {
          setValue("roleLevelId", "");
          setFeaturesOptions([]);
        }
      }
    },
    // eslint-disable-next-line
    [roleTypeId]
  );

  useEffect(() => {
    if (roleTypeId && roleLevelId) {
      getFeaturesOptions(roleTypeId, roleLevelId);
      if (id && roleLevelId !== roleData.level.id.toString()) {
        setSelectedPermissions([]);
        setValue("permissions", null);
      }
    }
    // eslint-disable-next-line
  }, [roleTypeId, roleLevelId]);

  const onSubmit = () => {
    setIsDisabled(true);
    if (id) {
      editRole();
    }
  };

  return (
    <Stack spacing={5}>
      <Heading as="h1">
        {isDisabled
          ? `Resumo | ${name}`
          : id
          ? `Editar ${name}`
          : "Criar Perfil"}
      </Heading>
      <Box>
        <Heading as="h2" size="md" textTransform="uppercase" mb={2}>
          Informação
        </Heading>
        <Box as="form" onSubmit={handleSubmit(onSubmit)}>
          <Box sx={formStyles} mb={5}>
            <Stack spacing={4}>
              <FormControl isInvalid={!!errors.name}>
                <Label title="Nome do perfil" isRequired />
                <Input {...register("name")} isDisabled={isDisabled} />
                <FormErrorMessage>
                  {errors.name && errors.name.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.roleTypeId}>
                <Label title="Este perfil pode:" isRequired />
                <Controller
                  control={control}
                  name="roleTypeId"
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup value={value} onChange={onChange}>
                      <Stack>
                        {roleTypesOptions.map((type) => (
                          <Radio
                            key={type.value}
                            value={type.value?.toString()}
                            isDisabled={isDisabled}
                          >
                            {type.label}
                          </Radio>
                        ))}
                      </Stack>
                    </RadioGroup>
                  )}
                />
                <FormErrorMessage>
                  {errors.roleTypeId && REQUIRED_FIELD}
                </FormErrorMessage>
              </FormControl>
              {roleTypeId && roleLevelsOptions.length > 0 && (
                <FormControl isInvalid={!!errors.roleLevelId}>
                  <Label title="Limitar ao nível de:" isRequired />
                  <Controller
                    control={control}
                    name="roleLevelId"
                    render={({ field: { onChange, value } }) => (
                      <RadioGroup value={value} onChange={onChange}>
                        <Stack>
                          {roleLevelsOptions.map((type) => (
                            <Radio
                              key={type.value}
                              value={type.value?.toString()}
                              isDisabled={isDisabled}
                            >
                              {type.label}
                            </Radio>
                          ))}
                        </Stack>
                      </RadioGroup>
                    )}
                  />
                  <FormErrorMessage>
                    {errors.roleLevelId && REQUIRED_FIELD}
                  </FormErrorMessage>
                </FormControl>
              )}
              {roleTypeId && roleLevelId && featuresOptions.length > 0 && (
                <FormControl isInvalid={!!errors.permissions}>
                  <Label
                    title="Permissões"
                    fontSize="md"
                    isRequired
                    helper="Seleccione as permissões que pretende atribuir ao perfil"
                    isDisabled={isDisabled}
                  />
                  <PickList
                    id={id}
                    setValue={setValue}
                    watch={watch}
                    featuresOptions={featuresOptions}
                    selectedPermissions={selectedPermissions}
                    setSelectedPermissions={setSelectedPermissions}
                    isDisabled={isDisabled}
                    roleData={roleData}
                    rolePermissions={rolePermissions}
                    isCancel={isCancel}
                    setIsCancel={setIsCancel}
                  />
                  <FormErrorMessage>
                    {errors.permissions && REQUIRED_FIELD}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Stack>
          </Box>
          <FormButtons
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
            id={id}
            createRole={createRole}
            cancelEdit={cancelEdit}
            deleteRole={deleteRole}
          />
        </Box>
      </Box>
    </Stack>
  );
}

export default Form;
