import { Box, Flex, Heading, Stack } from "@chakra-ui/react";
import TableSchools from "./TableSchools";

function TabSchools() {
  const data = [
    {
      id: 1,
      name: "Escolas 1",
    },
    {
      id: 2,
      name: "Escolas 2",
    },
  ];
  return (
    <Stack spacing={5}>
      <Box>
        <Heading as="h2" size="md" textTransform="uppercase" mb="10px">
          Filtrar
        </Heading>
      </Box>

      <Box>
        <Flex justifyContent="space-between" alignItems="end" mb="10px">
          <Heading as="h2" size="md" textTransform="uppercase">
            Lista de indicadores
          </Heading>
        </Flex>
        <TableSchools data={data} />
      </Box>
    </Stack>
  );
}

export default TabSchools;
