import {
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Stack,
  Radio,
  RadioGroup,
  Box,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import Label from "../../../components/Label";
import { getAcademicYearFormat } from "../../../services/getAcademicYearFormat";
import { REQUIRED_FIELD } from "../../../utils/constants";
import TableSchool from "../components/TableSchool";
import FilterArea from "../../../components/FilterArea";
import { formStyles } from "../../../utils/styles";
export default function FormTabInfoResume({
  errors,
  control,
  watch,
  year,
  yearFormat,
  setValue,
  isCreating = true,
}) {
  const dataSelect = [
    {
      fieldObjectName: "academicQualification",
      fieldName: "Estado",
      data: [
        {
          label: "Encerrado",
          value: 1,
        },
        {
          label: "Por encerrar",
          value: 2,
        },
      ],
    },
  ];
  // const yearFormat = watch("yearFormat")
  const [dataTableSchool, setDataTableSchool] = useState([]);
  const [academicYearFormatData, setAcademicYearFormatData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const response = await getAcademicYearFormat();
      setAcademicYearFormatData(response);
    };
    getData();
  }, []);
  const handleLabelInAcademicYear = (year) => {
    const label = year.includes("/")
      ? "4 + 4 dígitos - xxxx/xxxx"
      : "4 dígitos - xxxx";
    return label;
  };
  return (
    <>
      <Stack spacing={5} mb={5} sx={formStyles}>
        <FormControl isInvalid={!!errors.name} width="70%">
          <Label title="Selecione o Formato do Ano Lectivo" isRequired={true} />
          <Controller
            control={control}
            name="yearFormat"
            render={({ field: { onChange, value } }) => (
              <RadioGroup
                isDisabled={true}
                value={Number(value)}
                onChange={onChange}
              >
                <Stack direction="column">
                  {academicYearFormatData.map((e) => {
                    return (
                      <Radio value={e.value}>
                        {handleLabelInAcademicYear(e.code)}
                      </Radio>
                    );
                  })}
                </Stack>
              </RadioGroup>
            )}
          />
          <FormErrorMessage>
            {errors.yearFormat && <>{REQUIRED_FIELD}</>}
          </FormErrorMessage>
        </FormControl>

        <HStack width="29%">
          <FormControl isInvalid={!!errors.educationLevel}>
            <Label title="Ano Lectivo" isRequired={true} />
            <Input
              value={year}
              isDisabled
              placeholder="____/____"
              type="text"
            />
            <FormErrorMessage>
              {errors.year && <>{REQUIRED_FIELD}</>}
            </FormErrorMessage>
          </FormControl>
        </HStack>

        <Box>
          <FilterArea
            sizeH="30%"
            control={control}
            handleCleanFilters={() => {}}
            handleSearch={() => {}}
            dataSelect={dataSelect}
            title={"Filtrar Escola Por Estado"}

            // size={"100%"}
          />
        </Box>
      </Stack>
      {isCreating && (
        <>
          <Box mt={5}>
            <TableSchool
              dataTable={dataTableSchool}
              setDataTable={setDataTableSchool}
            />
          </Box>
        </>
      )}
    </>
  );
}
