/* eslint-disable no-unused-vars */
import Table from "../../components/Table";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Center, Heading, Spinner, Text } from "@chakra-ui/react";

import { NO_RESULTS } from "../../utils/constants";
import { EditIcon } from "@chakra-ui/icons";
import { formStyles } from "../../utils/styles";

export default function TableConfig({ setDataTable, dataTable }) {
  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "name",
      },
      {
        Header: "Editar",
        accessor: "edit",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <>
              <Center>
                <Link
                  to={`/configuracoes/items/${item.data[item.row.index].id}`}
                >
                  <EditIcon />
                </Link>
              </Center>
            </>
          );
        },
      },
    ],
    []
  );

  const [selectedIds, setSelectedIds] = useState([]);

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  return (
    <>
      <Heading as="h2" size="md" textTransform="uppercase" mb="10px">
        Lista de tabelas auxiliares
      </Heading>

      <>
        {dataTable ? (
          dataTable.length > 0 ? (
            <Table columns={columns} data={dataTable} isPaginated={false} />
          ) : (
            <Box width="100%" display="flex" sx={formStyles}>
              <Text>{NO_RESULTS}</Text>
            </Box>
          )
        ) : (
          <Spinner />
        )}
      </>
    </>
  );
}
