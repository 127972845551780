import { Stack } from "@chakra-ui/layout";
import { useState } from "react";
import TableCloseAcademicYear from "./TableCloseAcademicYear";

export default function CloseAcademicYear() {
  const [dataTable, setDataTable] = useState([]);

  return (
    <Stack spacing={5}>
      <TableCloseAcademicYear
        setDataTable={setDataTable}
        dataTable={dataTable}
      />
    </Stack>
  );
}
