import { useContext, useEffect, useState } from "react";
import { Box, Button, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import TableUsers from "./TableUsers";
import { MdAddCircle, MdArrowOutward, MdDownload } from "react-icons/md";
import api from "../../utils/api";
import { DOWNLOAD_USERS_URL, USER_URL } from "../../utils/endpoints";
import Search from "./components/Search";
import { PAGE_SIZE, USER_SUMMARY } from "../../utils/constants";
import { formStyles } from "../../utils/styles";
import Filters from "./components/Filters";
import { useForm } from "react-hook-form";
import { downloadExcelFile } from "../../utils/downloadExcelFile";
import { getUsers } from "../../services/getUsers";
import { AuthContext } from "../../provider/AuthProvider";

function Users() {
  const [users, setUsers] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [total, setTotal] = useState();
  const [isFromPagination, setIsFromPagination] = useState(false);
  const { auth } = useContext(AuthContext);
  const user = auth.user;

  useEffect(
    () => {
      const getData = async () => {
        const response = await getUsers("", auth);
        setUsers(response);
        setIsLoaded(true);
      };
      getData();
    },
    // eslint-disable-next-line
    []
  );

  const { register, handleSubmit, control, watch, setValue, getValues } =
    useForm();

  const getApiUrl = (url) => {
    const data = getValues();

    let apiUrl = `${url}?`;

    if (data.name) {
      apiUrl += `&NameOrEmail=${data.name}`;
    }

    if (data.role?.value) {
      apiUrl += `&RoleId=${data.role?.value}`;
    }
    if (data.province?.value) {
      apiUrl += `&ProvinceId=${data.province?.value}`;
    }
    if (data.county?.value) {
      apiUrl += `&CountyId=${data.county?.value}`;
    }
    if (data.school?.value) {
      apiUrl += `&SchoolId=${data.school?.value}`;
    }
    if (data.state?.value !== undefined) {
      apiUrl += `&IsActive=${data.state?.value}`;
    }

    apiUrl += `&PageSize=${PAGE_SIZE}`;

    return apiUrl;
  };

  const onSubmit = () => {
    const apiUrl = getApiUrl(USER_URL);
    setIsLoaded(false);
    api.get(apiUrl).then((response) => {
      const result = response.data.result;
      setUsers(result);
      setTotal(response.data.totalItems);
      setIsFromPagination(false);
    });
    setIsLoaded(true);
  };

  const download = () => {
    const apiUrl = getApiUrl(DOWNLOAD_USERS_URL);
    setIsDownloading(true);
    downloadExcelFile(apiUrl, "Utilizadores.xlsx");
    setIsDownloading(false);
  };

  return (
    <Stack spacing={5}>
      <Box>
        <Heading as="h1" mb="10px">
          Utilizadores
        </Heading>

        <Box w="lg">
          <Text>{USER_SUMMARY}</Text>
        </Box>
      </Box>

      {user.permissions.includes("CreateUser") && (
        <Box>
          <Link to="/utilizadores/cadastrar">
            <Button rightIcon={<MdAddCircle />}>Cadastrar</Button>
          </Link>
        </Box>
      )}

      <Box>
        <Heading as="h2" size="md" textTransform="uppercase" mb="10px">
          Pesquisar
        </Heading>
        <Box
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={formStyles}
          px="0!important"
        >
          <Search register={register} setValue={setValue} onSubmit={onSubmit} />
          <Filters
            watch={watch}
            control={control}
            setValue={setValue}
            onSubmit={onSubmit}
            isSchool={false}
          />
        </Box>
      </Box>

      <Box>
        <Flex justifyContent="space-between" alignItems="end" mb="10px">
          <Heading as="h2" size="md" textTransform="uppercase">
            Lista de utilizadores
          </Heading>
          <Button
            variant="secondary"
            rightIcon={<MdDownload />}
            onClick={download}
            isLoading={isDownloading}
          >
            Descarregar lista
          </Button>
        </Flex>

        <TableUsers
          data={users}
          isLoaded={isLoaded}
          setData={setUsers}
          getFunction={getUsers}
          setIsLoaded={setIsLoaded}
          apiUrl={getApiUrl(USER_URL)}
          total={total}
          isFromPagination={isFromPagination}
          setIsFromPagination={setIsFromPagination}
        />

        <Flex justifyContent="end" mt={3}>
          <Link to="/docs/manual-administracao-sietp.pdf" target="_blank">
            <Button variant="secondary" rightIcon={<MdArrowOutward />}>
              Consultar Manual de Apoio
            </Button>
          </Link>
        </Flex>
      </Box>
    </Stack>
  );
}

export default Users;
