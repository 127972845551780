import DatePicker from "react-datepicker";
import "./styles.css";

import pt from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";

const CustomDatePicker = ({
  selected,
  onChange,
  placeholder,
  disabled,
  minDate,
}) => {
  const parsedDate = selected ? new Date(selected) : null;
  const parsedMinDate = minDate ? new Date(minDate) : null;

  const currentYear = new Date().getFullYear();

  const years = [];
  for (let year = 1940; year <= currentYear; year++) {
    years.push(year);
  }

  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  return (
    <DatePicker
      selected={parsedDate}
      onChange={onChange}
      placeholderText={placeholder}
      disabled={disabled}
      dateFormat="dd/MM/yyyy"
      className="custom-datepicker"
      showPopperArrow={false}
      minDate={parsedMinDate}
      locale={pt}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: "5px 10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: 5,
            }}
          >
            <select
              value={months[date.getMonth()]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              value={date.getFullYear()}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div
            style={{
              display: "flex",
              gap: 10,
            }}
          >
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              {"<"}
            </button>
            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {">"}
            </button>
          </div>
        </div>
      )}
    />
  );
};

export default CustomDatePicker;
