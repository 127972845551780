import api from "../utils/api";
import { PAGE_SIZE } from "../utils/constants";
import { STUDENT_URL } from "../utils/endpoints";

export async function getStudent(id = false, schoolId = false) {
  try {
    const response = await api.get(
      !id
        ? schoolId
          ? `${STUDENT_URL}?SchoolId=${schoolId}&PageSize=${PAGE_SIZE}`
          : `${STUDENT_URL}?PageSize=${PAGE_SIZE}`
        : `${STUDENT_URL}?${id}&PageSize=${PAGE_SIZE}`
    );

    const data = response.data.result.map((option) => ({
      id: option.id,
      typeDocument: option.typeDocument,
      documentNumber: option.documentNumber,
      name: option.name,
      educationSponsorName: option.educationSponsorName,
      educationSponsorPhone: option.educationSponsorPhone,
      gender: option.gender.name,
      nationality: option.nationality,
      birthDate: option.birthDate,
      specialEducationNeedType: option.specialEducationNeedType,
      specialEducationNeed: option.specialEducationNeed.name,
      isActive: option.isActive,
      isValidated: option.isValidated,
      totalItems: response.data.totalItems,
    }));
    return data;
  } catch (error) {
    throw error;
  }
}
