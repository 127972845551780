import { z } from "zod";
import { REQUIRED_FIELD } from "../../utils/constants";

export const courseSchema = z.object({
  name: z.string().nonempty(REQUIRED_FIELD),

  className: z.string().nonempty(REQUIRED_FIELD).max(5, {message: 'Máximo 5 caracteres'}),
  
  educationLevel: z.object({
    value:  z.number(),
    label: z.string(),
  }),

  educationSubsystem: z.object({
    value: z.number(),
    label: z.string(),
  }),

  cicleEducation: z.object({
    value: z.number(),
    label: z.string(),
  }),

  generalArea: z.object({
    value: z.number().optional(),
    label: z.string().optional(),
  }).optional(),

  specificArea: z.object({
    value: z.number().optional(),
    label: z.string().optional(),
  }).optional(),

  detailedArea: z
  .object({
    value: z.number().optional(),
    label: z.string().optional(),
  }).optional(),

  technicalEducation: z
    .object({
      value: z.number(),
      label: z.string(),
    }),

  domainPNFQ: z
    .object({
      value: z.number().optional(),
      label: z.string().optional(),
    }).optional(),
});
