import { Switch } from "@chakra-ui/react";
import api from "../../../utils/api";
import {
  COMMUNE_URL,
  ENABLE_DISABLE_LOOKUP_ITEM,
  GENERAL_AREA_URL,
  SPECIFIC_AREA_URL,
  URBANDISTRICT_URL,
} from "../../../utils/endpoints";
import { Toast } from "../../../components/Toast";
import { lookUps } from "..";
import { useParams } from "react-router-dom";

export default function ChangeStatus({
  item,
  entityType,
  setDataTable,
  entityName,
  setData,
}) {
  const { addToast } = Toast();
  const { table } = useParams();
  const handleChange = async (id, status) => {
    try {
      await api.post(ENABLE_DISABLE_LOOKUP_ITEM, {
        entityType: entityType,
        id,
        enabled: !status,
      });
      if (entityName) {
        if (entityName === "UrbanDistrict") {
          const response = await api.get(
            `${URBANDISTRICT_URL}?OnlyActive=false&PageSize=2500`
          );
          setData(
            response.data
              .slice()
              .sort((a, b) =>
                a.fromCounty.name.localeCompare(b.fromCounty.name)
              )
              .sort((a, b) =>
                a.fromCounty.fromProvince.name.localeCompare(
                  b.fromCounty.fromProvince.name
                )
              )
          );
        } else if (entityName === "Commune") {
          const response = await api.get(
            `${COMMUNE_URL}?OnlyActive=false&PageSize=2500`
          );
          setData(
            response.data
              .slice()
              .sort((a, b) =>
                a.fromCounty.name.localeCompare(b.fromCounty.name)
              )
              .sort((a, b) =>
                a.fromCounty.fromProvince.name.localeCompare(
                  b.fromCounty.fromProvince.name
                )
              )
          );
        } else if (entityName === "CourseGeneralArea") {
          const response = await api.get(GENERAL_AREA_URL, {
            OnlyActive: false,
            PageSize: 2500,
          });
          setData(response.data);
        } else if (entityName === "CourseSpecificArea") {
          const response = await api.get(SPECIFIC_AREA_URL, {
            OnlyActive: false,
            PageSize: 2500,
          });
          setData(response.data);
        }
      } else {
        const response = await lookUps[table - 1].getFunction({
          OnlyActive: false,
          PageSize: 2500,
        });
        setDataTable(response);
      }
      addToast({
        title: "Estado do item alterado com sucesso",
        status: "success",
      });
    } catch (e) {
      addToast({
        title: "Ocorreu um erro ao alterar o estado do item",
        status: "error",
      });
    }
  };
  return (
    <Switch
      isChecked={item.isActive}
      onChange={() => handleChange(item.id, item.isActive)}
      size="sm"
    />
  );
}
