import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import Label from "../../../components/Label";
import { selectStyles } from "../../../utils/styles";
import { NO_OPTIONS_MESSAGE, REQUIRED_FIELD } from "../../../utils/constants";
import Select from "react-select";
import { useContext, useEffect, useState } from "react";
import { Toast } from "../../../components/Toast";
import {
  ACADEMIC_YEAR_URL,
  ADMINISTRATIVE_POSITION_URL,
  CONTRACTUAL_BOND_TYPE_URL,
  PROBATIONARY_PERIOD_URL,
  SCHOOL_URL,
  TEACHING_FUNCTION_URL,
} from "../../../utils/endpoints";
import api from "../../../utils/api";
import { AuthContext } from "../../../provider/AuthProvider";

function EnrollmentModal({
  contractualBondRequests,
  setContractualBondRequests,
  contractualBondRequestsData,
  setContractualBondRequestsData,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [academicYear, setAcademicYear] = useState();
  const [school, setSchool] = useState();
  const [contractualBondType, setContractualBondType] = useState();
  const [probationaryPeriod, setProbationaryPeriod] = useState();
  const [teachingFunction, setTeachingFunction] = useState();
  const [administrativePosition, setAdministrativePosition] = useState();
  const [academicYearOptions, setAcademicYearOptions] = useState([]);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [contractualBondTypeOptions, setContractualBondTypeOptions] = useState(
    []
  );
  const [probationaryPeriodOptions, setProbationaryPeriodOptions] = useState(
    []
  );
  const [teachingFunctionOptions, setTeachingFunctionOptions] = useState([]);
  const [administrativePositionOptions, setAdministrativePositionOptions] =
    useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const { addToast } = Toast();
  const { auth } = useContext(AuthContext);
  const user = auth.user;

  useEffect(() => {
    if (isOpen) {
      setAcademicYear("");
      if (!user?.schoolId) {
        setSchool("");
      }
      setContractualBondType("");
      setProbationaryPeriod("");
      setTeachingFunction("");
      setAdministrativePosition([]);
    }
  }, [isOpen]);

  async function getAcademicYears() {
    api
      .get(ACADEMIC_YEAR_URL)
      .then((res) => {
        const data = res.data.result;
        setAcademicYearOptions(
          data
            .filter((i) => i.isActive)
            .map((i) => ({
              value: i.id,
              label: i.year,
              isBaseYear: i.isBaseYear,
            }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getSchools() {
    api
      .get(`${SCHOOL_URL}?isValidated=true&isActive=true`)
      .then((res) => {
        const data = res.data.result;
        setSchoolOptions(
          data.map((i) => ({
            value: i.id,
            label: i.name,
          }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getContractualBondTypes() {
    api
      .get(`${CONTRACTUAL_BOND_TYPE_URL}?OnlyActive=true`)
      .then((res) => {
        const data = res.data;
        setContractualBondTypeOptions(
          data.map((i) => ({
            value: i.id,
            label: i.name,
          }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getProbationaryPeriods() {
    api
      .get(`${PROBATIONARY_PERIOD_URL}?OnlyActive=true`)
      .then((res) => {
        const data = res.data;
        setProbationaryPeriodOptions(
          data.map((i) => ({
            value: i.id,
            label: i.name,
          }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getTeachingFunctions() {
    api
      .get(`${TEACHING_FUNCTION_URL}?OnlyActive=true`)
      .then((res) => {
        const data = res.data;
        setTeachingFunctionOptions(
          data.map((i) => ({
            value: i.id,
            label: i.name,
          }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getAdministrativePositions() {
    api
      .get(`${ADMINISTRATIVE_POSITION_URL}?OnlyActive=true`)
      .then((res) => {
        const data = res.data;
        setAdministrativePositionOptions(
          data.map((i) => ({
            value: i.id,
            label: i.name,
          }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  const onChangeAcademicYear = (value) => {
    setAcademicYear(value);
  };

  const onChangeSchool = (value) => {
    setSchool(value);
  };

  const onChangeContractualBondType = (value) => {
    setContractualBondType(value);
  };

  const onChangeProbationaryPeriod = (value) => {
    setProbationaryPeriod(value);
  };

  const onChangeTeachingFunction = (value) => {
    setTeachingFunction(value);
  };

  const onChangeAdministrativePosition = (value) => {
    setAdministrativePosition(value);
  };

  useEffect(() => {
    getAcademicYears();
    getSchools();
    getContractualBondTypes();
    getTeachingFunctions();
    getProbationaryPeriods();
    getAdministrativePositions();
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (user?.schoolId && schoolOptions.length > 0) {
      setSchool(
        schoolOptions.filter((item) => item.value === user?.schoolId)[0]
      );
    }
  }, [user, schoolOptions]);

  const handleSchoolBondRequests = () => {
    if (
      !academicYear?.value ||
      !school?.value ||
      !contractualBondType?.value ||
      !probationaryPeriod ||
      !teachingFunction
    ) {
      addToast({ title: REQUIRED_FIELD, status: "error" });
    } else {
      const newContractualBondRequest = {
        academicYearId: academicYear?.value,
        schoolId: school?.value,
        contractualBondTypeId: contractualBondType?.value,
        probationaryPeriodId: probationaryPeriod,
        teachingFunctionId: teachingFunction,
        administrativePositionId: administrativePosition?.map(
          (item) => item.value
        ),
      };

      const newContractualBondRequestData = {
        academicYearBonded: academicYear,
        schoolBonded: school,
        contractualBondTypeBonded: contractualBondType,
        probationaryPeriodBonded: probationaryPeriod,
        teachingFunctionBonded: teachingFunction,
        administrativePositionBonded: administrativePosition?.map(
          (item) => item
        ),
      };

      setContractualBondRequests((prevState) => [
        ...prevState,
        newContractualBondRequest,
      ]);
      setContractualBondRequestsData((prevState) => [
        ...prevState,
        newContractualBondRequestData,
      ]);

      onClose();
    }
  };

  return (
    <Box mb={3}>
      <Button variant="secondary" onClick={onOpen}>
        Associar Vínculo e Actividade Profissional
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Associar Vínculo e Actividade Profissional</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoaded && (
              <Stack gap={5}>
                <Box>
                  <Label title="Ano Lectivo" isRequired />
                  <Select
                    placeholder="Seleccionar"
                    options={academicYearOptions}
                    onChange={onChangeAcademicYear}
                    styles={selectStyles}
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                </Box>
                <Box>
                  <Label title="Escola" isRequired />
                  <Select
                    placeholder="Seleccionar"
                    options={schoolOptions}
                    onChange={onChangeSchool}
                    value={school || ""}
                    isDisabled={user?.schoolId}
                    styles={selectStyles}
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                </Box>
                <Box>
                  <Label title="Vínculo" isRequired />
                  <Select
                    placeholder="Seleccionar"
                    options={contractualBondTypeOptions}
                    onChange={onChangeContractualBondType}
                    value={contractualBondType || ""}
                    styles={selectStyles}
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                </Box>
                <Box>
                  <Label title="Período Probatório" isRequired />
                  <RadioGroup
                    value={probationaryPeriod}
                    onChange={onChangeProbationaryPeriod}
                  >
                    <Stack>
                      {probationaryPeriodOptions.map((type) => (
                        <Radio key={type.value} value={type.value?.toString()}>
                          {type.label}
                        </Radio>
                      ))}
                    </Stack>
                  </RadioGroup>
                </Box>
                <Box>
                  <Label title="Funções Lectivas" isRequired />
                  <RadioGroup
                    value={teachingFunction}
                    onChange={onChangeTeachingFunction}
                  >
                    <Stack>
                      {teachingFunctionOptions.map((type) => (
                        <Radio key={type.value} value={type.value?.toString()}>
                          {type.label}
                        </Radio>
                      ))}
                    </Stack>
                  </RadioGroup>
                </Box>
                <Box>
                  <Label title="Cargo não lectivo exercido" />
                  <Select
                    placeholder="Seleccionar"
                    options={administrativePositionOptions}
                    onChange={onChangeAdministrativePosition}
                    value={administrativePosition || ""}
                    styles={selectStyles}
                    isMulti
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                </Box>
              </Stack>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" onClick={onClose} mr={3}>
              Cancelar
            </Button>
            <Button onClick={handleSchoolBondRequests}>Associar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default EnrollmentModal;
