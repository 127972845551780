import { z } from "zod";
import { REQUIRED_FIELD } from "../../utils/constants";

export const ItemSchema = z.object({
  code: z.string().nonempty(REQUIRED_FIELD),
  name: z.string().nonempty(REQUIRED_FIELD),
});

export const ItemSchemForDetail = z.object({
  code_general: z.string().optional(),
  name_general: z.string().optional(),

  area_general: z
    .object({
      value: z.number(),
      label: z.string(),
    })
    .optional(),

  code_specific: z.string().optional(),
  name_specific: z.string().optional(),

  area_specific: z
    .object({
      value: z.number(),
      label: z.string(),
    })
    .optional(),

  code_detailed: z.string().optional(),
  name_detailed: z.string().optional(),
});

export const ItemSchemForProvince = z.object({
  code_province: z.string().optional(),
  name_province: z.string().optional(),

  province: z
    .object({
      value: z.number(),
      label: z.string(),
    })
    .optional(),

  code_county: z.string().optional(),
  name_county: z.string().optional(),

  county: z
    .object({
      value: z.number(),
      label: z.string(),
    })
    .optional(),

  code_commune: z.string().optional(),
  name_commune: z.string().optional(),

  code_urban: z.string().optional(),
  name_urban: z.string().optional(),
});
