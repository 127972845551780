import { useContext, useMemo } from "react";
import Table from "../../components/Table";
import { Link } from "react-router-dom";
import { EditIcon } from "@chakra-ui/icons";
import { AuthContext } from "../../provider/AuthProvider";
import { Center } from "@chakra-ui/react";

function TableRoles({ data, isLoaded }) {
  const { auth } = useContext(AuthContext);
  const user = auth.user;

  let columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "name",
      },
      {
        Header: "Este perfil pode",
        accessor: "roleType.name",
      },
      {
        Header: "Editar",
        accessor: "edit",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <Link to={`/perfis/${item.row.original.id}`}>
              <Center>
                <EditIcon />
              </Center>
            </Link>
          );
        },
      },
    ],
    []
  );

  if (!user.permissions.includes("GetRole")) {
    columns = columns.filter((column) => column.accessor !== "edit");
  }

  return (
    <>
      {data && (
        <Table
          columns={columns}
          data={data}
          isLoaded={isLoaded}
          isPaginated={false}
        />
      )}
    </>
  );
}

export default TableRoles;
