import { useContext, useEffect, useState } from "react";
import Form from "./Form";
import { useForm } from "react-hook-form";
import { schema } from "./Form/schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate, useParams } from "react-router-dom";
import { getRoleTypes } from "../../services/getRoleTypes";
import { Toast } from "../../components/Toast";
import api from "../../utils/api";
import { EDIT_ROLE_URL, GET_ROLE_URL, ROLE_URL } from "../../utils/endpoints";
import { CrumbContext } from "../../provider/CrumbProvider";

function EditRole() {
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [roleData, setRoleData] = useState([]);
  const [roleTypesOptions, setRoleTypesOptions] = useState([]);
  const [rolePermissions, setRolePermissions] = useState([]);
  const [isCancel, setIsCancel] = useState(false);
  const navigate = useNavigate();
  const { addToast } = Toast();
  const { id } = useParams();
  const { crumb, setCrumb } = useContext(CrumbContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
  } = useForm({
    resolver: zodResolver(schema),
  });

  async function getRoleTypesOptions() {
    getRoleTypes()
      .then((res) => {
        setRoleTypesOptions(res);
        setIsLoaded(true);
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  useEffect(
    () => {
      getRoleTypesOptions();
    },
    // eslint-disable-next-line
    []
  );

  const populateRoleData = (data) => {
    setValue("name", data.name);
    setValue("roleTypeId", data.roleType.id.toString());
    setValue("roleLevelId", data.level.id.toString());
    setValue("permissions", data.permissions);
  };

  useEffect(
    () => {
      if (id && roleTypesOptions.length > 0) {
        api.get(`${GET_ROLE_URL}?RoleId=${id}`).then((response) => {
          const data = response.data;
          setRoleData(data);
          populateRoleData(data);
          setRolePermissions(data.permissions);
          setIsLoaded(true);
          setCrumb({
            ...crumb,
            role: data.name,
          });
        });
      }
    },
    // eslint-disable-next-line
    [id, roleTypesOptions]
  );

  const editRole = () => {
    const data = getValues();
    api
      .patch(EDIT_ROLE_URL, {
        id: id,
        name: data.name,
        roleTypeId: data.roleTypeId,
        roleLevelId: data.roleLevelId,
        permissions: data.permissions,
      })
      .then(() => {
        addToast({
          title: "Perfil actualizado com sucesso",
          status: "success",
        });
        navigate("/perfis");
      })
      .catch((error) => {
        let errors = error.response.data.errors;
        for (let err in errors) {
          addToast({ title: errors[err], status: "error" });
        }
      });
  };

  const cancelEdit = () => {
    populateRoleData(roleData);
    setIsDisabled(true);
    setIsCancel(true);
  };

  const deleteRole = () => {
    api
      .delete(`${ROLE_URL}/${id}`)
      .then(() => {
        addToast({
          title: "Perfil excluído com sucesso",
          status: "success",
        });
        navigate("/perfis");
      })
      .catch((error) => {
        let errors = error.response.data.errors;
        for (let err in errors) {
          addToast({ title: errors[err], status: "error" });
        }
      });
  };

  return (
    <>
      {isLoaded && (
        <Form
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
          id={id}
          register={register}
          handleSubmit={handleSubmit}
          errors={errors}
          control={control}
          watch={watch}
          setValue={setValue}
          roleTypesOptions={roleTypesOptions}
          roleData={roleData}
          editRole={editRole}
          cancelEdit={cancelEdit}
          deleteRole={deleteRole}
          rolePermissions={rolePermissions}
          isCancel={isCancel}
          setIsCancel={setIsCancel}
        />
      )}
    </>
  );
}

export default EditRole;
