import api from "../utils/api";
import { USER_URL } from "../utils/endpoints";
import { PAGE_SIZE } from "../utils/constants";

export async function getUsers(id = null, auth = null) {
  try {
    const apiURL = `${USER_URL}?PageSize=${PAGE_SIZE}`;

    const response = await api.get(apiURL);
    const data = response.data.result;
    if (data.length > 0) {
      data[0] = {
        ...data[0],
        totalItems: response.data.totalItems,
      };
    }
    return data;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
}
