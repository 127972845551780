import { MdAddCircle } from "react-icons/md";
import { Link } from "react-router-dom";
import { Button, HStack } from "@chakra-ui/react";
export default function ButtonGroupCourse() {
  return (
    <HStack>
      <Link to="/anos-lectivos/cadastrar">
        <Button rightIcon={<MdAddCircle />}>Abrir novo Ano Lectivo</Button>
      </Link>
    </HStack>
  );
}
