import { createContext, useState } from "react";

const CrumbContext = createContext();

const CrumbProvider = ({ children }) => {

  const [crumb, setCrumb] = useState({})

  return (
    <CrumbContext.Provider value={{ crumb, setCrumb }}>
      {children}
    </CrumbContext.Provider>
  );
};

export { CrumbProvider, CrumbContext };
