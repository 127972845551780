import { FormSchool } from "./Forms";
import { useState } from "react";

export default function RegisterSchool() {
  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <>
      <FormSchool isDisabled={isDisabled} setIsDisabled={setIsDisabled} id="" />
    </>
  );
}
