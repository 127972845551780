export default async function handleFilterOrSearch(
  paramsObj,
  addToast,
  getFunction,
  clean = true
) {
  let objectToUrlParams = {};

  for (const key in paramsObj) {
    if (paramsObj.hasOwnProperty(key) && paramsObj[key] !== undefined) {
      if (key === "IsValidated" && paramsObj[key] === "pending") {
        objectToUrlParams = {
          ...objectToUrlParams,
          OnlyPending: true,
        };
      } else {
        objectToUrlParams = {
          ...objectToUrlParams,
          [key]: paramsObj[key],
        };
      }
    }
  }

  let params = new URLSearchParams(objectToUrlParams).toString();

  try {
    const response = await getFunction(params);
    return response;
  } catch (e) {
    console.log(e);
  }
}
