import { useContext, useEffect, useState } from "react";
import Form from "./Form";
import { useForm } from "react-hook-form";
import { schema } from "./Form/schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate, useParams } from "react-router-dom";
import { getRole } from "../../services/getRole";
import { Toast } from "../../components/Toast";
import api from "../../utils/api";
import {
  DISABLE_USER_URL,
  EDIT_USER_URL,
  ENABLE_USER_URL,
  RESET_PASSWORD,
  USER_URL,
} from "../../utils/endpoints";
import { CrumbContext } from "../../provider/CrumbProvider";

function EditUser() {
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [userData, setUserData] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const navigate = useNavigate();
  const { addToast } = Toast();
  const { id } = useParams();
  const { crumb, setCrumb } = useContext(CrumbContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
    unregister,
  } = useForm({
    resolver: zodResolver(schema),
  });

  async function getRoleOptions() {
    getRole()
      .then((res) => setRoleOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  useEffect(
    () => {
      getRoleOptions();
    },
    // eslint-disable-next-line
    []
  );

  const populateUserData = (data) => {
    setValue("status", data.isActive);
    setValue("name", data.name);
    setValue("email", data.email);
    setValue(
      "role",
      roleOptions.filter((item) =>
        data.roles.some((role) => role.id === item.value)
      )
    );
    if (data.userProvince) {
      setValue("province", {
        value: data.userProvince.id,
        label: data.userProvince.name,
      });
    }
    if (data.userCounty) {
      setValue("county", {
        value: data.userCounty.id,
        label: data.userCounty.name,
      });
    }
    if (data.userSchool) {
      setValue("school", {
        value: data.userSchool.id,
        label: data.userSchool.name,
      });
    }
  };

  useEffect(
    () => {
      if (id && roleOptions.length > 0) {
        api.get(`${USER_URL}/${id}`).then((response) => {
          const data = response.data;
          setUserData(data);
          populateUserData(data);
          setIsLoaded(true);
          setCrumb({
            ...crumb,
            user: data.name,
          });
        });
      }
    },
    // eslint-disable-next-line
    [id, roleOptions]
  );

  const editUser = () => {
    const data = getValues();
    api
      .patch(EDIT_USER_URL, {
        id: id,
        name: data.name,
        email: data.email,
        roles: data.role.map((role) => role.value),
        provinceId: data.province ? data.province.value : null,
        countyId: data.county ? data.county.value : null,
        schoolId: data.school ? data.school.value : null,
      })
      .then(() => {
        if (data.status) {
          api.patch(ENABLE_USER_URL, { id: id });
        } else {
          api.patch(DISABLE_USER_URL, { id: id });
        }
        addToast({
          title: "Utilizador actualizado com sucesso",
          status: "success",
        });
        navigate("/utilizadores");
      })
      .catch((error) => {
        let errors = error.response.data.errors;
        for (let err in errors) {
          addToast({ title: errors[err], status: "error" });
        }
      });
  };

  const cancelEdit = () => {
    populateUserData(userData);
    setIsDisabled(true);
  };

  const deleteUser = () => {
    api
      .delete(`${USER_URL}/${id}`)
      .then(() => {
        addToast({
          title: "Utilizador excluído com sucesso",
          status: "success",
        });
        navigate("/utilizadores");
      })
      .catch((error) => {
        let errors = error.response.data.errors;
        for (let err in errors) {
          addToast({ title: errors[err], status: "error" });
        }
      });
  };

  const resetPassword = () => {
    const data = getValues();
    api
      .patch(RESET_PASSWORD, {
        email: data.email,
      })
      .then(() => {
        addToast({
          title: "Reposição de senha efectuada com sucesso",
          status: "success",
        });
        navigate("/utilizadores");
      })
      .catch((error) => {
        let errors = error.response.data.errors;
        for (let err in errors) {
          addToast({ title: errors[err], status: "error" });
        }
      });
  };

  return (
    <>
      {isLoaded && (
        <Form
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
          id={id}
          register={register}
          handleSubmit={handleSubmit}
          errors={errors}
          control={control}
          watch={watch}
          setValue={setValue}
          unregister={unregister}
          roleOptions={roleOptions}
          userData={userData}
          editUser={editUser}
          cancelEdit={cancelEdit}
          deleteUser={deleteUser}
          resetPassword={resetPassword}
        />
      )}
    </>
  );
}

export default EditUser;
