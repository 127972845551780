import { useEffect, useState } from "react";
import { Box, Button, Flex, Heading, Stack } from "@chakra-ui/react";
import { formStyles } from "../../utils/styles";
import { MdDownload } from "react-icons/md";
import { downloadExcelFile } from "../../utils/downloadExcelFile";
import {
  DOWNLOAD_AUDIT_ADMIN,
  GET_AUDIT_LIST_ADMIN,
} from "../../utils/endpoints";
import Search from "./components/Search";
import { useForm } from "react-hook-form";
import api from "../../utils/api";
import TableAudit from "./TableAudit";
import Filters from "./components/Filters";
import { getApiUrl } from "./getApiUrl";
import { PAGE_SIZE } from "../../utils/constants";

function Admin() {
  const [actions, setActions] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [total, setTotal] = useState();
  const [isFromPagination, setIsFromPagination] = useState(false);

  useEffect(
    () => {
      const getActions = async () => {
        const response = await api.get(
          `${GET_AUDIT_LIST_ADMIN}?PageSize=${PAGE_SIZE}`
        );
        setActions(response.data.result);
        setTotal(response.data.totalItems);
        setIsFromPagination(false);
        setIsLoaded(true);
      };
      getActions();
    },
    // eslint-disable-next-line
    []
  );

  const { register, handleSubmit, control, setValue, getValues } = useForm();
  const onSubmit = () => {
    const apiUrl = getApiUrl(GET_AUDIT_LIST_ADMIN, getValues());
    setIsLoaded(false);
    api.get(apiUrl).then((response) => {
      const result = response.data.result;
      setActions(result);
    });
    setIsLoaded(true);
  };

  const download = () => {
    const apiUrl = getApiUrl(DOWNLOAD_AUDIT_ADMIN, getValues());
    setIsDownloading(true);
    downloadExcelFile(apiUrl, "AccoesAdministracao.xlsx");
    setIsDownloading(false);
  };

  return (
    <Stack spacing={5}>
      <Box>
        <Heading as="h2" size="md" textTransform="uppercase" mb="10px">
          Pesquisar
        </Heading>
        <Box
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={formStyles}
          px="0!important"
        >
          <Search register={register} setValue={setValue} onSubmit={onSubmit} />
          <Filters
            control={control}
            setValue={setValue}
            onSubmit={onSubmit}
            isForAdmin="true"
          />
        </Box>
      </Box>

      <Box>
        <Flex justifyContent="space-between" alignItems="end" mb="10px">
          <Heading as="h2" size="md" textTransform="uppercase">
            Lista de acções
          </Heading>
          <Button
            variant="secondary"
            rightIcon={<MdDownload />}
            onClick={download}
            isLoading={isDownloading}
          >
            Descarregar lista
          </Button>
        </Flex>

        <TableAudit
          data={actions}
          isLoaded={isLoaded}
          setData={setActions}
          setIsLoaded={setIsLoaded}
          entity={"admin"}
          apiUrl={getApiUrl(GET_AUDIT_LIST_ADMIN, getValues())}
          total={total}
          isFromPagination={isFromPagination}
          setIsFromPagination={setIsFromPagination}
        />
      </Box>
    </Stack>
  );
}

export default Admin;
