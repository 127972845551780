import { useContext, useEffect, useState } from "react";
import { Heading, Stack, Text, Box } from "@chakra-ui/react";
import { useForm } from "react-hook-form";

import { AxiosError } from "axios";
import { Toast } from "../../../../components/Toast";
import { getSchool } from "../../../../services/getSchool";
import { getAcademicYear } from "../../../../services/getAcademicYear";
import { getClass } from "../../../../services/getClass";
import handleFilterOrSearch from "../../../../helpers/handleFilterOrSearch";
import { getStudent } from "../../../../services/getStudent";
import { formStyles } from "../../../../utils/styles";
import SearchArea from "../../../../components/SearchArea";
import FilterArea from "../../../../components/FilterArea";
import TableStudent from "../../../Student/components/TableStudent";
import ModalConfirmStudent from "../../../Student/components/Modal";
import api from "../../../../utils/api";
import ButtonGroupStudent from "../../../Student/components/ButtonGroupStudent";

import { AuthContext } from "../../../../provider/AuthProvider";
import { STUDENT_SUMMARY } from "../../../../utils/constants";
import { getClassModality } from "../../../../services/getClassModality";
import { getClassShift } from "../../../../services/getClassShift";
import { getEnrollmentStatus } from "../../../../services/getEnrollmentStatus";
import { getBondResult } from "../../../../services/getBondResult";
import { useParams } from "react-router-dom";
import { COURSE_URL } from "../../../../utils/endpoints";

export default function ManagerStudent() {
  const { addToast } = Toast();
  const { register, control, watch, setValue } = useForm();

  const [academicYearData, setAcademicYearData] = useState([]);
  const [schoolData, setSchoolData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [classData, setClassData] = useState([]);
  const [classModalityData, setClassModalityData] = useState([]);
  const [classShiftData, setClassShiftData] = useState([]);
  const [classFullNameData, setClassFullNameData] = useState([]);
  const [studentEnrollmentStatusData, setStudentEnrollmentStatusData] =
    useState([]);
  const [schoolBondResult, setSchoolBondResult] = useState([]);
  const [classFullNameDisabled, setClassFullNameDisabled] = useState(true);

  const inputSearch = watch("search");
  const academicYear = watch("academicYear");
  const school = watch("school");
  const course = watch("course");
  const classId = watch("grade");
  const modality = watch("modality");
  const shift = watch("shift");
  const classFullName = watch("class");
  const enrollmentStatus = watch("enrollmentStatus");
  const bondResult = watch("schoolBondResult");
  const state = watch("state");
  const isActive = watch("isActive");

  const [isFilter, setIsFilter] = useState(false);

  const { auth } = useContext(AuthContext);
  const user = auth.user;
  const { id } = useParams();

  useEffect(() => {
    if (user?.schoolId || id) {
      setValue(
        "school",
        schoolData.filter(
          (item) => item.value === user?.schoolId || item.value == id
        )
      );
      api.get(`${COURSE_URL}?SchoolId=${user?.schoolId}`).then((res) => {
        const response = res.data.result;
        setCourseData(
          response.map((e) => ({
            label: e.name,
            value: e.id,
          }))
        );
      });
    }
  }, [schoolData]);

  const dataSelect = [
    {
      fieldObjectName: "academicYear",
      fieldName: "Ano Lectivo",
      data: academicYearData,
    },
    {
      fieldObjectName: "school",
      fieldName: "Escola",
      data: schoolData,
      isDisabled: true,
    },
    {
      fieldObjectName: "course",
      fieldName: "Curso",
      data: courseData,
    },
    {
      fieldObjectName: "grade",
      fieldName: "Classe",
      data: classData,
    },
    {
      fieldObjectName: "modality",
      fieldName: "Modalidade",
      data: classModalityData,
    },
    {
      fieldObjectName: "shift",
      fieldName: "Turno",
      data: classShiftData,
    },
    {
      fieldObjectName: "class",
      fieldName: "Turma",
      data: classFullNameData,
      isDisabled: classFullNameDisabled,
    },
    {
      fieldObjectName: "enrollmentStatus",
      fieldName: "Estado da matrícula",
      data: studentEnrollmentStatusData,
    },
    {
      fieldObjectName: "schoolBondResult",
      fieldName: "Resultado",
      data: schoolBondResult,
    },
    {
      fieldObjectName: "state",
      fieldName: "Estado",
      data: [
        { value: true, label: "Validado" },
        { value: "pending", label: "Pendente de Validação" },
        { value: false, label: "Não validado" },
      ],
    },
    {
      fieldObjectName: "isActive",
      fieldName: "Arquivado",
      data: [
        { value: false, label: "Sim" },
        { value: true, label: "Não" },
      ],
    },
  ];

  const getClassFullNameData = async (params) => {
    let qs = "";
    qs += params?.AcademicYearId
      ? `&AcademicYearId=${params.AcademicYearId}`
      : "";
    qs += params?.SchoolId ? `&SchoolId=${params.SchoolId}` : "";
    qs += params?.CourseId ? `&CourseId=${params.CourseId}` : "";
    qs += params?.ClassId ? `&ClassId=${params.ClassId}` : "";
    const response = await api.get(`/api/Lookup/ClassFullName?${qs}`);
    setClassFullNameData(
      response.data.result.map((item) => ({
        value: item.classFullName,
        label: item.classFullName,
      }))
    );
  };

  useEffect(() => {
    const getData = async () => {
      const responseAcademicYear = (await getAcademicYear()).map((e) => ({
        label: e.year,
        value: e.id,
      }));
      const responseSchool = await getSchool();
      const responseClass = await getClass();
      const responseClassModality = await getClassModality();
      const responseClassShift = await getClassShift();
      const responseStudentEnrollmentStatus = await getEnrollmentStatus();
      const responseSchoolBondResultd = await getBondResult();

      setAcademicYearData(responseAcademicYear);
      setSchoolData(responseSchool);
      setClassData(responseClass);
      setClassModalityData(responseClassModality);
      setClassShiftData(responseClassShift);
      setStudentEnrollmentStatusData(responseStudentEnrollmentStatus);
      setSchoolBondResult(responseSchoolBondResultd);
    };
    getData();
  }, []);

  useEffect(() => {
    if (academicYear && school && course && classId) {
      let params = {
        AcademicYearId: academicYear.value,
        SchoolId: school.value,
        CourseId: course.value,
        ClassId: classId.value,
      };
      getClassFullNameData(params);
      setClassFullNameDisabled(false);
      setValue("class", undefined);
    } else {
      setClassFullNameDisabled(true);
    }
  }, [academicYear, school, course, classId]);

  const paramsObj = {
    Pesquisa: inputSearch,
    AcademicYearBonded: academicYear?.value,
    SchoolId: user?.schoolId ? user?.schoolId : id,
    CourseId: course?.value,
    ClassId: classId?.value,
    ClassModalityBonded: modality?.value,
    ClassShiftBonded: shift?.value,
    ClassFullName: classFullName?.value,
    StudentEnrollmentStatusId: enrollmentStatus?.value,
    SchoolBondResultBonded: bondResult?.value,
    IsValidated: state?.value,
    IsActive: isActive?.value,
  };

  const [isOpen, setIsOpen] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [checkedBoxes, setCheckedBoxes] = useState([]);

  const handleSearch = async () => {
    setIsFilter(true);

    const data = await handleFilterOrSearch(paramsObj, addToast, getStudent);
    setDataTable(data);
  };

  const handleCleanSearch = async () => {
    setValue("search", undefined);
    let valParams = [];
    for (const key in paramsObj) {
      valParams.push(paramsObj[key]);
    }

    const response = valParams.includes(undefined)
      ? await handleFilterOrSearch(
          { ...paramsObj, Pesquisa: undefined },
          addToast,
          getStudent,
          false
        )
      : await getStudent(user && user?.schoolId);
    setDataTable(response);
  };

  const handleCleanFilters = async () => {
    setValue("academicYear", undefined);
    setValue("course", undefined);
    setValue("grade", undefined);
    setValue("modality", undefined);
    setValue("shift", undefined);
    setValue("class", undefined);
    setValue("enrollmentStatus", undefined);
    setValue("schoolBondResult", undefined);
    setValue("state", undefined);
    setValue("isActive", undefined);

    const response = await handleFilterOrSearch(
      {
        Pesquisa: undefined,
        SchoolId: user?.schoolId ? user?.schoolId : id,
      },
      addToast,
      getStudent,
      false
    );
    setDataTable(response);
  };

  const handleValidateOrInvalidate = async (url) => {
    try {
      const ids = checkedBoxes.map((e) => {
        return e.id;
      });
      const response = await api.patch(url, {
        ids,
      });
      if (response.status === 200) {
        const responseAfterUpdate = await getStudent();
        setDataTable(responseAfterUpdate);
        addToast({
          status: "success",
          title: `Aluno ${
            url.includes("invalidate") ? "invalidado" : "validado"
          } com sucesso`,
        });
      } else {
        throw new AxiosError("Ocorreu um erro desconhecido", 400);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Stack spacing={5}>
      <Box>
        <Heading>Alunos</Heading>
        <Text width="sm">{STUDENT_SUMMARY}</Text>
      </Box>
      {user.permissions.includes("CreateStudents") && (
        <ButtonGroupStudent isOpen={isOpen} setIsOpen={setIsOpen} />
      )}

      <Box>
        <Heading as="h2" size="md" textTransform="uppercase" mb="10px">
          Pesquisar
        </Heading>

        <Box as="form" sx={formStyles} px="0!important">
          <SearchArea
            register={register}
            handleCleanFilters={handleCleanSearch}
            handleSearch={handleSearch}
            title={"Pesquisar pelo código ou designação do Aluno"}
          />
          <FilterArea
            control={control}
            handleSearch={handleSearch}
            handleCleanFilters={handleCleanFilters}
            dataSelect={dataSelect}
            title="Filtrar Alunos"
            gridNumberSelect={6}
          />
        </Box>
      </Box>

      <Box>
        <TableStudent
          handleValidateOrInvalidate={handleValidateOrInvalidate}
          checkedBoxes={checkedBoxes}
          setCheckedBoxes={setCheckedBoxes}
          setDataTable={setDataTable}
          dataTable={dataTable}
          isSchool={true}
          isFilter={isFilter}
          className={classFullName}
          classId={classId}
        />
      </Box>

      <ModalConfirmStudent
        title="Matricular Aluno"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </Stack>
  );
}
