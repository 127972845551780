import { Box, Flex, Heading, Stack } from "@chakra-ui/react";
import TableStudents from "./TableStudents";

function TabStudents() {
  const data = [
    {
      id: 1,
      name: "Alunos 1",
    },
    {
      id: 2,
      name: "Alunos 2",
    },
    {
      id: 3,
      name: "Alunos 3",
    },
    {
      id: 4,
      name: "Alunos 4",
    },
    {
      id: 5,
      name: "Alunos 5",
    },
    {
      id: 6,
      name: "Alunos 6",
    },
  ];
  return (
    <Stack spacing={5}>
      <Box>
        <Heading as="h2" size="md" textTransform="uppercase" mb="10px">
          Filtrar
        </Heading>
      </Box>

      <Box>
        <Flex justifyContent="space-between" alignItems="end" mb="10px">
          <Heading as="h2" size="md" textTransform="uppercase">
            Lista de indicadores
          </Heading>
        </Flex>
        <TableStudents data={data} />
      </Box>
    </Stack>
  );
}

export default TabStudents;
