import { useState, useMemo, useEffect, useContext } from "react";
import {
  Button,
  Checkbox,
  HStack,
  Flex,
  Heading,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  ModalFooter,
  Stack,
  Text,
} from "@chakra-ui/react";
import { MdCheckCircle, MdCancel, MdArchive } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import Table from "../../../components/Table";
import {
  ACADEMIC_YEAR_URL,
  INVALIDATE_STUDENT,
  VALIDATE_STUDENT,
} from "../../../utils/endpoints";
import { getStudent } from "../../../services/getStudent";
import StateRender from "../../../components/StateRender";
import handleFilterOrSearch from "../../../helpers/handleFilterOrSearch";
import { Toast } from "../../../components/Toast";
import { AuthContext } from "../../../provider/AuthProvider";
import { DOWNLOAD_STUDENTS } from "../../../utils/endpoints";
import { downloadExcelFile } from "../../../utils/downloadExcelFile";
import {
  ARCHIVED,
  NO_OPTIONS_MESSAGE,
  PAGE_SIZE,
} from "../../../utils/constants";
import { EditIcon } from "@chakra-ui/icons";
import api from "../../../utils/api";
import { AxiosError } from "axios";
import StudentResults from "./StudentResults";
import Select from "react-select";
import { selectStyles } from "../../../utils/styles";
import Label from "../../../components/Label";

export default function TableStudent({
  setDataTable,
  dataTable,
  params,
  isSchool,
  isFilter = false,
  className,
  classId,
  setValue,
}) {
  const [dataTableIn, setDataTableIn] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [report, setReport] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  const [academicYear, setAcademicYear] = useState();
  const [academicYearOptions, setAcademicYearOptions] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);

  const { auth } = useContext(AuthContext);

  const user = auth.user;

  const { addToast } = Toast();
  const [selectedRows, setSelectedRows] = useState([]);

  const { id } = useParams();

  async function getStudentswithParamens() {
    try {
      const data = await handleFilterOrSearch(
        {
          schoolId: id,
          PageSize: PAGE_SIZE,
        },
        addToast,
        getStudent,
        false
      );
      setDataTableIn(data);

      setIsLoaded(true);
    } catch (err) {
      console.log("Erro", err);
    }
  }

  const download = () => {
    let apiUrl = "";
    if (report?.value === 1) {
      apiUrl = "/api/Student/downloadStudentsSimple";
    } else if (report?.value === 2) {
      apiUrl = "/api/Student/downloadStudents";
    }
    setIsLoading(true);
    downloadExcelFile(
      `${apiUrl}?AcademicYearId=${academicYear?.value}`,
      `${report?.label}.xlsx`
    );
    setIsLoading(false);
    onClose();
  };

  useEffect(() => {
    if (!isFilter) {
      setDataTableIn(dataTable);

      getStudentswithParamens();
    } else {
      setDataTableIn(dataTable);
    }
  }, [dataTable]);

  const columns = useMemo(
    () => [
      {
        Header: "Seleccionar todos",
        disableSortBy: true,
        accessor: "checkbox",
        Cell: (item) => {
          return (
            <Checkbox
              isChecked={selectedRows.includes(item.row.original.id)}
              onChange={() => toggleRowSelection(item.row.original.id)}
            />
          );
        },
      },
      {
        Header: "Estado",
        Cell: (item) => {
          return item.row.original.isActive ? (
            <StateRender isValidated={item.row.original.isValidated} />
          ) : (
            <Flex gap={1}>
              <MdArchive size="20px" />
              {ARCHIVED}
            </Flex>
          );
        },
      },
      {
        Header: "Nome do Aluno",
        accessor: "name",
        Cell: (item) => {
          return (
            <Link
              to={
                isSchool
                  ? `/escolas/gerir/${id}/alunos/${item.data[item.row.id].id}`
                  : `/alunos/${item.data[item.row.id].id}`
              }
            >
              {item.row.original.name}
            </Link>
          );
        },
      },
      {
        Header: "Nº de Documento de Identificação",
        accessor: "documentNumber",
      },
      {
        Header: "Nacionalidade",
        accessor: "nationality.name",
      },
      {
        Header: "Editar",
        accessor: "edit",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <Link
              to={
                isSchool
                  ? `/escolas/gerir/${id}/alunos/${item.data[item.row.id].id}`
                  : `/alunos/${item.data[item.row.id].id}`
              }
            >
              <EditIcon />
              {/* <MdModeEditOutline /> */}
            </Link>
          );
        },
      },
    ],
    [selectedRows]
  );

  const toggleRowSelection = (rowId) => {
    const updatedSelection = selectedRows.includes(rowId)
      ? selectedRows.filter((id) => id !== rowId)
      : [...selectedRows, rowId];
    setSelectedRows(updatedSelection);
  };

  const handleValidateOrInvalidate = async (url) => {
    try {
      const response = await api.patch(url, {
        ids: selectedRows,
      });
      if (response.status === 200) {
        const responseAfterUpdate = await getStudent();
        setDataTable(responseAfterUpdate);
        setValue("academicYear", undefined);
        setValue("school", undefined);
        setValue("course", undefined);
        setValue("grade", undefined);
        setValue("modality", undefined);
        setValue("shift", undefined);
        setValue("class", undefined);
        setValue("enrollmentStatus", undefined);
        setValue("schoolBondResult", undefined);
        setValue("state", undefined);
        setSelectedRows([]);
        addToast({
          status: "success",
          title: `Aluno ${
            url.includes("invalidate") ? "invalidado" : "validado"
          } com sucesso`,
        });
      } else {
        throw new AxiosError("Ocorreu um erro desconhecido", 400);
      }
    } catch (e) {
      console.log(e);
    }
  };

  if (!user.permissions.includes("GetStudents")) {
    columns = columns.filter((column) => column.accessor !== "edit");
  }

  const options = [
    {
      value: 1,
      label: "Lista simples de alunos cadastrados por escola e ano lectivo",
    },
    {
      value: 2,
      label: "Lista global de alunos cadastrados por escola e ano lectivo",
    },
  ];

  const onChange = (e) => {
    setReport(e);
    if (e?.value) {
      onOpen();
    }
  };

  async function getAcademicYears() {
    api
      .get(ACADEMIC_YEAR_URL)
      .then((res) => {
        const data = res.data.result;
        setAcademicYearOptions(
          data
            .filter((i) => i.isActive)
            .map((i) => ({
              value: i.id,
              label: i.year,
              isBaseYear: i.isBaseYear,
            }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  useEffect(() => {
    setAcademicYear();
    getAcademicYears();
  }, [isOpen]);

  useEffect(() => {
    if (academicYear?.value) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [academicYear]);

  return (
    <>
      <Flex justifyContent="space-between" alignItems="end" mb="10px">
        <Heading as="h2" size="md" textTransform="uppercase">
          Lista de alunos
        </Heading>
        <Flex>
          <StudentResults className={className} classId={classId} />
          <Box minW="300px">
            <Select
              placeholder="Descarregar lista"
              options={options}
              isSearchable={false}
              isClearable={true}
              onChange={onChange}
              styles={selectStyles}
            />
            <Modal isOpen={isOpen} onClose={onClose} size="lg">
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Descarregar lista</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Stack spacing={5}>
                    <Text>{report?.label}</Text>
                    <Box>
                      <Label title="Ano Lectivo" isRequired />
                      <Select
                        placeholder="Seleccionar"
                        options={academicYearOptions}
                        onChange={(value) => {
                          setAcademicYear(value);
                        }}
                        noOptionsMessage={NO_OPTIONS_MESSAGE}
                      />
                    </Box>
                  </Stack>
                </ModalBody>

                <ModalFooter>
                  <Button variant="secondary" onClick={onClose} mr={3}>
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    onClick={download}
                    isLoading={isLoading}
                    isDisabled={isDisabled}
                  >
                    Descarregar
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Box>
        </Flex>
      </Flex>

      <Table
        columns={columns}
        data={dataTableIn}
        isLoaded={isLoaded}
        setData={setDataTableIn}
        getFunction={getStudent}
        setIsLoaded={setIsLoaded}
        entity="student"
        params={params}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />

      {user.permissions.includes("ValidateStudents") && (
        <HStack mt="5">
          <Button
            rightIcon={<MdCheckCircle />}
            onClick={() => {
              handleValidateOrInvalidate(VALIDATE_STUDENT);
            }}
          >
            Validar Seleccionados
          </Button>

          <Button
            variant="danger"
            rightIcon={<MdCancel />}
            onClick={() => {
              handleValidateOrInvalidate(INVALIDATE_STUDENT);
            }}
          >
            Não Validar Seleccionados
          </Button>
        </HStack>
      )}
    </>
  );
}
