const handleInputOnChange = async (e, setDataTable, setInputSearch, theData, id = null) =>{
    if(e.target.value.length <= 0){
      try{
        let response = typeof theData === "function" ? (await theData(id)).subjects : theData
        setDataTable(response.map(e => ({value: e.id, label: e.label, code:e.code})))
      } catch(e){
          console.log(e)
      }
    }
    setInputSearch(e.target.value)
  }

  export default handleInputOnChange