import { useMemo } from "react";
import Table from "../../../components/Table";
import Select from "react-select";
import { NO_OPTIONS_MESSAGE } from "../../../utils/constants";
import { Input } from "@chakra-ui/react";

function TableStudentResults({
  data,
  setNewData,
  className,
  classId,
  enrollmentStatusOptions,
  schoolBondResultOptions,
}) {
  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  const handleSelectChange = (id, field, value) => {
    setNewData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  const handleChange = (id, field, value) => {
    setNewData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  let columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "name",
        disableSortBy: true,
      },
      {
        Header: "Estado da matrícula",
        disableSortBy: true,
        Cell: (item) => {
          const initialValue = item.row.original
            ?.studentEnrollmentStatusBondedId
            ? item.row.original?.studentEnrollmentStatusBondedId
            : item.row.original?.schoolBonds?.filter(
                (item) => item.classFullName === className
              )[0]?.studentEnrollmentStatusBonded?.id;
          return (
            <Select
              placeholder="Seleccionar"
              options={enrollmentStatusOptions}
              value={enrollmentStatusOptions.find(
                (option) => option.value === initialValue
              )}
              onChange={(option) =>
                handleSelectChange(
                  item.row.original.id,
                  "studentEnrollmentStatusBondedId",
                  option.value
                )
              }
              styles={selectStyles}
              menuPortalTarget={document.body}
              noOptionsMessage={NO_OPTIONS_MESSAGE}
            />
          );
        },
      },
      {
        Header: "Resultado",
        disableSortBy: true,
        Cell: (item) => {
          const studentEnrollmentStatusBondedId = item.row.original
            ?.studentEnrollmentStatusBondedId
            ? item.row.original?.studentEnrollmentStatusBondedId
            : item.row.original?.schoolBonds?.filter(
                (item) => item.classFullName === className
              )[0]?.studentEnrollmentStatusBonded?.id;

          let initialValue = item.row.original?.schoolBondResultBondedId
            ? item.row.original?.schoolBondResultBondedId
            : item.row.original?.schoolBonds?.filter(
                (item) => item.classFullName === className
              )[0]?.schoolBondResultBonded?.id;

          const isDisabled = studentEnrollmentStatusBondedId !== 1;

          if (studentEnrollmentStatusBondedId === 2) {
            initialValue = 2;
          }

          return (
            <Select
              placeholder="Seleccionar"
              options={schoolBondResultOptions}
              value={
                studentEnrollmentStatusBondedId === 2
                  ? schoolBondResultOptions.find(
                      (option) => option.value === initialValue
                    )
                  : isDisabled
                  ? null
                  : schoolBondResultOptions.find(
                      (option) => option.value === initialValue
                    )
              }
              onChange={(option) =>
                handleSelectChange(
                  item.row.original.id,
                  "schoolBondResultBondedId",
                  option.value
                )
              }
              styles={selectStyles}
              menuPortalTarget={document.body}
              noOptionsMessage={NO_OPTIONS_MESSAGE}
              isDisabled={isDisabled}
            />
          );
        },
      },
      {
        Header: "Média de conclusão de curso",
        accessor: "average",
        disableSortBy: true,
        Cell: (item) => {
          const studentEnrollmentStatusBondedId = item.row.original
            ?.studentEnrollmentStatusBondedId
            ? item.row.original?.studentEnrollmentStatusBondedId
            : item.row.original?.schoolBonds?.filter(
                (item) => item.classFullName === className
              )[0]?.studentEnrollmentStatusBonded?.id;

          const schoolBondResultBondedId = item.row.original
            ?.schoolBondResultBondedId
            ? item.row.original?.schoolBondResultBondedId
            : item.row.original?.schoolBonds?.filter(
                (item) => item.classFullName === className
              )[0]?.schoolBondResultBonded?.id;

          const initialValue = item.row.original?.courseCompletionAverage
            ? item.row.original?.courseCompletionAverage
            : item.row.original?.schoolBonds?.filter(
                (item) => item.classFullName === className
              )[0]?.courseCompletionAverage;

          const isDisabled =
            studentEnrollmentStatusBondedId !== 1 ||
            schoolBondResultBondedId !== 1;

          return (
            <Input
              value={initialValue === 0 ? "" : initialValue}
              onChange={(e) => {
                handleChange(
                  item.row.original.id,
                  "courseCompletionAverage",
                  e.target.value
                );
              }}
              isDisabled={isDisabled}
            />
          );
        },
      },
    ],
    []
  );

  if (!(classId?.value === 18 || classId?.value === 22)) {
    columns = columns.filter((column) => column.accessor !== "average");
  }

  return (
    data?.length > 0 && (
      <Table columns={columns} data={data} isPaginated={false} />
    )
  );
}

export default TableStudentResults;
