import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Input,
  Stack,
  FormControl,
  FormErrorMessage,
  HStack,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import Label from "../../../../components/Label";
import api from "../../../../utils/api";
import {
  ADD_ITEM_LOOKUP,
  ADD_ITEM_LOOKUP_BULK,
  COMMUNE_URL,
  COUNTY_URL,
  PROVINCE_URL,
  URBANDISTRICT_URL,
} from "../../../../utils/endpoints";
import { Toast } from "../../../../components/Toast";
import {
  NO_OPTIONS_MESSAGE,
  REQUIRED_FIELD,
} from "../../../../utils/constants";
import { ItemSchemForProvince } from "../../ItemSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { selectStyles } from "../../../../utils/styles";

import Select from "react-select";

export default function ModalProvince({
  isOpen,
  setIsOpen,
  setDataCommune,
  setDataUrbanDistrict,
}) {
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(ItemSchemForProvince),
  });

  const [provinceOptions, setProvinceOptions] = useState([]);
  const [countyOptions, setCountyOptions] = useState([]);

  const { addToast } = Toast();

  const code_province = watch("code_province");
  const name_province = watch("name_general");
  const province = watch("province");

  const code_county = watch("code_county");
  const name_county = watch("name_county");
  const county = watch("county");

  const code_commune = watch("code_commune");
  const name_commune = watch("name_commune");

  const code_urban = watch("code_urban");
  const name_urban = watch("name_urban");

  async function getOptions(url, parentId = null) {
    try {
      const response = await api.get(
        parentId
          ? `${url}?ParentId=${parentId}&OnlyActive=true`
          : `${url}?OnlyActive=true`
      );
      const data = response.data.map((option) => ({
        value: option.id,
        label: option.name,
      }));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async function getProvinceOptions() {
    getOptions(PROVINCE_URL)
      .then((res) => setProvinceOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getCountyOptions(parentId = null) {
    getOptions(COUNTY_URL, parentId)
      .then((res) => setCountyOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  useEffect(
    () => {
      getProvinceOptions();
      getCountyOptions();
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (province) {
        setValue("county", undefined);
        getCountyOptions(province.value);
      } else {
        setValue("county", undefined);
        getCountyOptions();
      }
    },
    // eslint-disable-next-line
    [province]
  );

  const onSubmit = async (data) => {
    if (
      data.province?.value === undefined &&
      (data.name_province === "" || data.code_province === "")
    )
      return addToast({ title: REQUIRED_FIELD, status: "error" });

    if (
      data.county?.value === undefined &&
      (data.name_county === "" || data.code_county === "")
    )
      return addToast({ title: REQUIRED_FIELD, status: "error" });

    if (
      (data.name_commune === "" || data.code_commune === "") &&
      (data.name_urban === "" || data.code_urban === "")
    )
      return addToast({ title: REQUIRED_FIELD, status: "error" });

    let bodyRequest = {};
    let url = null;

    if (!province?.value && !county?.value) {
      if (data.code_commune && data.name_commune) {
        bodyRequest = {
          listLookupsRequest: [
            {
              entityType: "Province",
              code: data.code_province,
              name: data.name_province,
              order: null,
            },
            {
              entityType: "County",
              code: data.code_county,
              name: data.name_county,
              order: null,
            },
            {
              entityType: "Commune",
              code: data.code_commune,
              name: data.name_commune,
              order: null,
            },
          ],
        };
      } else if (data.code_urban && data.name_urban) {
        bodyRequest = {
          listLookupsRequest: [
            {
              entityType: "Province",
              code: data.code_province,
              name: data.name_province,
              order: null,
            },
            {
              entityType: "County",
              code: data.code_county,
              name: data.name_county,
              order: null,
            },
            {
              entityType: "UrbanDistrict",
              code: data.code_urban,
              name: data.name_urban,
              order: null,
            },
          ],
        };
      }
      url = ADD_ITEM_LOOKUP_BULK;
    } else if (
      (!province?.value && county?.value) ||
      (province?.value && county?.value)
    ) {
      if (data.code_commune && data.name_commune) {
        bodyRequest = {
          entityType: "Commune",
          code: data.code_commune,
          name: data.name_commune,
          order: null,
          parentId: county.value,
        };
      } else if (data.code_urban && data.name_urban) {
        bodyRequest = {
          entityType: "UrbanDistrict",
          code: data.code_urban,
          name: data.name_urban,
          order: null,
          parentId: county.value,
        };
      }
      url = ADD_ITEM_LOOKUP;
    } else if (province?.value && !county?.value) {
      if (data.code_commune && data.name_commune) {
        bodyRequest = {
          listLookupsRequest: [
            {
              entityType: "County",
              code: data.code_county,
              name: data.name_coucode_county,
              order: null,
              parentId: province.value,
            },
            {
              entityType: "Commune",
              code: data.code_commune,
              name: data.name_commune,
              order: null,
            },
          ],
        };
      } else if (data.code_urban && data.name_urban) {
        bodyRequest = {
          listLookupsRequest: [
            {
              entityType: "County",
              code: data.code_county,
              name: data.name_county,
              order: null,
              parentId: province.value,
            },
            {
              entityType: "UrbanDistrict",
              code: data.code_urban,
              name: data.name_urban,
              order: null,
            },
          ],
        };
      }
      url = ADD_ITEM_LOOKUP_BULK;
    }

    try {
      await api.post(url, bodyRequest);

      addToast({
        status: "success",
        title: "Item adicionado com sucesso",
      });

      if (data.code_commune && data.name_commune) {
        const response = await api.get(
          `${COMMUNE_URL}?OnlyActive=false&PageSize=2500`
        );
        setDataCommune(
          response.data
            .slice()
            .sort((a, b) => a.fromCounty.name.localeCompare(b.fromCounty.name))
            .sort((a, b) =>
              a.fromCounty.fromProvince.name.localeCompare(
                b.fromCounty.fromProvince.name
              )
            )
        );
      } else if (data.code_urban && data.name_urban) {
        const response = await api.get(
          `${URBANDISTRICT_URL}?OnlyActive=false&PageSize=2500`
        );
        setDataUrbanDistrict(
          response.data
            .slice()
            .sort((a, b) => a.fromCounty.name.localeCompare(b.fromCounty.name))
            .sort((a, b) =>
              a.fromCounty.fromProvince.name.localeCompare(
                b.fromCounty.fromProvince.name
              )
            )
        );
      }
      setIsOpen(false);
      reset();
    } catch (e) {
      console.log(e);
      addToast({
        status: "error",
        title: "Ocorreu um erro ao adicionar item",
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      size="3xl"
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <ModalOverlay />
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          <ModalHeader>Inserir novo item</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Stack spacing={5}>
              <Box>
                <Text mb="5" fontWeight="bold">
                  Província
                </Text>

                <HStack gap={5}>
                  <FormControl isInvalid={!!errors.code_province}>
                    <Label title="Código" isRequired />
                    <Input
                      value={code_province}
                      {...register("code_province")}
                      size="md"
                      isDisabled={!!province?.value}
                    />
                    <FormErrorMessage>
                      {errors.code_province && <>{REQUIRED_FIELD}</>}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={!!errors.name_province}>
                    <Label title="Nome" isRequired />
                    <Input
                      value={name_province}
                      {...register("name_province")}
                      size="md"
                      isDisabled={!!province?.value}
                    />
                    <FormErrorMessage>
                      {errors.name_province && <>{REQUIRED_FIELD}</>}
                    </FormErrorMessage>
                  </FormControl>

                  <Text
                    textTransform="uppercase"
                    fontWeight="bold"
                    fontSize="sm"
                  >
                    Ou
                  </Text>

                  <FormControl>
                    <Label title="Seleccionar" />

                    <Controller
                      control={control}
                      name="province"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          placeholder="Seleccionar"
                          options={provinceOptions}
                          onChange={onChange}
                          value={value || ""}
                          styles={selectStyles}
                          isClearable={true}
                          noOptionsMessage={NO_OPTIONS_MESSAGE}
                          isDisabled={!!code_province || !!name_province}
                        />
                      )}
                    />
                  </FormControl>
                </HStack>
              </Box>

              <Box>
                <Text mb="5" fontWeight="bold">
                  Município
                </Text>

                <HStack gap={5}>
                  <FormControl isInvalid={!!errors.code_county}>
                    <Label title="Código" isRequired />
                    <Input
                      value={code_county}
                      {...register("code_county")}
                      size="md"
                      isDisabled={!!county?.value}
                    />
                    <FormErrorMessage>
                      {errors.code_county && <>{REQUIRED_FIELD}</>}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={!!errors.name_county}>
                    <Label title="Nome" isRequired />
                    <Input
                      value={name_county}
                      {...register("name_county")}
                      size="md"
                      isDisabled={!!county?.value}
                    />
                    <FormErrorMessage>
                      {errors.name_county && <>{REQUIRED_FIELD}</>}
                    </FormErrorMessage>
                  </FormControl>

                  <Text
                    textTransform="uppercase"
                    fontWeight="bold"
                    fontSize="sm"
                  >
                    Ou
                  </Text>

                  <FormControl>
                    <Label title="Seleccionar" />

                    <Controller
                      control={control}
                      name="county"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          placeholder="Seleccionar"
                          options={countyOptions}
                          onChange={onChange}
                          value={value || ""}
                          styles={selectStyles}
                          isClearable={true}
                          noOptionsMessage={NO_OPTIONS_MESSAGE}
                          isDisabled={
                            !province?.value || !!code_county || !!name_county
                          }
                        />
                      )}
                    />
                  </FormControl>
                </HStack>
              </Box>

              <Box>
                <Text mb="5" fontWeight="bold">
                  Comuna
                </Text>

                <HStack gap={5}>
                  <FormControl isInvalid={!!errors.code_commune}>
                    <Label title="Código" />
                    <Input
                      value={code_commune}
                      {...register("code_commune")}
                      size="md"
                      isDisabled={!!code_urban || !!name_urban}
                    />
                    <FormErrorMessage>
                      {errors.code_commune && <>{REQUIRED_FIELD}</>}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={!!errors.name_commune}>
                    <Label title="Nome" />
                    <Input
                      value={name_commune}
                      {...register("name_commune")}
                      size="md"
                      isDisabled={!!code_urban || !!name_urban}
                    />
                    <FormErrorMessage>
                      {errors.name_commune && <>{REQUIRED_FIELD}</>}
                    </FormErrorMessage>
                  </FormControl>
                </HStack>
              </Box>

              <Box>
                <Text mb="5" fontWeight="bold">
                  Distrito Urbano
                </Text>

                <HStack gap={5}>
                  <FormControl isInvalid={!!errors.code_urban}>
                    <Label title="Código" />
                    <Input
                      value={code_urban}
                      {...register("code_urban")}
                      size="md"
                      isDisabled={!!code_commune || !!name_commune}
                    />
                    <FormErrorMessage>
                      {errors.code_urban && <>{REQUIRED_FIELD}</>}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={!!errors.name_urban}>
                    <Label title="Nome" />
                    <Input
                      value={name_urban}
                      {...register("name_urban")}
                      size="md"
                      isDisabled={!!code_commune || !!name_commune}
                    />
                    <FormErrorMessage>
                      {errors.name_urban && <>{REQUIRED_FIELD}</>}
                    </FormErrorMessage>
                  </FormControl>
                </HStack>
              </Box>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="secondary"
              onClick={() => {
                setIsOpen(false);
              }}
              mr={3}
            >
              Cancelar
            </Button>
            <Button type="submit">Inserir</Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
}
