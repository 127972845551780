import { useContext, useEffect, useState } from "react";
import Form from "./Form";
import { useForm } from "react-hook-form";
import { schema } from "./Form/schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Toast } from "../../components/Toast";
import api from "../../utils/api";
import { TEACHER_URL } from "../../utils/endpoints";
import { AuthContext } from "../../provider/AuthProvider";

function NewTeacher() {
  const [isDisabled, setIsDisabled] = useState(false);
  const [contractualBondRequests, setContractualBondRequests] = useState([]);
  const [contractualBondRequestsData, setContractualBondRequestsData] =
    useState([]);
  const navigate = useNavigate();
  const { addToast } = Toast();
  const [searchParams] = useSearchParams();
  const { auth } = useContext(AuthContext);
  const user = auth.user;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
    unregister,
  } = useForm({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    populateTeacherData();
  }, []);

  const populateTeacherData = () => {
    setValue("typeDocument", searchParams.get("typeDocumentValue"));
    setValue("typeDocumentName", searchParams.get("typeDocument"));
    setValue("documentNumber", searchParams.get("documentNumber"));
  };

  const createTeacher = () => {
    const data = getValues();
    api
      .post(TEACHER_URL, {
        name: data.name,
        typeDocument: data.typeDocument,
        documentNumber: data.documentNumber,
        agentNumber: data.agentNumber,
        birthDate: data.birthDate,
        gender: data.gender,
        nationality: data.nationality.value,
        academicQualification: data.academicQualification.value,
        trainingArea: data.trainingArea.value,
        pedagogicalTraining: data.pedagogicalTraining,
        createContractualBondRequests: contractualBondRequests,
      })
      .then(() => {
        addToast({
          title: "Docente cadastrado com sucesso",
          status: "success",
        });
        if (!user?.schoolId) {
          navigate("/docentes");
        } else {
          navigate(`/gerir/${user?.schoolId}/docentes`);
        }
      })
      .catch((error) => {
        let errors = error.response.data.errors;
        for (let err in errors) {
          addToast({ title: errors[err], status: "error" });
        }
      });
  };

  return (
    <Form
      isDisabled={isDisabled}
      setIsDisabled={setIsDisabled}
      register={register}
      handleSubmit={handleSubmit}
      errors={errors}
      control={control}
      watch={watch}
      setValue={setValue}
      unregister={unregister}
      createTeacher={createTeacher}
      contractualBondRequests={contractualBondRequests}
      setContractualBondRequests={setContractualBondRequests}
      contractualBondRequestsData={contractualBondRequestsData}
      setContractualBondRequestsData={setContractualBondRequestsData}
    />
  );
}

export default NewTeacher;
