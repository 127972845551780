import { useContext } from "react";
import { AuthContext } from "../provider/AuthProvider";
import { Navigate } from "react-router-dom";
import Layout from "../components/Layout";

function PrivateRoute() {
  const { auth } = useContext(AuthContext);
  const isAuthenticated = auth.isAuthenticated;

  return isAuthenticated ? <Layout /> : <Navigate to="/" />;
}

export default PrivateRoute;
