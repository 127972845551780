import { useContext, useEffect, useState } from "react";
import { Heading, Stack, Text, Box } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import handleFilterOrSearch from "../../../../helpers/handleFilterOrSearch";
import { getTeacher } from "../../../../services/getTeacher";
import { getSchool } from "../../../../services/getSchool";
import { getAcademicYear } from "../../../../services/getAcademicYear";
import { getAcademicQualification } from "../../../../services/getAcademicQualification";
import { getTrainingArea } from "../../../../services/getTrainingArea";
import { getBond } from "../../../../services/getBond";
import { getPedagogicalTraining } from "../../../../services/getPedagogicalTraining";
import { getCourse } from "../../../../services/getCourse";
import { getSubject } from "../../../../services/getSubject";
import api from "../../../../utils/api";
import ButtonGroupTeacher from "../../../Teacher/components/ButtonGroupTeacher";
import { formStyles } from "../../../../utils/styles";
import SearchArea from "../../../../components/SearchArea";
import FilterArea from "../../../../components/FilterArea";
import TableTeacher from "../../../Teacher/components/TableTeacher";
import ModalConfirmTeacher from "../../../Teacher/components/Modal";
import { Toast } from "../../../../components/Toast";
import { AuthContext } from "../../../../provider/AuthProvider";
import { TEACHER_SUMMARY } from "../../../../utils/constants";
import { useParams } from "react-router-dom";

export default function ManagerTeacher() {
  const [schoolData, setSchoolData] = useState([]);
  const [linkData, setLinkData] = useState([]);
  const [academicYearData, setAcademicYearData] = useState([]);
  const [academicQualificationData, setAcademicQualificationData] = useState(
    []
  );
  const [trainningAreaData, setTrainningArea] = useState([]);
  const [pedagogicalTrainingData, setPedagogicalTrainingData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [subjectData, setSubjectData] = useState([]);
  const { addToast } = Toast();
  const { register, control, watch, setValue } = useForm();

  const inputSearch = watch("search");
  const academicQualification = watch("academicQualification");
  const trainingArea = watch("trainingArea");
  const contractualBondType = watch("contractualBond");
  const pedagogicalTraining = watch("pedagogicalTraining");
  const course = watch("course");
  const subject = watch("subject");
  const state = watch("state");
  const isActive = watch("isActive");

  const [isFilter, setIsFilter] = useState(false);

  const { auth } = useContext(AuthContext);
  const user = auth.user;
  const { id } = useParams();

  useEffect(() => {
    if (user?.schoolId || id) {
      setValue(
        "school",
        schoolData.filter(
          (item) => item.value === user?.schoolId || item.value == id
        )
      );
    }
  }, [schoolData]);

  const dataSelect = [
    {
      fieldObjectName: "school",
      fieldName: "Escola",
      data: schoolData,
      isDisabled: true,
    },
    {
      fieldObjectName: "academicQualification",
      fieldName: "Habilitação Académica",
      data: academicQualificationData,
    },
    {
      fieldObjectName: "trainingArea",
      fieldName: "Área de Formação",
      data: trainningAreaData,
    },
    {
      fieldObjectName: "contractualBond",
      fieldName: "Vínculo",
      data: linkData,
    },
    {
      fieldObjectName: "pedagogicalTraining",
      fieldName: "Formação Pedagócica",
      data: pedagogicalTrainingData,
    },
    {
      fieldObjectName: "course",
      fieldName: "Curso",
      data: courseData,
    },
    {
      fieldObjectName: "subject",
      fieldName: "Disciplina",
      data: subjectData,
    },
    {
      fieldObjectName: "state",
      fieldName: "Estado",
      data: [
        { value: true, label: "Validado" },
        { value: "pending", label: "Pendente de Validação" },
        { value: false, label: "Não validado" },
      ],
    },
    {
      fieldObjectName: "isActive",
      fieldName: "Arquivado",
      data: [
        { value: false, label: "Sim" },
        { value: true, label: "Não" },
      ],
    },
  ];

  const paramsObj = {
    Pesquisa: inputSearch,
    AcademicQualification: academicQualification?.value,
    SchoolId: user?.schoolId ? user?.schoolId : id,
    TrainingArea: trainingArea?.value,
    ContractualBondType: contractualBondType?.value,
    PedagogicalTraining: pedagogicalTraining?.value,
    Courses: course?.id,
    Subjects: subject?.value,
    IsValidated: state?.value,
    IsActive: isActive?.value,
  };

  const [isOpen, setIsOpen] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [checkedBoxes, setCheckedBoxes] = useState([]);

  const handleSearch = async () => {
    setIsFilter(true);
    const data = await handleFilterOrSearch(paramsObj, addToast, getTeacher);
    setDataTable(data);
  };

  const handleCleanSearch = async () => {
    setValue("search", undefined);
    let valParams = [];
    for (const key in paramsObj) {
      valParams.push(paramsObj[key]);
    }

    const response = valParams.includes(undefined)
      ? await handleFilterOrSearch(
          { ...paramsObj, Pesquisa: undefined },
          addToast,
          getTeacher
        )
      : await getTeacher(user && user?.schoolId);
    setDataTable(response);
  };

  const handleCleanFilters = async () => {
    setValue("academicQualification", undefined);
    setValue("trainingArea", undefined);
    setValue("contractualBond", undefined);
    setValue("pedagogicalTraining", undefined);
    setValue("course", undefined);
    setValue("subject", undefined);
    setValue("state", undefined);
    setValue("isActive", undefined);

    const response = await handleFilterOrSearch(
      {
        Pesquisa: undefined,
        SchoolId: user?.schoolId ? user?.schoolId : id,
      },
      addToast,
      getTeacher,
      false
    );
    setDataTable(response);
  };

  useEffect(() => {
    const getData = async () => {
      const responseSchool = await getSchool();
      const responseAcademicYear = (await getAcademicYear()).map((e) => ({
        label: e.year,
        value: e.id,
      }));
      const responseAcademicQualification = await getAcademicQualification();
      const responseTrainingArea = await getTrainingArea();
      const responseBond = await getBond();
      const responsePedagogicalTraining = await getPedagogicalTraining();

      setAcademicYearData(responseAcademicYear);
      setSchoolData(responseSchool);
      setAcademicQualificationData(responseAcademicQualification);
      setTrainningArea(responseTrainingArea);
      setLinkData(responseBond);
      setPedagogicalTrainingData(responsePedagogicalTraining);
    };
    getData();
  }, []);

  useEffect(() => {
    if (user?.schoolId) {
      const getData = async () => {
        const responseCourse = await getCourse({
          SchoolId: user?.schoolId,
        });
        setCourseData(responseCourse);
      };
      getData();
    }
  }, [user?.schoolId]);

  useEffect(() => {
    let params = {};
    if (course?.id) {
      params = {
        CourseId: course?.id,
      };
    }
    const getData = async () => {
      const responseSubject = await getSubject(params);
      setSubjectData(responseSubject);
    };
    getData();
  }, [course]);

  const handleValidateOrInvalidate = async (url) => {
    try {
      checkedBoxes.map(async (e) => {
        await api.patch(url, {
          id: e.id,
        });
      });
      const responseAfterUpdate = await getTeacher();
      setDataTable(responseAfterUpdate);
      addToast({
        status: "success",
        title: `Docente ${
          url.includes("invalidate") ? "invalidado" : "validado"
        } com sucesso`,
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Stack spacing={5}>
      <Box>
        <Heading mb="10px">Docentes</Heading>
        <Text width="sm">{TEACHER_SUMMARY}</Text>
      </Box>

      {user.permissions.includes("CreateTeachers") && (
        <ButtonGroupTeacher setIsOpen={setIsOpen} />
      )}

      <Box>
        <Box>
          <Heading as="h2" size="md" textTransform="uppercase" mb="10px">
            Pesquisar
          </Heading>
        </Box>
        <Box as="form" sx={formStyles} px="0!important">
          <SearchArea
            register={register}
            handleCleanFilters={handleCleanSearch}
            handleSearch={handleSearch}
            title={
              "Pesquisar pelo número de identificação ou código do docente"
            }
          />
          <FilterArea
            control={control}
            handleSearch={handleSearch}
            handleCleanFilters={handleCleanFilters}
            dataSelect={dataSelect}
            title="Filtrar Docentes"
            gridNumberSelect={5}
          />
        </Box>
      </Box>

      <TableTeacher
        handleValidateOrInvalidate={handleValidateOrInvalidate}
        checkedBoxes={checkedBoxes}
        setCheckedBoxes={setCheckedBoxes}
        setDataTable={setDataTable}
        dataTable={dataTable}
        isSchool={true}
        isFilter={isFilter}
      />
      <ModalConfirmTeacher
        title="Cadastrar Docente"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </Stack>
  );
}
