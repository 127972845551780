import { ChakraProvider } from "@chakra-ui/react";
import { RouterProvider } from "react-router-dom";
import { theme } from "./utils/theme";
import { AuthProvider } from "./provider/AuthProvider";
import router from "./routes";
import { CrumbProvider } from "./provider/CrumbProvider";
import "./utils/styles.css";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <CrumbProvider>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
      </CrumbProvider>
    </ChakraProvider>
  );
}

export default App;
