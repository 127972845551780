import {
    FormControl,
    FormErrorMessage,
    HStack,
    Input,
    Stack,
    Textarea,
  } from "@chakra-ui/react";
  import { useEffect, useState } from "react";
  import { Controller } from "react-hook-form";

  import { Toast } from "../Toast";
  import Label from "../Label";
  
  export function FormUploadList({
    fileRef,
    setFile
  }) {

    const { addToast } = Toast();  
    return (
      <>
        <Stack spacing={4} w={350}>
          <HStack  >
            <FormControl>
              <Label title="Lista (.xls)" />
              <Input 
                type="file"
                id="name"  
                ref={fileRef}
              />
            </FormControl>
          </HStack> 
        </Stack>
      </>
    );
  }
  