import { useMemo } from "react";
import Details from "./Details";
import Table from "../../../../components/Table";

export default function TableErrorCloseAcademicYear({
  data,
  isLoaded,
  setData,
  setIsLoaded,
}) {
  setData(data);

  const getTableData = () => {
    const tableData = [];

    if (
      data &&
      data.coursesWithoutTeacherAssigned &&
      data.coursesWithoutTeacherAssigned.length > 0
    ) {
      const totalErrors = calcularTotalDeErros(
        data.coursesWithoutTeacherAssigned
      );
      const description = "Existem Cursos sem Docentes Associados";

      tableData.push({
        description: description,
        data: data.coursesWithoutTeacherAssigned,
        totalErrors: totalErrors,
      });
    }

    if (
      data &&
      data.studentsWithoutGrade &&
      data.studentsWithoutGrade.result.length > 0
    ) {
      const totalErrors = calcularTotalDeErros(
        data.studentsWithoutGrade.result
      );
      const description = "Existem Alunos sem Notas";

      tableData.push({
        description: description,
        data: data.studentsWithoutGrade.result,
        totalErrors: totalErrors,
      });
    }

    if (
      data &&
      data.studentsWithoutResult &&
      data.studentsWithoutResult.result.length > 0
    ) {
      const totalErrors = calcularTotalDeErros(
        data.studentsWithoutResult.result
      );
      const description = "Existem Alunos sem a Média de conclusão do Curso";

      tableData.push({
        description: description,
        data: data.studentsWithoutResult.result,
        totalErrors: totalErrors,
      });
    }

    if (
      data &&
      data.invalidStudents &&
      data.invalidStudents.result.length > 0
    ) {
      const totalErrors = calcularTotalDeErros(data.invalidStudents.result);
      const description =
        "Existem Alunos com estado Não Validado ou Pendente de Validação";

      tableData.push({
        description: description,
        data: data.invalidStudents.result,
        totalErrors: totalErrors,
      });
    }

    setIsLoaded(true);
    return tableData;
  };

  const calcularTotalDeErros = (dataArray) => {
    return dataArray.length;
  };

  let columns = useMemo(
    () => [
      {
        Header: "Número de erros",
        accessor: "totalErrors",
        disableSortBy: true,
      },
      {
        Header: "Descrição",
        accessor: "description",
      },

      {
        Header: "Detalhes",
        accessor: "edit",
        disableSortBy: true,
        Cell: (item) => {
          console.log("ER", item.row.original.description);
          return (
            <Details
              id={item.row.original.id}
              data={item.row.original.data}
              details={item.row.original.description}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <>
      {data && (
        <Table
          setData={setData}
          setIsLoaded={setIsLoaded}
          columns={columns}
          data={getTableData()}
          isLoaded={isLoaded}
        />
      )}
    </>
  );
}
