import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";
import { formStyles, selectStyles } from "../../../utils/styles";
import Label from "../../../components/Label";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { NO_OPTIONS_MESSAGE, REQUIRED_FIELD } from "../../../utils/constants";
import { getProvince } from "../../../services/getProvince";
import { getCounty } from "../../../services/getCounty";
import { getSchool } from "../../../services/getSchool";
import { useState, useEffect, useContext } from "react";
import { Toast } from "../../../components/Toast";
import FormButtons from "./FormButtons";
import { AuthContext } from "../../../provider/AuthProvider";
import ResetPassword from "../components/ResetPassword";

function Form({
  isDisabled,
  setIsDisabled,
  id,
  register,
  handleSubmit,
  errors,
  control,
  watch,
  setValue,
  unregister,
  roleOptions,
  userRoles,
  userData,
  createUser,
  editUser,
  cancelEdit,
  deleteUser,
  resetPassword,
}) {
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [countyOptions, setCountyOptions] = useState([]);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const { addToast } = Toast();
  const { auth } = useContext(AuthContext);
  const user = auth.user;
  const [canEdit, setCanEdit] = useState(false);

  const status = watch("status");
  const name = watch("name");
  const role = watch("role");
  const province = watch("province");
  const county = watch("county");
  const school = watch("school");
  const level = watch("level");

  async function getProvinceOptions() {
    getProvince()
      .then((res) => setProvinceOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getCountyOptions(id) {
    getCounty({ ParentId: id })
      .then((res) => setCountyOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getSchoolOptions(provinceId, countyId) {
    getSchool(provinceId, countyId)
      .then((res) => setSchoolOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  useEffect(
    () => {
      getProvinceOptions();
      if (role) {
        setValue("level", Math.max(...role.map((item) => item.level)));
        switch (level) {
          case 3:
            unregister("county");
            unregister("school");
            break;

          case 4:
            unregister("school");
            break;

          default:
            unregister("province");
            unregister("county");
            unregister("school");
        }
      } else {
        setValue("level", 1);
      }
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (role) {
        setValue("province", undefined);
        setValue("county", undefined);
        setValue("school", undefined);
        setValue("level", Math.max(...role.map((item) => item.level)));
        switch (level) {
          case 3:
            unregister("county");
            unregister("school");
            break;

          case 4:
            unregister("school");
            break;

          default:
            unregister("province");
            unregister("county");
            unregister("school");
        }
      } else {
        setValue("level", 1);
      }
    },
    // eslint-disable-next-line
    [role]
  );

  useEffect(
    () => {
      if (province) {
        getCountyOptions(province?.value);
        if (!id) {
          setValue("county", undefined);
          setValue("school", undefined);
        }
        if (id && province.value !== userData.userProvince?.id) {
          setValue("county", "");
          setValue("school", "");
        }
      }
    },
    // eslint-disable-next-line
    [province]
  );

  useEffect(
    () => {
      if (county) {
        getSchoolOptions(null, county?.value);
        if (!id) {
          setValue("school", undefined);
        }
        if (id && county.value !== userData.userCounty?.id) {
          setValue("school", "");
        }
      }
    },
    // eslint-disable-next-line
    [county]
  );

  const onSubmit = () => {
    setIsDisabled(true);
    if (id) {
      editUser();
    }
  };

  useEffect(
    () => {
      if (!isDisabled) {
        if (status) {
          if (user.permissions.includes("DisableUser")) {
            setCanEdit(true);
          } else {
            setCanEdit(false);
          }
        } else {
          if (user.permissions.includes("EnableUser")) {
            setCanEdit(true);
          } else {
            setCanEdit(false);
          }
        }
      } else {
        setCanEdit(false);
      }
    },
    // eslint-disable-next-line
    [isDisabled, status]
  );

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={5}>
        <Flex>
          <Heading as="h1" mr={4}>
            {isDisabled
              ? `Resumo | ${name}`
              : id
              ? `Editar ${name}`
              : "Cadastrar Utilizador"}
          </Heading>
          {id && (
            <Flex direction="column" alignSelf="end">
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <Switch {...field} isChecked={status} isDisabled={!canEdit} />
                )}
              />
              <Text as="b" fontSize="xs">
                {status ? "Activo" : "Inactivo"}
              </Text>
            </Flex>
          )}
        </Flex>
        <Box>
          <Heading as="h2" size="md" textTransform="uppercase" mb={2}>
            Informação
          </Heading>
          <Box sx={formStyles} mb={5}>
            <Stack spacing={5}>
              <FormControl isInvalid={!!errors.name}>
                <Label title="Nome" isRequired />
                <Input {...register("name")} isDisabled={isDisabled} />
                <FormErrorMessage>
                  {errors.name && errors.name.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.email}>
                <Label title="Email" isRequired />
                <Input {...register("email")} isDisabled={isDisabled} />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.role}>
                <Label title="Perfil" isRequired />
                <Controller
                  control={control}
                  name="role"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      placeholder="Seleccionar"
                      options={roleOptions}
                      isSearchable={true}
                      isDisabled={isDisabled}
                      onChange={onChange}
                      value={value || ""}
                      defaultValue={userRoles}
                      styles={selectStyles}
                      isMulti
                      isClearable={false}
                      noOptionsMessage={NO_OPTIONS_MESSAGE}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.role && <>{REQUIRED_FIELD}</>}
                </FormErrorMessage>
              </FormControl>
              {role &&
                role.filter(
                  (item) =>
                    item.level === 3 || item.level === 4 || item.level === 5
                ).length > 0 && (
                  <>
                    <FormControl isInvalid={!!errors.province}>
                      <Label title="Província" isRequired />
                      <Controller
                        control={control}
                        name="province"
                        render={({ field: { onChange, value } }) => (
                          <Select
                            placeholder="Seleccionar"
                            options={provinceOptions}
                            defaultValue={province}
                            isDisabled={isDisabled || !role}
                            onChange={onChange}
                            value={value || ""}
                            styles={selectStyles}
                            noOptionsMessage={NO_OPTIONS_MESSAGE}
                          />
                        )}
                      />
                      <FormErrorMessage>
                        {errors.province && <>{REQUIRED_FIELD}</>}
                      </FormErrorMessage>
                    </FormControl>

                    {role.filter((item) => item.level === 4 || item.level === 5)
                      .length > 0 && (
                      <FormControl isInvalid={!!errors.county}>
                        <Label title="Município" isRequired />{" "}
                        <Controller
                          control={control}
                          name="county"
                          render={({ field: { onChange, value } }) => (
                            <Select
                              placeholder="Seleccionar"
                              options={countyOptions}
                              defaultValue={county}
                              isDisabled={isDisabled || !province}
                              onChange={onChange}
                              value={value || ""}
                              styles={selectStyles}
                              noOptionsMessage={NO_OPTIONS_MESSAGE}
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {errors.county && <>{REQUIRED_FIELD}</>}
                        </FormErrorMessage>
                      </FormControl>
                    )}

                    {role.filter((item) => item.level === 5).length > 0 && (
                      <FormControl isInvalid={!!errors.school}>
                        <Label title="Escola" isRequired />
                        <Controller
                          control={control}
                          name="school"
                          render={({ field: { onChange, value } }) => (
                            <Select
                              placeholder="Seleccionar"
                              options={schoolOptions}
                              defaultValue={school}
                              isDisabled={isDisabled || !county}
                              onChange={onChange}
                              value={value || ""}
                              styles={selectStyles}
                              noOptionsMessage={NO_OPTIONS_MESSAGE}
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {errors.school && <>{REQUIRED_FIELD}</>}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </>
                )}
              {id && (
                <FormControl>
                  <Label title="Senha" />
                  <ResetPassword
                    resetPassword={resetPassword}
                    isDisabled={isDisabled}
                  />
                </FormControl>
              )}
            </Stack>
          </Box>
          <FormButtons
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
            id={id}
            createUser={createUser}
            cancelEdit={cancelEdit}
            deleteUser={deleteUser}
          />
        </Box>
      </Stack>
    </Box>
  );
}

export default Form;
