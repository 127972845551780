import { Box, Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { MdLogout } from "react-icons/md";
import { borderRadius, menuItemStyles } from "../../utils/styles";
import { useContext } from "react";
import { AuthContext } from "../../provider/AuthProvider";

function Logout() {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const logoutUser = () => {
    logout();
    navigate("/");
  };

  return (
    <Box w="100%" py="2px">
      <Box
        sx={menuItemStyles}
        onClick={logoutUser}
        _hover={{ cursor: "pointer" }}
      >
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center">
            <Box bgColor="#fff" borderRadius={borderRadius} p={1} mr={2}>
              <MdLogout color="#000" />
            </Box>
            Terminar sessão
          </Flex>
          <ChevronRightIcon />
        </Flex>
      </Box>
    </Box>
  );
}

export default Logout;
