import { useMemo, useEffect, useState } from 'react'
import {
    Button,
    Text
} from '@chakra-ui/react'
import {
    MdModeEditOutline
} from 'react-icons/md'
import { Link } from 'react-router-dom'
import { getAcademicYear } from '../../../services/getAcademicYear'
import Table from '../../../components/Table'
import { ACADEMIC_YEAR_URL } from '../../../utils/endpoints'
import api from '../../../utils/api'
import { Toast } from '../../../components/Toast'
import { getSchool } from '../../../services/getSchool'
import { getSchools } from '../../../services/getsSchools'
export default function TableSchool({
  setDataTable,
  dataTable,
}) {
  const { addToast } = Toast();
    const [isLoaded, setIsLoded] = useState(false)


    useEffect(() => {
          const getData = async () => {
            const response = await getSchools()
            
            setDataTable(response)
            setIsLoded(true)
          }
          getData()
    }, [])


    const columns = useMemo(
        () => [

          {
            Header: "Escola",
            accessor:"name"
          },

          {
            Header: "Estado",
            Cell: (item) => {
              return  <Text>A decorrer</Text> 
             
              
            },
          }          
        ],
        []
      );
return(
    <>
        <Table columns={columns} data={dataTable} isLoaded={isLoaded}/>
    </>
)
}