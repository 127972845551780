import {
    FormControl,
    FormErrorMessage,
    HStack,
    Stack,
    Textarea,
    Radio, 
    RadioGroup,
    Input
  } from "@chakra-ui/react";
  import { useEffect, useState } from "react";
  import { Controller } from "react-hook-form";
  import InputMask from "react-input-mask";
  import { Toast } from "../../../components/Toast";

import Label from "../../../components/Label";
import { getAcademicYearFormat } from "../../../services/getAcademicYearFormat";
import { REQUIRED_FIELD } from "../../../utils/constants";
  export default function FormTabInfo({
    isDisabled,
    errors,
    register,
    control,
    setValue,
    watch,
    regexFormatYear,
    setRegexFormatYear
  }) {
    const [formatYear, setFormatYear]= useState("")
    const [academicYearFormatData, setAcademicYearFormatData]= useState([])
    const { addToast } = Toast();
    const formatYearVar = watch("yearFormat")
    const simpleMargin = {
        ml:'20'
    }
    const handleLabelInAcademicYear = (year) => {
      const label = year.includes("/") ? "4 + 4 dígitos - xxxx/xxxx" : "4 dígitos - xxxx"
      return label
    }
    const inputCodeStyle= {
      padding:'10px',
      width: '100%',
      border:'1px solid gray',
      borderRadius:'5px'
    }
    useEffect(()=>{
      const getData = async () => {
        const response = await getAcademicYearFormat()
        setAcademicYearFormatData(response)
      }
      getData()
    }, [])
    useEffect(()=>{
      const getData = async () => {
        const responseFormatYear = await getAcademicYearFormat(watch("yearFormat"))
        setRegexFormatYear(responseFormatYear.filter(e => e.value === watch("yearFormat"))[0].regex)
        setFormatYear(responseFormatYear.filter(e => e.value === watch("yearFormat"))[0].code.replaceAll("y", "9"))
        console.log("TEST",responseFormatYear.filter(e => e.value === watch("yearFormat"))[0].code.replaceAll("y", "9"))
      }
      getData()    
    },[watch("yearFormat")])

    return (
      <>
        <Stack spacing={5} mb={5} >
          <FormControl isInvalid={!!errors.yearFormat}  width="70%">
            <Label
              title="Selecione o Formato do Ano Lectivo"
              isRequired={true}
            />
           <Controller
            control={control}
            name="yearFormat"
            render={({ field: { onChange, value } }) => (
              <RadioGroup onChange={(e)=>{
                setValue("year", "")
                onChange(Number(e))
                console.log(e)
              }} value={Number(value)}>
                <Stack direction='column'>
                  {
                    academicYearFormatData.map(e => (
                      <Radio onChange={()=>{
                        setRegexFormatYear(e.regex)
                        
                      }} value={Number(e.value)}>{handleLabelInAcademicYear(e.code)}</Radio>
                    ))
                  }
                </Stack>
              </RadioGroup>
            )}
          />
            <FormErrorMessage>
              {errors.yearFormat && <>{REQUIRED_FIELD}</>}
            </FormErrorMessage>
          </FormControl>
  
          <HStack  width="20%">
          <FormControl isInvalid={!!errors.year}>
              <Label
                title="Ano Lectivo"
                isRequired={true}
              />
                <InputMask
                  {...register("year")} 
                  style={inputCodeStyle}
                  mask={formatYear.replaceAll("x", "9")} 
                  placeholder={formatYear.replaceAll("9", "_")}  
                />
              <FormErrorMessage>
                {errors.year && <>{REQUIRED_FIELD}</> }
              </FormErrorMessage>
            </FormControl>
          </HStack>
        </Stack>
      </>
    );
  }
  