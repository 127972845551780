export const REQUIRED_FIELD = "Campo obrigatório não preenchido";
export const INVALID_EMAIL = "Email inválido";
export const NO_RESULTS = "Sem resultados";
export const DIALOG_MESSAGE =
  "Tem a certeza? Não é possível anular esta acção mais tarde.";
export const SCHOOL_SUMMARY =
  "Nesta página é possível cadastrar, editar, visualizar e validar todas as Escolas";
export const COURSE_SUMMARY =
  "Nesta página, é possível cadastrar, editar, visualizar e validar todos os Cursos";
export const TEACHER_SUMMARY =
  "Nesta página é possível cadastrar, editar, visualizar e validar todos os Docentes";
export const STUDENT_SUMMARY =
  "Nesta página é possível cadastrar, editar, visualizar e validar todos os Alunos";
export const ROLE_SUMMARY =
  "Nesta página é possível cadastrar, editar e visualizar todos os Perfis";
export const USER_SUMMARY =
  "Nesta página é possível cadastrar, editar e visualizar todos os Utilizadores";
export const LOOKUP_SUMMARY =
  "Nesta página é possível gerir tabelas auxiliares";
export const ACADEMIC_YEAR_SUMMARY =
  "Nesta página é possível abrir Anos Lectivos, notificar e tornar público relatórios";
export const AUDIT_SUMMARY =
  "Nesta página é possível auditar as acções realizadas no sistema";
export const REPORTS_SUMMARY =
  "Nesta página é possível aceder às estatísticas do Ensino Técnico-Profissional de acordo com os vários indicadores definidos e perfil do utilizador";
export const MY_SCHOOL_SUMMARY =
  "Nesta página é possível editar e visualizar os dados da minha Escola";
export const MY_COURSE_SUMMARY =
  "Nesta página é possível editar e visualizar os Cursos da minha Escola";
export const MY_STUDENT_SUMMARY =
  "Nesta página é possível cadastrar, editar, visualizar e validar os Alunos da minha Escola";
export const MY_USER_SUMMARY =
  "Nesta página é possível editar e visualizar os Utilizadores da minha Escola";
export const MY_TEACHER_SUMMARY =
  "Nesta página é possível cadastrar, editar, visualizar e validar os Docentes da minha Escola";
export const NO_OPTIONS_MESSAGE = () => "Sem resultados";
export const PUBLIC_NATURES = "Pública";
export const PRIVATE_NATURES = "Privada";
export const PRIVATE_OR_PUBLIC_NATURES = "Público-Privada";
export const regexPattern = /^\d{9}[A-Z]{2}\d{3}$/;
export const PAGE_SIZE = 10;

export const handleEnterOnSearch = (event: any, onSubmit: any) => {
  if (event.key === "Enter") {
    onSubmit();
  }
};

export const TYPEDOCUMENTBI = "Bilhete de Identidade";

export const HAS_LEGAL_EQUIVALENCE =
  "O aluno não frequentou/concluiu a classe anterior mas tem equivalência legal";
export const HAS_LEGAL_PROOF =
  "O aluno frequentou e concluiu a classe anterior em ano lectivo anterior e tem comprovativo legal";

export const ARCHIVED = "Arquivado";
