import { Stack, Center } from "@chakra-ui/react";
import { useMemo } from "react";
import Table from "../../../components/Table";
import { BsTrash } from "react-icons/bs";

export default function TableSubjectResume({ dataTable }) {
  const columns = useMemo(
    () => [
      {
        Header: "Código da Disciplina",
        accessor: "code",
      },
      {
        Header: "Designação da Disciplina",
        accessor: "label",
      },
    ],
    [dataTable]
  );

  return (
    <>
      <Stack spacing={5} mb={5}>
        <Table columns={columns} data={dataTable} isPaginated={false} />
      </Stack>
    </>
  );
}
