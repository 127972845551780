export default class SpecificationYear {
    static isSatisfiedBy(obj){
        const sumOfValue = (Number(obj.validateYear[1]) - Number(obj.validateYear[0]))
        obj.validateYear = obj.validateYear.join("")
        const regex = new RegExp(obj.regexFormatYear)
        let isValid = (Number(obj.validateYear.replaceAll(" ", "")) > 2000 && regex.test(obj.year?.replaceAll("_", "")))        
        if(obj.validateYear.length === 8){
            isValid = isValid && ((Number(obj.validateYear[0]) > Number(obj.validateYear[1])) && sumOfValue === 1)
        }
         return isValid
    }
}