import { Box, Button, Heading, Text } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Toast } from "../../components/Toast";
import FormTabInfo from "./Forms/FormTabInfo";
import api from "../../utils/api";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { academicYearSchema } from "./academicYearSchema";
import { ZodError } from "zod";
import { ACADEMIC_YEAR_URL } from "../../utils/endpoints";
import { generateCode } from "../../utils/generateCode";
import FormTabInfoResume from "./Forms/FormTabInfoResume";
import Alert from "../../components/Alert";
import SpecificationYear from "../../helpers/specificationYear";
export default function FormAcademicYear({ isDisabled, setIsDisabled }) {
  const navigate = useNavigate();

  const [needsInitialValue, setNeedsInitialValue] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [isOpenBack, setIsOpenBack] = useState(false);
  const [regexFormatYear, setRegexFormatYear] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setError,
    getValues,
    setValue,
    clearErrors,
  } = useForm({
    resolver: zodResolver(academicYearSchema),
  });
  const { addToast } = Toast();
  const year = watch("year");
  const yearFormat = watch("yearFormat");
  const onSubmit = async (data) => {
    const code = generateCode(1, 400);

    try {
      const res = await api.post(ACADEMIC_YEAR_URL, {
        ...data,
      });
      if (res.status === 200) {
        navigate("/anos-lectivos");
        addToast({
          title: "Ano Lectivo cadastrado com sucesso!",
          status: "success",
        });
      }
    } catch (e) {
      let errors = e.response.data.errors;
      for (let err in errors) {
        addToast({ title: errors[err], status: "error" });
      }
    }
  };

  const handleBack = () => {
    navigate("/anos-lectivos");
  };
  const handleValidateAndChangeTab = () => {
    try {
      academicYearSchema.parse(getValues());
      clearErrors();
    } catch (e) {
      clearErrors();
      if (e instanceof ZodError) {
        e.issues.forEach((errors) => {
          console.log(errors);
          setError(errors.path[0], {
            type: "manual",
            message: errors.message,
          });
        });
      }
      return e.issues.length;
    }
  };
  const handleSetToResumePageOrShowErrors = (e) => {
    e.preventDefault();
    const numberOfErrors = handleValidateAndChangeTab();
    let validateYear = year?.replaceAll("_", "")?.split("/");
    console.log(year, regexFormatYear, validateYear);
    if (
      !SpecificationYear.isSatisfiedBy({ year, regexFormatYear, validateYear })
    ) {
      addToast({
        title: "Ano Lectivo informado é inválido!",
        status: "error",
      });
      return;
    }
    if (numberOfErrors !== undefined) {
      addToast({
        status: "info",
        title: `Tem ${numberOfErrors} campos obrigatórios não preenchidos`,
      });
    } else {
      setIsDisabled(true);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Button
        onClick={() => {
          setIsOpenBack(true);
        }}
        mt={5}
      >
        Voltar
      </Button>
      <Heading mt={5} mb={10}>
        Abrir Novo Ano Lectivo
      </Heading>
      {isDisabled ? (
        <>
          <Heading as="h1" textTransform="uppercase" mb={5}>
            Resumo | {year}
          </Heading>
          <FormTabInfoResume
            errors={errors}
            control={control}
            watch={watch}
            year={year}
            needsInitialValue={needsInitialValue}
            setValue={setValue}
            isCreating={false}
          />
        </>
      ) : (
        <>
          <FormTabInfo
            errors={errors}
            register={register}
            control={control}
            setValue={setValue}
            watch={watch}
            regexFormatYear={regexFormatYear}
            setRegexFormatYear={setRegexFormatYear}
          />
        </>
      )}

      <Box
        display="flex"
        width="100%"
        mt="30px"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          {isDisabled ? (
            <>
              <Button type="submit">Confirmar</Button>
              <Button
                ml="5"
                variant="secondary"
                onClick={() => {
                  setNeedsInitialValue(true);
                  setIsDisabled(false);
                }}
              >
                Voltar a editar
              </Button>
            </>
          ) : (
            <>
              {isDisabled === true ? (
                ""
              ) : (
                <Button onClick={handleSetToResumePageOrShowErrors}>
                  Abrir Novo Ano Lectivo
                </Button>
              )}
            </>
          )}
        </Box>
      </Box>
      <Alert
        isOpen={isOpenBack}
        setIsOpen={setIsOpenBack}
        text="Tem certeza que prentende voltar?"
        title="Os dados não salvos serão perdidos"
        handleDelete={() => {
          navigate(-1);
        }}
        buttonText="Voltar"
      />
    </form>
  );
}
