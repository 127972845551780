import { PAGE_SIZE } from "../../utils/constants";

export const getApiUrl = (url, data) => {
  let apiUrl = `${url}?`;

  if (data.search) {
    apiUrl += `&Search=${data.search}`;
  }

  if (data.typology?.value) {
    apiUrl += `&Typology=${data.typology?.value}`;
  }
  if (data.from) {
    let modifiedDate = new Date(data.from);
    modifiedDate.setHours(modifiedDate.getHours() + 1);
    let isoFromDate = modifiedDate.toISOString();
    apiUrl += `&FromDate=${isoFromDate}`;
  }
  if (data.until) {
    let modifiedDate = new Date(data.until);
    modifiedDate.setHours(23 + 1);
    modifiedDate.setMinutes(59);
    modifiedDate.setSeconds(59);
    modifiedDate.setMilliseconds(999);
    let isoUntilDate = modifiedDate.toISOString();
    apiUrl += `&UntilDate=${isoUntilDate}`;
  }

  apiUrl += `&PageSize=${PAGE_SIZE}`;

  return apiUrl;
};
