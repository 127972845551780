/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import Table from "../../components/Table";
import { useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Box, Center, Heading, Spinner, Text } from "@chakra-ui/react";

import { Switch } from "@chakra-ui/react";

import { Checkbox } from "@chakra-ui/react";
import { DIALOG_MESSAGE, NO_RESULTS } from "../../utils/constants";
import { BsTrash } from "react-icons/bs";
import {
  DELETE_LOOKUP_ITEM,
  ENABLE_DISABLE_LOOKUP_ITEM,
} from "../../utils/endpoints";
import api from "../../utils/api";
import { lookUps } from ".";
import Alert from "../../components/Alert";
import { Toast } from "../../components/Toast";
import { EditIcon } from "@chakra-ui/icons";
import { formStyles } from "../../utils/styles";

export default function TableItems({
  setIdUpdateItem,
  setDataTable,
  dataTable,
  isLoaded,
  setIsLoaded,
  isOpenUpdate,
  setIsOpenUpdate,
  setTitle,
  setValue,
  setButtonTitle,
}) {
  const { table } = useParams();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [idItem, setIdItem] = useState(0);
  const { addToast } = Toast();
  const handleDeleteItem = async () => {
    try {
      await api.delete(DELETE_LOOKUP_ITEM, {
        data: {
          entityType: lookUps[table - 1].entityName,
          id: idItem,
        },
      });
      const response = await lookUps[table - 1].getFunction({
        OnlyActive: false,
        PageSize: 2500,
      });
      setDataTable(response);
      setIsAlertOpen(false);
      addToast({
        status: "success",
        title: "Item eliminado com sucesso",
      });
    } catch (e) {
      console.log(e);
    }
  };
  const handleOnChangeStateItem = async (id, enabled) => {
    try {
      await api.post(ENABLE_DISABLE_LOOKUP_ITEM, {
        entityType: lookUps[table - 1].entityName,
        id,
        enabled: enabled,
      });
      const response = await lookUps[table - 1].getFunction({
        OnlyActive: false,
        PageSize: 2500,
      });
      setDataTable(response);
      addToast({
        title: "Estado do item alterado com sucesso",
        status: "success",
      });
    } catch (e) {
      addToast({
        title: "Ocorreu um erro ao alterar o estado do item",
        status: "error",
      });
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "value",
      },
      {
        Header: "Nome do Item",
        accessor: "label",
      },

      {
        Header: "Código",
        accessor: "code",
      },
      {
        Header: "Estado",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <Switch
              isChecked={item.data[item.row.index].isActive}
              onChange={() => {
                handleOnChangeStateItem(
                  item.data[item.row.index].value,
                  !item.data[item.row.index].isActive
                );
              }}
              size="sm"
            />
          );
        },
      },
      {
        Header: "Editar",
        accessor: "edit",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <Center>
              <Link>
                <EditIcon
                  onClick={() => {
                    setButtonTitle("Actualizar");
                    setIdUpdateItem(item.data[item.row.index].value);
                    setValue("name", item.data[item.row.index].label);
                    setValue("code", item.data[item.row.index].code);
                    setIsOpenUpdate(true);
                    setTitle("Actualizar item");
                  }}
                />
              </Link>
            </Center>
          );
        },
      },
      {
        Header: "Excluir",
        accessor: "delete",
        disableSortBy: true,
        Cell: (item) => {
          return item.row.original.isDeletable === false ? (
            <Center _hover={{ cursor: "not-allowed" }}>
              <BsTrash />
            </Center>
          ) : (
            <Center>
            <Link>
              <BsTrash
                onClick={() => {
                  setIdItem(item.data[item.row.index].value);
                  setIsAlertOpen(true);
                }}
              />
            </Link>
          </Center>
          );
        },
      },
    ],
    []
  );

  const [selectedIds, setSelectedIds] = useState([]);

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  return (
    <>
      <>
        {dataTable ? (
          dataTable.length > 0 ? (
            <Table
              setIsLoaded={setIsLoaded}
              isLoaded={isLoaded}
              setData={setDataTable}
              getFunction={lookUps[table - 1].getFunction}
              columns={columns}
              data={dataTable}
              isPaginated={false}
            />
          ) : (
            <Box width="100%" display="flex" sx={formStyles}>
              <Text>{NO_RESULTS}</Text>
            </Box>
          )
        ) : (
          <Spinner />
        )}
      </>
      <Alert
        isOpen={isAlertOpen}
        setIsOpen={setIsAlertOpen}
        text={DIALOG_MESSAGE}
        title="Excluir item"
        handleDelete={handleDeleteItem}
        buttonText="Excluir"
      />
    </>
  );
}
