import { useParams } from "react-router-dom";

import { FormSchool } from "./Forms";
import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";
import { schoolSchema } from "./Forms/schoolSchema";

export default function EditSchool() {
  const [isDisabled, setIsDisabled] = useState(true);
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
    unregister,
  } = useForm({
    resolver: zodResolver(schoolSchema),
  });

  return (
    <>
      <FormSchool
        isDisabled={isDisabled}
        setIsDisabled={setIsDisabled}
        watch={watch}
        id={id}
        setValue={setValue}
        control={control}
        errors={errors}
        register={register}
        handleSubmit={handleSubmit}
        unregister={unregister}
        getValues={getValues}
      />
    </>
  );
}
