import { Box, Flex } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { ChevronRightIcon } from "@chakra-ui/icons";

import {
  borderRadius,
  menuItemStyles,
  menuItemActiveStyles,
} from "../../utils/styles";

function MenuItem({ title, link, icon }) {
  return (
    <Box w="100%" py="2px">
      <NavLink
        to={link}
        style={({ isActive }) => {
          return isActive ? menuItemActiveStyles : menuItemStyles;
        }}
        end
      >
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center">
            <Box bgColor="#fff" borderRadius={borderRadius} p={1} mr={2}>
              {icon}
            </Box>
            {title}
          </Flex>
          <ChevronRightIcon />
        </Flex>
      </NavLink>
    </Box>
  );
}

export default MenuItem;
