import { Box, Button, Flex, Heading, HStack, Tooltip } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Toast } from "../../components/Toast";
import FormEditTabInfo from "./FormsEdit/FormTabInfo";
import api from "../../utils/api";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { academicYearSchema } from "./academicYearSchema";

import { ACADEMIC_YEAR_URL } from "../../utils/endpoints";
import FormEditTabInfoResume from "./Forms/FormTabInfoResume";
import { MdOutlineQuestionMark } from "react-icons/md";
import Alert from "../../components/Alert";
import { getAcademicYearById } from "../../services/getAcademicYearById";
import SpecificationYear from "../../helpers/specificationYear";
import { getAcademicYearFormat } from "../../services/getAcademicYearFormat";

import { AuthContext } from "../../provider/AuthProvider";
import { CrumbContext } from "../../provider/CrumbProvider";
import { formStyles } from "../../utils/styles";
import { BsQuestionCircle } from "react-icons/bs";
import { DIALOG_MESSAGE } from "../../utils/constants";
export default function EditAcademicYear() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenBack, setIsOpenBack] = useState(false);
  const [regexFormatYear, setRegexFormatYear] = useState([]);
  const [formatYear, setFormatYear] = useState("xxxx/xxxx");

  const { id } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    resolver: zodResolver(academicYearSchema),
  });
  const { addToast } = Toast();
  const { auth } = useContext(AuthContext);
  const { crumb, setCrumb } = useContext(CrumbContext);

  const year = watch("year");
  const yearFormat = watch("yearFormat");

  useEffect(() => {
    const getData = async () => {
      const response = await getAcademicYearById(id);

      setValue("year", response.year);
      setValue("yearFormat", response.yearFormat);
      setCrumb({
        ...crumb,
        academicYear: response.year,
      });

      setValue("year", response.year);
      setValue("yearFormat", response.yearFormat);
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      const responseFormatYear = await getAcademicYearFormat(yearFormat);
      setRegexFormatYear(
        responseFormatYear.filter((e) => e.value === yearFormat)[0].regex
      );
      setFormatYear(
        responseFormatYear
          .filter((e) => e.value === yearFormat)[0]
          .code.replaceAll("y", "9")
      );
    };
    getData();
  }, [yearFormat]);
  const handleDeleteAcademicYear = async () => {
    try {
      await api.delete(`${ACADEMIC_YEAR_URL}/${id}`);
      navigate("/anos-lectivos");
      addToast({
        title: "Ano Lectivo Excluído com sucesso!",
        status: "success",
      });
    } catch (e) {
      let errors = e.response.data.errors;
      for (let err in errors) {
        addToast({ title: errors[err], status: "error" });
      }
    }
  };

  const onSubmit = async (data) => {
    let validateYear = year?.replaceAll("_", "")?.split("/");
    if (
      !SpecificationYear.isSatisfiedBy({ year, regexFormatYear, validateYear })
    ) {
      addToast({
        title: "Ano Lectivo informado é inválido!",
        status: "error",
      });
      return;
    }

    try {
      const res = await api.patch(`${ACADEMIC_YEAR_URL}/editAcademicYear`, {
        ...data,
        id,
      });

      if (res.status === 200) {
        navigate("/anos-lectivos");
        addToast({
          title: "Ano Lectivo Actualizado com sucesso!",
          status: "success",
        });
      }
    } catch (e) {
      console.log(e);
      let errors = e.response.data.errors;
      for (let err in errors) {
        addToast({ title: errors[err], status: "error" });
      }
    }
  };

  const [isToBeEdited, setIsToBeEdited] = useState(true);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isToBeEdited ? (
        <>
          <Heading as="h1" textTransform="uppercase" mb={5}>
            Resumo | {year}
          </Heading>
          <FormEditTabInfoResume
            errors={errors}
            control={control}
            watch={watch}
            year={year}
          />
        </>
      ) : (
        <>
          <Heading as="h1" textTransform="uppercase" mb={5}>
            Editar
          </Heading>

          <Heading as="h2" fontSize="md" py="4">
            Informações do Ano Lectivo
          </Heading>

          <Box sx={formStyles}>
            <FormEditTabInfo
              errors={errors}
              register={register}
              control={control}
              isToBeEdited={isToBeEdited}
              setValue={setValue}
              year={year}
              yearFormat={yearFormat}
              setFormatYear={setFormatYear}
              formatYear={formatYear}
            />
          </Box>
        </>
      )}

      <Box
        display="flex"
        width="100%"
        mt="5"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          {isToBeEdited ? (
            <>
              <Button
                onClick={() => {
                  setIsToBeEdited(false);
                  console.log(errors);
                }}
              >
                Editar Ano Lectivo
              </Button>
            </>
          ) : (
            ""
          )}

          {!isToBeEdited ? (
            <>
              <Button
                type="submit"
                onClick={() => {
                  console.log(errors);
                }}
              >
                Guardar
              </Button>
              <HStack mt={3}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  Excluir
                </Button>
                <Tooltip
                  hasArrow
                  label="Esta acção exclui, de forma permanente, este utilizador do sistema"
                >
                  <Flex alignItems="center" cursor="pointer">
                    <BsQuestionCircle />
                  </Flex>
                </Tooltip>
              </HStack>
            </>
          ) : (
            ""
          )}
        </Box>
      </Box>
      <Alert
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        text={DIALOG_MESSAGE}
        title="Excluir Ano Académico"
        handleDelete={handleDeleteAcademicYear}
        buttonText="Excluir"
      />
      <Alert
        isOpen={isOpenBack}
        setIsOpen={setIsOpenBack}
        text="Tem certeza que prentende voltar?"
        title="Os dados não salvos serão perdidos"
        handleDelete={() => {
          navigate(-1);
        }}
        buttonText="Voltar"
      />
    </form>
  );
}
